/*
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

// Multi-select component using react-select

const MultiSelectFieldWithCheckboxes = ({ options }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

const [activeOptions, setActiveOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    const tmpOtions = options.map((parent) => {
      return {
        label: parent.label,
        value: parent.value,

        options: parent.children.map((child) => ({
          value: child.value,
          label: child.label,
        })),
      };
    });
    setActiveOptions(tmpOtions);
  }, [options]);

  const handleChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);
  };

  const handleParentCheckboxChange = (parentOption, checked) => {
    let updatedSelectedValues = selectedValues.filter(
      (item) =>
        !parentOption.options.some((option) => option.value === item.value)
    );

    if (checked) {
      updatedSelectedValues = [
        ...updatedSelectedValues,
        ...parentOption.options,
      ];
    }

    setSelectedValues(updatedSelectedValues);
    handleChange(updatedSelectedValues);
  };

  const handleChildCheckboxChange = (childOption, checked) => {
    let updatedSelectedValues;
    if (checked) {
      updatedSelectedValues = [...selectedValues, childOption];
    } else {
      updatedSelectedValues = selectedValues.filter(
        (item) => item.value !== childOption.value
      );
    }
    setSelectedValues(updatedSelectedValues);
    handleChange(updatedSelectedValues);
  };

  const customOption = ({ data, isSelected, onChange }) => (
    <div
      style={{ paddingLeft: "20px", paddingTop: "5px", paddingBottom: "5px" }}
    >
      <input
        type="checkbox"
        value={data.value}
        checked={isSelected}
        onChange={() => handleChildCheckboxChange(data, !isSelected)} // Use handleChildCheckboxChange here
        style={{ marginRight: "8px" }}
      />
      {data.label}
    </div>
  );
  const customParentOption = ({ data, isSelected, onChange }) => (
    <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
      <input
        type="checkbox"
        value={data.value}
        checked={isSelected}
        onChange={() => handleParentCheckboxChange(data, !isSelected)} // Use handleParentCheckboxChange here
        style={{ marginRight: "8px" }}
      />
      <strong>{data.label}</strong>
    </div>
  );

  return (
    <div className="App">
      {console.log("SelectedValues", selectedValues)}

      {
        JSON.stringify(selectedOptions)
      }
      <h1>MultiCheck Dropdown with Tags</h1>
      <Select
        options={activeOptions}
        value={selectedValues}
        onChange={handleChange}
        isMulti
        closeMenuOnSelect={false}
        components={{
          Option: customOption,
          GroupHeading: customParentOption,
        }}
        styles={{
          multiValue: (base) => ({
            ...base,
            backgroundColor: "lightblue",
            marginRight: "5px",
          }),
          groupHeading: (base) => ({ ...base, fontWeight: "bold" }),
          option: (base) => ({ ...base, paddingLeft: "20px" }),
        }}
      />
    </div>
  );
}


 

export default MultiSelectFieldWithCheckboxes;
*/



import React, { useState, useEffect } from 'react';
import Select from 'react-select';

// Multi-select component using react-select
const MultiSelectFieldWithCheckboxes = ({ options }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [activeOptions, setActiveOptions] = useState([]);

  useEffect(() => {
    // Ensure that we include parents without children, and structure the options accordingly
    const tmpOptions = options.map((parent) => ({
      label: parent.label,
      value: parent.value,
      options: parent.children && parent.children.length > 0 ? parent.children.map((child) => ({
        value: child.value,
        label: child.label,
      })) : [],
    }));
    setActiveOptions(tmpOptions);
  }, [options]);

  const handleChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);
  };

  const handleParentCheckboxChange = (parentOption, checked) => {
    let updatedSelectedValues = selectedValues.filter(
      (item) =>
        !parentOption.options.some((option) => option.value === item.value)
    );

    if (checked) {
      updatedSelectedValues = [
        ...updatedSelectedValues,
        ...parentOption.options,
      ];
    }

    setSelectedValues(updatedSelectedValues);
    handleChange(updatedSelectedValues);
  };

  const handleChildCheckboxChange = (childOption, checked) => {
    let updatedSelectedValues;
    if (checked) {
      updatedSelectedValues = [...selectedValues, childOption];
    } else {
      updatedSelectedValues = selectedValues.filter(
        (item) => item.value !== childOption.value
      );
    }
    setSelectedValues(updatedSelectedValues);
    handleChange(updatedSelectedValues);
  };

  const customOption = ({ data, isSelected, onChange }) => (
    console.log("CUSTOM OPTION FUNCITON TRIGGERED --- ", data),
    <div
      style={{ paddingLeft: '20px', paddingTop: '5px', paddingBottom: '5px' }}
    >
      <input
        type="checkbox"
        value={data.value}
        checked={isSelected}
        onChange={() => handleChildCheckboxChange(data, !isSelected)}
        style={{ marginRight: '8px' }}
      />
      {data.label}
    </div>
  );

  const customParentOption = ({ data, isSelected, onChange }) => (
    <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
      <input
        type="checkbox"
        value={data.value}
        checked={isSelected}
        onChange={() => handleParentCheckboxChange(data, !isSelected)}
        style={{ marginRight: '8px', marginLeft: '10px' }}
      />
      <strong>{data.label}</strong>
    </div>
  );

  return (
    <div className="App">
      <h1>MultiCheck Dropdown with Tags</h1>
      {
        JSON.stringify(activeOptions)
      }
      <Select
        options={activeOptions}
        value={selectedValues}
        onChange={handleChange}
        isMulti
        closeMenuOnSelect={false}
        components={{
          Option: customOption,
          GroupHeading: customParentOption,
        }}
        styles={{
          multiValue: (base) => ({
            ...base,
            backgroundColor: 'lightblue',
            marginRight: '5px',
          }),
          groupHeading: (base) => ({ ...base, fontWeight: 'bold' }),
          option: (base) => ({ ...base, paddingLeft: '20px' }),
        }}
      />
    </div>
  );
};

export default MultiSelectFieldWithCheckboxes;
