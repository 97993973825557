//GiftCardRecipients
import { useEffect, useState } from "react";
import { SelectField, CustomButton } from "../HtmlComponents";
import { getCurrentDate, getPreviousMonth, getCurrentMonth, convertDateintoUnix, getLastDayOfMonth } from '../../helpers';
import { createReferrerGiftCardReport } from '../../api';

import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../global/Notifications';
import SearchByDateAndCreditRep from "../global/SearchForms/SearchByDateAndCreditRep";
import SearchByDate from "../global/SearchForms/SearchByDate";
import { useEntityType, useToken } from "../../hooks/useAuth";

const GiftCardRecipients = () => {
    const [filter, setFilter] = useState({ startDate: 0, endDate: 0, creditRepId: 0 });
    const [processing, setProcessing] = useState(false);
    const entityType = useEntityType();
    const [allowSubmission, setAllowSubmission] = useState(false);

    var token = useToken();


    const updateHandler = (name, value) => {
        switch (name) {
            case "dateFilter":
                setFilter({ ...filter, "startDate": value["startDate"], "endDate": value["endDate"] });
                callApi({startDate: value["startDate"], endDate: value["endDate"], creditRepId: 0});
                break;
            case "searchParams":
                //NAME IS MODIFIED FOR GENERALIZATION BUT ITS ONLY APPLIED IN SearchByDateAndCreditRep FOR NOW
                setFilter({ ...filter, "startDate": value["startDate"], "endDate": value["endDate"], "creditRepId": value["selectedCreditRepId"] });
                callApi({startDate: value["startDate"], endDate: value["endDate"], creditRepId: value["selectedCreditRepId"] || 0 });
                break;
        }
    }



    const callApi = async (payload) => {

        setProcessing(true);
        console.log(payload);

        //MAKE API REQUEST
        var resp = await createReferrerGiftCardReport(payload, token);
        console.log("API resp:: ", resp);
        if (resp["error"]) {
            Notifications("error", resp["message"] || "Unable to process the request. ")
        }
        else {
            var pdfBytes = resp["referrerGroup"]["pdfBytes"];
            //PDF-BYTES IS AN OBJECT WITH KEYS AND VALUES, ADD ALL VALUES TO ARRAY                  
            const bytes = new Uint8Array(Object.values(pdfBytes));
            const blob = new Blob([bytes], { type: "application/pdf" });
            const docUrl = URL.createObjectURL(blob);

            let alink = document.createElement('a');
            alink.href = docUrl;
            alink.download = 'Gift Card Report.pdf';
            alink.click();
            Notifications("success", resp["message"] || "Downloaded Successfully. ")
        }
        setProcessing(false);


    }

    return (<>

        <div id="kt_content_container" className="container-xxl">
            <div className="row g-12 g-xl-12">
                <div className="col-xl-12">
                    <div className="row">
                        {
                            entityType === "company" ?
                                <SearchByDateAndCreditRep callback={updateHandler} /> :
                                <SearchByDate callback={updateHandler} />
                        }
                    </div>
                </div>
            </div>
        </div>
        {processing ? <LoadingScreen /> : ""}

    </>)
}

export default GiftCardRecipients;