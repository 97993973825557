import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { getFolders, getFiles, updateFile } from "../../api";
import { useRole, useToken, useEntityType, useEntityId } from '../../hooks/useAuth';
import { SelectField } from "../HtmlComponents";
import { useParams } from "react-router-dom";

import UploadFile from "./UploadFile";
import FileListItem from './FileListItem';
import LoadingScreen from '../../components/global/LoadingScreen';
import Notifications from '../../components/global/Notifications';
import DecideErrorPage from "../../components/HtmlComponents/errors/DecideErrorPage";
import SelectFieldGroup from '../HtmlComponents/Fields/SelectFieldGroup';

import { useMethodAllowed } from '../../hooks/usePermission';

//IGNORE THE ENABLE UPLAOD PARAM AND GET THE VALUE FROM PERMISSION | USE ALLOWED HOOK

const Files = ({ entityType, entityId, enableUpload }) => {

    //const [foldersGroup, setFoldersGroup] = useState([]);                   //IF ENTITY TYPE IS APPLICATIONS THEN NESTED FOLDERS 
    const [folders, setFolders] = useState([]);                             //IF ENTITY TYPE IS NOT APPLICATION THEN PLAIN LIST OF FOLDERS
    const [selectedFolderId, setSelectedFolderId] = useState(0);           //TO FILTER FILES AGAINST SELECTED FOLDER
    const [files, setFiles] = useState([]);     //ALL FILES FROM ALL FOLDERS (UNDER SAME ENTITY TYPE)
    const [filteredFiles, setFilteredFiles] = useState([]);  //FILTER FILES IF FOLDER IS SELECTED
    const [previousDocumentsFolderId, setPreviousDocumentsFolderId] = useState(0);  //FOR NOW IT WILL BE USED FOE LENDER TYPE FILES TO STOP LISTING IF ALL FILES ARE SELECTED -IN FUTURE IT MAY APPLIED TO OTHER CASES
    // const [parentFolders, setParentFolders ] = useState({});  //LIST OF 

    const [processing, setProcessing] = useState(true);        //IF UPDATING OR DELETING A FILE
    const [serverStatusCode, setServerStatusCode] = useState(null);

    const token = useToken();
    var uploadFilesAllowed = useMethodAllowed(entityType, "files", "create");  //UPLOAD FILE PERMISSION
    var updateFilesAllowed = useMethodAllowed(entityType, "files", "edit");    //EDIT FILE PERMISSION(UPDATE FOLDER ETC )
    var deleteFilesAllowed = useMethodAllowed(entityType, "files", "delete");  //DELETE FILES PERMISSION 

    const userEntityId = useEntityId();
    const userRole = useRole();
    const userEntityType = useEntityType();

    //LENDER
    uploadFilesAllowed = userEntityType === "lender" && userRole == "user" ? true : uploadFilesAllowed;
    updateFilesAllowed = userEntityType === "lender" && userRole == "user" ? true : updateFilesAllowed;
    deleteFilesAllowed = userEntityType === "lender" && userRole == "user" ? true : deleteFilesAllowed;


    //REFERRER
    uploadFilesAllowed = userEntityType === "referrer" && userRole == "user" ? true : uploadFilesAllowed;



    const fetchFolders = async () => {
        var tmpFolderGroups = [];
        var folders = await getFolders(token, entityType);
        if (folders["error"] == 0) {

            //FOLDER GROUP(TREE STRUCTURE- NESTED FODLERS) RETURNS IN THE CASE OF APPLICATION ENTITY TYPE
            if (entityType === "application") {
                //DEFAULT GROUP WITH ALL FILES OPTION
                tmpFolderGroups.push({ label: "Default", options: [{ label: "All Files", value: 0 }] });

                Object.keys(folders["folders"]).map(singleFolderGroupIndex => {
                    let tmpFolderGroup = { label: folders["folders"][singleFolderGroupIndex]["label"], options: [] };
                    var singleFolderGroupOptions = folders["folders"][singleFolderGroupIndex]["children"];

                    if (Array.isArray(singleFolderGroupOptions)) {
                        singleFolderGroupOptions.map(singleOption => {
                            tmpFolderGroup["options"].push({ label: singleOption["label"], value: singleOption["id"] })
                        })
                    }
                    tmpFolderGroups.push(tmpFolderGroup);
                });
                setFolders(tmpFolderGroups);
            }
            else {
                var folders = await getFolders(token, entityType);
                var options = [];
                options.push({ value: 0, label: entityType === "lender" ? "All Files except Previous Documents" : "All Files" })

                if (folders["folders"] !== undefined && Array.isArray(folders["folders"])) {
                    folders["folders"].map(folder => {
                        options.push({ value: folder.id, label: folder.label });
                    })
                    setFolders(options);
                    findPreviousDocumentsFolderId(folders["folders"]);
                }
            }
        }
    }
    const fetchFiles = async () => {
        //ADD QUERY PARAMS TO LAST PARAM IF REFERRER USER 
        var thirdParam = entityId;
        if (userEntityType == "referrer") {
            var selectedReferrerId = localStorage.getItem("selectedReferrer");
            thirdParam = entityId + `?referrerId=${selectedReferrerId}`;
        }

        var response = await getFiles(token, entityType, thirdParam);
        setServerStatusCode(response["httpCode"]);
        setProcessing(false);
        setFiles(response["data"]);
    }
    const filterFiles = () => {
        if (selectedFolderId == 0) { setFilteredFiles(files); }
        else {
            var tempFilteredFiles = [];
            files.map(file => { if (file.folderId === selectedFolderId) { tempFilteredFiles.push(file); } });
            setFilteredFiles(tempFilteredFiles);
        }
    }

    //FIND THE PREVIOUS DOCUMENTS FOLDER ID AND UPDATE THE STATE - lFolders=> local folders
    //IT IS CALLED WHEN THE FOLDERS ARE LOADED FIRST TIME AND ONLY FOR PLAIN FOLDERS LIST
    const findPreviousDocumentsFolderId = (lFolders) => {
        let previousDocumentsFolderIdTmp = 0; 
        if (Array.isArray(lFolders) && lFolders.length > 0) {
            var findFolder = lFolders.find(singleFolder => singleFolder.name == "previousDocuments"); 
            previousDocumentsFolderIdTmp = findFolder !== undefined && findFolder["id"] !== undefined ? findFolder["id"] : 0;
        }
        setPreviousDocumentsFolderId(previousDocumentsFolderIdTmp);
    }

    const updateHandler = async (name, value) => {
        console.log(name, value);
        switch (name) {
            //FILTER FILES AND DISPLAY ONLY THOSE FILES THAT BELONGS TO SELECTED FOLDER
            case "selectedFolder":
                setSelectedFolderId(value);
                break;
            case "updateFileFolder":
                setProcessing(true);
                var currentFolderId = value.currentFolderId;
                var fileObj = value.file;
                var response = await updateFile(token, entityType, entityId, fileObj.id, fileObj)

                //FOLDER IS UPDATED SUCCESSFULLY   
                if (response["error"] == undefined) {
                    //UPDATE THE FILES STATE
                    var updatedFilesState = files.map(file => {
                        if (file.id === fileObj.id) {
                            return { ...file, folderId: fileObj.folderId }
                        }
                        return file;
                    })
                    setFiles(updatedFilesState);
                    Notifications("success", "Updated Successfully")
                }
                else {
                    //SOME ERROR OCCURED ABD FOLDER COULD NOT UPDATED
                    Notifications("error", response["message"] || "Could not update the folder.")
                }
                setProcessing(false);
                break;
            case "deleteFile":
                setProcessing(true);
                var fileObj = value.file;

                var response = await updateFile(token, entityType, entityId, fileObj.id, fileObj)
                if (response["error"] == undefined) {
                    //UPDATE THE FILES STATE
                    fetchFiles();
                    Notifications("success", "Deleted Successfully")
                }
                else {
                    //SOME ERROR OCCURED ABD FOLDER COULD NOT UPDATED
                    Notifications("error", response["message"] || "Could not perform this action.")
                }
                setProcessing(false);
                break;
            case "updateFileLabel":
                setProcessing(true);
                var fileObj = value.file;

                var response = await updateFile(token, entityType, entityId, fileObj.id, fileObj)
                if (response["error"] == undefined) {
                    //UPDATE THE FILES STATE
                    fetchFiles();
                    Notifications("success", "Updated Successfully")
                }
                else {
                    //SOME ERROR OCCURED ABD FOLDER COULD NOT UPDATED
                    Notifications("error", response["message"] || "Could not perform this action.")
                }
                setProcessing(false);

                break;
            case "refreshFileListing":
                fetchFiles();
                break;

        }
    }

    //CALL FILE LIST ITEM COMPONENT - BUT IT APPLIES SOME CONDITIONS BEFORE CALLING
    const CallFileListItem = ({ singleFile }) => {
        switch (entityType) {
            case "lender":
                //IF NO FOLDER IS SELECTED THEN DO NOT LIST THE FILES BELONGS TO PREVIOUS DOCUMENTS
                if (selectedFolderId === 0 && singleFile.folderId !== undefined && singleFile.folderId === previousDocumentsFolderId) {  }
                else { return <FileListItem key={singleFile.id} singleFile={singleFile} folders={folders} entityType={entityType} entityId={entityId} filesCallback={updateHandler} updateFilesAllowed={updateFilesAllowed} deleteFilesAllowed={deleteFilesAllowed} /> }
                break;
            default:
                //DEFAULT - FILES LISTING FOR ALL OTHERS EXCEPT LENDERS
                return <FileListItem key={singleFile.id} singleFile={singleFile} folders={folders} entityType={entityType} entityId={entityId} filesCallback={updateHandler} updateFilesAllowed={updateFilesAllowed} deleteFilesAllowed={deleteFilesAllowed} />
                break;
        }
    }

    useEffect(() => {
        fetchFolders();
        fetchFiles();
    }, []);

    //on selectedFolder change, hide other files from options
    useEffect(() => {
        //FILTER FILES AGAINST SELECTED FOLDER
        filterFiles();
    }, [selectedFolderId, files])

    return (
        <>
            {processing ? <LoadingScreen /> : (serverStatusCode != 200 ? <DecideErrorPage errorCode={serverStatusCode} /> :
                <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                    {
                        uploadFilesAllowed ? <UploadFile entityId={entityId} entityType={entityType} folderIdRequired={entityType === "application" ? true : false} selectedFolderId={entityType === "application" && userEntityType === "referrer" ? 44 : selectedFolderId} callback={updateHandler} /> : ""
                    }

                    <div className='' style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row" }}>
                        <div style={{ maxWidth: "300px", minWidth: "250px", marginBottom: "20px" }}>
                            {/**  FOLDER DROP DOWN START  */}
                            {entityType !== "application" ?
                                <SelectField
                                    fieldName="selectedFolder"
                                    defaultSelectedOption={{ value: selectedFolderId, label: "" }}
                                    options={folders}
                                    setFieldValue={updateHandler}
                                    callback=""
                                /> : ""}

                            {
                                entityType === "application" ?
                                    <SelectFieldGroup
                                        fieldName="selectedFolder"
                                        options={folders}
                                        defaultSelectedOption={{ value: selectedFolderId, label: "" }}
                                        setFieldValue={updateHandler}
                                    /> : ""

                            }
                            {/**  FOLDER DROP DOWN END  */}
                        </div>
                    </div>
                    <div className="card" data-select2-id="select2-data-132-dpd0">
                        <div>
                            <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div className="table-responsive" style={{ overflow: "unset" }}>
                                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                        <thead>
                                            <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                                <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Name</th>
                                                <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Date</th>
                                                {updateFilesAllowed ? <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "30%" }} >Folder</th> : ""}
                                                {deleteFilesAllowed ? <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" >Actions</th> : ""}
                                            </tr>
                                        </thead>
                                        <tbody className="fw-bold text-gray-600">
                                            {
                                                filteredFiles && Array.isArray(filteredFiles) && filteredFiles.length > 0 ?
                                                    filteredFiles.map(singleFile => (
                                                        <CallFileListItem singleFile={singleFile} />
                                                        /*<FileListItem key={singleFile.id} singleFile={singleFile} folders={folders} entityType={entityType} entityId={entityId} filesCallback={updateHandler} updateFilesAllowed={updateFilesAllowed} deleteFilesAllowed={deleteFilesAllowed} /> */
                                                    )) : <tr>
                                                        <td colSpan={5}> <p> No File found, Please upload</p> </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>

    )
}

export default Files;
