import { IoCloseCircle, IoCopySharp, IoPersonAdd, IoDocumentSharp } from "react-icons/io5";
import { MdDeleteForever, MdGroupAdd , MdModeEditOutline, MdThumbDown} from "react-icons/md";
import {TiTick} from "react-icons/ti";
import {AiFillPrinter, AiOutlineCheck, AiOutlineCloseCircle , AiOutlineLogout , AiOutlineMenu} from "react-icons/ai";
import { FaArrowLeft, FaSort, FaFolderPlus, FaFolder } from "react-icons/fa";
import {ImSwitch , ImPrinter} from "react-icons/im";
import DownArrowIcon from "./DownArrowIcon";



import { BsFillPlusSquareFill } from "react-icons/bs";

const FilledTick = () => {
   return  <TiTick />
}
const FilledTick2 = () => {
   return  <AiOutlineCheck />
}

const CancelIcon = () => {
   return  <AiOutlineCloseCircle />
}

const LeftArrowIcon = () => {
   return  <FaArrowLeft />
}

const SwitchOff = () => {
   return <ImSwitch />
}

const LogOut =({style}) =>{
   return <AiOutlineLogout style= {style} />
}
const ClosedMenuIcon =({style}) =>{
   return <AiOutlineMenu style= {style} />
}

const ThumbDown = () => {
   return <MdThumbDown style={{color: "red"}} />
}

const FileIcon = ({style}) => {
   return <IoDocumentSharp style={{style}} />
}

const Printer = ({style}) =>{
   return <ImPrinter />
}

const PlusIcon = ({style}) => {
   return <BsFillPlusSquareFill/>

}

const SortingIcon = ({iconStyle}) => {
   return <FaSort style={iconStyle}  />
}

const FolderPlusIcon = ({style}) => {
   return <FaFolderPlus style={style} />
}

const FolderIcon = ({style}) => {
   return <FaFolder style={style} />
}

export {DownArrowIcon , 
   CancelIcon ,
   ClosedMenuIcon,
   FilledTick , 
   FilledTick2 , 
   LeftArrowIcon, 
   LogOut,
   SwitchOff,
   ThumbDown,
   FileIcon,
   Printer,
   PlusIcon,
   SortingIcon,
   FolderPlusIcon,
   FolderIcon 
};