//GiftCardRecipientsText
import { useEffect, useRef, useState } from "react";
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup, CheckBox } from '../HtmlComponents';
import { createReferrerGiftCardReport, createSMS } from '../../api';
import { useEntityType, useToken } from "../../hooks/useAuth";
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';
import { Formik, Form, Field, FieldArray } from 'formik';
import SearchByDate from '../global/SearchForms/SearchByDate';
import SearchByDateAndCreditRep from "../global/SearchForms/SearchByDateAndCreditRep";

const GiftCardRecipientsText = () => {
    const [recipients, setRecipients] = useState({ list: [] });
    const [processing, setProcessing] = useState(false);
    const [filter, setFilter] = useState({ startDate: 0, endDate: 0, creditRepId: 0 });
    const [message, setMessage] = useState("Good afternoon {first_name}"
    +"Your National Finance + Loans Mastercard has been loaded with ${amount} for your (month) referrals."    
    +"The NFAL team thank you and appreciate your continued support. Happy spending!");

    const formRef = useRef();
    var token = useToken();

    const entityType = useEntityType(); 

    const handleAreaChange = (event) => { setMessage(event.target.value); }
    useEffect(() => { updateRecipientMessage(); }, [message])


    const updateHandler = (name, value) => {
        switch (name) {
            case "dateFilter":
                setFilter({ ...filter, "startDate": value["startDate"], "endDate": value["endDate"] });
                break;
            case "searchParams": 
                //NAME IS MODIFIED FOR GENERALIZATION BUT ITS ONLY APPLIED IN SearchByDateAndCreditRep FOR NOW
                setFilter({ ...filter, "startDate": value["startDate"], "endDate": value["endDate"], "creditRepId": value["selectedCreditRepId"] });

                break;    
        }
    }

    useEffect(() => {
        fetchRecipients();
    }, [filter])

    const fetchRecipients = async () => {
        var paylaod = { startDate: filter["startDate"], endDate: filter["endDate"], creditRepId: filter["creditRepId"] || 0,  pdfFile: false };

        //MAKE API REQUEST
        var response = await createReferrerGiftCardReport(paylaod, token);
 
        if (response["error"]) { 
            Notifications("error", response["message"] || "Error");
            setRecipients( prev => ({ ...prev, list: [] }));
        }
        else {
            if (response["referrerGroup"] != undefined && response["referrerGroup"]["records"] !== undefined && Array.isArray(response["referrerGroup"]["records"]) && response["referrerGroup"]["records"].length > 0) {
                setRecipients({ list: response["referrerGroup"]["records"] });
            } 
            else{  
                Notifications("error", "No records found.");
                setRecipients( prev => ({ ...prev, list: [] })); 
            }
        }
        setProcessing(false);
    }

    //UPDATE RECIPIENT MESSAGE
    const updateRecipientMessage = () => {
        try{
            const regexName = /{name}/ig;
            const regexMoney = /{amount}/ig;
            const regexSurname = /{surname}/ig;
            const regexFirstName = /{first_name}/ig;
            var tmpMessage = message;
            var tmpRecipients = JSON.parse(JSON.stringify(formRef.current.values));
            var newRecipients = [];
    
            if (tmpRecipients !== undefined && tmpRecipients !== null && tmpRecipients["list"] !== undefined && Array.isArray(tmpRecipients["list"]) && tmpRecipients["list"].length > 0) {
                tmpRecipients["list"].map(singleRecipient => {
                    let singleRecipientMessage = tmpMessage.replaceAll(regexName, singleRecipient["name"]);
                    singleRecipientMessage = singleRecipientMessage.replaceAll(regexMoney, singleRecipient["amount"]);
                    singleRecipientMessage = singleRecipientMessage.replaceAll(regexSurname, singleRecipient["surname"]);
                    singleRecipientMessage = singleRecipientMessage.replaceAll(regexFirstName, singleRecipient["firstName"]);
                    singleRecipient["message"] = singleRecipientMessage;
                    newRecipients.push(singleRecipient);
                })
                setRecipients({ list: newRecipients });
            }
        }
        catch(e){
            console.log("EXCEPTION - when replacing tags with values.")
        }
 
    }

    //SELECT/UNSELECT ALL RECIPIENTS
    const handleSelectAllRecipients = (e) => {
        var isCheckedAll = e.target.checked;
        var newRecipients = [];
        var tmpRecipients = JSON.parse(JSON.stringify(formRef.current.values));

        if (tmpRecipients !== undefined && tmpRecipients !== null && tmpRecipients["list"] !== undefined && Array.isArray(tmpRecipients["list"]) && tmpRecipients["list"].length > 0) {
            tmpRecipients["list"].map(singleRecipient => {
                singleRecipient["toggle"] = isCheckedAll;
                newRecipients.push(singleRecipient);
            })
            setRecipients({ list: newRecipients });
        }
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl">
                <div className="row g-12 g-xl-12">
                    <div className="col-xl-12">
                        <div className="row">
                            {
                                entityType === "company" ?
                                <SearchByDateAndCreditRep   callback={updateHandler} /> :
                                <SearchByDate callback={updateHandler} />
                            }
                        </div>
                    </div>
                </div>


                <Formik
                    initialValues={recipients}
                    enableReinitialize={true}
                    validateOnBlur={true}
                    innerRef={formRef}
                    onSubmit={async (values) => {
                        setProcessing(true);
                        var paylaod = [];

                        if (Array.isArray(values.list)) {
                            values.list.map(singleValue => {
                                if (singleValue["toggle"] !== undefined && singleValue["toggle"]) {
                                    paylaod.push(singleValue)
                                }
                            })
                        }
                        if (paylaod.length > 0) {
                            const response = await createSMS(token, paylaod);
                            if (response["error"]) {
                                Notifications("error", response["message"] || "Error")
                            } else {
                                Notifications("success", response["message"] || "Updated Successfully")
                            }
                        }
                        else {
                            Notifications("error", "Please select atleast 1 recipient.")
                        }
                        setProcessing(false);
                    }}
                >
                    {({ errors, values, handleChange }) => (
                        <Form>
                            <div className="row g-12 g-xl-12">
                                <div className="col-xl-12">
                                    <div className="row">
                                        <FieldArray
                                            name="list"
                                            render={(arrayHelpers) => (
                                                <div>
                                                    < div className="card">
                                                        <div className="row card-body pt-0" style={{ marginTop: "30px" }}>
                                                            <div className="col-sm-12">
                                                                <label style={{ marginBottom: "10px", fontWeight: "600" }}>Please type the message for receivers.</label>
                                                                <textarea type="text" name="textarea" value={message} onChange={handleAreaChange} style={{ width: "100%", minHeight: "130px" }} />
                                                                <label style={{ marginBottom: "10px", color: "#919091" }}>Supported tags: {`{name}`}  {`{first_name}`}  {`{surname}`}  {`{amount}`}  </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    < div className="card" style={{ marginTop: "30px" }}>
                                                        <div className="row card-body pt-0" style={{ marginTop: "30px" }}>
                                                            <div className='col-sm-12 mb-10' style={{ display: "flex", gap: "1rem", alignContent: "center", justifyContent: "center" }}>
                                                                <div className="col-sm-6" ><label>
                                                                    <input type="checkbox" onChange={handleSelectAllRecipients} name="selectAllRecipients1" /> Select ALL</label>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.unshift({ id: "", firstName: "", surname: "", name: "", amount: "", contactNumber: "", message: "", toggle: false })}
                                                                        className='btn btn-sm btn-primary  btn'
                                                                        style={{ float: "right" }}
                                                                    >
                                                                        Add a Field
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-12 mb-10' style={{ display: "flex", gap: "1rem", backgroundColor: "#ffa500", paddingTop: "10px", paddingBottom: "10px" }}>
                                                                <div className="col-sm-3"> <label style={{ color: "white", fontWeight: "600" }}>Name</label></div>
                                                                <div className="col-sm-3"> <label style={{ color: "white", fontWeight: "600" }}>Mobile #</label></div>
                                                                <div className="col-sm-1"> <label style={{ color: "white", fontWeight: "600" }}>Amount</label></div>
                                                                <div className="col-sm-5"> <label style={{ color: "white", fontWeight: "600" }}>Message</label></div>
                                                            </div>
                                                            {values.list.map((value, index) => (
                                                                <div key={index}>
                                                                    <div className='col-sm-12 mb-10' style={{ display: "flex", gap: "1rem" }}>
                                                                        <div className="col-sm-3">
                                                                            <div className="row"  >
                                                                                <div className="col-sm-1" style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                                                    <input type="checkbox" name={`list[${index}].toggle`} id={index} checked={values.list[index].toggle} onChange={handleChange} />
                                                                                </div>
                                                                                <div className="col-sm-11">
                                                                                    <input
                                                                                        name={`list[${index}].name`}
                                                                                        value={values.list[index].name}
                                                                                        className="form-control"
                                                                                        style={{ height: "35px", fontWeight: "400" }}
                                                                                        onChange={(e) => {
                                                                                            var fullName = e.target.value;
                                                                                            var fullNameArray = fullName.split(" ");
                                                                                            values.list[index].firstName = fullNameArray[0] !== undefined ? fullNameArray[0] : "";
                                                                                            values.list[index].surname = fullNameArray[1] !== undefined ? fullNameArray[1] : "";
                                                                                            handleChange(e)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-3">
                                                                            <input
                                                                                name={`list[${index}].contactNumber`}
                                                                                value={values.list[index].contactNumber}
                                                                                className="form-control"
                                                                                style={{ height: "35px", fontWeight: "400" }}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </div>
                                                                        <div className="col-sm-1">
                                                                            <input
                                                                                name={`list[${index}].amount`}
                                                                                value={values.list[index].amount}
                                                                                className="form-control"
                                                                                style={{ height: "35px", fontWeight: "400" }}
                                                                                onChange={handleChange}
                                                                            /></div>
                                                                        <div className="col-sm-5">
                                                                            {values.list[index].message}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>

                                </div>

                                <div className="row" style={{ marginTop: "30px" }}>
                                    <div className="col-sm-9"></div>
                                    <div className="col-sm-3" style={{ float: "right" }}>
                                        {<FormSubmissionButton isActive={Object.keys(errors).length == 0 ? true : false} label="Send" />}
                                    </div>
                                </div>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
            {processing ? <LoadingScreen /> : ""}

        </>

    )
}



export default GiftCardRecipientsText;