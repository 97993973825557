
import { useState } from "react";
import { formatDate } from "../../helpers";
import Notifications from "../global/Notifications";
import { InputField, SelectField, SelectFieldGroup, ConfirmDialog, IconButton } from "../HtmlComponents";

//fileType => general -  fileType flag is added to customize the generalFiles optins
const FileListItem = ({ singleFile, folders, entityType, entityId, filesCallback, updateFilesAllowed, deleteFilesAllowed, fileType }) => {
  const [editFileName, setEditFileName] = useState(false);
  const [fileNewLabel, setFileNewLabel] = useState(singleFile.label)

  //UPDATE FOLDER
  const updateHandler = async (name, value) => {
    var currentFolderId = singleFile.folderId;
    singleFile.folderId = value;
    filesCallback("updateFileFolder", { currentFolderId: currentFolderId, file: singleFile });
  }

  //DELETE FILE IF YES IS CLIKED FROM CONFIRM DIALOG
  const callback = async () => {
    singleFile.status = "inActive";
    filesCallback("deleteFile", { file: singleFile });
  }

  //UPDATE FILE LABLE
  const updateFileLabel = async () => {
    if (fileNewLabel.length > 4 && fileNewLabel.length < 100) {
      singleFile.label = fileNewLabel;
      filesCallback("updateFileLabel", { file: singleFile });
    }
    else {
      Notifications("error", "File name length should be between 5 - 100")
    }
  }

  const FileName = () => {
    if (!editFileName) {
      return (
        <>
          <a href={singleFile.preSignedURL} target={"_blank"}>{singleFile.label}</a>
          <IconButton
            iconType="edit"
            buttonClassName=""
            buttonStyle={{ border: "0px", backgroundColor: "transparent", color: "gray" }}
            buttonOnClick={() => { console.log("edit button clicked ------------ "); setEditFileName(true); }}
          />
        </>
      )
    } else if (editFileName) {
      return (
        <>
          <InputField
            fieldName="fileNewLabel"
            fieldValue={fileNewLabel}
            setFieldValue={(name, value) => { setFileNewLabel(value); }}
          />
          <IconButton
            iconType="delete"
            buttonClassName=""
            buttonStyle={{ border: "0px", backgroundColor: "transparent", color: "red" }}
            buttonOnClick={() => { setEditFileName(false); }}
          />
          <IconButton
            iconType="tick"
            buttonClassName=""
            buttonStyle={{ border: "0px", backgroundColor: "transparent", color: "green" }}
            buttonOnClick={() => { updateFileLabel(); }}
          />
        </>
      )
    }


  }

  if (fileType == "generalFile") {
    return (
      <>
        <tr>
          <td> <a href={singleFile.preSignedURL} target={"_blank"}>{singleFile.label}</a> </td>
          <td>{formatDate(singleFile.createdAt, "unix", "DD/MM/YYYY HH:mm:ss")}</td>
          {
            updateFilesAllowed ?
              <td style={{ width: "15%" }}>
                <SelectFieldGroup
                  fieldName="fileSelectedFolder"
                  defaultSelectedOption={{ value: singleFile.generalFolderId, label: "" }}
                  options={folders}
                  setFieldValue={updateHandler}
                  callback=""
                />
              </td>
              : ""

          }
          {
            deleteFilesAllowed ?
              <td>
                <IconButton
                  iconType="delete"
                  buttonClassName=""
                  buttonStyle={{ border: "0px", backgroundColor: "transparent", color: "red" }}
                  buttonOnClick={() => { ConfirmDialog({ callback, "purpose": "deleteFile" }) }}
                />
              </td>
              : ""
          }
        </tr>
      </>
    )
  }
  else {
    return (
      <>
        <tr>
          <td>
            {FileName()}

          </td>
          <td>{formatDate(singleFile.createdAt, "unix", "DD/MM/YYYY HH:mm:ss")}</td>
          {
            updateFilesAllowed && entityType == "application" ?
              <td>
                <SelectFieldGroup
                  fieldName="fileSelectedFolder"
                  defaultSelectedOption={{ value: singleFile.folderId, label: "" }}
                  options={folders}
                  setFieldValue={updateHandler}
                  callback=""
                />
              </td>
              : ""

          }

          {
            updateFilesAllowed && entityType !== "application" ?
              <td>
                <SelectField
                  fieldName="fileSelectedFolder"
                  defaultSelectedOption={{ value: singleFile.folderId, label: "" }}
                  options={folders}
                  setFieldValue={updateHandler}
                  callback=""
                />
              </td>
              : ""

          }


          {
            deleteFilesAllowed ?
              <td>
                <IconButton
                  iconType="delete"
                  buttonClassName=""
                  buttonStyle={{ border: "0px", backgroundColor: "transparent", color: "red" }}
                  buttonOnClick={() => { ConfirmDialog({ callback, "purpose": "deleteFile" }) }}
                />

              </td>
              : ""
          }
        </tr>

      </>
    )

  }

}

export default FileListItem;