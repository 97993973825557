import { useState, useEffect } from 'react';
import { getCreditRepList } from '../api';
import { useToken, useRole, useEntityId } from './useAuth';
import { CustomButton, InputField, ConfirmDialog, RightDrawer, SelectField } from '../components/HtmlComponents';


const useCreditReps = () => {
    const [creditReps, setCreditReps] = useState([]);
    const [selectedCreditRepId, setSelectedCreditRepId] = useState(null);

    const entityId = useEntityId();
    const role = useRole();
    const token = useToken();

    useEffect(() => {
        const fetchCreditRepsList = async () => {
            if (["superAdmin"].includes(role)) {
                try {
                    const creditRepsList = await getCreditRepList(entityId, token, "basic");
                    if (Array.isArray(creditRepsList) && creditRepsList.length > 0) {
                        const creditRepsOptions = creditRepsList
                            .filter(item => item.id)
                            .map(item => ({ value: item.id, label: item.tradingName }));
                        setCreditReps(creditRepsOptions);
                    }
                } catch (error) {
                    console.error("Error fetching credit reps:", error);
                }
            }
        };

        fetchCreditRepsList();
    }, [role, entityId, token]);

    // Dropdown Component
    const CreditRepsDropdown = () => (
        
        <SelectField
            fieldName="creditRepId"
            defaultSelectedOption={{ value: selectedCreditRepId, label: "" }}
            options={creditReps}
            setFieldValue={(id, value) => setSelectedCreditRepId(value)}
        />
    );

    return { selectedCreditRepId, CreditRepsDropdown };

    //return { creditReps, selectedCreditRepId, setSelectedCreditRepId };
};

export default useCreditReps;
