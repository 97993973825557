import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import { useToken , useEntityType, useRole} from '../../hooks/useAuth';
import { FormSubmissionButton, SelectField, CheckBox, MultiSelectField, MaskedField } from '../HtmlComponents';
import { Formik, Form, Field } from 'formik';
import { getUser, updateUser, getReferrersListAgainstReferrerGroup } from "../../api";
import { UpdateUserSchema } from "./schema/UserSchema";
import ErrorMessage from '../global/errors/Error';
import Notifications from '../../components/global/Notifications';
import LoadingScreen from '../global/LoadingScreen';
import Constants from '../global/Constants';
import{dateFilter , convertDateintoUnix , formatDate} from '../../helpers';

//additionalParams WOULD BE AN ARRAY AND ANY CUSTOM DATA CAN BE PASSED TO IT ACCORIDNG TO NEEDS - 
//REFERRER USER PASSING REFERRER GROUP ID 
const UpdateUser = ({ entityType, userId, callback, additionalParams }) => {
    const [error, setError] = useState({ "exist": false, "message": "" });
    const [user, setUser] = useState({});
    const [roles, setRoles] = useState([]);
    const [referrerUserOccupations, setReferrerUserOccupations] = useState([]);
    const [secondaryReferrersOptions, setSecondaryReferrersOptions] = useState([]);
    const token = useToken();

    var isInactiveUser = additionalParams !== null && additionalParams !== undefined && additionalParams["userStatus"] !== undefined && additionalParams["userStatus"] == 'inActive' ? true : false;

    //LOAD REFERRER USER OCCUPATIONLIST
    const loadReferrerUserOccupations = () => {
        var occupations = Constants.REFERRER_OCCUPATIONS_LIST;
        var options = [];
        Object.keys(occupations).map(key => {
            var option = { value: key, label: occupations[key] }
            options.push(option)
        })
        setReferrerUserOccupations(options);
    }

    //ASSIGN ROLE TO 
    const updateRolesList = () => {
        var options = [];
        switch (entityType) {
            case "creditRep":
                options = [{ value: "admin", label: "Admin" }, { value: "analyst", label: "Analyst" }, { value: "referrerGroupManager", label: "Referrer Group Manager" }];
                setRoles(options);
                break;
            case "company":
                options = [{ value: "superrAdmin", label: "Super Admin" }, { value: "finance", label: "Finance" }, { value: "auditor", label: "Auditor" }];
                setRoles(options);
                break;
        }
    }

    //LOAD REFERRERS ONLY IF UPDATING REFERER USER
    const loadReferrers = async (userReferrerId) => {
        if (entityType === "referrer") {
            let referrerGroupId = additionalParams["referrerGroupId"] !== undefined && additionalParams["referrerGroupId"] !== null ? additionalParams["referrerGroupId"] : 0;
            if (referrerGroupId !== 0) {
                var tmpReferrersOptions = [];

                let response = await getReferrersListAgainstReferrerGroup(token, referrerGroupId);
                if (Array.isArray(response)) {
                    response.map((singleReferrer) => {
                        //EXCLUDE THE PRIMARY REFERRER
                        if (singleReferrer["id"] !== userReferrerId) {
                            tmpReferrersOptions.push({ value: singleReferrer["id"], label: singleReferrer["tradingName"] })
                        }
                    })
                    setSecondaryReferrersOptions(tmpReferrersOptions);
                }
            }
        }
    }

    //GET USER
    useEffect(() => {
        loadReferrerUserOccupations();
        async function fetchUser() {
            const response = await getUser(token, userId);
            console.log(response);
            if (response[error]) {
                setError({ exist: true, message: response["message"] })
            }
            else {
                response["password"] = '';
                //CONVERT CREDIT LICENSE DATE TO DD/MM/YYYY IF PRESENT
                if(response["extraInfo"] !== null && response["extraInfo"] !== undefined && response["extraInfo"]["creditLicenseDate"] !== undefined){
                    response["extraInfo"]["creditLicenseDate"] = formatDate(response["extraInfo"]["creditLicenseDate"] , "unix" , "DD/MM/YYYY");
                }

                //CHECK IF ITS FOR REFERRER USER THEN CHECK IF allowToSubmitApplication EXIST IN THE EXTRAiNFO OTHERWISE ADD IT AND SET TO TRUE BY DEFAULT - THIS FEATURE ADDED LATER SO THIS KEY MAY NOT EXIST FOR OLD USERS
                if(response["entityType"] == "referrer" && response["extraInfo"]["additionalInfo"] !== undefined && response["extraInfo"]["additionalInfo"]["allowToSubmitApplication"] ===undefined){
                    response["extraInfo"]["additionalInfo"]["allowToSubmitApplication"] = true;
                }

                setUser(response);
                if ((response["entityType"] || false) && response["entityType"] == "referrer") {
                    loadReferrers(response["entityId"]);
                }
            }
        }
        fetchUser();

        //UPDATE THE ROLE LIST 
        updateRolesList();

    }, []);


    const EmailNotificationSettingsForAdmin = (role, errors, touched, setFieldValue) => {
        if (["admin", "analyst"].includes(role) || entityType == "referrerGroup") {
            return (
                <>
                    <div className="row">
                        <div className="col-sm-12">
                            <table className="table gy-1 fw-bolder dataTable no-footer">
                                <tbody>
                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal", width: "20px" }}>
                                            <Field name="extraInfo.notificationSettings.email">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.notificationSettings.email" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">Email Notifications for a new Referral from Referrer</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal", width: "20px" }}>
                                            <Field name="extraInfo.notificationSettings.sms">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.notificationSettings.sms" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">SMS Notifications for a new Referral from Referrer</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )
        }
    }

    //REPORTS SETTINGS - WILL BE OF 2 TYPES
    /*
      1. FOR ADMIN AND ANALYST
        reportingPermissions: {dashboard: {}, listApplications : {applicationCountAgainstStatusAndAnalyst: false}  }
    */
    
    const ReportsSettings = (role, errors, touched, setFieldValue) => {
        if (!["admin", "analyst"].includes(role)) {
            return "";
        }
        return (
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <label className=" fs-8 fw-bold mb-2">Reports Settings(Application Listing Page)</label>
                    </div>
                    <div className="col-sm-12">
                        <table className="table gy-1 fw-bolder dataTable no-footer">
                            <tbody>
                                <tr>
                                    <td className="text-start" style={{ fontWeight: "normal", width: "20px" }}>
                                        <Field name="extraInfo.reportingPermissions.listApplicationsPage.applicationCountAgainstStatusAndAnalyst">
                                            {({ field }) => (
                                                <CheckBox name="extraInfo.reportingPermissions.listApplicationsPage.applicationCountAgainstStatusAndAnalyst" 
                                                defaultChecked={field.value} setFieldValue={setFieldValue} />
                                            )}
                                        </Field>
                                    </td>
                                    <td className="text-start">Application Count against Status And Analyst</td>
                                </tr>                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }


    //HTML SECTION ONLY IF ENTITYTYPE IS REFERRER (ADDING REFERRER USER)
    const additionalComponentForReferrerUser = (errors, touched, setFieldValue) => {
        if (entityType !== "referrer") {
            return "";
        }
        else {
            //COnstant.REFERRER_OCCUPATIONS_LIST
            return (
                <>
                    <div className="row">
                        <div className="col-m-12 mb-2">
                            {/***********LOAD OTHER REFERRERS BELONGS TO SAME REFERRER GROUPS - START*****************/}
                            <label className=" fs-8 fw-bold mb-2">Secondary Referrers</label>
                            <Field name="extraInfo.secondaryReferrers">
                                {({ field }) => (
                                    <MultiSelectField
                                        fieldName="extraInfo.secondaryReferrers"
                                        options={secondaryReferrersOptions}
                                        defaultSelectedOptions={field.value}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </Field>

                            {/***********LOAD OTHER REFERRERS BELONGS TO SAME REFERRER GROUPS - END*****************/}
                        </div>
                        <div className="col-sm-12">
                            <table className="table gy-1 fw-bolder dataTable no-footer">
                                <tbody>
                                    <tr>
                                        <td colSpan={2} className="text-start">Position</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <div className="mb-3">
                                                <Field name="extraInfo.occupations">
                                                    {({ field }) => (
                                                        <MultiSelectField
                                                            fieldName="extraInfo.occupations"
                                                            options={referrerUserOccupations}
                                                            defaultSelectedOptions={field.value}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal", width: "20px" }}>
                                            <Field name="extraInfo.additionalInfo.applicationUpdatesForAllSystemUsers">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.additionalInfo.applicationUpdatesForAllSystemUsers" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">Application updates for all system users</td>
                                    </tr>
                                    
                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal", width: "20px" }}>
                                            <Field name="extraInfo.additionalInfo.allowToSubmitApplication">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.additionalInfo.allowToSubmitApplication" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">Allow to submit applications</td>
                                    </tr>

                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal" }}>
                                            <Field name="extraInfo.additionalInfo.systemAccessGranted">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.additionalInfo.systemAccessGranted" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">System access granted</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal" }}>
                                            <Field name="extraInfo.additionalInfo.exportEmail">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.additionalInfo.exportEmail" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">Export email (CMS)</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal" }}>
                                            <Field name="extraInfo.additionalInfo.statusNotificationViaEmail">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.additionalInfo.statusNotificationViaEmail" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">Email (Status Notifications)</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start" style={{ fontWeight: "normal" }}>
                                            <Field name="extraInfo.additionalInfo.statusNotificationViaSMS">
                                                {({ field }) => (
                                                    <CheckBox name="extraInfo.additionalInfo.statusNotificationViaSMS" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                        </td>
                                        <td className="text-start">SMS (Status Notifications)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>

            )
        }
    }

    //ONLY FOR CREDIT REP USERS(ADMIN, ANALYST) AND REFERRER GROUP USERS(I.E ROHAN )  => IF entityType IS CREDITREP OR REFERRERGROUP
    const LicenseField = ({ errors, touched, setFieldValue , handleChange }) => {
        if (!["creditRep", "referrerGroup"].includes(entityType)) { return ""; }
        else {
            return (
                <>
                    <div className="col-sm-12 col-md-6">
                        <label className=" fs-8 fw-bold mb-2  mt-4">Credit License Date </label>
                        <Field type="text" name="extraInfo.creditLicenseDate" placeholder="dd/mm/yyyy" className="form-control form-control-solid" onChange={handleChange}>
                            {({ field }) => ( 
                                <MaskedField fieldName="extraInfo.creditLicenseDate" fieldValue={dateFilter(field.value)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                            )}
                        </Field>
                        {errors.extraInfo && errors.extraInfo.creditLicenseDate ? (<div className="error">{errors.extraInfo.creditLicenseDate}</div>) : null} 
                    </div>
                </>
            )
        }

    }

    const userFormHtmlClone = (errors, touched, setFieldValue, handleChange) => {
        return (<>
            <div className="card mb-xl-4">
                <div className="card-body p-0">

                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2">UserName</label>
                            {isInactiveUser == true ? 
                                <Field name="userName" placeholder="User Name" className="form-control form-control-solid" /> :
                                <Field name="userName" placeholder="User Name" className="form-control form-control-solid" disabled />
                            }
                            {errors.userName ? (<div className="error">{errors.userName}</div>) : null}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2">First Name </label>
                            <Field name="firstName" placeholder="First Name" className="form-control form-control-solid" />
                            {errors.firstName ? (<div className="error">{errors.firstName}</div>) : null}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2 mt-4">Surname </label>
                            <Field name="surname" placeholder="Surname" className="form-control form-control-solid" />
                            {errors.surname ? (<div className="error">{errors.surname}</div>) : null}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2  mt-4">Email</label>
                            <Field name="email" placeholder="Email" className="form-control form-control-solid" />
                            {errors.email ? (<div className="error">{errors.email}</div>) : null}
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2  mt-4">Mobile Number </label>
                            <Field name="contactNumber" placeholder="Mobile Number" className="form-control form-control-solid" />
                            {errors.contactNumber ? (<div className="error">{errors.contactNumber}</div>) : null}
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2  mt-4">Landline Number </label>
                            <Field name="landlineNumber" placeholder="Landline Number" className="form-control" />
                            {errors.landlineNumber ? (<div className="error">{errors.landlineNumber}</div>) : null}
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <label className=" fs-8 fw-bold mb-2  mt-4">Password</label>
                            <Field type="password" name="password" autoComplete='off' placeholder="Password" className="form-control form-control-solid" />
                            {errors.password ? (<div className="error">{errors.password}</div>) : null}
                        </div>
 

                        <div className="col-sm-12 col-md-6">
                            <div style={{ marginTop: "44px" }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="text-start" style={{ fontWeight: "normal", width: "30px" }}>
                                                <Field name="isTwoFaEnabled">
                                                    {({ field }) => (
                                                        <CheckBox name="isTwoFaEnabled" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                    )}
                                                </Field>
                                            </td>
                                            <td className="text-start">2 FA Eabled</td>
                                        </tr>
                                        {
                                            isInactiveUser ?
                                                <tr>
                                                    <td className="text-start" style={{ fontWeight: "normal", width: "30px" }}>
                                                        <Field name="activateUser">
                                                            {({ field }) => (
                                                                <CheckBox name="activateUser" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                            )}
                                                        </Field>                                                         
                                                    </td>
                                                    <td className="text-start">Activate User</td>
                                                </tr> 
                                                : ""
                                        } 
                                    </tbody>
                                </table>
                            </div>
                            {
                                isInactiveUser ? <div><p>User name will be updated only if 'Activate User' is checked</p></div> : ""
                            }
                        </div>

                        <LicenseField setFieldValue={setFieldValue} errors={errors} handleChange={handleChange} />
                        
                        <div className="col-sm-12 col-md-6">
                            {roles.length > 0 ?
                                <div>
                                    <label className=" fs-8 fw-bold mb-2  mt-4">Role </label>
                                    <Field name="role">
                                        {({ field }) => (
                                            <SelectField
                                                fieldName="role"
                                                defaultSelectedOption={{ value: field.value }}
                                                options={roles}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </div>
                                : ""

                            }
                        </div>                        
                         

                    </div>
                </div>
            </div >
        </>)
    }
    const userFormHtml = (errors, touched, setFieldValue) => {
        return (<>
            <div className="card mb-xl-12">
                <div className="card-body p-0">

                    <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                        <tbody className="fs-6">
                            <tr className="even">
                                <td className="text-start">UserName</td>
                                <td className="text-start text-gray-600">
                                    <Field name="userName" placeholder="User Name" className="form-control form-control-solid" disabled />
                                    {errors.userName ? (<div>{errors.userName}</div>) : null}
                                </td>
                            </tr>

                            <tr className="even">
                                <td className="text-start">First Name</td>
                                <td className="text-start text-gray-600"><Field name="firstName" placeholder="First Name" className="form-control form-control-solid" />
                                    {errors.firstName ? (<div>{errors.firstName}</div>) : null}</td>
                            </tr>
                            <tr className="even">
                                <td className="text-start">Surname</td>
                                <td className="text-start text-gray-600"><Field name="surname" placeholder="Surname" className="form-control form-control-solid" />
                                    {errors.surname ? (<div>{errors.surname}</div>) : null}</td>
                            </tr>
                            <tr className="even">
                                <td className="text-start">Email</td>
                                <td className="text-start text-gray-600"><Field name="email" placeholder="Email" className="form-control form-control-solid" />
                                    {errors.email ? (<div>{errors.email}</div>) : null}</td>
                            </tr>
                            <tr className="even">
                                <td className="text-start">Contact Number</td>
                                <td className="text-start text-gray-600"><Field name="contactNumber" placeholder="Contact Number" className="form-control form-control-solid" />
                                    {errors.contactNumber ? (<div>{errors.contactNumber}</div>) : null}</td>
                            </tr>
                            <tr className="even">
                                <td className="text-start">Password</td>
                                <td className="text-start text-gray-600"><Field type="password" name="password" placeholder="Password" className="form-control form-control-solid" />
                                    {errors.password ? (<div>{errors.password}</div>) : null}</td>
                            </tr>


                            {roles.length > 0 ?
                                <tr className="even">
                                    <td className="text-start">Role</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="role">
                                            {({ field }) => (
                                                <SelectField
                                                    fieldName="role"
                                                    defaultSelectedOption={{ value: field.value }}
                                                    options={roles}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr> : ""
                            }
                        </tbody>
                    </table>

                </div>
            </div>
        </>)
    }

    const LoadView = () => {
        return (
            <>
                <Formik
                    initialValues={user}
                    validationSchema={UpdateUserSchema}
                    validateOnBlur={true}
                    onSubmit={async (values) => {
                        setError(""); 
                        let valuesClone = JSON.parse(JSON.stringify(values));
                        console.log("valuesClone ----------------------------   ", valuesClone);
                        if(valuesClone["extraInfo"] !== undefined && valuesClone["extraInfo"]["creditLicenseDate"] !== undefined){
                            valuesClone["extraInfo"]["creditLicenseDate"] = convertDateintoUnix(valuesClone["extraInfo"]["creditLicenseDate"]);
                        }
                        const response = await updateUser(token, userId, valuesClone);
                        setUser(values);

                        if (response["error"]) {
                            Notifications("error", response["message"] || "Error");

                        } else {
                            Notifications("success", response["message"] || "Updated Successfully")
                            //CHECK IF RELEVANT ENTITY PATH IS AVAILABLE
                            callback(response.user);
                        }
                        if (response["error"]) { setError({ "exist": true, "message": response["message"] }); }
                        else { if (response.id !== undefined) { console.log("ressssssssss: --"); } }
                    }}
                >
                    {({ values, errors, touched, setFieldValue , handleChange }) => (
                        <Form autoComplete='off'>
                            <div id="kt_content_container" className="container-xxl">
                                <div className="row g-12 g-xl-12">
                                    <ErrorMessage isErrorExist={error["exist"]} errorMessage={error["message"]} />
                                    <div className="col-xl-12">
                                        {userFormHtmlClone(errors, touched, setFieldValue , handleChange)}
                                        {additionalComponentForReferrerUser(errors, touched, setFieldValue)}
                                        {EmailNotificationSettingsForAdmin(values.role, errors, touched, setFieldValue)}
                                        {ReportsSettings(values.role, errors, touched, setFieldValue)}
                                        {<FormSubmissionButton isActive={true} />}
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>)
    }
    return (
        <LoadView />
    );
}


export default UpdateUser;