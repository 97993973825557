import React, { useState, useEffect } from 'react';
import Constants from '../../components/global/Constants';
import { createLead } from '../../api/leadsApi';
import { SearchLocationInput } from '../../api/googleApi';
import { CheckBox } from '../HtmlComponents';

const NewLead = () => {
  const [loanPurposes, setLoanPurposes] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState({ "fullAddress": "", "unitNumber": "", "streetNumber": "", "streetTypeShortName": "", "streetTypeLongName": "", "streetName": "", "state": "", "postCode": "", "suburb": "" });
  const [contactNumber, setContactNumber] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [purpose, setPurpose] = useState('');
  const [addressNotFound, setAddressNotFound] = useState(false);
  const [unitNumber, setUnitNumber] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [streetName, setStreetName] = useState('');
  const [streetTypeShortName, setStreetTypeShortName] = useState('');
  const [suburb, setSuburb] = useState('');
  const [postCode, setPostCode] = useState('');
  const [state, setState] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const loanPurposeOptions = [];
    Object.keys(Constants.LOAN_PURPOSES).map((key) => {
      loanPurposeOptions.push({ value: key, label: Constants.LOAN_PURPOSES[key] });
    });
    setLoanPurposes(loanPurposeOptions);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const leadData = {
      firstName,
      middleName,
      surname,
      email,
      address: address,
      contactNumber,
      loanAmount,
      purpose,
    };

    try {
      const response = await createLead(leadData);
      console.log('Lead created:', response);
      setSuccessMessage("The information has been submitted and the broker will contact you in the next 24 hours.");
      setFirstName('');
      setMiddleName('');
      setSurname('');
      setEmail('');
      setAddress({ "fullAddress": "", "unitNumber": "", "streetNumber": "", "streetTypeShortName": "", "streetTypeLongName": "", "streetName": "", "state": "", "postCode": "", "suburb": "" });
      setContactNumber('');
      setLoanAmount('');
      setPurpose('');
    } catch (error) {
      console.error('Error creating lead:', error);
      // TODO: Handle error (e.g., show an error message)
    }
  };

  const setAddressModule = (newAddress) => {
    setAddress(newAddress);
    setUnitNumber(newAddress.unitNumber || '');
    setStreetNumber(newAddress.streetNumber || '');
    setStreetName(newAddress.streetName || '');
    setStreetTypeShortName(newAddress.streetTypeShortName || '');
    setSuburb(newAddress.suburb || '');
    setPostCode(newAddress.postCode || '');
    setState(newAddress.state || '');
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="card mb-5 mb-xxl-8" style={{ padding: '20px' }}>
        <div className="card-body card-body pt-6 pb-6">
          {successMessage ? (
            <div className="alert alert-success">
              <h4 className="alert-heading">Success!</h4>
              <p>{successMessage}</p>
            </div>
          ) : (
            <>
              <h3>New Lead Form</h3>
              <form onSubmit={handleSubmit}>
            <div className="row mb-5">
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2" htmlFor="firstName">
                  First Name:
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="form-control form-control-solid"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <label className="fs-8 fw-bold mb-2" htmlFor="middleName">
                  Middle Name:
                </label>
                <input
                  type="text"
                  id="middleName"
                  name="middleName"
                  className="form-control form-control-solid"
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2" htmlFor="surname">
                  Surname:
                </label>
                <input
                  type="text"
                  id="surname"
                  name="surname"
                  className="form-control form-control-solid"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </div>
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <label className="fs-8 fw-bold mb-2" htmlFor="email">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control form-control-solid"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="col-md-12 fv-row fv-plugins-icon-container">
              <label className="required fs-8 fw-bold mb-2" htmlFor="address">
                Address:
              </label>
              {!addressNotFound ? (
                <SearchLocationInput setAddressModule={setAddressModule} />
              ) : "" }
            </div> {/* Closing div added here */}

            <div className="col-sm-12 mt-2">
              <div className="d-flex flex-column mb-2">
                <div className="d-flex">
                  <CheckBox
                    name="addressNotFound"
                    defaultChecked={addressNotFound}
                    setFieldValue={(name, value) => {
                      setAddressNotFound(value);
                    }}
                  />
                  <label className="fs-6 fw-semibold mb-2" style={{ marginLeft: '12px' }}>
                    Address Not Found
                  </label>
                </div>
              </div>
            </div>

            {
              addressNotFound ?
              <>
                  <div className="row mb-5">
                    <div className="col-md-6 fv-row fv-plugins-icon-container">
                      <label className="fs-8 fw-bold mb-2" htmlFor="unitNumber">
                        Unit Number:
                      </label>
                      <input
                        type="text"
                        id="unitNumber"
                        name="unitNumber"
                        className="form-control form-control-solid"
                        value={unitNumber}
                        onChange={(e) => setUnitNumber(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 fv-row fv-plugins-icon-container">
                      <label className="fs-8 fw-bold mb-2" htmlFor="streetNumber">
                        Street Number:
                      </label>
                      <input
                        type="text"
                        id="streetNumber"
                        name="streetNumber"
                        className="form-control form-control-solid"
                        value={streetNumber}
                        onChange={(e) => setStreetNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-md-6 fv-row fv-plugins-icon-container">
                      <label className="fs-8 fw-bold mb-2" htmlFor="streetName">
                        Street Name:
                      </label>
                      <input
                        type="text"
                        id="streetName"
                        name="streetName"
                        className="form-control form-control-solid"
                        value={streetName}
                        onChange={(e) => setStreetName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 fv-row fv-plugins-icon-container">
                      <label className="fs-8 fw-bold mb-2" htmlFor="streetTypeShortName">
                        Street Type (Short Name):
                      </label>
                      <input
                        type="text"
                        id="streetTypeShortName"
                        name="streetTypeShortName"
                        className="form-control form-control-solid"
                        value={streetTypeShortName}
                        onChange={(e) => setStreetTypeShortName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-md-6 fv-row fv-plugins-icon-container">
                      <label className="fs-8 fw-bold mb-2" htmlFor="suburb">
                        Suburb:
                      </label>
                      <input
                        type="text"
                        id="suburb"
                        name="suburb"
                        className="form-control form-control-solid"
                        value={suburb}
                        onChange={(e) => setSuburb(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 fv-row fv-plugins-icon-container">
                      <label className="fs-8 fw-bold mb-2" htmlFor="postCode">
                        Post Code:
                      </label>
                      <input
                        type="text"
                        id="postCode"
                        name="postCode"
                        className="form-control form-control-solid"
                        value={postCode}
                        onChange={(e) => setPostCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-md-6 fv-row fv-plugins-icon-container">
                      <label className="fs-8 fw-bold mb-2" htmlFor="state">
                        State:
                      </label>
                      <input
                        type="text"
                        id="state"
                        name="state"
                        className="form-control form-control-solid"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                  </div>
                </> : ""
            }


            <div className="row mb-5">
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2" htmlFor="contactNumber">
                  Contact Number:
                </label>
                <input
                  type="text"
                  id="contactNumber"
                  name="contactNumber"
                  className="form-control form-control-solid"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </div>

              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2" htmlFor="loanAmount">
                  Loan Amount:
                </label>
                <input
                  type="number"
                  id="loanAmount"
                  name="loanAmount"
                  className="form-control form-control-solid"
                  value={loanAmount}
                  onChange={(e) => setLoanAmount(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <label className="required fs-8 fw-bold mb-2" htmlFor="purpose">
                  Purpose:
                </label>
                <select
                  id="purpose"
                  name="purpose"
                  className="form-control form-control-solid"
                  value={purpose}
                  onChange={(e) => setPurpose(e.target.value)}
                >
                  {loanPurposes.map((purpose) => (
                    <option key={purpose.value} value={purpose.value}>
                      {purpose.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewLead;
