import React, { useEffect, useState } from 'react';
import { InputField, SelectField, TextArea, CustomButton } from '../../HtmlComponents';
import { getUsers } from '../../../api';
import { useToken, useEntityId } from '../../../hooks/useAuth';
import { performApplicationAdditionalActions, getComments } from '../../../api';
import Notifications from '../../../components/global//Notifications';
import { formatDate } from '../../../helpers';

const ConvertToApplication = ({ lead, analystList, callback }) => {
    const [selectedAnalyst, setSelectedAnalyst] = useState('');
    const [comments, setComments] = useState("");


    const updateHandler = () => {
        callback("convertLead", lead, selectedAnalyst);
    };

    return (
        <div>
            <p>Assign to Analyst:</p>

            <SelectField
                fieldName="analystId"
                defaultSelectedOption={{ value: selectedAnalyst , label: "" }} 
                options={analystList} 
                setFieldValue={(name, value) => setSelectedAnalyst(value)}
            />

            <div className='d-flex flex-column mb-8 mt-4'>
                <label className="fs-6 fw-semibold mb-2">Comments</label>
                <TextArea
                    fieldName="comments"
                    setFieldValue={setComments}
                />
            </div>

            <div>
                <CustomButton
                    buttonClassName="btn-sm btn-primary"
                    buttonStyle={{ float: "right" }}
                    buttonOnClick={updateHandler}
                    buttonText="Assign"
                    isActive={selectedAnalyst ? true : false}
                />
                <CustomButton
                    buttonClassName="btn-sm btn-secondary"
                    buttonStyle={{ float: "right", marginRight: "20px" }}
                    buttonOnClick={() => { callback("closeDrawer", true); }}
                    buttonText="Close"
                    isActive={ true}
                />
            </div>
        </div>
    );
};

export default ConvertToApplication;
