import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useRole, useToken, useEntityType, useEntityId } from '../../hooks/useAuth';
import { EditButton, AddButton, CustomButton, RightDrawer } from '../HtmlComponents';
import Files from '../files/Files';

import CreateContact from '../contacts/CreateContact';
import ListContacts from '../contacts/ListContacts';
import UpdateContact from '../contacts/UpdateContact';

import ListUsers from "../users/ListUsers";
import AddUser from "../users/AddUser";
import UpdateUser from "../users/UpdateUser";

//EMAIL MODULE
import LenderWelcomeEmail from "./components/LenderWelcomeEmail";

import { useMethodAllowed } from '../../hooks/usePermission';

import { getLender } from "../../api/lenderApi";

const LenderDetail = () => {
    const [singleLender, setSingleLender] = useState({});
    const [supportedProducts, setSupportedProducts] = useState({});
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "Files", childName: "", meta: { width: "800" } });

    let { id } = useParams();
    const userEntityId = useEntityId();
    const userRole = useRole();
    const userEntityType = useEntityType();

    //CHECK IF LENDER IS LOGGED IN THEN GET THE LENDER ID FROM AUTH SESSION AND ASSIGN THE ID TO id
    id = userEntityType === "lender" && userRole == "user" ? userEntityId : id;

    const token = useToken();
    var updateLenderAllowed = useMethodAllowed("lender", "edit");
    var createContactAllowed = useMethodAllowed("lender", "contact", "create");
    var updateContactAllowed = useMethodAllowed("lender", "contact", "update");
    var viewFilesAllowed = useMethodAllowed("lender", "files", "view");
    var addUserAllowed = useMethodAllowed("lender", "users", "create");


    updateLenderAllowed = userEntityType === "lender" && userRole == "user" ? true :  ( userEntityType == "creditRep" && userEntityId !== 21 ? false: updateLenderAllowed )  ;
    createContactAllowed = userEntityType === "lender" && userRole == "user" ? true : ( userEntityType == "creditRep" && userEntityId !== 21 ? false: createContactAllowed );
    updateContactAllowed = userEntityType === "lender" && userRole == "user" ? true : ( userEntityType == "creditRep" && userEntityId !== 21 ? false: updateContactAllowed );
    viewFilesAllowed = userEntityType === "lender" && userRole == "user" ? true : ( userEntityType == "creditRep" && userEntityId !== 21 ? false: viewFilesAllowed );
    addUserAllowed = userEntityType === "lender" && userRole == "user" ? false : ( userEntityType == "creditRep" && userEntityId !== 21 ? false: addUserAllowed );

    var editUrl = userEntityType === "lender" && userRole == "user" ? "/lender/editLender/" : "/user/editLender/" + id;
    useEffect(() => {
        fetchLenderDetail();
    }, []);


    const fetchLenderDetail = async () => {
        const lender = await getLender(id, token);
        console.log(lender)
        setSingleLender(lender);
        setSupportedProducts(lender.supportedProducts)
    }

    //UPDATE THE LENDER PAYLAOD ON UPDATION | CREATION OF CONTACT
    const payloadUpdateHandler = (action, values, meta = {}) => {
        switch (action) {
            case "updatePayloadOnContactCreation":
                //ADD THE NEW CONTACT TO CONTACTS ARRAY
                var contacts = singleLender.contacts;
                if (Array.isArray(contacts)) { contacts.push(values); }
                else {
                    contacts = [];
                    contacts.push(values);
                }
                //UPDAET THE STATE                    
                setSingleLender({ ...singleLender, contacts: contacts });
                drawHandler("closeDrawer");   //CLOSE DRAW
                break;
            case "updatePayloadOnContactUpdation":
                //FIND THE EXISTING CONTACT AND UPDATE THE DATA
                var contacts = singleLender.contacts;
                var newContactsList = [];
                contacts.map((contact) => {
                    if (contact.id == values.id) {
                        newContactsList.push(values);
                    }
                    else {
                        newContactsList.push(contact);
                    }
                })
                //UPDAET THE STATE                    
                setSingleLender({ ...singleLender, contacts: newContactsList });
                drawHandler("closeDraw");   //CLOSE DRAW
                break;
            case "updatePayloadOnDeleteContact": 
                var contacts = singleLender.contacts;
                var newContactsList = [];
                contacts.map((contact) => { if (contact.id !== values.id) { newContactsList.push(contact); } }) 
                //UPDAET THE STATE
                setSingleLender({ ...singleLender, contacts: newContactsList });
                break;    
            case "updatePayloadOnAddUser":
                var users = singleLender.users;
                if (!Array.isArray(users)) { users = []; }
                users.push(values);
                //UPDAET THE STATE                    
                setSingleLender({ ...singleLender, users: users });
                drawHandler("closeDrawer");   //CLOSE DRAW
                drawHandler("lenderWelcomeEmail", {firstName: values["firstName"], userName: values["userName"], email: values["email"], defaultPassword : "asdfgh123" });
                break;
            case "updatePayloadOnUpdateUser":
                var users = singleLender.users;
                var newUsers = [];
                users.map((user) => {
                    if (user.id == values.id) {  newUsers.push(values); }
                    else {  newUsers.push(user); }
                })
                //UPDAET THE STATE                    
                setSingleLender({ ...singleLender, users: newUsers });
                drawHandler("closeDrawer");   //CLOSE DRAW
                break;
            case "updatePayloadOnDeleteUser":
                var users = singleLender.users;
                var newUsers = [];
                users.map((user) => { if (user.id !== values.id) { newUsers.push(user); } })
                //UPDAET THE STATE
                setSingleLender({ ...singleLender, users: newUsers });
                break;
        }
    }
    const drawHandler = (actionName, meta = {}) => {
        switch (actionName) {
            case "closeDrawer":
                setDrawerSettings({ ...drawerSettings, visible: false })
                break;
            case "openFilesDrawer":
                setDrawerSettings({ visible: true, heading: "Files", childName: "Files", meta: { width: "800" } });
                break;
            case "openCreateContactDrawer":
                setDrawerSettings({ visible: true, heading: "Create Contact", childName: "CreateContact", meta: { width: "800" } });
                break;
            case "openUpdateContactDrawer":
                setDrawerSettings({ visible: true, heading: "Update Contact", childName: "UpdateContact", meta: { width: "800", contactId: meta["contactId"] || 0 } });
                break;
            case "openAddUserDrawer":
                setDrawerSettings({ visible: true, heading: "Add User", childName: "AddUser", meta: { width: "800" } });
                break;
            case "openUpdateUserDrawer":
                setDrawerSettings({ visible: true, heading: "Update User", childName: "UpdateUser", meta: { width: "800", userId: meta.userId ? meta.userId : 0 } });
                break;
            case "lenderWelcomeEmail": 
                setDrawerSettings({ visible: true, heading: "Welcome Email", childName: "lenderWelcomeEmail", meta: {firstName: meta["firstName"] || "" , userName: meta["userName"] || "" , email : meta["email"] ||"",  defaultPassword: meta["defaultPassword"] || "" , cc: ""} });
                break; 
        }
    }

    const BasicDetail = () => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">{singleLender.name} Contact Details</h3>
                            {updateLenderAllowed ? <EditButton URL={editUrl} /> : ""}
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered  gy-4 align-middle fw-bolder border-gray-900 no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Name</td>
                                    <td className="text-start fw-bold text-gray-600"> {singleLender.name}  </td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Assessment Email</td>
                                    <td className="text-start fw-bold text-gray-600 "> {singleLender.assessmentEmail} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Settlement Email</td>
                                    <td className="text-start fw-bold text-gray-600"> {singleLender.settlementEmail} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Portal Web Address</td>
                                    <td className="text-start fw-bold text-gray-600"> {singleLender.websiteUrl}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Contact #</td>
                                    <td className="text-start fw-bold text-gray-600"> {singleLender.contactNumber}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Address</td>
                                    <td className="text-start fw-bold text-gray-600"> {singleLender.address && singleLender.address.fullAddress ? singleLender.address.fullAddress : ""} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    const Contacts = () => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Contacts</h3>
                            {createContactAllowed ?
                                <CustomButton
                                    buttonClassName="btn btn-sm btn-light btn-active-secondary"
                                    isActive={true}
                                    buttonText="Add"
                                    buttonOnClick={() => { drawHandler("openCreateContactDrawer") }}
                                />
                                : ""}
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <ListContacts contacts={singleLender && singleLender.contacts ? singleLender.contacts : []} callback={(action, id) => {
                            switch (action) {
                                case "updateContact":
                                    drawHandler("openUpdateContactDrawer", { contactId: id })
                                    break; 
                                case "refreshContactsListing":
                                    payloadUpdateHandler("updatePayloadOnDeleteContact", { id: id });
                                    break;

                            }
                        }
                        } />
                    </div>
                </div>
            </div>
        </>)
    }

    const Users = () => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Users</h3>
                            {addUserAllowed ?
                                <CustomButton
                                    buttonClassName="btn btn-sm btn-light btn-active-secondary"
                                    isActive={true}
                                    buttonText="Add"
                                    buttonOnClick={() => { drawHandler("openAddUserDrawer") }}
                                /> : ""}
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <ListUsers users={singleLender && singleLender.users ? singleLender.users : []} callback={(action, id) => {
                            switch (action) {
                                case "updateUser":
                                    drawHandler("openUpdateUserDrawer", { userId: id })
                                    break;
                                case "refreshUsersListing":
                                    payloadUpdateHandler("updatePayloadOnDeleteUser", { id: id });
                                    break;

                            }
                        }} />
                    </div>
                </div>
            </div>
        </>)
    }


    const WhySection = () => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Why {singleLender.name}? </h3>
                            {updateLenderAllowed ? <EditButton URL={editUrl} /> : ""}
                        </div>
                    </div>
                    <div className="bg-body text-start shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <div dangerouslySetInnerHTML={{ __html: singleLender.otherNotes }} />
                    </div>
                </div>
            </div>
        </>)
    }

    const AboutSection = () => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">About {singleLender.name} </h3>
                            {updateLenderAllowed ? <EditButton URL={editUrl} /> : ""}
                        </div>
                    </div>
                    <div className="bg-body text-start shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <div dangerouslySetInnerHTML={{ __html: singleLender.productNotes }} />
                    </div>
                </div>
            </div>
        </>)
    }


    const FeesAndChargesSection = () => {
        return (
            <>
                <div className="px-9 pt-3 card-rounded  w-100 bg-primary pb-3" style={{ "marginTop": "30px" }}>
                    <div className="d-flex flex-stack">
                        <h3 className="m-0 text-white fw-bolder fs-3">Fees and Charges  </h3>
                        {updateLenderAllowed ? <EditButton URL={editUrl} /> : ""}
                    </div>
                </div>

                <div className="bg-body shadow-sm card-rounded mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "20px" }}>
                    <table className="table table-row-bordered gy-4 align-middle fw-bolder dataTable no-footer">
                        <tbody>
                            <tr>
                                <th className="text-start">Consumer Fee:       </th><td className="text-start" style={{ fontWeight: "normal" }}>    $ {singleLender.feeStructure ? singleLender.feeStructure.consumerFee : 0} </td>
                                <td className="text-start">Commercial Fee:     </td><td className="text-start" style={{ fontWeight: "normal" }}>    $ {singleLender.feeStructure ? singleLender.feeStructure.commercialFee : 0}</td>
                                <td className="text-start">Registration Fee:   </td><td className="text-start" style={{ fontWeight: "normal" }}>    $ {singleLender.feeStructure ? singleLender.feeStructure.registrationFee : 0} </td>
                                <td className="text-start">Credit Search Fee:  </td><td className="text-start" style={{ fontWeight: "normal" }}>    $ {singleLender.feeStructure ? singleLender.feeStructure.creditSearchFee : 0} </td>
                                <td className="text-start">Monthly Fee:        </td><td className="text-start" style={{ fontWeight: "normal" }}>    $ {singleLender.feeStructure ? singleLender.feeStructure.monthlyFee : 0} </td>                                 
                            </tr>
                            <tr>
                                <td className="text-start">Rebates:</td>
                                <td colSpan={9} className="text-start" style={{ fontWeight: "normal" }}> {singleLender.feeStructure ? singleLender.feeStructure.rebates : ''} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    const ProductTypeSection = () => {
        return (
            <>
                <div className="px-9 pt-3 card-rounded  w-100 bg-primary pb-3" style={{ "marginTop": "30px" }}>
                    <div className="d-flex flex-stack">
                        <h3 className="m-0 text-white fw-bolder fs-3">Product Availability</h3>
                        {updateLenderAllowed ? <EditButton URL={editUrl} /> : ""}
                    </div>
                </div>
                <div className="bg-body shadow-sm card-rounded mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "20px" }}>
                    <table className="table table-row-bordered gy-4 align-middle fw-bolder dataTable no-footer">
                        <tbody>
                            <tr>
                                <td className="text-start">Consumer:        </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.consumer == true ? " ✓ " : " x "} </td>
                                <td className="text-start">Commercial:      </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.commercial == true ? " ✓ " : " x "}</td>
                                <td className="text-start">Private Sale:    </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.privateSale == true ? " ✓ " : " x "}</td>
                                <td className="text-start">Imports:         </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.imports == true ? " ✓ " : " x "}</td>
                            </tr>
                            <tr>
                                <td className="text-start">WOVR:            </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.wovr == true ? " ✓ " : " x "}</td>
                                <td className="text-start">Docusign:        </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.docusign == true ? " ✓ " : " x "}</td>
                                <td className="text-start">Car:             </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.car == true ? " ✓ " : " x "}</td>
                                <td className="text-start">Motorcycle:      </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.motorcycle == true ? " ✓ " : " x "}</td>
                            </tr>
                            <tr>
                                <td className="text-start">Caravan:         </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.caravan == true ? " ✓ " : " x "}</td>
                                <td className="text-start">Horse Float:     </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.horsefloat == true ? " ✓ " : " x "}</td>
                                <td className="text-start">Equipment:       </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.equipment == true ? " ✓ " : " x "}</td>
                                <td className="text-start">Marine:          </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.marine == true ? " ✓ " : " x "}</td>
                            </tr>
                            <tr>
                                <td className="text-start">Novated Lease:         </td><td className="text-start" style={{ fontWeight: "normal" }}>    {supportedProducts.novatedLease == true ? " ✓ " : " x "}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </>
        )
    }


    return (
        <>
            <div id="kt_content_container" className="container-xxl">
                <div className="row g-5 g-xl-8">
                    <div className="col-xl-12">
                        {BasicDetail()}
                    </div>
                    <div className="col-xl-12">
                        {Contacts()}
                        {/*BankingDetails() */}
                    </div>
                    <div className="col-xl-12">
                        {userEntityType === "lender" && userRole == "user" ? "" : Users()}
                    </div>
                </div>

                <div className="row g-5 g-xl-8">
                    <div className="col-xl-6">
                        {WhySection()}
                    </div>
                    <div className="col-xl-6">
                        {AboutSection()}
                    </div>
                </div>

                <div className="row g-5 g-xl-8">
                    <div className="col-xl-12">
                        {FeesAndChargesSection()}
                    </div>
                </div>
                <div className="row g-5 g-xl-8">
                    <div className="col-xl-12">
                        {ProductTypeSection()}
                    </div>
                </div>
            </div>

            {viewFilesAllowed ?
                <div className="engage-toolbar d-flex position-fixed px-5 fw-bold zindex-2 top-50 end-0 transform-90 mt-5 mt-lg-20 gap-2" style={{ zIndex: "1" }}>
                    <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "100px" }} isActive={true} buttonOnClick={() => { drawHandler("openFilesDrawer") }} buttonText="Files" />
                </div>

                : ""}

            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={drawHandler}>
                    {
                        drawerSettings["childName"] == "Files" ? <Files entityType="lender" entityId={id} enableUpload={true} /> : ""
                    }
                    {
                        drawerSettings["childName"] == "CreateContact" ? <CreateContact entityType="lender" entityId={id} callback={(data) => { payloadUpdateHandler("updatePayloadOnContactCreation", data) }} /> : ""
                    }
                    {
                        drawerSettings["childName"] == "UpdateContact" ? <UpdateContact contactId={drawerSettings["meta"]["contactId"]} callback={(id, data) => { payloadUpdateHandler("updatePayloadOnContactUpdation", data) }} /> : ""
                    }
                    {
                        drawerSettings["childName"] == "AddUser" ? <AddUser entityType="lender" entityId={id} callback={(data) => { payloadUpdateHandler("updatePayloadOnAddUser", data) }} contacts={singleLender && singleLender.contacts ? singleLender.contacts : []} /> : ""
                    }
                    {
                        drawerSettings["childName"] == "UpdateUser" ? <UpdateUser entityType="lender" entityId={id} userId={drawerSettings["meta"]["userId"]} callback={(data) => { payloadUpdateHandler("updatePayloadOnUpdateUser", data) }} /> : ""
                    }
                    {
                        drawerSettings["childName"] == "lenderWelcomeEmail" ? <LenderWelcomeEmail payload={ { firstName: drawerSettings["meta"]["firstName"] || "" , userName: drawerSettings["meta"]["userName"] || "", email : drawerSettings["meta"]["email"] || "", defaultPassword: drawerSettings["meta"]["defaultPassword"] || "" , cc: drawerSettings["meta"]["cc"] }} callback={(id, data) => { }} /> : ""
                    }
                </RightDrawer> : ""
            }
        </>
    );

}


export default LenderDetail;