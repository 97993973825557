import React from "react";

import Leftbar from "../global/Leftbar";
import Header from "../global/Header";
import { Outlet } from "react-router-dom";
import {useState } from 'react';
import {ClosedMenuIcon} from "../HtmlComponents/Icons"

import SendNotificationToAdmin from "../utilities/SendNotificationToAdmin";

import ListenRealTimeNotifications from "../utilities/ListenRealTimeNotifications";

const UserLayout = ({ children }) => {
    const [displayLeftBar , setDisplayLeftBar] = useState(false);
    return (
        <React.Fragment>
        {/* LEFT BAR */}
        <Leftbar openMobileLeftBar= {displayLeftBar} />
         
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper" style={{paddingTop: "0px"}}>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
               { /** MENU ICON FOR MOBILE TO CONTROL LEFT BAR VISIBILITY */}
                <div  className="mobileLeftBarContainer">
                    <span className="leftNavBarIcon" onClick= { () => {
                        let newValue = !displayLeftBar;
                        setDisplayLeftBar( newValue)
                    }} > <ClosedMenuIcon /></span>
                </div>
                {/*<Header /> */}
                <Outlet/>
                <SendNotificationToAdmin />
                { /*<ListenRealTimeNotifications /> */ }
            </div>
        </div>
        </React.Fragment>);
}

export default UserLayout;