

import { useState } from "react";
import { useToken } from "../../../hooks/useAuth";
import LoadingScreen from '../../global/LoadingScreen';
import SearchByDate from "../../global/SearchForms/SearchByDate";
import {  getCurrentMonth, convertDateintoUnix } from '../../../helpers';

import AnalystDetailedCommissionReport from "../components/analyst/AnalystDetailedCommissionReport";
import AnalystCommissionStructure from "../components/analyst/AnalystCommissionStructure";
import AnalystGrossIncomeReport from "../components/analyst/AnalystGrossIncomeReport";
import AnalystApplicationsCountByProgressStatusReport from "../components/analyst/AnalystApplicationsCountByProgressStatusReport";
import AnalystinsuranceReport from "../components/analyst/AnalystInsuranceReport";
import AnalystBudgetReport from "../components/analyst/AnalystBudgetReport";

const AnalystReporting = () => {
    const [dateFilter, setDateFilter] = useState({startDate: convertDateintoUnix(getCurrentMonth("firstDay") , "YYYY-MM-DD H:mm:ss"), 
    endDate: convertDateintoUnix(getCurrentMonth("lastDay"), "YYYY-MM-DD H:mm:ss") })

    const handler = (name, value) => {
        switch(name){
            case "dateFilter":
                setDateFilter({startDate: value["startDate"], endDate: value["endDate"] });
                break;
        }
    }
    return (<>
        <div id="kt_content_container" className="container-xxl">
            <div className="row g-5 g-xl-8">
                <SearchByDate callback={handler} />
            </div>


            {/**  */}
            <div className="row g-5 g-xl-8 mb-20">
                <div className="col-sm-120">
                    <AnalystBudgetReport startDate={dateFilter["startDate"]} endDate={dateFilter["endDate"]} />
                </div>
                {
                    /*
                    <div className="col-sm-6">
                        <AnalystApplicationsCountByProgressStatusReport startDate={dateFilter["startDate"]} endDate={dateFilter["endDate"]} />
                    </div>   
                    */
                }
                <div className="col-sm-6">
                    <AnalystinsuranceReport startDate={dateFilter["startDate"]} endDate={dateFilter["endDate"]} />
                </div>

                <div className="col-sm-6">
                    <AnalystGrossIncomeReport startDate={dateFilter["startDate"]} endDate={dateFilter["endDate"]} />
                </div>

                { /** My Commission Structure */}
                <div className="col-sm-6">
                    <AnalystCommissionStructure />
                </div>
            </div>
            {/** ANALYST DETAIL COMMISSION REPORT **/}
            <div className="row g-5 g-xl-8">
                <AnalystDetailedCommissionReport  startDate={dateFilter["startDate"]} endDate={dateFilter["endDate"]}  />
            </div>
        </div>

    </>)
}

export default AnalystReporting;