import  {useDispatch} from "react-redux";
import {setUserAuth} from '../../redux/authSlice';
import { useNavigate } from 'react-router-dom'
import {useEffect} from 'react';

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect( () => {
        dispatch(setUserAuth({})); 
        navigate("/login");
    }, []);
}

export default Logout;