
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useEntityId, useEntityType, useRole, useToken } from '../../hooks/useAuth';
import { CustomButton, RightDrawer, InputField } from '../HtmlComponents';

import CreateStaffBudget from './CreateStaffBudget';
import UpdateStaffBudget from './UpdateStaffBudget';
import SingleStaffBudget from "./SingleStaffBudget";
import SearchByDate from "../global/SearchForms/SearchByDate";

import { getStaffBudget, getStaffBudgetList, createStaffBudget, updateStaffBudget , getUsers } from "../../api";
import useCreditReps from "../../hooks/useCreditReps";

const ListStaffBudget = () => {
    const [budgetCollection, setBudgetCollection] = useState([]);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });
    const [filter, setFilter] = useState({ startDate: 0, endDate: 0 });
    const [users, setUsers] = useState({});

    const { selectedCreditRepId, CreditRepsDropdown } = useCreditReps();

    const userRole = useRole();
    const token = useToken();
    const entityId =  useEntityId();
    const entityType = useEntityType();


    //FILTER OBSERVER
    useEffect(() => { 
        fetchUsers(); 
        fetchBudgets();
    }, [selectedCreditRepId])
    
    useEffect(() => { fetchBudgets(); }, [filter])


    const fetchUsers = async() => {
        var usersResponse = await getUsers(token, "creditRep", ["company"].includes(entityType) ? selectedCreditRepId : entityId);
        var tmpUsers = {};

        if(usersResponse["data"] !== undefined && Array.isArray(usersResponse["data"])){
            usersResponse["data"].map(singleUser => {
                if(singleUser.id){
                    tmpUsers["userId"+singleUser.id] = {id: singleUser.id, name: singleUser.firstName  || "" + " "+ singleUser.surnamrre || ""} 
                }
            })
        }        
        setUsers(tmpUsers)
    }

    const fetchBudgets = async() => {
        var query = "";
        if (filter["startDate"] > 0) { query = `startDate=${filter["startDate"]}`; }
        if (filter["endDate"] > 0) { query += `&&endDate=${filter["endDate"]}`; }

        var response = await getStaffBudgetList(
            token, ["company"].includes(entityType) ? selectedCreditRepId : entityId, query);
        if(Array.isArray(response)){
            console.log("response", response)
            setBudgetCollection(response);
        }

    }


    //UPDATE THE LENDER PAYLAOD ON UPDATION | CREATION OF CONTACT
    const updateHandler = (action, payload) => {
        switch (action) {
            case "newBudgetCreated":
                drawHandler("closeDrawer");
                break;
            case "budgetUpdated":
                drawHandler("closeDrawer");
                fetchBudgets();
                break;
            case "dateFilter":
                setFilter({ ...filter, "startDate": payload["startDate"], "endDate": payload["endDate"] });
                break;
        }
    }

    const drawHandler = (actionName, meta = {}) => {
        console.log("meta", meta)
        switch (actionName) {
            case "closeDrawer":
                setDrawerSettings({ ...drawerSettings, visible: false })
                break;
            case "openCreateStaffBudgetDrawer":
                setDrawerSettings({ visible: true, heading: "Create Budget", childName: "CreateStaffBudget", meta: { width: "800" } });
                break;
            case "openUpdateStaffBudgetDrawer":
                setDrawerSettings({ visible: true, heading: "Update Budget", childName: "UpdateStaffBudget", meta: { width: "800", budgetId: meta["budgetId"] || 0 } });
                break;

        }
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl">
                <div className='row'>                          
                    { entityType === "company" ? <><div className='col-sm-6 mt-7 mb-5'><label className="required fs-8 fw-bold mb-2">CreditRep</label><CreditRepsDropdown /></div></> : ""}                         
                </div>

                <div className="row g-5 g-xl-8">                     
                    <div className="col-xl-12">
                        <div className='row mb-5'>
                            <SearchByDate callback={updateHandler} />
                        </div>
                        <div className="card card-xl-stretch mb-xl-8">
                            <div className="card-body p-0">
                                <div className="px-9 pt-7 card-rounded h-175px w-100 ">
                                    <div className="d-flex flex-stack">
                                        <h3 className="m-0 text-black fw-bolder fs-3">Staff Budget</h3>
                                        <CustomButton
                                            buttonClassName="btn btn-sm btn-light btn-active-secondary"
                                            isActive={true}
                                            buttonText="Add New Budget"
                                            buttonOnClick={() => { drawHandler("openCreateStaffBudgetDrawer") }}
                                        />
                                    </div>
                                </div>
                                <div className="bg-body card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                                    <div className="row">
                                        {
                                            Array.isArray(budgetCollection) ?
                                             budgetCollection.map(singleBudget => {
                                                    return (
                                                        <>
                                                            <SingleStaffBudget singleBudget={singleBudget} users={users} callback={drawHandler} />
                                                        </>
                                                    )
                                                })
                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={drawHandler}>
                    {
                        drawerSettings["childName"] == "CreateStaffBudget" ? <CreateStaffBudget callback={updateHandler} /> : ""
                    }
                    {
                        drawerSettings["childName"] == "UpdateStaffBudget" ? <UpdateStaffBudget budgetId={drawerSettings["meta"]["budgetId"]} singleBudget={drawerSettings["meta"]["singleBudget"]} callback={updateHandler} /> : ""
                    }
                </RightDrawer> : ""
            }
        </>
    );

}


export default ListStaffBudget;