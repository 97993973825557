//ListenRealTimeNotifications


import React, { useEffect, useState } from 'react';
import { isClientConnected, createEventSource, registerListener, unregisterListener, closeEventSource, } from "../../api";
import { useToken, useRole } from '../../hooks/useAuth';
import { useRealTimeNotificationId } from '../../hooks/useRealTimeNotification';
import Notifications from '../global/Notifications';
import { getUniqueId } from '../../helpers';

import { useDispatch } from 'react-redux';
import { addNotificationId, removeNotificationId } from '../../redux/realTimeNotificationSlice';
import { broadcastMessage, setupReceiver } from './BroadcastChannel';

var eventSource = null;
const primaryTabId = Date.now().toString();

const ListenRealTimeNotifications = () => {
    const [notifications, setNotifications] = useState([]);
    const token = useToken();
    const userRole = useRole();
    const realTimeNotificationId = useRealTimeNotificationId();

    const dispatch = useDispatch();

    //REAL TIME UPDATES LISTENER
    useEffect(() => {
        establishPrimaryTab();
    }, []);

    useEffect(() => {
        registerForRealTimeUpdates();
    }, [token]);

    useEffect(() => {
        askForNotificationPermission();
    }, [token]);


    //CONNECT TO THE SERVER FOR LIVE UPDATES
    const registerForRealTimeUpdates = async () => {
        if (!["superAdmin", "admin", "analyst"].includes(userRole)) { return; }

        //RETRIEVE PRIMARY TAB ID FROM LOCAL STORAGE AND CHECK IF IT MATCHES THIS TAB ID THEN ONLY REGISTER FOR REAL TIME UPDATES
        const primaryTab = localStorage.getItem('primaryTab');
        if (primaryTab !== primaryTabId) { return; }

        try {
            if (token !== null && token !== undefined) {
                createEventSource(token, realTimeNotificationId);

                //ADD LISTENER  FOR THE REAL TIME UPDATES
                registerListener(getUniqueId(), (newMessage) => {
                    console.log("New message received: ", newMessage);
                    //CHECK IF IT IS HANDSHAKE THEN ADD THE NOTIFICATION ID TO THE REDUX STORE
                    if (newMessage.type === "handshake") { dispatch(addNotificationId(newMessage.uid));}
                    else{
                        console.log("New message received: line 58 - MESSAGE TYPE : ", newMessage.type);
                        console.log("New message received: line 59 - inside the else block  ");
                        handleNotifications(newMessage);
                        broadcastMessage({ type: newMessage.type, message : newMessage.message });
                    }
                    console.log("New message received: line 61 - MESSAGE TYPE : ", newMessage.type);
                    handleNotifications(newMessage);
                    broadcastMessage({ type: newMessage.type, message : newMessage.message });
                });
            }
        }
        catch (e) {
            console.log("ERROR IN CREATING EVENT SOURCE::: ", e);
        }
    }



    const handleNotifications = (newMessage) => {
        console.log("New message received: line 79  ", newMessage, userRole);
        console.log("token: ", token);        
        
        if(["superAdmin", "admin", "analyst"].includes(userRole) && token !== null && token !== undefined){

            //EXECUTE WINDOW NOTIFICATION ONLY FOR THE PRIMARY TAB
            if (localStorage.getItem('primaryTab') === primaryTabId) { windowNotificationForAllBrowsers(newMessage); }
            browserNotification(newMessage);
        }
    }

    //ASK USER TO GRANT THE BROWSER PERMISSION TO SHOW NOTIFICATIONS (WINDOE NOTIFICATION)
    const askForNotificationPermission = () => {
        if (!["superAdmin", "admin", "analyst"].includes(userRole)) { return; }

        if (!("Notification" in window)) {
        } else {
            if (Notification.permission !== 'granted' && ["superAdmin", "admin", "analyst"].includes(userRole)) {
                Notification.requestPermission();
            }
        }
    }

    //DISPLAY THE NOTIFICAITON IN THE BROWSER
    const browserNotification = (messagePaylaod) => {
        //if(messagePaylaod.type === "handshake"){return;}
        Notifications("success", "New message received: \n" + messagePaylaod.message);
    }

    //DISPLAY NOTIFICATIONS IN THE WINDOW NOTIFICATION - LIKE OUTLOOK GIVES THE NOTIFICAITON EVEN IF IT MINIMISED 
    const windowNotificationForAllBrowsers = (messagePaylaod) => {
        console.log("Notification for winddoe style -- line 169  :", messagePaylaod);
        var options = {
            body: messagePaylaod.message,
            icon: "https://images.pexels.com/photos/879356/pexels-photo-879356.jpeg?auto=compress&cs=tinysrgb&w=500&dpr=1",
            dir: "ltr"
        };
        try {
            var notification = new Notification(getNotificationLabel(messagePaylaod.type), options);
            //else { console.log("ELSE EXECUTED LINE 178"); }
        }
        catch (e) {console.log("catch EXECUTED LINE 180" , e.message);  }
    }

    // RETURN THE CUSTOM NOTIFICATION LABEL BASED ON MESSAGE TYPE
    const getNotificationLabel = (messageType) => {
        var notificationLabel = "New Notification";
        switch (messageType) {
            case "handshake": notificationLabel = "Handshake Request"; break;
            case "sms": notificationLabel = "New SMS"; break;
            case "message": notificationLabel = "New Message"; break;
            default: notificationLabel = "New Notification"; break;
        }
        return notificationLabel;
    }

    const getBrowser = () => {
        const userAgent = navigator.userAgent;

        if (userAgent.includes("Chrome") && !userAgent.includes("Edge")) {
            return "chrome";
        } else if (userAgent.includes("Firefox")) {
            return "firefox";
        } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
            return "safari";
        } else if (userAgent.includes("Edge")) {
            return "edge";
        } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
            return "internetExplorer";
        } else {
            return "Unknown";
        }
    };


    // OTHER TABS THAT WERE NOT ABLE TO SETUP THE SSE CONNECTION WILL LISTEN FOR THE UPDATES FORM THE PRIMARY TAB
    setupReceiver((message) => {
        switch (message.type) {
            case 'primaryTabChanged':
                //console.log('Primary tab changed to:', message + primaryTabId);
                //Notifications("success", "Primary tab changed to: " + message + primaryTabId);
                break;
            case 'primaryTabClosed':
                //console.log('Primary tab closed. Checking if this tab can become primary...');
                //Notifications("success", "Primary tab closed. Checking if this tab can become primary...");                
                break;
            default:
                handleNotifications(message);
        }
    });



    //ESTABLISH PRIMARY TAB
    const establishPrimaryTab = () => {
        if (!localStorage.getItem('primaryTab')) {
            console.log('This tab is now the primary tab.');
            localStorage.setItem('primaryTab', primaryTabId);
            //establishConnection();
        } else {
            console.log('Another tab is already the primary tab.');
        }
    };

    //WHEN PRIMARY TAB IS CLOSED THEN RELEASE THE PRIMARY TAB 
    window.addEventListener('beforeunload', () => {
        if (localStorage.getItem('primaryTab') === primaryTabId) {
            console.log('Releasing primary tab.');
            localStorage.removeItem('primaryTab');
            broadcastMessage({ type: 'primaryTabClosed', primaryTabId });
        }
    });

    //WHEN PRIMARY TAB IS CLOSED THEN OTHER TABS CAN ACT AND BECOME THE PRIMARY TAB
    window.addEventListener('storage', (event) => {
        if (event.key === 'primaryTab' && !event.newValue) {
            console.log('Primary tab closed. Attempting to become primary...');
            localStorage.setItem('primaryTab', primaryTabId);
            establishPrimaryTab(); // Try to become the new primary tab
            registerForRealTimeUpdates();
        }
    });
}


export default ListenRealTimeNotifications;