import axios from '../api/axios';

/*GET SINGLE APPLICATION*/
const getApplicationsCount = async (token) => {
    try {
      const response = await axios.get(`reporting/applicationsCount`, { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }

  //
const getApplicationsCountAgainstGivenProgressStatus = async (token, applicationStatus) => {
    try {
      const response = await axios.get(`reporting/applicationsCountAgainstGivenProgressStatus/${applicationStatus}`, { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }

  
const getApplicationsCountAgaintStatusAndAnalyst = async (token) => {
    try {
      const response = await axios.get(`reporting/applicationsCountAgaintStatusAndAnalyst`, { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }
//GET ANALYST DETAILED COMMISSION REPORT 
  const getAnalystDetailedCommissionReport = async (token, startDate, endDate) => {
    try {
      const response = await axios.get(`reporting/analystDetailedCommissionReport?startDate=${startDate}&endDate=${endDate}`, { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }
  //ANALYST GROSS INCOME REPORT
  const getAnalystGrossIncomeReport = async (token, startDate, endDate) => {
    try {
      const response = await axios.get(`reporting/analystGrossIncomeReport?startDate=${startDate}&endDate=${endDate}`, { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }

  //ANALYST APPLICATIONS COUNT AGAINST EACH PROGRESS STATUS(ONLY THOSE PROGRESS STATUS ARE INCLUDED WHICH HAVE ATLEAST AN APPLICATION ASSIGNED TO IT.)
  const getAnalystApplicationsCountByProgressStatus = async (token, startDate, endDate) => {
    try {
      const response = await axios.get(`reporting/analystApplicationsCountByProgressStatus?startDate=${startDate}&endDate=${endDate}`, { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }

  //ANALYST INSURANCE REPORT -analystInsuranceReport
  const getAnalystInsuranceReport = async (token, startDate, endDate) => {
    try {
      const response = await axios.get(`reporting/analystInsuranceReport?startDate=${startDate}&endDate=${endDate}`, { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }
  
  //ANALYST BUDGET REPORTING
  const getAnalystBudgetReport = async (token, startDate, endDate) => {
    try {
      const response = await axios.get(`reporting/analystBudgetReport?startDate=${startDate}&endDate=${endDate}`, { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }


  //ADMIN | ANALYST SETTLEMENT PERCENTAGE REPORTING
  const getSettlementRateAgainstReceivedApplications = async (token, startDate, endDate) => {
    try {
      const response = await axios.get(`reporting/settlementRateAgainstReceivedApplications?startDate=${startDate}&endDate=${endDate}`, { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }
  

/************************************ ADMIN - START  ******************************************/

  //ANALYST BUDGET REPORTING
  const getTotalReferralsReporting = async (token, startDate, endDate) => {
    try {
      const response = await axios.get(`reporting/totalReferralsReporting?startDate=${startDate}&endDate=${endDate}`, { headers: { Authorization: "Bearer " + token } });
      return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
      }
    }
  }

//SETTLEMENT REPORTING
 const getTotalSettledReferralReporting = async (token, startDate, endDate) => {
  try {
    const response = await axios.get(`reporting/totalSettledReferralReporting?startDate=${startDate}&endDate=${endDate}`, { headers: { Authorization: "Bearer " + token } });
    return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
  }
  catch (e) {
    if (e.response) {
      return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
    }
  }
}

//FinancedAmountReporting  totalFinancedAmountReporting
const getTotalFinancedAmountReporting = async (token, startDate, endDate) => {
  try {
    const response = await axios.get(`reporting/totalFinancedAmountReporting?startDate=${startDate}&endDate=${endDate}`, { headers: { Authorization: "Bearer " + token } });
    return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
  }
  catch (e) {
    if (e.response) {
      return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
    }
  }
}


//AverageIncomePerDealBeforeReferrerCommission
const getAverageIncomePerDealBeforeReferrerCommissionReporting = async (token, startDate, endDate) => {
  try {
    const response = await axios.get(`reporting/averageIncomePerDealBeforeReferrerCommissionReporting?startDate=${startDate}&endDate=${endDate}`, { headers: { Authorization: "Bearer " + token } });
    return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
  }
  catch (e) {
    if (e.response) {
      return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
    }
  }
}
//AverageIncomePerDealAfterReferrerCommission
const getAverageIncomePerDealAfterReferrerCommissionReporting = async (token, startDate, endDate) => {
  try {
    const response = await axios.get(`reporting/averageIncomePerDealAfterReferrerCommissionReporting?startDate=${startDate}&endDate=${endDate}`, { headers: { Authorization: "Bearer " + token } });
    return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
  }
  catch (e) {
    if (e.response) {
      return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
    }
  }
}

//BRANCH INCOME REPORTING
const getBranchIncomeReporting = async (token, startDate, endDate) => {
  try {
    const response = await axios.get(`reporting/branchIncomeReporting?startDate=${startDate}&endDate=${endDate}`, { headers: { Authorization: "Bearer " + token } });
    return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
  }
  catch (e) {
    if (e.response) {
      return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
    }
  }
}

/************************************ ADMIN - END  ******************************************/


/************************************ SUPER-ADMIN - START  ******************************************/
const loadReporting = async(token, data) => {
  try {
    console.log("data:::::::::::  ", data);
    const response = await axios.post(`reporting/loadReporting`, data,  { headers: { Authorization: "Bearer " + token } });
    return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
  }
  catch (e) {
    if (e.response) {
      return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: e.response.data }
    }
  }
}


/************************************ SUPER-ADMIN - END  ******************************************/

  export{
    getApplicationsCount, getApplicationsCountAgainstGivenProgressStatus, getApplicationsCountAgaintStatusAndAnalyst, 
    getAnalystDetailedCommissionReport, getAnalystGrossIncomeReport, getAnalystApplicationsCountByProgressStatus,
    getAnalystInsuranceReport, getAnalystBudgetReport, 
    
    getSettlementRateAgainstReceivedApplications,
    
    getTotalReferralsReporting , getTotalSettledReferralReporting, getTotalFinancedAmountReporting , 
    getAverageIncomePerDealAfterReferrerCommissionReporting, getAverageIncomePerDealBeforeReferrerCommissionReporting, 
    getBranchIncomeReporting,
    loadReporting
  }