/**
 * THESE ACTIONS ARE TRIGGERED FROM THE APPLICATION LISTING PAGE - 
 * WE HAVE MORE ACTIONS THAT ARE AVAILABLE IN COMPLIANCE-TAB BUT THEY ARE LOADED ONLY WHEN THE APPLICATION IS OPENED.
 */
import { useState, useEffect } from 'react';
import { RightDrawer, ConfirmDialog, CustomButton } from '../../HtmlComponents/';
import { getGeneralFiles, performApplicationAdditionalActions, getApplication } from "../../../api";
import { useRole, useToken, useEntityType, useEntityId } from '../../../hooks/useAuth';
import Notifications from '../../../components/global/Notifications';
import { useNavigate, useParams } from 'react-router-dom'
import LoadingScreen from '../../../components/global/LoadingScreen';


//APPLICATION COMPONENTS
import AssignToAnalyst from '../appComponents/AssignToAnalyst';
import UpdateProgressStatus from '../appComponents/UpdateProgressStatus';
import Comments from '../appComponents/Comments';
import AssignedReferrerDetails from '../appComponents/AssignedReferrerDetails';
import QuickReferral from '../appComponents/QuickReferral';
import PrintApplication from '../appComponents/PrintApplication';
import AdvanceSearch from '../appComponents/AdvanceSearch';
import ApplicationsCountAgaintStatusAndAnalyst from '../appComponents/ApplicationsCountAgainstStausAndAnalysts';
import ApplicationFiles from '../../files/ApplicationFiles';
import TaxInvoice from './TaxInvoice';
import SmsModule from './SmsModule';

//CREATE DECLINED 
import CreateDeclined from '../../declined/CreateDeclined';

const SingleApplicationActions = ({ name, value, callback }) => {
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });
    const [applicationId, setApplicationId] = useState(0);
    const [application, setApplication] = useState({});
    const [applicants, setApplicants] = useState({});
    const [loading, setLoading] = useState(false);

    const token = useToken();
    const navigate = useNavigate();

    useEffect(() => {
        updateHandler(name, value);
    }, [name]);

    const loadApplication = async (applicationId) => {
        const response = await getApplication(applicationId, token);
        if (response["httpCode"] == 200) {
            //CHECK IF IT HAS AN APPLICANTS ARRAY AND IT CONTAINS APPLICANTS THEN ASSIGN FIRST APPLICANT TO APPLIANT STATE
            if (response.data.applicants !== undefined && response.data.applicants[0] !== undefined) {
                setApplicants(response.data.applicants);
            } setApplication(response.data);
            setLoading(false);
        }
        else {
            Notifications("error", response.message || "Error")
            setLoading(false);
        }
    }


    const updateHandler = async (name, value) => {
        switch (name) {
            case "applicationFiles":
                setDrawerSettings({ visible: true, heading: `Files (Referral # ${value} )`, childName: "applicationFiles", meta: { width: "800", applicationId: value } })
                break;
            //STEP 1: COPY APPLICATION - OPEN POPUP FOR CONFIRMATION
            case "copyApplication":
                console.log("COPY APPLICATION", value);
                //THIS WILL OPEN THE POPUP FOR CONFIRMATION , ON CONFORMATION  executeCopyApplicationOperation WILL BE EXECUTED
                ConfirmDialog({
                    callback: () => { updateHandler("keepReferrerDetailsForCopiedApplication", { applicationId: value }) },
                    onCloseHandler: () => { updateHandler("closeDrawer") },
                    "purpose": "general",
                    "heading": `Are you sure you want to copy Ref # ${value}`, "description": "Documents will not be carried over.",
                    "firstButton": "No",
                    "secondButton": "Yes, Copy it!"
                });
                break;
            //STEP 2 - COPY APPLICATION - ASK IF WANT TO COPY THE REFERRER DETAILS OR NOT
            case "keepReferrerDetailsForCopiedApplication":
                setLoading(true);
                await new Promise(r => setTimeout(r, 1000));
                setLoading(false);

                ConfirmDialog({
                    callback: () => { updateHandler("executeCopyApplicationOperation", { applicationId: value["applicationId"], keepReferrerDetails: true }) },
                    onCloseHandler: () => { updateHandler("executeCopyApplicationOperation", { applicationId: value["applicationId"], keepReferrerDetails: false }) },
                    "purpose": "general",
                    "heading": `Would you like to retain the referrer details as well?`,
                    "description": "Please click 'No' if you wish to copy the application but prefer not to retain the referrer details..",
                    "firstButton": "No",
                    "secondButton": "Yes"
                });
                break;
            case "executeCopyApplicationOperation":
                console.log("value line 75 ----------  ", value);
                 
                var response = await performApplicationAdditionalActions(token, "copyApplication", value["applicationId"], {keepReferrerDetails: value["keepReferrerDetails"]}, {});
                console.log("response line 77 ----------  ", response);
                //callback("hideSingleApplicationActions");
                if (response["error"] != undefined && response["error"] == 1) {
                    Notifications("error", response["message"] || "Some error occured.")
                }
                else {
                    Notifications("success", response["message"] || "Created Successfully.");
                    var applicationId = response["application"]["id"];
                    navigate(`/user/editConsumerApplication/${applicationId}`);
                }
                break;

            case "deleteApplication":
                ConfirmDialog({
                    callback: () => { updateHandler("executeDeleteApplicationOperation", value) },
                    onCloseHandler: () => { updateHandler("closeDrawer") },
                    "purpose": "general", "heading": `Are you sure you want to delete Ref # ${value}`, "description": ""
                })
                break;
            case "executeDeleteApplicationOperation":
                var response = await performApplicationAdditionalActions(token, "deleteApplication", value, {});
                if (response["error"] != undefined && response["error"] == 1) {
                    Notifications("error", response["message"] || "Some error occured.")
                }
                else {
                    Notifications("success", response["message"] || "Deleted Successfully.");
                    callback("fetchApplications"); // CALLBACK TO UPDATE THE LISTING
                    //fetchApplications(); // CALLBACK TO UPDATE THE LISTING
                }
                break;
            case "addAnotherApplicant": setDrawerSettings({ visible: true, heading: "Add another applicant", childName: "assignToAnalyst", meta: { applicationId: value['applicationId'], analystId: value["analystId"] } }); break;
            case "assignToAnalyst": setDrawerSettings({ visible: true, heading: "Assign to analyst", childName: "assignToAnalyst", meta: { applicationId: value['applicationId'], analystId: value["analystId"] } }); break;
            case "updateProgressStatus": setDrawerSettings({ visible: true, heading: "Status", childName: "updateProgressStatus", meta: { applicationId: value['applicationId'], progressStatus: value["progressStatus"], applicationType: value["applicationType"], loanPurpose: value["loanPurpose"] } }); break;
            case "viewComentsOnly": setDrawerSettings({ visible: true, heading: "Comments", childName: "Comments", meta: { applicationId: value['applicationId'], progressStatus: value["progressStatus"] } }); break;

            case "fetchReferrerDetails": setDrawerSettings({ visible: true, heading: "Referrer Details", childName: "fetchReferrerDetails", meta: { applicationId: value['applicationId'] } }); break;
            case "createDeclined": setDrawerSettings({ visible: true, heading: "Decline", childName: "createDeclined", meta: { applicationId: value['applicationId'] } }); break;


            case "quickReferral": setDrawerSettings({ visible: true, heading: "Referral Details", childName: "quickReferral", meta: {} }); break;
            //POP UP ASKING REFERRER IF THEY WANT TO UPLOAD THE DOCUMENT FOR NEWLY CREATED APPLICAITON.
            case "uploadDocumentsPopUp":
                var tmpApplicationId = value;
                updateHandler("closeDrawer");
                ConfirmDialog({
                    callback: () => { updateHandler("applicationFiles", tmpApplicationId) },
                    onCloseHandler: () => { updateHandler("closeDrawer") },
                    "purpose": "general", 
                    "heading": `Would you like to upload any supporting documents such as Retail Buyers Order / Invoice ?`, 
                    "description": "Documents can be uploaded any time using upload folder under Actions."
                })
                break;
            case "printApplication": setDrawerSettings({ visible: true, heading: "Application", childName: "printApplication", meta: { applicationId: value['applicationId'] } }); break;
            case "addComments": break;
            case "applicationsCountAgaintStatusAndAnalyst": setDrawerSettings({ visible: true, heading: "", childName: "applicationsCountAgaintStatusAndAnalyst" }); break;
            case "taxInvoice":
                setApplicationId(value['applicationId']);
                await loadApplication(value['applicationId']);
                setDrawerSettings({ visible: true, heading: "Tax Invoice", childName: "taxInvoice", meta: { applicationId: value['applicationId'] } });
                break;
            case "smsModule":
                setApplicationId(value['applicationId']);
                await loadApplication(value['applicationId']);
                setDrawerSettings({ visible: true, heading: "SMS", childName: "smsModule", meta: { applicationId: value['applicationId'] } });
                break;

            case "closeDrawer": setDrawerSettings({ visible: false }); callback("fetchApplications"); // /*CALLBACK TO UPDATE THE LISTING
                break;
        }
    }

    return (
        <>
            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={updateHandler}>
                    {drawerSettings["childName"] === "assignToAnalyst" ? <AssignToAnalyst applicationId={drawerSettings["meta"]["applicationId"]} currentAnalystId={drawerSettings["meta"]["analystId"]} closeDrawer={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "updateProgressStatus" ? <UpdateProgressStatus applicationId={drawerSettings["meta"]["applicationId"]} currentProgressStatus={drawerSettings["meta"]["progressStatus"]} applicationType={drawerSettings["meta"]["applicationType"]} loanPurpose={drawerSettings["meta"]["loanPurpose"]} callback={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "Comments" ? <Comments applicationId={drawerSettings["meta"]["applicationId"]} currentProgressStatus={drawerSettings["meta"]["progressStatus"]} callback={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "fetchReferrerDetails" ? <AssignedReferrerDetails applicationId={drawerSettings["meta"]["applicationId"]} callback={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "quickReferral" ? <QuickReferral callback={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "applicationFiles" ? <ApplicationFiles entityType="application" entityId={drawerSettings["meta"]["applicationId"]} enableUpload={true} /> : ""}
                    {drawerSettings["childName"] === "printApplication" ? <PrintApplication applicationId={drawerSettings["meta"]["applicationId"]} /> : ""}
                    {drawerSettings["childName"] === "applicationsCountAgaintStatusAndAnalyst" ? <ApplicationsCountAgaintStatusAndAnalyst /> : ""}
                    {drawerSettings["childName"] === "createDeclined" ? <CreateDeclined applicationId={drawerSettings["meta"]["applicationId"]} callback={() => { updateHandler("closeDrawer"); }} /> : ""}
                    {drawerSettings["childName"] === "taxInvoice" ? <TaxInvoice application={application} applicationId={applicationId} applicant={applicants[0]} callback={updateHandler} /> : ""}
                    {drawerSettings["childName"] === "smsModule" ? <SmsModule entityType="application" entityId={applicationId} applicants={applicants} callback={updateHandler} /> : ""}

                </RightDrawer> : ""
            }
            {
                loading ? <LoadingScreen /> : ""
            }


        </>
    )

}

export default SingleApplicationActions;
