import { Link } from "react-router-dom";
import { formatDate, calculateTimeDifference, capitalizeFirstLowercaseRest } from '../../helpers';
import { CustomButton, IconButton } from '../HtmlComponents/';
import constants from '../../components/global/Constants';
import { useEntityType, useRole } from '../../hooks/useAuth';

const ApplicationListItem = ({ singleApplication, type, callback }) => {
    const entityType = useEntityType();
    const userRole = useRole();

    function calculateTimeForLatsUpdate(timestamp) {
        var hours = calculateTimeDifference(singleApplication.updatedAt);
        var progressStatus = singleApplication.progressStatus;

        var label = "";
        if (hours < 72) { label = hours + " hrs" }
        else { label =  Math.round(hours / 24) + " days"; }

        var returnValue = '';
        switch(progressStatus){
            case "assignedtToAnalyst": if(hours > 1){ returnValue = <span className="blink">{label}</span>} else{ returnValue = <span className="">{label}</span> }; break;
            case "attemptedToContactCustomer": if(hours > 4){ returnValue = <span className="blink">{label}</span>} else{ returnValue = <span className="">{label}</span> }; break;
            case "customerContacted-WaitingOnPrivacyConsents": if(hours > 24){ returnValue = <span className="blink">{label}</span>} else{ returnValue = <span className="">{label}</span> }; break;
            case "loanApplicationTaken-Assessing": if(hours > 24){ returnValue = <span className="blink">{label}</span>} else{ returnValue = <span className="">{label}</span> }; break;
            case "loanApplicationSubmitted": if(hours > 24){ returnValue = <span className="blink">{label}</span>} else{ returnValue = <span className="">{label}</span> }; break;
            case "loanContractsSentForSigning": if(hours > 3){ returnValue = <span className="blink">{label}</span>} else{ returnValue = <span className="">{label}</span> }; break;
            case "loanContractsSigned": if(hours > 3){ returnValue = <span className="blink">{label}</span>} else{ returnValue = <span className="">{label}</span> }; break;
            case "loanContractsSubmittedForSettlement": if(hours > 24){ returnValue = <span className="blink">{label}</span>} else{ returnValue = <span className="">{label}</span> }; break;
            case "pendingAdditionalInformation": if(hours > 24){ returnValue = <span className="blink">{label}</span>} else{ returnValue = <span className="">{label}</span> }; break;
            case "reassigned": if(hours > 0){ returnValue = <span className="blink">{label}</span>} else{ returnValue = <span className="">{label}</span> }; break;
            default: returnValue = <span className="">{label}</span>; break;
        } 
        return returnValue;
        //<span className="blink">
       
    }

    const calculateTimeSinceCreated = () => {
        //CALCULATE TIME SINCE APPLICATION CREATED 
        var hoursSinceCreated = calculateTimeDifference(singleApplication.createdAt);
        var hoursSinceCreatedLabel = ""; 

        if(hoursSinceCreated > 168 ){ hoursSinceCreatedLabel =  Math.round(hoursSinceCreated / 24) + " days"; }
        else{hoursSinceCreatedLabel = hoursSinceCreated + " hrs" ;}

        return hoursSinceCreatedLabel;
    }

    //RETURN THE NUMBER OF COLUMNS BASED ON LOGGED IN USER ENTITY TYPE
    const TableRow = () => {
        if (["referrerGroup"].includes(entityType) || ["referrerGroupManager"].includes(userRole)) {
            let applicationStatus = singleApplication.progressStatus != undefined ? (singleApplication.progressStatus.toLowerCase() === "created" ? "Submitted" : singleApplication.progressStatus) : " NA";

            return (
                <>
                    <tr className="even text-start">
                        <td> <Link to={{ pathname: `/user/editConsumerApplication/${singleApplication.id}` }} className="text-gray-800 text-hover-primary mb-1">{singleApplication.id}</Link> </td>
                        <td>
                            {singleApplication.firstName !== null && singleApplication.firstName !== undefined ? singleApplication.firstName + " " : ""}
                            {singleApplication.surname}
                            {singleApplication.category === "company" ? <><br></br><span style={{ fontSize: "10px" }}>{singleApplication.companyName}</span></> : ''}
                        </td>
                        <td>
                            {formatDate(singleApplication.createdAt, "unix", "DD/MM/YYYY HH:mm:ss")}
                        </td>
                        <td>{singleApplication.amount}</td>
                        <td>{constants.LOAN_PURPOSES[singleApplication.purpose]}</td>
                        <td>{capitalizeFirstLowercaseRest(singleApplication.type || "  ")}</td>
                        <td>{singleApplication.analyst}</td>
                        <td> <span className="applicationStatus" onClick={() => { callback("updateProgressStatus", { "applicationId": singleApplication.id, "progressStatus": singleApplication.progressStatus }) }}> {applicationStatus} </span></td>
                        <td>{singleApplication.referrerGroup}</td>
                        <td> <span className="activeReferrer" onClick={() => { callback("fetchReferrerDetails", { "applicationId": singleApplication.id }) }}> {singleApplication.referrer != undefined ? singleApplication.referrer : " NA"} </span></td>
                    </tr>
                </>
            )
        }
        else if (entityType == "referrer") {
            let applicationStatus = "";
            switch (singleApplication.progressStatus) {
                case "Declined Pending Final Review": applicationStatus = "Assessing"; break;
                case "Assigned to Concierge": applicationStatus = "Assigned to Analyst"; break;
                case "Settlement Complied": applicationStatus = "Loan Contracts Settled with Lender"; break;
                case "Approved - Waiting on Goods to Arrive": applicationStatus = "Approved"; break;
                default: applicationStatus = singleApplication.progressStatus;
            }
            return (
                <>
                    <tr className="even text-start">
                        <td> {singleApplication.id} </td>
                        <td>
                            {singleApplication.firstName !== null && singleApplication.firstName !== undefined ? singleApplication.firstName + " " : ""}
                            {singleApplication.surname}
                            {singleApplication.category === "company" ? <><br></br><span style={{ fontSize: "10px" }}>{singleApplication.companyName}</span></> : ''}
                        </td>
                        <td>{formatDate(singleApplication.createdAt, "unix", "DD/MM/YYYY HH:mm")}</td>
                        <td>{constants.LOAN_PURPOSES[singleApplication.purpose]}</td>
                        <td>{singleApplication.analyst}</td>
                        <td> <span className="applicationStatus" onClick={() => { callback("viewComentsOnly", { "applicationId": singleApplication.id, "progressStatus": singleApplication.progressStatus }) }}> {applicationStatus} </span></td>
                        <td>
                            <IconButton iconType="files" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Files" buttonOnClick={() => { callback("applicationFiles", singleApplication.id) }} />
                        </td>
                    </tr>
                </>
            )
        }
        else {
            //DECLIEND BUTTON
            //ThumbDown
            var turnDownLink = "";
            var applicationStatus = singleApplication.progressStatus !== undefined ? singleApplication.progressStatus : undefined;
            if (applicationStatus !== undefined && ["unableToAssist", "customerNotResponding-UnableToAssist", "withdrawnByCustomer-UnableToAssist", "withdrawnByReferrer-UnableToAssist"].includes(applicationStatus)) {
                var turnDownLink = <Link to={{ pathname: `/user/createDeclined/${singleApplication.id}` }} className=" applicationId text-hover-primary mb-1"><IconButton iconType="thumbDown" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px", color: "#b20f0f" }} tootltip={true} tooltipDescription="Unable to Assist" buttonOnClick={() => { }} /> </Link>;
            }

            {/** APPLICATION ACTIONS IF CREDIT-REP / COMPANY BUT NOT ADMIN */ }
            var actions = (
                <>
                    <IconButton iconType="addPerson" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Assign to analyst" buttonOnClick={() => { callback("assignToAnalyst", { "applicationId": singleApplication.id, "analystId": singleApplication.analystId }) }} />
                    <IconButton iconType="sms" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="SMS" buttonOnClick={() => { callback("smsModule", { "applicationId": singleApplication.id }) }} />                        
                    <IconButton iconType="files" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Files" buttonOnClick={() => { callback("applicationFiles", singleApplication.id) }} />
                    <IconButton iconType="printer" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Print" buttonOnClick={() => { callback("printApplication", { "applicationId": singleApplication.id }) }} />
                    <IconButton iconType="copy" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Copy" buttonOnClick={() => { callback("copyApplication", singleApplication.id) }} />

                </>
            );

            { /** APPLICATIONS ACTIONS IF USER ROLE IS AUDITOR */ }
            if (userRole == "auditor") {
                var actions = (
                    <>
                        <IconButton iconType="files" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Files" buttonOnClick={() => { callback("applicationFiles", singleApplication.id) }} />
                        <IconButton iconType="printer" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Print" buttonOnClick={() => { callback("printApplication", { "applicationId": singleApplication.id }) }} />
                    </>
                );
            }


            { /** APPLICATIONS ACTIONS IF USER ROLE IS ADMIN */ }
            if (userRole == "admin") {
                var actions = (
                    <>
                        <IconButton iconType="addPerson" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Assign to analyst" buttonOnClick={() => { callback("assignToAnalyst", { "applicationId": singleApplication.id, "analystId": singleApplication.analystId }) }} />
                        <IconButton iconType="sms" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="SMS" buttonOnClick={() => { callback("smsModule", { "applicationId": singleApplication.id }) }} />
                        <IconButton iconType="files" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Files" buttonOnClick={() => { callback("applicationFiles", singleApplication.id) }} />
                        <IconButton iconType="printer" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Print" buttonOnClick={() => { callback("printApplication", { "applicationId": singleApplication.id }) }} />
                        <IconButton iconType="copy" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Copy" buttonOnClick={() => { callback("copyApplication", singleApplication.id) }} />
                        {turnDownLink}
                        <IconButton iconType="deleteBin" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "15px", color: "#b20f0f" }} tootltip={true} tooltipDescription="Delete" buttonOnClick={() => { callback("deleteApplication", singleApplication.id) }} />
                    </>
                );
            }

            if (type == "deleted") {
                actions = (
                    <>
                        <IconButton iconType="files" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Files" buttonOnClick={() => { callback("applicationFiles", singleApplication.id) }} />
                        <IconButton iconType="printer" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} tootltip={true} tooltipDescription="Print" buttonOnClick={() => { callback("printApplication", { "applicationId": singleApplication.id }) }} />
                    </>
                );
            }

            return (
                <>
                    <tr className="even text-start">
                        <td> <Link to={{ pathname: `/user/editConsumerApplication/${singleApplication.id}` }} className=" applicationId text-hover-primary mb-1">{singleApplication.id}</Link> </td>
                        <td>
                            {singleApplication.firstName !== null && singleApplication.firstName !== undefined ? singleApplication.firstName + " " : ""}
                            {singleApplication.surname}
                            {singleApplication.category === "company" ? <><br></br><span style={{ fontSize: "10px" }}>{singleApplication.companyName}</span></> : ''}
                        </td>
                        { /*<td>{singleApplication.surname}</td> */}
                        <td>
                            <div>{formatDate(singleApplication.createdAt, "unix", "DD/MM/YYYY HH:mm:ss")}</div>
                            <div>{calculateTimeSinceCreated()}</div>
                        </td>
                        <td>{singleApplication.analyst}</td>
                        <td> <span className="applicationStatus" onClick={() => { callback("updateProgressStatus", { "applicationId": singleApplication.id, "progressStatus": singleApplication.progressStatus, applicationType: singleApplication.type, loanPurpose: singleApplication.purpose }) }}> {singleApplication.progressStatusLabel != undefined ? singleApplication.progressStatusLabel : " NA"} </span></td>
                        <td> {calculateTimeForLatsUpdate()} </td>
                        <td>{singleApplication.referrerGroup}</td>
                        <td> <span className="activeReferrer" onClick={() => { callback("fetchReferrerDetails", { "applicationId": singleApplication.id }) }}> {singleApplication.referrer != undefined ? singleApplication.referrer : " NA"} </span></td>
                        <td> <span className="" > {singleApplication.referrerUserName != undefined ? singleApplication.referrerUserName : " NA"} </span></td>

                        <td> {actions} </td>
                    </tr>
                </>
            )
        }
    }


    /** editConsumerApplication => use path based on applicaiton type , FOR CONSUMER APPLICATIONS USE editConsumerApplication, OTHERWISE USE COMMERCIAL ONE */
    return (
        <>
            <TableRow />
        </>
    )
}

export default ApplicationListItem;