import axios from './axios';

const createLead = async (leadData) => {
  try {
    const response = await axios.post('/public/newLead', leadData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteLead = async (token, id) => {
  try {
    const response = await axios.delete(`leads/${id}` , { headers: { Authorization: "Bearer " + token } } );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getLeads = async (token, query) => {
  try {
    const response = await axios.get(`leads/listing?${query}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0 , httpCode: response.status, "message":  "", data: response.data ? response.data : [] }
   
  }
  catch (e) {
    if (e.response) {      
    console.log(e.response.data)
      return { "error": 1,httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, applications: {} }
    }
  }
}
const convertLeadToApplication = async (token, id, analystId) => {
  try {
    const response = await axios.post(`leads/convertLeadToApplication`, { analystId: analystId, leadId: id },  { headers: { Authorization: "Bearer " + token } });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export { createLead, deleteLead, getLeads, convertLeadToApplication };
