import React, { useState, useEffect } from 'react';
import { useToken, useEntityType, useEntityId, useRole } from '../../hooks/useAuth';
import { getEquifaxFees, listEquifaxFees, createEquifaxFees, updateEquifaxFees } from '../../api';
import EquifaxListItem from './EquifaxListItem';
import { CustomButton, InputField, ConfirmDialog } from '../HtmlComponents';
import { Navigate, NavigationType, useNavigate } from 'react-router-dom';
import Notifications from '../global/Notifications';
import RightDrawer from "../HtmlComponents/modals/RightDrawer";
import AddEquifaxFees from './AddEquifaxFees';
import EditEquifaxFees from './EditEquifaxFees';


const ListEquifaxFees = () => {

    const [equifaxFeesList, setEquifaxFeesList] = useState([]);  //list of all equifax fees
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });

    const token = useToken();
    const entityId = useEntityId();
    const entityType = useEntityType();
    const role = useRole();

    const status= "active";  //later on pass this as param and get in ListReferrerGroups() to identify if we need to list active or archived referrer groups

    let navigate = useNavigate();

    //FETCH REFERER GROUPS LIST
    const fetchEquifaxFeesList = async () => {
        const result = await listEquifaxFees(token);  
        console.log(result);       
        if (result["error"] == 0 && result["data"]) {  setEquifaxFeesList(result["data"]);  }
        else {  Notifications("error", result["message"] !== undefined ? result["message"] : "Request failed.")  }
    }

    useEffect(() => {     
        fetchEquifaxFeesList();
    }, []);

    const handler = async (name, value) => {
        switch (name) {
            case "archiveReferrerGroup":
                ConfirmDialog({ callback: () => { handler("executeArchiveReferrerGroup", value) }, "purpose": "general", "heading": `Are you sure you want to archive it?`, "description": "" })
                break;
            case "openCreateExuifaxFeesModal":
                setDrawerSettings({ visible: true, heading: "Create Equifax Fees", childName: "addEquifaxFees", meta: {} });
                break;
            case "addEquifaxFeesToList":
                    fetchEquifaxFeesList();
                    //handler("closeDrawer");
                break;    
                
            case "editEquifaxFees":
                setDrawerSettings({ visible: true, heading: "Edit Equifax Fees", childName: "editEquifaxFees", meta: { "equifaxFeeId": value } });    
                break;
            case "closeDrawer":
                setDrawerSettings({ visible: false, heading: "", childName: "", meta: {} });
                break;    
            case "executeArchiveReferrerGroup":
/*
                var response = await deleteEquifaxFees(value, token);
                console.log(response);
                if (response["error"] != undefined && response["error"] == 1) {
                    Notifications("error", response["message"] || "Some error occured.")
                }
                else {
                    Notifications("success", response["message"] || "Deleted Successfully.");
                    fetchReferrerGroupList();
                }

                    console.log("value-----------", value)
                break;
                */
        }
    }


    const CreateEquifaxFeesButtonHTML = () => {
        if ( ["company"].includes(entityType) && ["superAdmin",  "finance"].includes(role)) {
            return (
                <>
                <div className='row'>
                    <div className='cols-m-12'>
                    <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right", marginBottom: "10px" }} buttonOnClick={() => { handler("openCreateExuifaxFeesModal") }} buttonText="Create" />
                    </div>
                </div>
                </>
            )
        }
        else {
            return "";
        }
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <CreateEquifaxFeesButtonHTML />

                <div className="card" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ }}>Name</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ }} >TXN Code</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{  }}>Amount</th>
                                            {status == "active" ? <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">Actions</th> : ""}
                                        </tr>
                                    </thead>
                                    <tbody className="fw-bold">
                                        {
                                            equifaxFeesList && Array.isArray(equifaxFeesList) ?
                                            equifaxFeesList.map(singleEquifaxFees => (
                                                    <EquifaxListItem key={singleEquifaxFees.id} item={singleEquifaxFees} callback={handler} status= {status} />
                                                )) : <tr></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={handler}>
                    {drawerSettings["childName"] === "addEquifaxFees" ? <AddEquifaxFees callback={handler}  closeDrawer={handler} /> : ""}
                    {drawerSettings["childName"] === "editEquifaxFees" ? <EditEquifaxFees callback={handler}  closeDrawer={handler} equifaxFeeId={drawerSettings.meta.equifaxFeeId} /> : ""}
                </RightDrawer> : ""
            }
        </>
    )
}

export default ListEquifaxFees;