import { useEffect, useState } from "react";
import { SelectField, CustomButton, CheckBox } from "../HtmlComponents";
import { getCurrentDate, getPreviousMonth, getCurrentMonth, convertDateintoUnix, getLastDayOfMonth } from '../../helpers';
import { getReferrerGroupsList } from '../../api/referrerGroupApi';
import { createBrokerRCTI } from '../../api/';
import { useToken } from "../../hooks/useAuth";
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';
import Select from 'react-select';

const BrokerRCTI = () => {
    const [referrerGroups, setReferrerGroups] = useState([]);
    const [selectedReferrerGroups, setSelectedReferrerGroups] = useState([]);
    const [month, setMonth] = useState("");     //current, previous
    const [duration, setDuration] = useState({ fromMonth: 0, fromYear: 0, toMonth: 0, toYear: 0 });
    const [rebates, setRebates] = useState([]);
    const [fileFormat, setFileFormat] = useState("pdf");
    const [processing, setProcessing] = useState(false);

    const months = [{ value: "01", label: "January" }, { value: "02", label: "February" }, { value: "03", label: "March" }, { value: "04", label: "April" },
    { value: "05", label: "May" }, { value: "06", label: "June" }, { value: "07", label: "july" }, { value: "08", label: "August" }, { value: "09", label: "September" },
    { value: "10", label: "October" }, { value: "11", label: "November" }, { value: "12", label: "December" },];
    const [years, setYears] = useState([]);
    const [allowSubmission, setAllowSubmission] = useState(false);

    var token = useToken();

    const updateMonthsAndYears = () => {
        var localYears = [];
        for (var i = 2018; i <= getCurrentDate("YYYY"); i++) {
            localYears.push({ value: i, label: i });
        }
        setYears(localYears);
    }

    async function fetchReferrerGroupsList() {
        const result = await getReferrerGroupsList(token);

        var referrerGrousps = [];
        if (result["error"] == 0 && result["referrerGroups"] != undefined) { referrerGrousps = result["referrerGroups"]; }

        var referrerGroupList = [];
        if (referrerGrousps !== undefined && Array.isArray(referrerGrousps)) {
            referrerGrousps.map(singleReferrerGroup => { 
                referrerGroupList.push({ 
                    "value": singleReferrerGroup["id"], 
                    "label": singleReferrerGroup["tradingName"] 
                }) 
            })
            setReferrerGroups(referrerGroupList)
        }
    }

    useEffect(() => {
        updateMonthsAndYears();
        fetchReferrerGroupsList();
    }, [])

    useEffect(() => {
        if (duration["fromMonth"] > 0 && duration["fromYear"] > 0 && duration["toMonth"] > 0 && duration["toYear"] > 0) {
            setAllowSubmission(true);
        }
        else if (month !== "") { setAllowSubmission(true); }
        else { setAllowSubmission(false); }

    }, [duration, month])

    useEffect(() => {
        console.log("rebates", rebates);
    }, [rebates]);

    useEffect(() => {
        // Check if at least one referrer group is selected
        if (selectedReferrerGroups.length > 0) {
            setAllowSubmission(true);
        } else {
            setAllowSubmission(false);
        }
    }, [selectedReferrerGroups]);

    const handleReferrerGroupChange = (selectedOptions) => {
        setSelectedReferrerGroups(selectedOptions || []);
    };

    const handler = async (name, value) => {
        switch (name) {
            case "monthSelection":
                setMonth(value);
                break;
            case "fromMonth":
                setDuration({ ...duration, fromMonth: value })
                break;
            case "fromYear":
                setDuration({ ...duration, fromYear: value })
                break;
            case "toMonth":
                setDuration({ ...duration, toMonth: value })
                break;
            case "toYear":
                setDuration({ ...duration, toYear: value })
                break;
            case "addRebate":
                setRebates(current => [...current, { description: "", amount: "" }])
                break;
            case "updateRebateValue":
                var fieldName = value["name"];
                var fieldValue = value["value"];
                var index = value["index"];

                setRebates(current => current.map((rebate, rebateIndex) => { return index == rebateIndex ? { ...rebate, [fieldName]: fieldValue } : rebate; }))
                break;
            case "deleteRebate":
                var index = value["index"];
                setRebates(current => current.filter((rebate, rebateIndex) => index !== rebateIndex))
                break;
            case "clearFilters":
                setDuration({ ...duration, fromMonth: "", fromYear: "", toMonth: "", toYear: "" });
                setMonth("");
                setSelectedReferrerGroups([]);
                break;
            case "csvFormat":
                if(value === true){setFileFormat("csv");}else{setFileFormat("pdf");}
                console.log("CSV FORMAT:: ", value); 
                break;   
            case "submit":
                setProcessing(true);
                var startDate = 0;
                var endDate = 0;

                if (month.length > 0) {
                    if (month === "currentMonth") {
                        startDate = convertDateintoUnix(getCurrentMonth("firstDay"), "YYYY-MM-DD H:mm:ss");
                        endDate = convertDateintoUnix(getCurrentMonth("lastDay"), "YYYY-MM-DD H:mm:ss");
                    } else {
                        startDate = convertDateintoUnix(getPreviousMonth("firstDay"), "YYYY-MM-DD H:mm:ss");
                        endDate = convertDateintoUnix(getPreviousMonth("lastDay"), "YYYY-MM-DD H:mm:ss");
                    }
                }
                else {
                    //DURATION IS SELECTED
                    startDate = convertDateintoUnix(`01/${duration["fromMonth"]}/${duration["fromYear"]}`);
                    endDate = convertDateintoUnix(getLastDayOfMonth(`01/${duration["toMonth"]}/${duration["toYear"]}`) + " 23:59:59" , "YYYY-MM-DD H:mm:ss"); 
                }

                // Extract referrer group IDs from selected options
                const referrerGroupIds = selectedReferrerGroups.map(group => group.value);
                
                var paylaod = { 
                    startDate: startDate, 
                    endDate: endDate, 
                    referrerGroupIds: referrerGroupIds, 
                    fileFormat: fileFormat
                };
                console.log("PAYLOAD::: ", paylaod);
                
                //MAKE API REQUEST
                var resp = await createBrokerRCTI(paylaod, token);
                console.log("API resp:: ", resp);

                if(resp["error"]){
                    Notifications("error", resp["message"] || "Unable to process the request. " )
                }
                else{
                    if(fileFormat === "csv"){
                        var csvBytes = resp["broker"]["fileData"]; 
                        const link = document.createElement("a");
                        link.setAttribute("href", csvBytes);
                        link.setAttribute("download", "data.csv");
                        document.body.appendChild(link); // Required for Firefox
                        link.click();

                    }
                    else{
                        var pdfBytes = resp["broker"]["fileData"];
                        //PDF-BYTES IS AN OBJECT WITH KEYS AND VALUES, ADD ALL VALUES TO ARRAY                  
                        const bytes = new Uint8Array(Object.values(pdfBytes));
                        const blob =  new Blob([bytes], { type: "application/pdf" });
                        const docUrl = URL.createObjectURL(blob);
        
                        let alink = document.createElement('a');
                        alink.href = docUrl;
                        alink.download = 'BrokerRCTI.pdf';
                        alink.click();
                        Notifications("success", resp["message"] || "Downloaded Successfully. " )
                    }
                }
                setProcessing(false);
                break;
        }
    }

    return (<>
        <div id="kt_content_container" className="container-xxl">
            <div className="row g-5 g-xl-8">
                <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                    <div className="card-body">
                        <div className="row">

                            <div className="col-sm-12 mb-5" style={{ padding: "0px" }} >
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>Referrer Groups</label>
                                <Select
                                    isMulti
                                    name="referrerGroups"
                                    options={referrerGroups}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={handleReferrerGroupChange}
                                    value={selectedReferrerGroups}
                                    placeholder="Select Referrer Groups"
                                />
                            </div>

                            { /** SEARCH TYPE 1 **/}
                            <div className="col-sm-2 fieldSet" >
                                <div className="heading">Select</div>
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>Select Month</label>
                                <SelectField
                                    fieldName="monthSelection"
                                    defaultSelectedOption={{ value: month, label: "" }}
                                    options={[{ "value": "", "label": "Select Month" }, { "value": "currentMonth", "label": "Current Month" }, { "value": "previousMonth", "label": "Previous Month" }]}
                                    setFieldValue={handler}
                                />
                            </div>

                            { /** SEARCH TYPE 2 **/}
                            <div className="col-sm-10 fieldSet">
                                <div className="heading">Or Search</div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>From Month</label>
                                        <SelectField
                                            fieldName="fromMonth"
                                            defaultSelectedOption={{ value: duration['fromMonth'], label: "" }}
                                            options={months}
                                            setFieldValue={handler}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>From Year</label>
                                        <SelectField
                                            fieldName="fromYear"
                                            defaultSelectedOption={{ value: duration['fromYear'], label: "" }}
                                            options={years}
                                            setFieldValue={handler}
                                        />
                                    </div>

                                    <div className="col-sm-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>To Month</label>
                                        <SelectField
                                            fieldName="toMonth"
                                            defaultSelectedOption={{ value: duration['toMonth'], label: "" }}
                                            options={months}
                                            setFieldValue={handler}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <label style={{ fontSize: "10px", fontWeight: "600" }}>To Year</label>
                                        <SelectField
                                            fieldName="toYear"
                                            defaultSelectedOption={{ value: duration['toYear'], label: "" }}
                                            options={years}
                                            setFieldValue={handler}
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="col-sm-12">
                                {/** CHECKBOX TO ASK IF ITS CSV **/}
                                <div style={{ display: "flex", justifyContent: "start", marginTop:"10px" }} className="mb-2">
                                    <CheckBox name="csvFormat" defaultChecked={false} setFieldValue={handler} />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> Download CSV </span>
                                </div>
                            </div>

                     

                            <div className="col-sm-12">
                                <CustomButton
                                    buttonClassName="btn btn-sm btn-primary "
                                    buttonStyle={{ marginTop: "10px", float: "right" }}
                                    buttonOnClick={() => { handler("submit", "") }}
                                    buttonText="Submit"
                                    isActive={allowSubmission && selectedReferrerGroups.length > 0}
                                />

                                <CustomButton
                                    buttonClassName="btn btn-sm btn-secondary "
                                    buttonStyle={{ marginTop: "10px", float: "right", marginRight: "20px" }}
                                    buttonOnClick={() => { handler("clearFilters", "") }}
                                    buttonText="Clear Filters"
                                    isActive={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {processing ? <LoadingScreen /> : ""}
    </>)
}

export default BrokerRCTI;
