
import { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { FormSubmissionButton, SelectField, RadioGroup, InputField, CheckBox, IconButton } from '../../HtmlComponents/index.js';
import { formatDate, getCurrentDate } from '../../../helpers/index.js';
import Notifications from "../../global/Notifications.js";

import { createApplicantAccessToken, getApplicantAccessTokensByApplicationId , deleteApplicantAccessTokens} from "../../../api/index.js";
import { useToken } from '../../../hooks/useAuth.js';

import LoadingScreen from '../../global/LoadingScreen.js';

import ApplicantTokenValidationSchema from '../schema/ApplicantTokenValidationSchema.js';

const CreateApplicantAccessToken = ({ applicationId, applicants }) => {
    const [applicantOptions, setApplicantOptions] = useState([]);
    const [applicantsTokens, setApplicantsTokens] = useState([]);  //WILL HOLD ACCESS TOKEN FOR ALL APPLICANTS THAT BELONGS TO SAME APPLICATION ID
    const [processing, setProcessing] = useState(false);
    const token = useToken();

    //GET APPLICANT TOKENS
    const getApplicantTokens = async () => {
        var response = await getApplicantAccessTokensByApplicationId(token, applicationId);
        if (response["error"] == 0) {
            console.log("response from getApplicantTokens ::: ", response);
            //setApplicantsTokens(response["accessTokens"]);
            if (response["data"]["applicationTokens"] !== undefined && Array.isArray(response["data"]["applicationTokens"])
                && response["data"]["applicationTokens"].length > 0) {
                setApplicantsTokens(response["data"]["applicationTokens"]);
            }
            else{ setApplicantsTokens([]); }
        }
        else {
            console.log("error from getApplicantTokens ::: ", response);
        }
    }

    const deleteAccessToken = async (accessTokenId) => {  

        var response = await deleteApplicantAccessTokens(token, accessTokenId);
        if (response["error"] == 0) {
            console.log("response from getApplicantTokens ::: ", response);
             Notifications("success", response["message"]); 
             getApplicantTokens(); //LOAD AGAIN
             
        }
        else {
            console.log("error from getApplicantTokens ::: ", response);
        }

    }

    useEffect(() => {
        //GET FRST NAME AND APPLICANT FROM APPLICANTS
        const createApplicantOptions = () => {
            var tmpApplicantOptions = [];
            applicants.map(singleApplicant => {
                tmpApplicantOptions.push({ label: singleApplicant["firstName"], value: singleApplicant["id"] });
            });
            setApplicantOptions(tmpApplicantOptions);   //UPDATE STATE - APPLICANT OPTIONS
        }
        createApplicantOptions();


        //CALL API TO GET APPLICANT TOKENS
        getApplicantTokens();


    }, []);


    //LIST APPLICANT TOKENS
    const ListApplicantTOkens = () => {
        if (applicantsTokens.length > 0) {
            return (
                <div className='table-responsive'>
                    <h3>Access Tokens</h3>
                    <table style={{ maxWidth: "600px" }} className="table align-middle gs-0 gy-4">
                        <thead>
                            <tr className="fw-bolder text-muted bg-light">
                                <th>Applicant Name</th>
                                <th>Update Application Permission</th>
                                <th>Upload Files Permission</th>
                                <th>Is Valid</th>
                                <th>Created At</th>
                                <th>Actions</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {applicantsTokens.map((singleToken, index) => {
                                return (
                                    <>
                                        <tr key={index}>
                                            <td> {singleToken["applicantName"]} </td>
                                            <td> {singleToken["updateApplicationPermission"] == 1 ? "Yes" : "No"} </td>
                                            <td> {singleToken["uploadFilesPermission"] == 1 ? "Yes" : "No"} </td>
                                            <td> {singleToken["isValid"] === true ? "Yes" : "No"} </td>
                                            <td> {formatDate(singleToken["timeStamp"], "unix", "DD/MM/YYYY HH:mm:ss")} </td>
                                            <td> <IconButton iconType="delete" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "15px", color: "#b20f0f" }} tootltip={true} tooltipDescription="Delete" 
                                            buttonOnClick={() => {  
                                                deleteAccessToken(singleToken["id"]);
                                            }} /></td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" style={{ maxWidth: "800px" }}> { "https://application.nfal.com.au/?token="+singleToken["token"]} </td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>

                </div>
            )
        }
    }


    return (
        <div className="card mb-5 mb-xxl-8" key="personalinfoBlock">
            <div className="card-body card-body pt-6 pb-6">
                <Formik
                    initialValues={{ applicationId: applicationId, applicantId: "", updateApplicationPermission: "", uploadFilesPermission: "" }}
                    enableReinitialize={true}
                    validateOnBlur={false}
                    validationSchema={ApplicantTokenValidationSchema}
                    onSubmit={async (values) => {
                        //MAKE AN API CALL 
                        setProcessing(true);
                        console.log("values before sending ::: ", values);

                        var response = await createApplicantAccessToken(
                            token,
                            {
                                applicationId: applicationId,
                                "applicantId": values.applicantId,
                                "updateApplicationPermission": values.updateApplicationPermission,
                                "uploadFilesPermission": values.uploadFilesPermission
                            }
                        );

                        console.log('authority cpnosent form ::::: ::', response);
                        setProcessing(false);

                        if (response["error"] == 0) { Notifications("success", response["message"]); getApplicantTokens(); }
                        else { Notifications("error", response["message"]); }
                    }}
                >
                    {({ errors, touched, setFieldValue, values }) => (
                        <Form>
                            <div className="row">
                                <div className="col-sm-12 mt-5">
                                    <label className="required fs-8 fw-bold mb-2">Select Applicant </label>
                                    <Field name="applicantId">
                                        {({ field }) => (
                                            <SelectField
                                                fieldName="applicantId"
                                                defaultSelectedOption={{ value: field.value, label: '' }}
                                                options={applicantOptions}
                                                setFieldValue={setFieldValue}
                                                callback={() => { }}
                                            />

                                        )}
                                    </Field>
                                    <div className="error">{errors.applicantId ? errors.applicantId : ""}</div>
                                </div>

                                <div className="col-sm-6 mt-5">
                                    <label className="required fs-8 fw-bold mb-2">Update Application Permission</label>
                                    <Field name="updateApplicationPermission">
                                        {({ field }) => (
                                            <SelectField
                                                fieldName="updateApplicationPermission"
                                                defaultSelectedOption={{ value: field.value, label: '' }}
                                                options={[{ value: "0", label: "Select" }, { value: "1", label: "Yes" }, { value: "0", label: "No" }]}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                    <div className="error">{errors.updateApplicationPermission ? errors.updateApplicationPermission : ""}</div>
                                </div>

                                <div className="col-sm-6 mt-5">
                                    <label className="required fs-8 fw-bold mb-2">Upload File Permission</label>
                                    <Field name="uploadFilesPermission">
                                        {({ field }) => (
                                            <SelectField
                                                fieldName="uploadFilesPermission"
                                                defaultSelectedOption={{ value: field.value, label: '' }}
                                                options={[{ value: "0", label: "Select" }, { value: "1", label: "Yes" }, { value: "0", label: "No" }]}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                    <div className="error">{errors.uploadFilesPermission ? errors.uploadFilesPermission : ""}</div>
                                </div>


                                <div className="col-xl-12 mt-4"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>
                            </div>
                        </Form>
                    )}
                </Formik>

                <ListApplicantTOkens />

                {/*
                <div className='d-flex row'>
                    <h3>Applicant Privacy Consent Form Log</h3>
                    <div className='col-sm-12' style={{ maxHeight: "200px", overflowY: "auto" }}>
                        {Array.isArray(comments) ? comments.map((singleComment, index) => { return <div key={index}> {singleComment.comment} </div> }) : ""}
                    </div>
                </div>
                */}
            </div>
        </div>
    )
}
export default CreateApplicantAccessToken;