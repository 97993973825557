import axios from './axios';


//GET ALL VBANK STATEMENT LINKS
const getBankStatementLinks = async (token, creditRepId, ) => {

    try {
      const response = await axios.get(`bankStatamentLinks/${creditRepId}`, { headers: { Authorization: "Bearer " + token } });
      return { "error": 0, httpCode: response.status, "message": "",  data: response.data ? response.data : [] }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
      }
    }
  }
  

  //GET SINGLE BANK STATEMENT LINK
  const getBankStatementLink = async (token, linkId, ) => {

    try {
      const response = await axios.get(`bankStatamentLinks/${linkId}`, { headers: { Authorization: "Bearer " + token } });
      return { "error": 0, httpCode: response.status, "message": "",  data: response.data ? response.data : [] }
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
      }
    }
  }

//CREATE
const createBankStatementLink = async (token,  linkObj) => {
  try{
    const response = await axios.post(`bankStatamentLinks`, linkObj , { headers: { Authorization: "Bearer " + token } });
    return  { "error": 0, data : response.data ? response.data : [] , httpCode : response.status}
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
    }
  }

}
//SEND A BANK STATEMENT REQUEST TO CUSTOMER
const sendBankStatementRequestToCustomer = async (token,  linkObj) => {
  try{
    const response = await axios.post(`bankStatamentLinks/sendBankStatementRequestToCustomer`, linkObj , { headers: { Authorization: "Bearer " + token } });
    return  { "error": 0, message : response.data.message ? response.data.message : [] , httpCode : response.status}
  }
  catch (e) {
    if (e.response) {
      console.log(e.response.status)
      console.log(e.response.data)
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
    }
  }

}


//UPDATE 
const updateBankStatementLink = async (token,  linkObj) => {
    try{
      const response = await axios.put(`bankStatamentLinks`, linkObj , { headers: { Authorization: "Bearer " + token } });
      return  { "error": 0, data : response.data ? response.data : [] , httpCode : response.status}
    }
    catch (e) {
      if (e.response) {
        console.log(e.response.status)
        console.log(e.response.data)
        return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
      }
    }
  
  }
  

export { getBankStatementLinks, getBankStatementLink , createBankStatementLink, updateBankStatementLink, sendBankStatementRequestToCustomer};