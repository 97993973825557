import axios from '../api/axios';



/* CREATE BROKER RCTI */
const createBrokerRCTI = async (payload, token) => {
  try {
    const response = await axios.post("broker/rcti/", payload, { headers: { Authorization: "Bearer " + token } });
    return { "error": response.data["error"] || 0, httpCode: response.status, "message": response.data["message"] || "Created successfully", broker: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : "Unexpected error occured" }
    }
  }
}

export { 
  createBrokerRCTI 
};
