const broadcastChannel = new BroadcastChannel('sseChannel');

/**
 * Sends a message to other tabs.
 * @param {Object} message - The message object to broadcast.
 */
export const broadcastMessage = (message) => {

    console.log("BROADCAST CHANNEL : ", broadcastChannel);
    console.log('Broadcasting message:', message);
    if (broadcastChannel) {
        console.log('Broadcasting message:', message);
        broadcastChannel.postMessage(message);
    }
};

/**
 * Closes the broadcast channel (cleanup).
 */
export const closeBroadcastChannel = () => {
    if (broadcastChannel) {
        broadcastChannel.close();
        console.log('Broadcast channel closed.');
    }
};



/**
 * Initialize the message listener.
 * @param {Function} onMessage - Callback to handle incoming messages.
 */
export const setupReceiver = (onMessage) => {
    if (broadcastChannel) {
        broadcastChannel.onmessage = (event) => {
            console.log('Received message:', event.data);
            onMessage(event.data);
        };
    }
};

/**
 * Stop listening for messages.
 */
export const stopReceiving = () => {
    if (broadcastChannel) {
        broadcastChannel.onmessage = null;
        console.log('Stopped receiving messages.');
    }
};


export default broadcastChannel;
