import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    notificationId: null
}

export const RealTimeNotificatiosSlice = createSlice({
    name : "realTimeNotification",
    initialState,
    reducers : {
        addNotificationId : (state, action) => {
            state.notificationId = action.payload
        },
        removeNotificationId : (state) => {
            state.notificationId = null
        }
    }
})

export const {addNotificationId, removeNotificationId} = RealTimeNotificatiosSlice.actions; 
export default RealTimeNotificatiosSlice.reducer;