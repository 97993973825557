
import React, { useState, useEffect } from 'react';
import { useToken, useRole, useEntityType, useEntityId } from '../../hooks/useAuth';
import { CustomButton, InputField, ConfirmDialog, RightDrawer, SelectField } from '../HtmlComponents';
import { getBankStatementLinks, getBankStatementLink, createBankStatementLink, updateBankStatementLink } from '../../api';
import useCreditReps from '../../hooks/useCreditReps';
import SingleBankStatementLink from './SingleBankStatementLink';
import CreateBankStatementLink from './components/CreateBankStatementLink';

const ListBankStatementLinks = () => {
    const { selectedCreditRepId, CreditRepsDropdown  } = useCreditReps();

    const [bankStatementLinks, setBankStatementLinks] = useState([]);    
    const token = useToken();

    const entityType = useEntityType();
    const entityId = useEntityId();
    const role = useRole();

    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });

    useEffect(() => {
        fetchBankStatementLinks();
    }, [selectedCreditRepId]);


    const fetchBankStatementLinks = async () => {
        if( (entityType === "company" && selectedCreditRepId) || ( entityType === "creditRep" && entityId ) ){
            const result = await getBankStatementLinks(token , entityType === "company" ? selectedCreditRepId : entityId);
            console.log("RESPONSE FRPOM LIST BANK STATEMENT LINKS", result);
            if(result["error"] !== undefined && result["error"] == 0){
                setBankStatementLinks(result["data"]);
            }
        }
    }

    const handler = (action) => {
        switch (action) {
            case "openCreateBankStatementLink":
                setDrawerSettings({ visible: true, heading: "Bank Statement Link", childName: "createBankStatementLink", meta: {} });
                break;
            case "refreshListing":
                fetchBankStatementLinks();
                handler("closeDrawer");
                break;
            case "closeDrawer": setDrawerSettings({ visible: false });
                break;
        }
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <div className='row'>
                     
                    <div className='col-sm-6 col-md-4'>
                        {/**LOAD CREDIT REPS IN A DROPDOWN  */}              
                        { entityType === "company" ?  <CreditRepsDropdown /> : "" }
                    </div>

                    <div className='col-sm-6 col-md-8'>
                        <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right", marginBottom: "10px" }} buttonOnClick={() => { handler("openCreateBankStatementLink") }} buttonText="Create" />
                    </div>

                </div>
                <div className="card" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                            <th>Subject</th>
                                            <th>Duration</th>
                                            <th>Message</th> 
                                        </tr>
                                    </thead>
                                    <tbody className="fw-bold  fw-bold text-gray-600">
                                        {
                                            Array.isArray(bankStatementLinks) && bankStatementLinks.length > 0 ?
                                                bankStatementLinks.map(singleBankStatementLink => (
                                                    <SingleBankStatementLink key={singleBankStatementLink.id} singleBankStatementLink={singleBankStatementLink} callback={handler} />
                                                ))
                                                : <tr>
                                                    <td colSpan="4">
                                                       <div style={{textAlign: "center"}}> <h1>No data found</h1> </div> 
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {drawerSettings["visible"] ?
                <RightDrawer heading={drawerSettings["heading"]} callback={handler}>
                    {drawerSettings["childName"] === "createBankStatementLink" ? <CreateBankStatementLink callback={handler} /> : ""}
                </RightDrawer> : ""
            }


        </>
    )
}

export default ListBankStatementLinks;