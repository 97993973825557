

import { useState } from "react";
import SearchByDate from "../../global/SearchForms/SearchByDate";
import {  getCurrentMonth, convertDateintoUnix } from '../../../helpers';

import TotalReferralReport from "../components/admin/TotalReferralReport";
import TotalSettledReferralReport from "../components/admin/TotalSettledReferralReport";
import TotalFinancedAmountReport from "../components/admin/TotalFinancedAmountReport";
import AverageIncomePerDealAfterReferrerCommission from "../components/admin/AverageIncomePerDealAfterReferrerCommission";
import AverageIncomePerDealBeforeReferrerCommission from "../components/admin/AverageIncomePerDealBeforeReferrerCommission";
import BranchIncomeReport from "../components/admin/BranchIncomeReporting";

//getTotalReferralsReporting
const AdminReporting = () => {
    const [dateFilter, setDateFilter] = useState({startDate: convertDateintoUnix(getCurrentMonth("firstDay") , "YYYY-MM-DD H:mm:ss"), 
    endDate: convertDateintoUnix(getCurrentMonth("lastDay"), "YYYY-MM-DD H:mm:ss") })

    const handler = (name, value) => {
        switch(name){
            case "dateFilter":
                setDateFilter({startDate: value["startDate"], endDate: value["endDate"] });
                break;
        }
    }
    return (<>
        <div id="kt_content_container" className="container-xxl">
            <div className="row g-5 g-xl-8">
                <SearchByDate callback={handler} />
            </div>

            <div className="row g-5 g-xl-8 mb-20">
                <div className="col-sm-6">
                    <TotalReferralReport startDate={dateFilter["startDate"]} endDate={dateFilter["endDate"]} />
                </div>
                <div className="col-sm-6">
                    <TotalSettledReferralReport startDate={dateFilter["startDate"]} endDate={dateFilter["endDate"]} />
                </div>
                <div className="col-sm-6">
                    <TotalFinancedAmountReport startDate={dateFilter["startDate"]} endDate={dateFilter["endDate"]} />
                </div>
                {/*
                <div className="col-sm-6">
                    <AverageIncomePerDealAfterReferrerCommission startDate={dateFilter["startDate"]} endDate={dateFilter["endDate"]} />
                </div> */}
                <div className="col-sm-6">
                    <AverageIncomePerDealBeforeReferrerCommission startDate={dateFilter["startDate"]} endDate={dateFilter["endDate"]} />
                </div>
            </div>
        </div>

    </>)
}

export default AdminReporting;