
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import { useToken } from '../../hooks/useAuth';
import { Formik, Form, Field } from 'formik';
import { updateDeclined, getDeclined } from "../../api";
import { FormSubmissionButton, TextArea, CheckBox, FloatField, SelectField, InputGroup, InputField, MaskedField } from '../HtmlComponents';
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';
import DeclinedSchema from './schema/DeclinedSchema.json';

import Constants from "../global/Constants";
import DeclinedReason from "./components/DeclinedReason";


const EditDeclined = () => {
    const [singleDeclined, setSingleDeclined] = useState({reasons: []});  // Constants.DECLINED_REASONS
    const declinedRef = useRef();
    let { id } = useParams();
    let navigate = useNavigate();
    const token = useToken();

    const getReasons = () => {
        var reasons = Object.keys(Constants.DECLINED_REASONS).map(index => {
            return { type: index, description: "", isChecked: false }
        })
        return reasons;
    }


    const fetchDeclined = async () => {
        var reasons = getReasons();
        var data = await getDeclined(id, token,);

        if (!data["error"]) {
            //DECLINED DATE
            data["declined"]["declinedDate"] = data["declined"]["declinedDate"] !== undefined && data["declined"]["declinedDate"] !== 0 ? formatDate(data["declined"]["declinedDate"], 'unix', 'DD/MM/YYYY') : "";
            //FOLLOW UP DATE
            data["declined"]["followUpDate"] = data["declined"]["followUpDate"] !== undefined && data["declined"]["followUpDate"] !== 0 ? formatDate(data["declined"]["followUpDate"], 'unix', 'DD/MM/YYYY') : "";

            data["declined"]["reasons"] = await formatDecilendReasons(data["declined"]["reasons"]);;
            
            //await new Promise(resolve => setTimeout(resolve, 1000));
            setSingleDeclined((prev) => ({ ...prev, ...data["declined"] }));
        }
        else {
            Notifications("error",data["message"]);
        }
        
    }

    const formatDecilendReasons = async(singleDecliendReasons) => {
        //GET ALL REASONS SAVED IN CONSTANTS AND THEN MAP THE PROVIDED REASONS FOR SINGLE DECLINED AND MARK THEM TRUE

        let ids = singleDecliendReasons.ids ? singleDecliendReasons.ids : [];
        let otherData = singleDecliendReasons.otherData ? singleDecliendReasons.otherData : {};
        let otherDescription = otherData ? otherData.description : "";

        let singleDecliendReasonsSelectedTypes = [];

        //MAP THE REASONS BY ID AND GET THE TYPES
        ids.map(id => {
            let type = Constants.DECLINED_ID_TO_REASONS_MAPPING[id];
            singleDecliendReasonsSelectedTypes.push(type);
        })
        //RUN THE RECURSEIVE ON DECLIEND REASONS AND THEN MARK TRUE OF EXISTS IN THE singleDecliendReasonsSelectedTypes
        const declinedReasonsUpdateVersion = (reasons, singleDecliendReasonsSelectedTypes, otherDescription ) => {
            if(reasons && Array.isArray(reasons)){
                for(const reason of reasons){
                    let type = reason["type"];
                    if(singleDecliendReasonsSelectedTypes.includes(type)){
                        reason["isChecked"] = true;
                        if(type == "other"){
                            reason["description"] = otherDescription;
                        }
                    }
                    if(reason["children"]){
                        declinedReasonsUpdateVersion(reason["children"], singleDecliendReasonsSelectedTypes, otherDescription);
                    }
                }
            } 
            return reasons;
        };


        //CALL FUNCTION TO UPDATE THE REASONS
        var resp = declinedReasonsUpdateVersion(
            JSON.parse(JSON.stringify(Constants.DECLINED_REASONS)),
            singleDecliendReasonsSelectedTypes,
            otherDescription
        );

        await new Promise(resolve => setTimeout(resolve, 1000));

        return resp;


    }
    
    useEffect(() => {
        fetchDeclined();
    }, [])

    const DeclinedReasonsHtml = ({values, setFieldValue }) => {       
        const renderReasons = (reasons, parentIndex = null, level = 0) => {
          return reasons.map((reason, index) => {
            const currentIndex = parentIndex !== null ? `${parentIndex}.children[${index}]` : `[${index}]`;
            return (
              <div key={currentIndex}>
                <div style={{ display: 'flex', justifyContent: 'start', marginBottom: '8px', marginLeft: `${level * 40}px` }}>
                  <DeclinedReason
                    name={`reasons${currentIndex}`} 
                    label={reason.label}
                    isChecked={reason.isChecked}
                    setFieldValue={setFieldValue}
                  />
                </div>
                {reason.children && Array.isArray(reason.children) && renderReasons(reason.children, currentIndex, level + 1)}
              </div>
            );
          });
        };
        let reasonsTmp = values && Array.isArray(values["reasons"]) ? values["reasons"] : [];
        if (!Array.isArray(reasonsTmp) || reasonsTmp.length === 0) { return <div>Loading Reasons ... </div>; }
        return <>{renderReasons(reasonsTmp)}</>;
      };
      

    const DeclinedHtml = ({errors, touched, values, setFieldValue}) => {
        return (
            <>
                <div className="card mb-5 mb-xxl-8">
                    <div className="card-body card-body pt-6 pb-6">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <h3 className="pb-0 mb-0">Edit Declined (Ref # {id}) </h3>
                        </div>
                        <div className="separator separator-dashed my-5"></div>
                        <div className="position-relative z-index-1">

                            <div className="row">
                                <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                                    <label className="fs-8 fw-bold mb-2">Declined Date</label>
                                    <Field type="text" name={`declinedDate`}  >
                                        {({ field }) => (
                                            <MaskedField
                                                fieldName={`declinedDate`}
                                                fieldValue={field.value !== undefined && dateFilter(field.value) !== 0 ? dateFilter(field.value) : " "}
                                                maskRule="date"
                                                fieldPlaceholder="dd/mm/yyyy"
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div className="col-sm-6 col-md-4 fv-row fv-plugins-icon-container">
                                    <label className="fs-8 fw-bold mb-2">Follow Up Date</label>
                                    <Field type="text" name={`followUpDate`}  >
                                        {({ field }) => (
                                            <MaskedField
                                                fieldName={`followUpDate`}
                                                fieldValue={field.value !== undefined && dateFilter(field.value) !== 0 ? dateFilter(field.value) : " "}
                                                maskRule="date"
                                                fieldPlaceholder="dd/mm/yyyy"
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 mt-8 mb-5">
                                    <div className="d-flex flex-wrap flex-sm-nowrap">
                                        <h5 className="pb-0 mb-0">Declined For</h5>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <DeclinedReasonsHtml  values={values} setFieldValue= {setFieldValue} />
                                </div>

                                <div className="col-sm-12 mt-8 mb-5">
                                    <div className="d-flex flex-wrap flex-sm-nowrap">
                                        <h5 className="pb-0 mb-0">Comments</h5>
                                    </div>
                                    <div>
                                        <Field type="text" name={`comments`}  >
                                            {({ field }) => (
                                                <TextArea
                                                    fieldName="comments"
                                                    fieldValue={field.value}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Formik
                initialValues={singleDeclined}
                //validationSchema={}
                innerRef={declinedRef}
                enableReinitialize={true}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    //CHECK IF ANALYST COMMISSION HAS A RECORD THAT DOES NOT HAVE A ANALYST ID - REMOVE IT
                    var localValues = JSON.parse(JSON.stringify(values));
                    //FORMAT THE DATES
                    localValues["declinedDate"] = localValues["declinedDate"].includes("/") ? formatDate(localValues["declinedDate"], 'DD/MM/YYYY', 'YYYY-MM-DD') : localValues["declinedDate"];
                    localValues["followUpDate"] = localValues["followUpDate"].includes("/") ? formatDate(localValues["followUpDate"], 'DD/MM/YYYY', 'YYYY-MM-DD') : localValues["followUpDate"];

                    var reasons = localValues["reasons"];
                    //fetch only reasons having isChecked true
                    var filteredReasons = reasons.filter(reason => reason.isChecked == true);
                    localValues["reasons"] = filteredReasons;

                    console.log("VALUES:: line 240 -------------------- ", localValues);

                    var response = await updateDeclined(id, localValues, token);
                    if (!response["error"]) {
                        Notifications("success", response["message"] || "Created Successfully");
                        navigate(`/user/declined`);
                    }
                    else {
                        Notifications("error", response["message"] || "Error occured");
                    }
                }}
            >
                {({ errors, touched, values, setFieldValue }) => (                                        
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-5 g-xl-8">
                                <div className="col-xl-12">
                                    <div className="row">
                                        <DeclinedHtml errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} />
                                    </div>
                                </div>                                
                                <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{ float: "right" }}>Please fix the above errors first. </div> : ""}</div>
                                <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>

                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default EditDeclined;