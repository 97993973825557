import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import { useEntityType, useRole, useEntityId, useToken } from '../../hooks/useAuth';
import { FormSubmissionButton, CustomButton, Modal, MaskedField, RadioGroup, CheckBox, SelectField } from '../HtmlComponents';
import { Formik, Form, Field } from 'formik';
import { createReferrerGroup, updateReferrerGroup, getReferrerGroupsList, getCreditRepList } from "../../api";
import Notifications from '../../components/global/Notifications';

//SCHEMA
import referrerGroupSchema from './schema/referrerGroupSchema.json';
import { ReferrerGroupValidationSchema } from './schema/ReferrerGroupValidationSchema';

//MODALS
import AddressForm from '../global/AddressForm';
//HELPERS
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';
//ERRORS
import ErrorMessage from '../global/errors/Error';
import LoadingScreen from '../global/LoadingScreen';

//CONSTANTS
const xeroTaxTypes = ["EXCLUSIVE", "INCLUSIVE", "NOTAX"]

const CreateReferrerGroup = () => {
    const [singleReferrerGroup, setSingleReferrerGroup] = useState({});
    const [referrerGroups, setReferrerGroups] = useState([]);
    const [creditReps, setCreditReps] = useState([]);  //LIST OF CREDIT REPS - ONLY IF LOGGED IN USER IS SUPER ADMIN
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [addressModal, setAddressModal] = useState({ visible: false, fieldName: "", callback: '' });

    const taxTypes = [
        { value: "EXCLUSIVE", label: "EXCLUSIVE" },
        { value: "INCLUSIVE", label: "INCLUSIVE" },
        { value: "NOTAX", label: "NO TAX" }
    ];

    let { id } = useParams();
    const token = useToken();
    let navigate = useNavigate();

    const entityType = useEntityType();
    const entityId = useEntityId();
    const role = useRole();



    useEffect( () => {
        const fetchReferrerGroupList = async () => {
            const result = await getReferrerGroupsList(token);
            if (result["error"] == 0 && result["referrerGroups"]) {
                //GET REFERRER GROUPS LIST TO SET PARENT REFERRER GROUP - REFERRER GROUP DROPDOWN OPTIONS
                let referrerGroupsOptions = [{value: 0, label: "Select Parent Referrer Group"}];
                if(Array.isArray(result["referrerGroups"]) && result["referrerGroups"].length > 0 ){
                    result["referrerGroups"].map((item) => {
                        // EXCLUDE THE CURRENT REFERRER GROUP FROM THE LIST
                        if(item.id != id){ referrerGroupsOptions.push({ value: item.id, label: item.tradingName });}
                    })
                }                
                setReferrerGroups(referrerGroupsOptions); 
            }
        }
        fetchReferrerGroupList();



        //FETCH CREDITREPS LIST
        const fetchCreditRepsList = async () => {
            console.log("FETCHING CREDIT REPS LIST -   role -----------" , role);
            if(role === "superAdmin" ){
                var creditRepsOptions = [];
                const creditRepsList = await getCreditRepList(entityId, token, "basic");
                console.log("CREDIT REPS LIST", creditRepsList);

                if(Array.isArray(creditRepsList) && creditRepsList.length > 0 ){
                    creditRepsList.map((item) => {
                        // EXCLUDE THE CURRENT REFERRER GROUP FROM THE LIST
                        if(item.id != id){ creditRepsOptions.push({ value: item.id, label: item.tradingName });}
                    })
                }    

                setCreditReps(creditRepsOptions);
            }
        }
        fetchCreditRepsList();

    }, [])

    /*  ********************* HTML COMPONENTS OF REFERRER GROUP  - START *****************/

    //IF LOGGED IN USER IS SUPER ADMIN THEN LIST THE CREDITREPS TOO IN THE DROPDOWN 
    const CreditRepsField = ({ setFieldValue, errors , touched }) => {
        if(role === "superAdmin"){
            return (
                <tr className="even">
                    <td className="text-start">CreditRep</td>
                    <td className="text-start text-gray-600">
                        <Field name="creditRepId">
                            {({ field }) => (
                                <SelectField
                                    fieldName="creditRepId"
                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                    options={creditReps}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors.creditRepId && touched.creditRepId ? (<div className="error">{errors.creditRepId}</div>) : null}</td>
                </tr>
            )
        }
        else{
            return "";
        }
    }

    const summary = (errors, touched, handleChange, setFieldValue) => {
        return (<>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Brokerage Details</h3>
                        </div>
                    </div>

                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">

                                <CreditRepsField setFieldValue={setFieldValue}  errors={errors} touched={touched} />
                                <tr className="even">
                                    <td className="text-start">Name</td>
                                    <td className="text-start text-gray-600"><Field name="referrerGroupName" placeholder="Name" className="form-control form-control-solid" />
                                        {errors.referrerGroupName && touched.referrerGroupName ? (<div className="error">{errors.referrerGroupName}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Trading Name</td>
                                    <td className="text-start text-gray-600"><Field name="tradingName" placeholder="Trading Name" className="form-control form-control-solid" />
                                        {errors.tradingName && touched.tradingName ? (<div className="error">{errors.tradingName}</div>) : null}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Entity Name </td>
                                    <td className="text-start text-gray-600"><Field name="entityName" placeholder="Entity Name" className="form-control form-control-solid" />
                                        {errors.entityName && touched.entityName ? (<div className="error">{errors.entityName}</div>) : null}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Structure</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="structure">
                                            {({ field }) => (
                                                <RadioGroup
                                                    fieldName="structure"
                                                    defaultSelectedOption={field.value}
                                                    options={[{ label: "Company", value: "company" }, { label: "Partnership", value: "partnership" }, { label: "Sole Trader", value: "soleTrader" }]}
                                                    setFieldValue={setFieldValue}
                                                    layoutStyle="vertical"
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">ABN </td>
                                    <td className="text-start text-gray-600"><Field name="abn" placeholder="ABN" className="form-control form-control-solid" />
                                        {errors.abn && touched.abn ? (<div className="error">{errors.abn}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">ACN </td>
                                    <td className="text-start text-gray-600"><Field name="acn" placeholder="ACN" className="form-control form-control-solid" />
                                        {errors.acn && touched.acn ? (<div className="error">{errors.acn}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Date Established </td>
                                    <td className="text-start text-gray-600">
                                        <Field type="text" name="dateEstablished" placeholder="dd/mm/yyyy" className="form-control form-control-solid" onChange={handleChange}>
                                            {({ field }) => (
                                                <MaskedField fieldName="dateEstablished" fieldValue={dateFilter(field.value)} fieldPlaceholder="dd/mm/yyyy" maskRule="date" setFieldValue={setFieldValue} />
                                            )}
                                        </Field>
                                        {errors.dateEstablished && touched.dateEstablished ? (<div className="error">{errors.dateEstablished}</div>) : null}
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">contact Number </td>
                                    <td className="text-start text-gray-600"><Field name="contactNumber" placeholder="Contact Number" className="form-control form-control-solid" onChange={handleChange} />
                                        {errors.contactNumber && touched.contactNumber ? (<div className="error">{errors.contactNumber}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Fax </td>
                                    <td className="text-start text-gray-600"><Field name="fax" placeholder="Fax" className="form-control form-control-solid" />
                                        {errors.fax && touched.fax ? (<div className="error">{errors.fax}</div>) : null}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Address</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="address">
                                            {({ field }) => (
                                                <input
                                                    className="form-control form-control-solid"
                                                    type="text"
                                                    value={field.value && field.value.fullAddress ? field.value.fullAddress : ""}
                                                    onClick={() => setAddressModal({ visible: true, filedName: "address", callback: setFieldValue })}
                                                    onChange={(e) => { }}
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Website Url </td>
                                    <td className="text-start text-gray-600"><Field name="websiteUrl" placeholder="Website Url" className="form-control form-control-solid" />
                                        {errors.websiteUrl && touched.websiteUrl ? (<div className="error">{errors.websiteUrl}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Email</td>
                                    <td className="text-start text-gray-600"><Field name="email" placeholder="Email" className="form-control form-control-solid" />
                                        {errors.email && touched.email ? (<div className="error">{errors.email}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Payment Method</td>
                                    <td className="text-start text-gray-600">

                                        <Field name="paymentMethod">
                                            {({ field }) => (
                                                <RadioGroup
                                                    fieldName="paymentMethod"
                                                    defaultSelectedOption={field.value}
                                                    options={[{ label: "CHEQUE", value: "CHEQUE" }, { label: "EFT", value: "EFT" }, { label: "CARD", value: "CARD" }]}
                                                    setFieldValue={setFieldValue}
                                                    layoutStyle="vertical"
                                                />
                                            )}
                                        </Field>
                                        {errors.email && touched.email ? (<div className="error">{errors.email}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Commission Structure</td>
                                    <td className="text-start text-gray-600"><Field name="commissionStructure" placeholder="Commission Structure" className="form-control form-control-solid" />
                                        {errors.commissionStructure && touched.commissionStructure ? (<div className="error">{errors.commissionStructure}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Gst Registered</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="isGstRegistered">
                                            {({ field }) => (
                                                <RadioGroup
                                                    fieldName="isGstRegistered"
                                                    defaultSelectedOption={field.value}
                                                    options={[{ label: "Yes", value: "true" }, { label: "No", value: "false" }]}
                                                    setFieldValue={setFieldValue}
                                                    layoutStyle="vertical"
                                                />
                                            )}
                                        </Field>
                                        {errors.isGstRegistered && touched.isGstRegistered ? (<div className="error">{errors.isGstRegistered}</div>) : null}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    const BankingDetails = (errors, touched) => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Banking Details</h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Bank Name</td>
                                    <td className="text-start text-gray-600"><Field name="bankingDetail.name" placeholder="Bank Name" className="form-control form-control-solid" />
                                        {errors.bankingDetail && errors.bankingDetail.name && touched.bankingDetail.name ? (<div className="error">{errors.bankingDetail.name}</div>) : null}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Branch</td>
                                    <td className="text-start text-gray-600"><Field name="bankingDetail.branchName" placeholder="Branch Name" className="form-control form-control-solid" />
                                        {errors.bankingDetail && errors.bankingDetail.branchName && touched.bankingDetail.branchName ? (<div className="error">{errors.bankingDetail.branchName}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">BSB</td>
                                    <td className="text-start text-gray-600"><Field name="bankingDetail.bsb" placeholder="BSB" className="form-control form-control-solid" />
                                        {errors.bankingDetail && errors.bankingDetail.bsb && touched.bankingDetail.bsb ? (<div className="error">{errors.bankingDetail.bsb}</div>) : null}</td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Account Number</td>
                                    <td className="text-start text-gray-600"><Field name="bankingDetail.accountNumber" placeholder="Account Name" className="form-control form-control-solid" />
                                        {errors.bankingDetail && errors.bankingDetail.accountNumber && touched.bankingDetail.accountNumber ? (<div className="error">{errors.bankingDetail.accountNumber}</div>) : null}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }


    const XeroConfig = (errors, touched, setFieldValue) => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">XERO Config</h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Contact ID</td>
                                    <td className="text-start text-gray-600"><Field name="xeroConfig.xeroContactId" placeholder=" " className="form-control form-control-solid" /></td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Tax Type</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="xeroConfig.xeroTaxType">
                                            {({ field }) => (
                                                <SelectField
                                                    fieldName="xeroConfig.xeroTaxType"
                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                    options={taxTypes}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    //PARENT REFERRER GROUP COMMISSION
    const ParentReferrerGroupCommission = (errors, touched, setFieldValue) => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Parent Referrer Group Commission settings</h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Parent Referrer Group</td>
                                    <td className="text-start text-gray-600">
                                        <Field name="parentReferrerGroupId">
                                            {({ field }) => (
                                                <SelectField
                                                    fieldName="parentReferrerGroupId"
                                                    defaultSelectedOption={{ value: field.value, label: "" }}
                                                    options={referrerGroups}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </Field>
                                    </td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Commission %</td>
                                    <td className="text-start text-gray-600"><Field name="parentReferrerGroupCommission" placeholder="" className="form-control form-control-solid" /></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    //PERMISSOINS  | Privileges 
    const permissions = (errors, touched, setFieldValue) => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Privileges </h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">
                                        <div style={{ display: "flex", justifyContent: "start" }} className="mb-2">
                                            <Field name="permissions.allowToEditApplication">
                                                {({ field }) => (
                                                    <CheckBox name="permissions.allowToEditApplication" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Allow to edit application </span>
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "start" }} className="mb-2">
                                            <Field name="permissions.allowToViewNotes">
                                                {({ field }) => (
                                                    <CheckBox name="permissions.allowToViewNotes" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Allow To View Notes </span>
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "start" }} className="mb-2">
                                            <Field name="permissions.allowToAddNotes">
                                                {({ field }) => (
                                                    <CheckBox name="permissions.allowToAddNotes" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Allow to add notes </span>
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "start" }} className="mb-2">
                                            <Field name="permissions.referrerTab">
                                                {({ field }) => (
                                                    <CheckBox name="permissions.referrerTab" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Referrer Tab </span>
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "start" }} className="mb-2">
                                            <Field name="permissions.addNewReferral">
                                                {({ field }) => (
                                                    <CheckBox name="permissions.addNewReferral" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Add New Referral </span>
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "start" }} className="mb-2">
                                            <Field name="permissions.referralsListing">
                                                {({ field }) => (
                                                    <CheckBox name="permissions.referralsListing" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Referrals Listing </span>
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "start" }} className="mb-2">
                                            <Field name="permissions.settlementTab">
                                                {({ field }) => (
                                                    <CheckBox name="permissions.settlementTab" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Settlement Tab </span>
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "start" }} className="mb-2">
                                            <Field name="permissions.archiveTab">
                                                {({ field }) => (
                                                    <CheckBox name="permissions.archiveTab" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Archive Tab </span>
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "start" }} className="mb-2">
                                            <Field name="permissions.emailServer">
                                                {({ field }) => (
                                                    <CheckBox name="permissions.emailServer" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Email Server </span>
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "start" }} className="mb-2">
                                            <Field name="permissions.sendEmailToReferrer">
                                                {({ field }) => (
                                                    <CheckBox name="permissions.sendEmailToReferrer" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                )}
                                            </Field>
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Send Email To Referrer </span>
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }


    /********************** HTML COMPONENTS OF REFERRER GROUP  - END *****************/


    function LoadView() {
        return (
            <>
                <Formik
                    initialValues={referrerGroupSchema}
                    validationSchema={ReferrerGroupValidationSchema}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        let valuesClone = JSON.parse(JSON.stringify(values));
                        valuesClone["dateEstablished"] = valuesClone["dateEstablished"].includes("/") ? formatDate(valuesClone["dateEstablished"], 'DD/MM/YYYY', 'YYYY-MM-DD') : valuesClone["dateEstablished"];
                        //console.log("VALUES", valuesClone);

                        //CHECK IF USER IS SUPERADMIN AND CREDITREP IS SELECTED OR NOT 
                        if(role === "superAdmin" && valuesClone["creditRepId"] == 0){
                            Notifications("error", "Please select a credit rep.");
                            return;
                        }

                        console.log("VALUES", valuesClone);

                        setProcessing(true);
                        var response = await createReferrerGroup(valuesClone, token);
                        console.log(response);
                        if (response["error"]) {
                            Notifications("error", response["message"] || "Could not create the referrer group.")
                        }
                        else {
                            Notifications("success", response["message"] || "Created Successfully");
                            let referrerGroupId = response["referrerGroup"]["id"];
                            if (referrerGroupId > 0) {
                                navigate(`/user/referrerGroupDetail/${referrerGroupId}`);
                            }
                            else {
                                navigate(`/user/listReferrerGroups`);
                            }
                        }
                        setProcessing(false);
                    }}
                >
                    {({ errors, touched, handleChange, setFieldValue }) => (
                        <Form>
                            <div id="kt_content_container" className="container-xxl">
                                <div className="col-xl-12">
                                    <pre>
                                        { /*JSON.stringify(singleCreditRep , undefined, 4) */}
                                    </pre>
                                </div>
                                <div className="row g-5 g-xl-8">
                                    <div className="col-xl-6">
                                        {summary(errors, touched, handleChange, setFieldValue)}
                                    </div>
                                    <div className="col-xl-6">
                                        {BankingDetails(errors, touched)}
                                        {XeroConfig(errors, touched, setFieldValue)}
                                        {permissions(errors, touched, setFieldValue)}
                                    </div>
                                    
                                    <div className="col-xl-12">
                                        {ParentReferrerGroupCommission(errors, touched, setFieldValue)}
                                    </div>

                                    <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{ float: "right" }}>Please fix the above errors first. </div> : ""}</div>
                                    <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>

                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }

    return (
        <>
            {loading || processing ? <LoadingScreen /> : LoadView()}


            {addressModal.visible === true ?
                <Modal modalVisibility={setAddressModal} heading="Referrer Group Address">
                    <AddressForm
                        fieldName={addressModal.filedName}
                        closeButtonHandler={() => setAddressModal({ visible: false, callback: '' })}
                        addressHandler={addressModal.callback}
                    />
                </Modal>
                : ""}

        </>
    );
}

export default CreateReferrerGroup;