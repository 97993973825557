import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import axios from '../../api/axios';

import { RightDrawer, ConfirmDialog, CustomButton } from '../HtmlComponents';

//REDUS PERSISTENT
import { useSelector, useDispatch } from "react-redux";
import { setUserAuth } from '../../redux/authSlice';

const Login = () => {
    const userRef = useRef();
    const [user, setUser] = useState({ 'userName': "", "password": "", 'token': "", "role": "", "otp": "" });
    const [twoFaCodeRequired, setTwoFaCodeRequired] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    let navigate = useNavigate();

    const userValue = useSelector((state) => state.auth.user);
    const theme = useSelector((state) => state.theme);
    const dispatch = useDispatch();


    const focusUserNameField = () => {
        if (!twoFaCodeRequired) {
            userRef.current.focus();
        }
        else { }
    }

    //TRIGGER ON FIRST LOAD AND NOT BIND TO ANY EVENT BECAUSE [] EMPTY SUPPLIED
    useEffect(() => {
        focusUserNameField();
        console.log(userValue);
    }, []);

    //LISTEN ANY CHANGE TO FORM ERRORS AND TRIGGER IF ANYTHING CHANGES
    useEffect(() => {
    }, [formErrors]);

    //REMOVE ERRORS IF USER INPUT SOMETHING NEW
    useEffect(() => {
        setFormErrors({});
    }, [user]);


    //TRIGGER ON FORM SUBMISSION
    const submitHandler = async (e) => {
        e.preventDefault();
        const errors = await ValiditeForm(user);
        setFormErrors(errors);

        try {
            if (Object.keys(formErrors).length === 0) {
                let basicAuth = btoa(user.userName + ":" + user.password);
                try {
                    const response = await axios.get("token", { headers: { Authorization: "Basic " + basicAuth } });
                    if (response !== undefined && response.data !== undefined && response.data.token !== undefined) {
                        if (["referrer"].includes(response.data.entityType)) {
                            var referrersList = response.data.referrersList !== undefined ? response.data.referrersList : [];
                            var selectedReferrer = response.data.referrersList !== undefined && response.data.referrersList[0] !== undefined && response.data.referrersList[0]["id"] !== undefined ? response.data.referrersList[0]["id"] : 0;

                            localStorage.setItem('referrersList', JSON.stringify(referrersList));
                            localStorage.setItem('selectedReferrer', selectedReferrer);
                        }
                        console.log("RESPONSE DATA FROM LINE 64 -------------------------------------------  ",response.data);
                        dispatch(setUserAuth({
                            userName: response.data.userName,
                            fullName: response.data.fullName,
                            token: response.data.token,
                            role: response.data.role,
                            entityType: response.data.entityType,
                            entityId: response.data.entityId,
                            extraInfo: response.data.extraInfo !== undefined ? response.data.extraInfo : {},
                            accessAdvanceReporting: response.data.accessAdvanceReporting !== undefined ? response.data.accessAdvanceReporting : false,
                        }));
                        localStorage.setItem('activeSession', "true");
                        localStorage.setItem("firstTimePasswordChanged", response.data.firstTimePasswordChanged !== undefined ? response.data.firstTimePasswordChanged : -1);
                        if (response.data.entityType === "lender") { navigate(`/lender/lenderDetail/`); }
                        else if (["referrer", "referrerGroup"].includes(response.data.entityType)) { navigate(`/user/listApplications/`); }
                        else if (["auditor"].includes(response.data.role)) { navigate(`/user/settlements/`); }
                        else { navigate("/user/listApplications"); }
                    }
                    else if (response !== undefined && response.data !== undefined && response.data.otpRequired !== undefined && response.data.otpRequired == true) {
                        setTwoFaCodeRequired(true);
                    }
                    else {
                        //console.log("API RESPONSE::", response);
                        let errorMessage = response !== undefined && response["data"] !== undefined && response["data"]["message"] !== undefined ? response["data"]["message"] : "Failed to login due to unexpected error.";
                        setFormErrors({ "error": errorMessage });
                    }
                }
                catch (e) {
                    //console.log(e.response.status)
                    //console.log(e.response)
                    //console.log(" catch --> ", e.response.data)
                    setFormErrors({ "error": e.response.data.message !== undefined ? e.response.data.message : "Failed to login due to unexpected error." });
                }
            }
            else {
                //IF FORM ERROR EXISTS
            }
        } catch (err) {
            console.log(err);
            if (!err.response) { setFormErrors({ "error": "No Response from SERVER. Please contact IT team." }); }
            else if (err.response.status === 400) { setFormErrors({ "error": "Missing username or password." }); }
            else if (err.response.status === 401) { setFormErrors({ "error": "Unauthorized." }); }
            else { setFormErrors({ "error": "Login Failed." }); }
        }
    }

    //USER NAME AND PASSWORD FOMR
    const ValiditeForm = async (formValues) => {
        const errors = {};
        if ((!formValues.userName) || formValues.userName.length < 5) {
            errors.userName = "Username length must be more than 5 characters";
        }
        if (!formValues.password || formValues.password.length < 8) {
            errors.password = "Password length must be more than 8 characters";
        }
        return errors;
    }


    //OTP FORM VALIDAITON
    const ValiditeOTPForm = async (formValues) => {
        const errors = {};
        if (formValues.otp.length !== 6) {
            errors.otp = "OTP length should be 6 characters";
        }
        return errors;
    }

    //The code entered is invalid. Please try again.
    const verifyTwoFaCode = async (e) => {
        e.preventDefault();
        const errors = await ValiditeOTPForm(user);
        setFormErrors(errors);

        try {
            if (Object.keys(errors).length === 0) {
                let basicAuth = btoa(user.userName + ":" + user.password);
                try {
                    const response = await axios.get("verifyOTP", { headers: { Authorization: "Basic " + basicAuth, Otp: user.otp } });
                    if (response !== undefined && response.data !== undefined && response.data.token !== undefined) {
                        if (["referrer"].includes(response.data.entityType)) {
                            var referrersList = response.data.referrersList !== undefined ? response.data.referrersList : [];
                            var selectedReferrer = response.data.referrersList !== undefined && response.data.referrersList[0] !== undefined && response.data.referrersList[0]["id"] !== undefined ? response.data.referrersList[0]["id"] : 0;

                            localStorage.setItem('referrersList', JSON.stringify(referrersList));
                            localStorage.setItem('selectedReferrer', selectedReferrer);
                        }

                        dispatch(setUserAuth({
                            userName: response.data.userName,
                            fullName: response.data.fullName,
                            token: response.data.token,
                            role: response.data.role,
                            entityType: response.data.entityType,
                            entityId: response.data.entityId,
                            extraInfo: response.data.extraInfo !== undefined ? response.data.extraInfo : {},
                            accessAdvanceReporting: response.data.accessAdvanceReporting !== undefined ? response.data.accessAdvanceReporting : false,
                        }));
                        localStorage.setItem('activeSession', "true");
                        localStorage.setItem("firstTimePasswordChanged", response.data.firstTimePasswordChanged !== undefined ? response.data.firstTimePasswordChanged : -1);
                        if (response.data.entityType === "lender") { navigate(`/lender/lenderDetail/`); }
                        else if (["referrer", "referrerGroup"].includes(response.data.entityType)) { navigate(`/user/listApplications/`); }
                        else { navigate("/user/listApplications"); }
                    }
                    else {
                        let errorMessage = response !== undefined && response["data"] !== undefined && response["data"]["message"] !== undefined ? response["data"]["message"] : "Failed to login due to unexpected error.";
                        setFormErrors({ "error": errorMessage });
                    }
                }
                catch (e) {
                    console.log(" catch --> ", e.response.data)
                    setFormErrors({ "error": e.response.data.message !== undefined ? e.response.data.message : "Failed to login due to unexpected error." });
                }
            }
            else {
                //IF FORM ERROR EXISTS
            }
        } catch (err) {
            console.log(err);
            if (!err.response) { setFormErrors({ "error": "No Response from SERVER. Please contact IT team." }); }
            else if (err.response.status === 400) { setFormErrors({ "error": "Unauthorized." }); }
            else if (err.response.status === 401) { setFormErrors({ "error": "Unauthorized." }); }
            else { setFormErrors({ "error": "Login Failed." }); }
        }
    }


    return (
        <>
            <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    <div className="d-flex flex-column flex-lg-row-fluid">
                        <div className="d-flex flex-center flex-column flex-column-fluid">
                            <div className="w-lg-600px p-10 p-lg-15 mx-auto">
                                <div className="d-flex flex-row-fluid flex-column text-center">
                                    <a href="#" className="py-9 mb-5">
                                        <img alt="Logo" src={theme.loginLogo} className={`h-${theme.loginLogoHeight}`} />
                                    </a>
                                </div>

                                {
                                    !twoFaCodeRequired ?
                                        <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" id="kt_sign_in_form" onSubmit={submitHandler}>
                                            <div className="text-center mb-10">
                                                <h5 className="text-dark mb-3">{theme.logoPageTagLine}</h5>
                                            </div>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div className="fv-row mb-10 mt-10 fv-plugins-icon-container">
                                                        <input className="form-control form-control-lg form-control-solid" style={{ borderColor: "#ccccd1" }}
                                                            type="text"
                                                            ref={userRef}
                                                            onChange={(e) => setUser({ ...user, userName: e.target.value })}
                                                            name="userName"
                                                            autoComplete="off"
                                                            placeholder='Username'
                                                            required
                                                        />
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.userName}</div></div>
                                                    <div className="fv-row mb-10 fv-plugins-icon-container">
                                                        <div className="d-flex flex-stack mb-2">
                                                            { /*<a href="../../demo1/dist/authentication/layouts/aside/password-reset.html" className="link-primary fs-6 fw-bolder">Forgot Password ?</a>   */}
                                                        </div>
                                                        <input className="form-control form-control-lg form-control-solid" style={{ borderColor: "#ccccd1" }}
                                                            type="password"
                                                            onChange={(e) => setUser({ ...user, password: e.target.value })}
                                                            name="password"
                                                            placeholder='Password'
                                                            required
                                                        />
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.password}</div>
                                                    </div>

                                                    <div className="text-center">
                                                        <button type="submit" className="btn btn-lg custom-btn w-100 mb-5">
                                                            <span className="indicator-label">Login</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                        </button>
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.error}</div>
                                                        <div style={{ "display": "flex", "flexDirection": "row", justifyContent: "end" }}>
                                                            <Link to="/forgotPassword" className="link-primary">Forgot Password</Link>
                                                            <div style={{marginLeft:"4px", marginRight: "4px", color: "#a1a5b7"}}> | </div>
                                                            <div> <a className="link-primary" href="#" onClick={() => {
                                                                ConfirmDialog({
                                                                    callback: () => { },
                                                                    onCloseHandler: () => { },
                                                                    "purpose": "infoMessage",
                                                                    "heading": `Reset Username`,
                                                                    "description": <div style={{marginTop:"20px"}}><span style={{fontSize:"13px"}}>Please contact the office for further assistance at</span> <h4 style={{fontSize:"14px"}}>1300 844 970</h4></div>
                                                                });
                                                            }}> Forgot Username</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                        :

                                        <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" id="kt_sign_in_form" onSubmit={verifyTwoFaCode}>
                                            <div className="text-center mb-10">
                                                <h5 className="text-dark mb-3">Welcome, please enter the OTP to access Brokahub Portal.</h5>
                                            </div>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div className="fv-row mb-10 mt-10 fv-plugins-icon-container">
                                                        <input className="form-control form-control-lg form-control-solid" style={{ borderColor: "#ccccd1" }}
                                                            type="text"
                                                            onChange={(e) => setUser({ ...user, otp: e.target.value })}
                                                            name="otp"
                                                            autoComplete="off"
                                                            placeholder='OTP'
                                                            maxLength='6'
                                                            value={user.otp}
                                                        />
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.otp}</div></div>
                                                    <div className="text-center">
                                                        <button type="submit" className="btn btn-lg custom-btn w-100 mb-5">
                                                            <span className="indicator-label">Submit</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                        </button>
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.error}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>


                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


}
export default Login;
