import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useToken } from '../../hooks/useAuth';
import { Formik, Form, Field, useField, useFormikContext, FieldArray } from 'formik';
import { createSettlement, getInsuranceCompaniesList, getUsers, getApplication, getApplicationsList, getLender, getLendersList, getParentReferrerGroupCommission } from "../../api";
import { FormSubmissionButton, TextArea, CheckBox, NumberField, FloatField, SelectField, InputGroup, InputField, MaskedField, CustomButton } from '../HtmlComponents';
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';
import { LeftArrowIcon } from '../HtmlComponents/Icons';
import SingleInsurance from './components/SingleInsurance';
import AnalystCommission from './components/AnalystCommission';
import GiftCardReceiver from './components/GiftCardReceiver';
import SettlementSchema from './schema/SettlementSchema.json';
import SettlementValidationSchema from './schema/SettlementValidationSchema';
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';

const CreateSettlement = () => {

    const [applicationId, setApplicationId] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [lookupApplicationResult, setLookupApplicationResult] = useState([]);
    const [selectedApplicationOverview, setSelectedApplicationOverview] = useState([]);
    const [lender, setLender] = useState([]);


    const [singleSettlement, setSingleSettlement] = useState({});
    const [analysts, setAnalysts] = useState([]);
    const [referrerUsers, setReferrerUsers] = useState([]);
    const [lenders, setLenders] = useState([]);
    const [parentReferrerGroupCommissionRate, setParentReferrerGroupCommissionRate] = useState(0);
    const [parentReferrerGroupCommissionAmount, setParentReferrerGroupCommissionAmount] = useState(0);
    const [processing, setProcessing] = useState(true);

    const [insurancesCheckboxes, setInsurancesCheckboxes] = useState({ comprehensive: false, cci: false, warrenty: false, gap: false, lti: false, rti: false });
    const [insuranceCompanies, setInsuranceCompanies] = useState([]);
    const settlementRef = useRef();

    let navigate = useNavigate();
    const token = useToken();

    useEffect(() => {
        setSingleSettlement(SettlementSchema);
        fetchInsuranceCompanies();
        fetchLendersList();
    }, []);

    useEffect(() => {
    }, [singleSettlement])


    //CALL FUNCTION TO LAOD APPLICATION UPON SELECTION 
    useEffect(() => {
        if (!isNaN(parseInt(applicationId)) && parseInt(applicationId) > 0) {
            //FIND THE SELECTED APPLICATION AND STORE IT TO selectedApplicationOverview
            fetchApplication();  //FETCH SAVED DATA OF AN APPLICATION
            fetchParentReferrerGroupCommissionRate(); //FETCH THE PARENT REFERRER GROUP COMMISSION FOR THE GIVEN APPLICATION
            if (Array.isArray(lookupApplicationResult)) {
                var findApplication = lookupApplicationResult.find(singleApplicaiotn => singleApplicaiotn.id == applicationId);
                setSelectedApplicationOverview(findApplication);
            }
        }
        setLookupApplicationResult([]);
    }, [applicationId])

    const LoadSelectedApplicationOverview = () => {
        if (selectedApplicationOverview !== undefined && selectedApplicationOverview !== null) {
            return (
                <>
                    <div className="row">
                        <div className="col-sm-12 mt-2" style={{ display: "flex" }}>
                            <div> <b>Application #: </b>{selectedApplicationOverview.id} &nbsp; </div>
                            <div> <b>First Name: </b>{selectedApplicationOverview.firstName} &nbsp; </div>
                            <div> <b>Surname: </b>  {selectedApplicationOverview.surname} &nbsp; </div>
                            <div> <b>Referrer Group: </b>{selectedApplicationOverview.referrerGroup} &nbsp; </div>
                            <div> <b>Referrer: </b>{selectedApplicationOverview.referrer}  &nbsp;</div>
                            <div> <b>Lender: </b>{lender !== null && lender.name !== undefined ? lender.name : ""} </div>
                        </div>
                    </div>
                </>
            )
        }
        else {
            return "";
        }
    }

    const fetchApplicationsList = async () => {
        var query = `?page=1&lookupForSettlement=${searchValue}`;
        const response = await getApplicationsList(token, query);
        //console.log("response", response)
        if (response["error"] == 0 && response["data"] !== undefined && response["data"]["records"] !== undefined) {
            setLookupApplicationResult(response["data"]["records"])
            // console.log("IS ARRAY:: ", Array.isArray(response["data"]["records"]));
        }
    }

    //FETCH LENDERS
    async function fetchLendersList() {
        const response = await getLendersList(token);

        if (response["error"] === 0 && response["lenders"]) {
            var lenders = response["lenders"];
            var lendersList = [];
            lendersList.push({ "value": 0, "label": 'Select Lender' }); //FIRST OPTION SO USER CAN DESELECT THE LEDER IF REQUIRED(INSURANC ONLY CASE)

            if (lenders !== undefined && Array.isArray(lenders)) {
                lenders.map(singleLender => { lendersList.push({ "value": singleLender["id"], "label": singleLender["name"] }) })
                setLenders(lendersList)
            }
        }
        else {
        }
    }

    const fetchApplication = async () => {
        var response = await getApplication(applicationId, token);

        if (response["error"] == 0) {
            var data = response["data"];
            var localSettlement = JSON.parse(JSON.stringify(singleSettlement), true); //CREATE DEEP COPY OF SINGLE-SETTLEMENT STATE

            //LOAD LENDER DETAILS
            localSettlement["applicationInfo"] = {};
            localSettlement["applicationInfo"]["lenderId"] = data["lenderId"] !== undefined ? data["lenderId"] : 0;

            //INSURANCE START
            var applicationInsurances = data["insurances"];

            //ADDING UNIQUE KEY TO EACH INSURANCE AND SET TRUE IF INSURANCE ALREADY EXIST
            /*if (Array.isArray(applicationInsurances)) {
                localSettlement["insurances"] = applicationInsurances.map((insurance) => {
                    if (localInsurances[insurance["type"]]) { localInsurances[insurance["type"]]["active"] = true; }
                    //CONVERT INSURANCE UNIX DATE INTO DD/MM/YYYY FORMAT AND UPDATE THE SETTLEMENT PAYLOAD AND THEN LOAD THAT INTO SINGLE-SETTLEMENT STATE
                    insurance["expiryDate"] = formatDate(insurance["expiryDate"], 'unix', 'DD/MM/YYYY');
                    insurance["uid"] = uuid();
                    return insurance;
                })
            }*/

            //INSURANCE START
            var applicationInsurances = data["insurances"];
            var localInsurances = JSON.parse(JSON.stringify(insurancesCheckboxes));

            //ADDING UNIQUE KEY TO EACH INSURANCE
            if (Array.isArray(applicationInsurances)) {
                localSettlement["insurances"] = applicationInsurances.map((insurance) => {
                    localInsurances[insurance["type"]] = true;
                    //CONVERT INSURANCE UNIX DATE INTO DD/MM/YYYY FORMAT AND UPDATE THE SETTLEMENT PAYLOAD AND THEN LOAD THAT INTO SINGLE-SETTLEMENT STATE
                    insurance["expiryDate"] = formatDate(insurance["expiryDate"], 'unix', 'DD/MM/YYYY');
                    return insurance;
                })
            }
            //INSURANCE END

            //UPDATE SETTLEMENT AND INSURANCE STATE
            setInsurancesCheckboxes({
                ...insurancesCheckboxes, comprehensive: localInsurances["comprehensive"], cci: localInsurances["cci"],
                warrenty: localInsurances["warrenty"], gap: localInsurances["gap"], lti: localInsurances["lti"], rti: localInsurances["rti"]
            });
            //INSURANCE END

            //LOAD OTHER DATA INTO SETTLEMENT STATE FROM APPLICATION OBJECT
            var financial = data["financial"];
            var loan = data["loan"];

            //UPDATE IT UPON 
            localSettlement["applicationId"] = applicationId;
            //ORIGINATION FEE
            localSettlement["originationFee"] = financial["originationFee"] !== undefined ? financial["originationFee"] : 0;
            localSettlement["brokerageFee"] = financial["brokerageFee"] !== undefined ? financial["brokerageFee"] : 0;
            localSettlement["totalFinancedAmount"] = loan["totalFinancedAmountIncludingInsurances"] !== undefined ? loan["totalFinancedAmountIncludingInsurances"] : 0;
            localSettlement["interestRate"] = loan["interestRate"] !== undefined ? loan["interestRate"] : 0;
            localSettlement["term"] = loan["loanTerm"] !== undefined ? loan["loanTerm"] : 0;


            var creditRepId = data["creditRepId"];
            var referrId = data["referrerId"];
            var analystId = data["analystId"];
            var referrerStaffId = data["referrerStaffId"];

            localSettlement["analystCommission"][0]["analystId"] = analystId;
            //IF REFERRER STAFF IS NOT 0 AND IT IS NUMBER THEN ASSIGN IT FIRST GIFT CARD RCVR
            if (!isNaN(parseInt(referrerStaffId)) && referrerStaffId > 0) { localSettlement["giftCardReceivers"][0]["userId"] = referrerStaffId; }

            fetchAnalysts(creditRepId);
            fetchReferrerUsers(referrId);
            setSingleSettlement(localSettlement);

            console.log("localSettlement ::: ", localSettlement);

        }
    }

    //FETCH PARENT REFERRER GORUP COMMISSION RATE FOR THE GIVEN APPLICATION
    const fetchParentReferrerGroupCommissionRate = async () => {
        var response = await getParentReferrerGroupCommission("applicationId", applicationId, token);
        if (response["error"] === 0) {
            var data = response["data"];
            setParentReferrerGroupCommissionRate(data["parentReferrerGroupCommission"] !== undefined ? data["parentReferrerGroupCommission"] : 0);

            let parentReferrerGroupCommissionTmp = data["parentReferrerGroupCommission"] !== undefined ? data["parentReferrerGroupCommission"] : 0;
            console.log("PARENT REFERRER GROUP COMMISSION:: ", parentReferrerGroupCommissionTmp);
        }
    }

    //FETCH INSURANCE COMPANIES - API CALL
    const fetchInsuranceCompanies = async () => {
        var response = await getInsuranceCompaniesList(token);
        if (response["error"] != undefined && response["error"] == 0 && response["httpCode"] !== undefined && response["httpCode"] == 200) {
            setInsuranceCompanies(response["data"] || []);
        }
    }

    //FETCH BROKERS
    const fetchAnalysts = async (creditRepId) => {
        var response = await getUsers(token, "creditRep", creditRepId);
        if (response["error"] === 0) {
            var data = response["data"];
            if (Array.isArray(data)) {
                var options = data.map(singleUSer => {
                    return { value: singleUSer["id"], label: singleUSer["firstName"] + " " + singleUSer["surname"] }
                })
                setAnalysts(options);
            }
        }
    }

    //FETCH REFERRER STAFF FOR GIFT CARD RECEIVER
    const fetchReferrerUsers = async (referrerId) => {
        var response = await getUsers(token, "referrer", referrerId);
        if (response["error"] === 0) {
            var data = response["data"];
            if (Array.isArray(data)) {
                var options = data.map(singleUSer => {
                    return { value: singleUSer["id"], label: singleUSer["firstName"] + " " + singleUSer["surname"] }
                })
                setReferrerUsers(options);
            }
        }
    }

    const GoBackSection = () => {
        return (<>
            <div className="row">
                <div className="col-sm-12 mb-5">
                    <Link to={`/user/settlements`} >  <LeftArrowIcon /> Go Back</Link>
                </div>
            </div>

        </>)
    }

    const updateHandler = (name, value) => {
        switch (name) {
            case "searchApplicationId":
                setApplicationId(value);
                break;
            case "lookupApplications":
                setSearchValue(value);
                break;
        }
    }

    //TOTAL COMMISSINO
    const TotalCommission = (props) => {

        const {
            values: { financeCommission, originationFee, brokerageFee, referrerCommission, referrerGroupCommission},
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            var totalAmount = 0;
            totalAmount += financeCommission !== undefined && (!isNaN(parseFloat(financeCommission))) ? parseFloat(financeCommission) : 0;
            totalAmount += originationFee !== undefined && (!isNaN(parseFloat(originationFee))) ? parseFloat(originationFee) : 0;
            totalAmount += brokerageFee !== undefined && (!isNaN(parseFloat(brokerageFee))) ? parseFloat(brokerageFee) : 0;
            //totalAmount -= referrerCommission !== undefined && (!isNaN(parseFloat(referrerCommission))) ? parseFloat(referrerCommission) : 0;

            //GET INSURANCES
            var insurances = settlementRef !== null && settlementRef !== undefined && settlementRef.current !== null && settlementRef.current !== undefined &&
                settlementRef.current.values !== null && settlementRef.current.values !== undefined && settlementRef.current.values.insurances !== undefined ?
                settlementRef.current.values.insurances : [];

            let toalInsuranceCommission = 0;
            if (Array.isArray(insurances)) {
                insurances.map(singleInsurance => {
                    toalInsuranceCommission += singleInsurance.commission !== undefined && (!isNaN(parseFloat(singleInsurance.commission))) ? parseFloat(singleInsurance.commission) : 0;
                })
            }
            totalAmount += (!isNaN(parseFloat(toalInsuranceCommission))) ? parseFloat(toalInsuranceCommission) : 0;
            setFieldValue(props.name, totalAmount.toFixed(2));

            //CALL PARENT REFERRER GROUP COMMISSION HANDLER TO CALCULATE THE COMMISSION
            let referrerCommissionAmount = referrerCommission !== undefined && (!isNaN(parseFloat(referrerCommission))) ? parseFloat(referrerCommission) : 0;
            parentReferrerGroupCommissionHandler((totalAmount-referrerCommissionAmount).toFixed(2) , setFieldValue); //CALCULATE PARENT REFERRER GROUP COMMISSION

        }, [financeCommission, originationFee, brokerageFee, referrerCommission, referrerGroupCommission, 
            touched.financeCommission, touched.originationFee, touched.brokerageFee, touched.referrerCommission, touched.referrerGroupCommission, setFieldValue, props.name]);
        return (
            <>
                <InputGroup
                    fieldName="f2"
                    symbol="dollar"
                    symbolPosition="left"
                    placeholder="0.00"
                    fieldValue={field.value}
                    setFieldValue={setFieldValue}
                />
                {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
            </>
        );
    };
    //NETT COMMISSINO
    const NettCommission = (props) => {

        const {
            values: { financeCommission, originationFee, brokerageFee, referrerCommission, referrerGroupCommission, parentReferrerGroupCommission},
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            var totalAmount = 0;
            totalAmount += financeCommission !== undefined && (!isNaN(parseFloat(financeCommission))) ? parseFloat(financeCommission) : 0;
            totalAmount += originationFee !== undefined && (!isNaN(parseFloat(originationFee))) ? parseFloat(originationFee) : 0;
            totalAmount += brokerageFee !== undefined && (!isNaN(parseFloat(brokerageFee))) ? parseFloat(brokerageFee) : 0;
            totalAmount -= referrerCommission !== undefined && (!isNaN(parseFloat(referrerCommission))) ? parseFloat(referrerCommission) : 0;

            let parentReferrerGroupCommissionValue = parentReferrerGroupCommission !== undefined && (!isNaN(parseFloat(parentReferrerGroupCommission))) ? parseFloat(parentReferrerGroupCommission) : 0;
            totalAmount -= parentReferrerGroupCommissionValue;   //WE  DID THIS IN 2 STEPS AS WE NEED TEH VALUE OF parentReferrerGroupCommissionValue IN NEXT STEP FOR PARENT REFERRER GROUP COMMISSION HANDLER 

            //GET INSURANCES
            var insurances = settlementRef !== null && settlementRef !== undefined && settlementRef.current !== null && settlementRef.current !== undefined &&
                settlementRef.current.values !== null && settlementRef.current.values !== undefined && settlementRef.current.values.insurances !== undefined ?
                settlementRef.current.values.insurances : [];

            let toalInsuranceCommission = 0;
            if (Array.isArray(insurances)) {
                insurances.map(singleInsurance => {
                    toalInsuranceCommission += singleInsurance.commission !== undefined && (!isNaN(parseFloat(singleInsurance.commission))) ? parseFloat(singleInsurance.commission) : 0;
                })
            }
            totalAmount += (!isNaN(parseFloat(toalInsuranceCommission))) ? parseFloat(toalInsuranceCommission) : 0;
            setFieldValue(props.name, totalAmount.toFixed(2));

            //parentReferrerGroupCommissionHandler((parseFloat(totalAmount) + parseFloat(parentReferrerGroupCommissionValue)).toFixed(2)); //CALL PARENT REFERRER GROUP COMMISSION HANDLER TO CALCULATE THE COMMISSION

        }, [financeCommission, originationFee, brokerageFee, referrerCommission, referrerGroupCommission, parentReferrerGroupCommission,
            touched.financeCommission, touched.originationFee, touched.brokerageFee, touched.referrerCommission, touched.referrerGroupCommission, touched.parentReferrerGroupCommission, setFieldValue, props.name]);
        return (
            <>
                <InputGroup
                    fieldName="f2"
                    symbol="dollar"
                    symbolPosition="left"
                    placeholder="0.00"
                    fieldValue={field.value}
                    setFieldValue={setFieldValue}
                />
                {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
            </>
        );
    };

    //TotalCommissionHandler - EXECUTED WHEN INURANCE AMOUNT CHANGED
    const TotalCommissionHandler = (setFieldValue) => {
        let toalInsuranceCommission = 0;
        let totalAmount = 0;

        let values = settlementRef !== null && settlementRef !== undefined && settlementRef.current !== null && settlementRef.current !== undefined &&
            settlementRef.current.values !== null && settlementRef.current.values !== undefined ? settlementRef.current.values : {};

        let insurances = values.insurances !== undefined ? values.insurances : [];

        if (Array.isArray(insurances)) {
            insurances.map(singleInsurance => {
                toalInsuranceCommission += singleInsurance.commission !== undefined && (!isNaN(parseFloat(singleInsurance.commission))) ? parseFloat(singleInsurance.commission) : 0;
            })
        }

        totalAmount += values.financeCommission !== undefined && (!isNaN(parseFloat(values.financeCommission))) ? parseFloat(values.financeCommission) : 0;
        totalAmount += values.originationFee !== undefined && (!isNaN(parseFloat(values.originationFee))) ? parseFloat(values.originationFee) : 0;
        totalAmount += values.brokerageFee !== undefined && (!isNaN(parseFloat(values.brokerageFee))) ? parseFloat(values.brokerageFee) : 0;
        totalAmount -= values.referrerCommission !== undefined && (!isNaN(parseFloat(values.referrerCommission))) ? parseFloat(values.referrerCommission) : 0;
        let parentReferrerGroupCommissionValue = values.parentReferrerGroupCommission !== undefined && (!isNaN(parseFloat(values.parentReferrerGroupCommission))) ? parseFloat(values.parentReferrerGroupCommission) : 0;

        totalAmount = parseFloat(toalInsuranceCommission) + parseFloat(totalAmount);
        //NETT COMMISSION
        setFieldValue("nettCommission", (totalAmount-parentReferrerGroupCommissionValue).toFixed(2));
        parentReferrerGroupCommissionHandler((totalAmount-parentReferrerGroupCommissionValue).toFixed(2), setFieldValue); //CALCULATE PARENT REFERRER GROUP COMMISSION

        //TOTAL COMMISSION
        let totalCommission = totalAmount;
        totalCommission += values.referrerCommission !== undefined && (!isNaN(parseFloat(values.referrerCommission))) ? parseFloat(values.referrerCommission) : 0;
        setFieldValue("totalCommission", totalCommission.toFixed(2));
    }

    //CALCULATE PARENT REFERRER GROUP COMMISSION
    /*const parentReferrerGroupCommissionHandler = (nettCommission) => {

        //CHECK IF REFERRER GROUP COMMISSION IS NOT 0 THEN CALCULATE THE PARENT REFERRER GROUP COMMISSION
        try{
            console.log(" nettCommission::: ", nettCommission);
            setParentReferrerGroupCommissionAmount((nettCommission * (parentReferrerGroupCommissionRate / 100)).toFixed(2));
        }
        catch(e){
        }
    }*/

    //PARENT REFERRER GROUP SOMMISSION CALCLATION BASED ON THE PROVIDED RATE
    const parentReferrerGroupCommissionHandler = (nettCommission , setFieldValue) => {
        try {

            //CHECK THE REFERRAL GROUP COMMISSION 
            let referralGroupCommission = settlementRef !== null && settlementRef !== undefined && settlementRef.current !== null && settlementRef.current !== undefined 
            && settlementRef.current.values !== null && settlementRef.current.values !== undefined && 
            settlementRef.current.values.referrerGroupCommission !== undefined && !isNaN(parseFloat(settlementRef.current.values.referrerGroupCommission)) ? parseFloat(settlementRef.current.values.referrerGroupCommission) : 0;

            console.log("referralGroupCommissio n   line 417 -- :: ", referralGroupCommission);


            const commissionAmount = ( (nettCommission-referralGroupCommission) * (parentReferrerGroupCommissionRate / 100)).toFixed(2);
            setParentReferrerGroupCommissionAmount(commissionAmount);

            setFieldValue("parentReferrerGroupCommission", commissionAmount);

            //UPDATE THE FIELD VALUE

        } catch (e) {
            console.error('Error calculating parent referrer group commission:', e);
        }
    };

    //SETTLEMENT BASIC DETAIL BLOCK
    const BasicDetails = (errors, touched, setFieldValue) => {
        return (<>
            <div className="card mb-5 mb-xxl-8">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <h3 className="pb-0 mb-0">Create Settlement</h3>
                        <div style={{ display: "flex", paddingLeft: "20px" }}>
                            <Field name="insuranceOnly" >
                                {({ field }) => (
                                    <CheckBox
                                        name="insuranceOnly"
                                        defaultChecked={field.value}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </Field>
                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Insurance Only </span>
                        </div>
                    </div>
                    <div className="separator separator-dashed my-5"></div>

                    <div className="position-relative z-index-1">
                        <div className="row">
                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="  fs-8 fw-bold mb-2">Settlement Date</label>
                                <Field type="text" name={`settlementDate`}  >
                                    {({ field }) => (
                                        <MaskedField
                                            fieldName="settlementDate"
                                            fieldValue={field.value !== undefined && dateFilter(field.value) !== 0 ? dateFilter(field.value) : ""}
                                            maskRule="date"
                                            fieldPlaceholder="dd/mm/yyyy"
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                {errors.settlementDate ? (<div className="error">{errors.settlementDate}</div>) : null}
                            </div>
                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container mb-2">
                                <label className="  fs-8 fw-bold mb-2">Reinstate Date (optional)</label>
                                <Field type="text" name={`reinstateDate`}  >
                                    {({ field }) => (
                                        <MaskedField
                                            fieldName={`reinstateDate`}
                                            fieldValue={dateFilter(field.value) !== 0 ? dateFilter(field.value) : ""}
                                            maskRule="date"
                                            fieldPlaceholder="dd/mm/yyyy"
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                {errors.reinstateDate ? (<div className="error">{errors.reinstateDate}</div>) : null}
                            </div>
                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="required fs-8 fw-bold mb-2">Lender</label>
                                <Field name="applicationInfo.lenderId">
                                    {({ field }) => (
                                        <SelectField
                                            fieldName="applicationInfo.lenderId"
                                            defaultSelectedOption={{ value: field.value, label: '' }}
                                            options={lenders}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                { /*<LenderObserver name="lenderValueObserver" /> */}
                            </div>
                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="  fs-8 fw-bold mb-2">Loan Contract #</label>
                                <Field name="loanContractNumber">
                                    {({ field }) => (
                                        <InputField
                                            fieldName="loanContractNumber"
                                            fieldValue={field.value !== undefined ? field.value : ""}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                {errors.loanContractNumber ? (<div className="error">{errors.loanContractNumber}</div>) : null}
                            </div>
                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="  fs-8 fw-bold mb-2">Total Financed Amount <span style={{ fontSize: "10px" }}>(incl Est fee + Insurances)</span></label>
                                <Field name="totalFinancedAmount" >
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="totalFinancedAmount"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            fieldValue={field.value !== undefined ? field.value : ""}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                {errors.totalFinancedAmount ? (<div className="error">{errors.totalFinancedAmount}</div>) : null}
                            </div>
                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="  fs-8 fw-bold mb-2">Interest Rate %</label>
                                <Field name="interestRate" >
                                    {({ field }) => (
                                        <FloatField
                                            fieldName="interestRate"
                                            fieldValue={field.value !== undefined ? field.value : ""}
                                            placeholder=""
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                {errors.interestRate ? (<div className="error">{errors.interestRate}</div>) : null}
                            </div>

                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="  fs-8 fw-bold mb-2">Term(Months)</label>
                                <Field name="term" >
                                    {({ field }) => (
                                        <NumberField
                                            fieldName="term"
                                            fieldValue={field.value !== undefined ? field.value : ""}
                                            placeholder=""
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                {errors.term ? (<div className="error">{errors.term}</div>) : null}
                            </div>

                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="  fs-8 fw-bold mb-2">Finance Commission</label>
                                <Field name="financeCommission" >
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="financeCommission"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            fieldValue={field.value !== undefined ? field.value : ""}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                {errors.financeCommission ? (<div className="error">{errors.financeCommission}</div>) : null}
                            </div>

                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="  fs-8 fw-bold mb-2">Origination Fee</label>
                                <Field name="originationFee">
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="originationFee"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            fieldValue={field.value !== undefined ? field.value : ""}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                {errors.originationFee ? (<div className="error">{errors.originationFee}</div>) : null}
                            </div>

                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="  fs-8 fw-bold mb-2">Brokerage Fee</label>
                                <Field name="brokerageFee" >
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="brokerageFee"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            fieldValue={field.value !== undefined ? field.value : ""}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                {errors.brokerageFee ? (<div className="error">{errors.brokerageFee}</div>) : null}
                            </div>

                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="  fs-8 fw-bold mb-2">Referrer Commission</label>
                                <Field name="referrerCommission" >
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="referrerCommission"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            fieldValue={field.value !== undefined ? field.value : ""}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                {errors.referrerCommission ? (<div className="error">{errors.referrerCommission}</div>) : null}
                            </div>

                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="  fs-8 fw-bold mb-2">Referrer Group Commission</label>
                                <Field name="referrerGroupCommission" >
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="referrerGroupCommission"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            fieldValue={field.value !== undefined ? field.value : ""}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                {errors.referrerGroupCommission ? (<div className="error">{errors.referrerGroupCommission}</div>) : null}
                            </div>

                            {/** PARENT REFERRER GROUP COMMISSION - AUTO CALCULATED */}
                            <div className="col-sm-12 col-md-6 fv-row fv-plugins-icon-container">
                                <label className="  fs-8 fw-bold mb-2">Parent Referrer Group Manager Commission( Rate: {parentReferrerGroupCommissionRate} % - Calculated Commission : {parentReferrerGroupCommissionAmount})</label>
                                <Field name="parentReferrerGroupCommission" >
                                    {({ field }) => (
                                        <InputGroup
                                            fieldName="parentReferrerGroupCommission"
                                            symbol="dollar"
                                            symbolPosition="left"
                                            placeholder="0.00"
                                            fieldValue={field.value}
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                </Field>
                                {errors.parentReferrerGroupCommission ? (<div className="error">{errors.parentReferrerGroupCommission}</div>) : null}
                            </div>

                             
                            <div className="col-sm-12"></div>
                            {GiftCardReceiverHtmlBlock(setFieldValue)}
                            {BrokersHtmlBlock(setFieldValue)}
                        </div>

                        <div className="row">
                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="fs-8 fw-bold mb-2">Nett Commission</label>
                                <NettCommission name="nettCommission" />
                            </div>
                            <div className="col-sm-6 col-md-3 fv-row fv-plugins-icon-container">
                                <label className="fs-8 fw-bold mb-2">Total Commission</label>
                                <TotalCommission name="totalCommission" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

    //INSURANCES HTML BLOCK
    const InsurancesHtmlBlock = ({ errors, touched, setFieldValue, values }) => {
        return (<>
            <div className="card mb-5 mb-xxl-8">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <h3 className="pb-0 mb-0">Insurances</h3>
                    </div>
                    <div className="separator separator-dashed my-5"></div>
                    <div className="position-relative z-index-1">
                        <div className='row mb-5'>

                        </div>
                        <FieldArray name="insurances">
                            {({ remove, push }) => (
                                <div style={{ display: "flex", justifyContent: "start" }}>

                                    <CheckBox name="comprehensive" defaultChecked={insurancesCheckboxes["comprehensive"]} setFieldValue={
                                        (name, value) => {
                                            //ADD COMP INSURANCE
                                            if (value) {
                                                push({ type: "comprehensive", insuranceCompanyId: 0, commission: 0, grossPremium: 0, expiryDate: '', extraDetail: { policyNumber: "" } })
                                            }
                                            else {
                                                //FIND THE COMP INSURANCE INDEX AND REMOVE IT
                                                if (values !== undefined && values !== null && values.insurances !== undefined && Array.isArray(values.insurances)) {
                                                    values.insurances.map((singleInsurance, index) => { if (singleInsurance.type == "comprehensive") { remove(index); } })
                                                }
                                            }
                                        }
                                    } />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> Comprehensive </span>

                                    <CheckBox name="cci" defaultChecked={insurancesCheckboxes["cci"]} setFieldValue={
                                        (name, value) => {
                                            //ADD COMP INSURANCE
                                            if (value) { push({ type: "cci", insuranceCompanyId: 0, commission: 0, grossPremium: 0, expiryDate: '', extraDetail: { policyType: "" } }) }
                                            else {
                                                //FIND THE COMP INSURANCE INDEX AND REMOVE IT
                                                if (values !== undefined && values !== null && values.insurances !== undefined && Array.isArray(values.insurances)) {
                                                    values.insurances.map((singleInsurance, index) => { if (singleInsurance.type == "cci") { remove(index); } })
                                                }
                                            }
                                        }
                                    } />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> CCI </span>

                                    <CheckBox name="cci" defaultChecked={insurancesCheckboxes["warrenty"]} setFieldValue={
                                        (name, value) => {
                                            //ADD COMP INSURANCE
                                            if (value) { push({ type: "warrenty", insuranceCompanyId: 0, commission: 0, grossPremium: 0, netPremium: 0, expiryDate: '' }) }
                                            else {
                                                //FIND THE COMP INSURANCE INDEX AND REMOVE IT
                                                if (values !== undefined && values !== null && values.insurances !== undefined && Array.isArray(values.insurances)) {
                                                    values.insurances.map((singleInsurance, index) => { if (singleInsurance.type == "warrenty") { remove(index); } })
                                                }
                                            }
                                        }
                                    } />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> Warrenty </span>

                                    <CheckBox name="cci" defaultChecked={insurancesCheckboxes["gap"]} setFieldValue={
                                        (name, value) => {
                                            //ADD COMP INSURANCE
                                            if (value) { push({ type: "gap", insuranceCompanyId: 0, commission: 0, grossPremium: 0, expiryDate: '' }) }
                                            else {
                                                //FIND THE COMP INSURANCE INDEX AND REMOVE IT
                                                if (values !== undefined && values !== null && values.insurances !== undefined && Array.isArray(values.insurances)) {
                                                    values.insurances.map((singleInsurance, index) => { if (singleInsurance.type == "gap") { remove(index); } })
                                                }
                                            }
                                        }
                                    } />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> GAP </span>

                                    <CheckBox name="cci" defaultChecked={insurancesCheckboxes["lti"]} setFieldValue={
                                        (name, value) => {
                                            //ADD COMP INSURANCE
                                            if (value) { push({ type: "lti", insuranceCompanyId: 0, commission: 0, grossPremium: 0, expiryDate: '' }) }
                                            else {
                                                //FIND THE COMP INSURANCE INDEX AND REMOVE IT
                                                if (values !== undefined && values !== null && values.insurances !== undefined && Array.isArray(values.insurances)) {
                                                    values.insurances.map((singleInsurance, index) => { if (singleInsurance.type == "lti") { remove(index); } })
                                                }
                                            }
                                        }
                                    } />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> LTI </span>

                                    <CheckBox name="cci" defaultChecked={insurancesCheckboxes["rti"]} setFieldValue={
                                        (name, value) => {
                                            //ADD COMP INSURANCE
                                            if (value) { push({ type: "rti", insuranceCompanyId: 0, commission: 0, grossPremium: 0, expiryDate: '' }) }
                                            else {
                                                //FIND THE COMP INSURANCE INDEX AND REMOVE IT
                                                if (values !== undefined && values !== null && values.insurances !== undefined && Array.isArray(values.insurances)) {
                                                    values.insurances.map((singleInsurance, index) => { if (singleInsurance.type == "rti") { remove(index); } })
                                                }
                                            }
                                        }
                                    } />
                                    <span style={{ marginLeft: "3px", marginRight: "20px" }}> RTI </span>


                                </div>
                            )}
                        </FieldArray>



                    </div>
                </div>
            </div>
        </>)
    }

    //GIFT CAR RECEIVERS
    const GiftCardReceiverHtmlBlock = (setFieldValue) => {
        return (<>
            {
                singleSettlement !== undefined && singleSettlement.giftCardReceivers !== undefined ?
                    singleSettlement.giftCardReceivers.map((giftCardReceiver, index) => {
                        return <GiftCardReceiver index={index} referrerUsers={referrerUsers} setFieldValue={setFieldValue} key={index} />
                    }) :
                    ""
            }
        </>)
    }


    //BROKERS | ANALYSTS
    const BrokersHtmlBlock = (setFieldValue) => {
        return (<>
            {
                singleSettlement !== undefined && singleSettlement.analystCommission !== undefined ?
                    singleSettlement.analystCommission.map((analystCommission, index) => {
                        return <AnalystCommission index={index} analysts={analysts} setFieldValue={setFieldValue} key={index} />
                    }) :
                    ""
            }
        </>)
    }

    const CommentSection = (errors, touched, setFieldValue) => {
        return (<>
            <div className="card mb-5 mb-xxl-8">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <h3 className="pb-0 mb-0">Comments</h3>
                    </div>
                    <div className="separator separator-dashed my-5"></div>

                    <div className="position-relative z-index-1">
                        <Field name="comments">
                            {({ field }) => (
                                <TextArea
                                    fieldName="comments"
                                    placeholder="Comments"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                    </div>
                </div>
            </div>
        </>)
    }


    return (
        <>
            <div id="kt_content_container" className="container-xxl">
                <GoBackSection />
                <div className="row g-5 g-xl-8">
                    <div className="col-xl-12">
                        <div className="card mb-5 mb-xxl-8">
                            <div className="card-body card-body pt-6 pb-6">
                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                    <h3 className="pb-0 mb-0">Find Referral By application id, first name or surname</h3>
                                </div>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <div className="col-md-10 position-relative z-index-1">
                                        <InputField
                                            fieldName="lookupApplications"
                                            placeholder="Search by Application Id, first name, surname"
                                            fieldValue={searchValue}
                                            setFieldValue={updateHandler}
                                        />
                                    </div>

                                    <div className="col-md-2 position-relative z-index-1">
                                        <CustomButton
                                            buttonClassName=" btn-primary"
                                            isActive={searchValue.length > 0 ? true : false}
                                            buttonOnClick={() => { fetchApplicationsList() }}
                                            buttonStyle={{ padding: "6px 14px  6px 15px" }}
                                            buttonText="Load"
                                        />
                                    </div>
                                </div>
                                <LoadSelectedApplicationOverview />

                                <div className="row">
                                    {Array.isArray(lookupApplicationResult)}
                                    {
                                        Array.isArray(lookupApplicationResult) ?
                                            lookupApplicationResult.map((singleApplication, index) => {
                                                return (<>
                                                    <div className="col-sm-12" key={index + "application"} >
                                                        <div className="mt-2" style={{ display: "flex", justifyContent: "start" }}>
                                                            <div style={{ paddingRight: "10px" }}>
                                                                <CheckBox
                                                                    name={singleApplication.id}
                                                                    defaultChecked={false}
                                                                    setFieldValue={(name, value) => { setApplicationId(name); }}
                                                                />
                                                            </div>
                                                            <div>
                                                                {singleApplication.firstName + "  "} {singleApplication.surname} ( {singleApplication.referrerGroup + " - "} {singleApplication.referrer} )
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)
                                            }) : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            { /************************  SEARCH APPLICAITON BY ID COMPNENT END *************************************/}

            <Formik
                initialValues={singleSettlement}
                validationSchema={SettlementValidationSchema}
                innerRef={settlementRef}
                enableReinitialize={true}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    let valuesClone = JSON.parse(JSON.stringify(values));
                    //CHECK IF ANALYST COMMISSION HAS A RECORD THAT DOES NOT HAVE A ANALYST ID - REMOVE IT
                    var analystCommission = valuesClone["analystCommission"];
                    if (analystCommission[1] !== undefined && analystCommission[1]["analystId"] === 0) {
                        analystCommission.pop();
                        valuesClone["analystCommission"] = analystCommission;
                    }

                    //CONVERT ALL DATES INTO YYYY/MM/DD FORMAT
                    var localInsurances = valuesClone["insurances"];
                    if (Array.isArray(localInsurances)) {
                        var modifiedInsurances = localInsurances.map((singleInsurance) => {
                            singleInsurance["expiryDate"] = singleInsurance["expiryDate"].includes("/") ? formatDate(singleInsurance["expiryDate"], 'DD/MM/YYYY', 'YYYY-MM-DD') : singleInsurance["expiryDate"];
                            return singleInsurance;
                        })
                        valuesClone["insurances"] = modifiedInsurances;
                    }
                    //SETTLEMENT DATE
                    valuesClone["settlementDate"] = valuesClone["settlementDate"].includes("/") ? formatDate(valuesClone["settlementDate"], 'DD/MM/YYYY', 'YYYY-MM-DD') : valuesClone["settlementDate"];

                    //REINSTATE DATE
                    valuesClone["reinstateDate"] = valuesClone["reinstateDate"] !== undefined && valuesClone["reinstateDate"].includes("/") ? formatDate(valuesClone["reinstateDate"], 'DD/MM/YYYY', 'YYYY-MM-DD') : valuesClone["reinstateDate"];

                    //SUBMIT TO API
                    setProcessing(true);
                    console.log("valuesClone ::: ", valuesClone);
                    var response = await createSettlement(valuesClone, token);
                    setProcessing(false);

                    if (response["error"] === 0) {
                        //update settlement 
                        Notifications("success", response["message"] || "Updated!")
                        navigate(`/user/settlements`);
                    }
                    else {
                        //notification
                        Notifications("error", response["message"] || "Error occured")
                    }
                }}
            >
                {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-5 g-xl-8">
                                <div className="col-xl-12">
                                    <div className="row">
                                        {BasicDetails(errors, touched, setFieldValue)}
                                    </div>
                                    {/* LOADING THE INSURANCE BLOCK DIRECTLY HERE TO AVOID UNCESSARY RERENDERS */}
                                    <div className="row">                                    
                                        <div className="card mb-5 mb-xxl-8">
                                            <div className="card-body card-body pt-6 pb-6">
                                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                                    <h3 className="pb-0 mb-0">Insurances</h3>                                                     
                                                </div>
                                                <div className="separator separator-dashed my-5"></div>
                                                <div className="position-relative z-index-1">
                                                    <div className='row mb-5'>

                                                    </div>
                                                    <FieldArray name="insurances">
                                                        {({ remove, push }) => (
                                                            <div style={{ display: "flex", justifyContent: "start" }}>

                                                                <CheckBox name="comprehensive" defaultChecked={insurancesCheckboxes["comprehensive"]} setFieldValue={
                                                                    (name, value) => {
                                                                        //ADD COMP INSURANCE
                                                                        if (value) {
                                                                            push({ type: "comprehensive", insuranceCompanyId: 0, commission: 0, grossPremium: 0, expiryDate: '', extraDetail: { policyNumber: "" } })
                                                                        }
                                                                        else {
                                                                            //FIND THE COMP INSURANCE INDEX AND REMOVE IT
                                                                            if (values !== undefined && values !== null && values.insurances !== undefined && Array.isArray(values.insurances)) {
                                                                                values.insurances.map((singleInsurance, index) => { if (singleInsurance.type == "comprehensive") { remove(index); } })
                                                                            }
                                                                        }
                                                                    }
                                                                } />
                                                                <span style={{ marginLeft: "3px", marginRight: "20px" }}> Comprehensive </span>

                                                                <CheckBox name="cci" defaultChecked={insurancesCheckboxes["cci"]} setFieldValue={
                                                                    (name, value) => {
                                                                        //ADD COMP INSURANCE
                                                                        if (value) { push({ type: "cci", insuranceCompanyId: 0, commission: 0, grossPremium: 0, expiryDate: '', extraDetail: { policyType: "" } }) }
                                                                        else {
                                                                            //FIND THE COMP INSURANCE INDEX AND REMOVE IT
                                                                            if (values !== undefined && values !== null && values.insurances !== undefined && Array.isArray(values.insurances)) {
                                                                                values.insurances.map((singleInsurance, index) => { if (singleInsurance.type == "cci") { remove(index); } })
                                                                            }
                                                                        }
                                                                    }
                                                                } />
                                                                <span style={{ marginLeft: "3px", marginRight: "20px" }}> CCI </span>

                                                                <CheckBox name="cci" defaultChecked={insurancesCheckboxes["warrenty"]} setFieldValue={
                                                                    (name, value) => {
                                                                        //ADD COMP INSURANCE
                                                                        if (value) { push({ type: "warrenty", insuranceCompanyId: 0, commission: 0, grossPremium: 0, netPremium: 0, expiryDate: '' }) }
                                                                        else {
                                                                            //FIND THE COMP INSURANCE INDEX AND REMOVE IT
                                                                            if (values !== undefined && values !== null && values.insurances !== undefined && Array.isArray(values.insurances)) {
                                                                                values.insurances.map((singleInsurance, index) => { if (singleInsurance.type == "warrenty") { remove(index); } })
                                                                            }
                                                                        }
                                                                    }
                                                                } />
                                                                <span style={{ marginLeft: "3px", marginRight: "20px" }}> Warrenty </span>

                                                                <CheckBox name="cci" defaultChecked={insurancesCheckboxes["gap"]} setFieldValue={
                                                                    (name, value) => {
                                                                        //ADD COMP INSURANCE
                                                                        if (value) { push({ type: "gap", insuranceCompanyId: 0, commission: 0, grossPremium: 0, expiryDate: '' }) }
                                                                        else {
                                                                            //FIND THE COMP INSURANCE INDEX AND REMOVE IT
                                                                            if (values !== undefined && values !== null && values.insurances !== undefined && Array.isArray(values.insurances)) {
                                                                                values.insurances.map((singleInsurance, index) => { if (singleInsurance.type == "gap") { remove(index); } })
                                                                            }
                                                                        }
                                                                    }
                                                                } />
                                                                <span style={{ marginLeft: "3px", marginRight: "20px" }}> GAP </span>

                                                                <CheckBox name="cci" defaultChecked={insurancesCheckboxes["lti"]} setFieldValue={
                                                                    (name, value) => {
                                                                        //ADD COMP INSURANCE
                                                                        if (value) { push({ type: "lti", insuranceCompanyId: 0, commission: 0, grossPremium: 0, expiryDate: '' }) }
                                                                        else {
                                                                            //FIND THE COMP INSURANCE INDEX AND REMOVE IT
                                                                            if (values !== undefined && values !== null && values.insurances !== undefined && Array.isArray(values.insurances)) {
                                                                                values.insurances.map((singleInsurance, index) => { if (singleInsurance.type == "lti") { remove(index); } })
                                                                            }
                                                                        }
                                                                    }
                                                                } />
                                                                <span style={{ marginLeft: "3px", marginRight: "20px" }}> LTI </span>

                                                                <CheckBox name="cci" defaultChecked={insurancesCheckboxes["rti"]} setFieldValue={
                                                                    (name, value) => {
                                                                        //ADD COMP INSURANCE
                                                                        if (value) { push({ type: "rti", insuranceCompanyId: 0, commission: 0, grossPremium: 0, expiryDate: '' }) }
                                                                        else {
                                                                            //FIND THE COMP INSURANCE INDEX AND REMOVE IT
                                                                            if (values !== undefined && values !== null && values.insurances !== undefined && Array.isArray(values.insurances)) {
                                                                                values.insurances.map((singleInsurance, index) => { if (singleInsurance.type == "rti") { remove(index); } })
                                                                            }
                                                                        }
                                                                    }
                                                                } />
                                                                <span style={{ marginLeft: "3px", marginRight: "20px" }}> RTI </span>


                                                            </div>
                                                        )}
                                                    </FieldArray>

                                                    <div className='row mt-2'>
                                                        {
                                                            Array.isArray(insuranceCompanies) && insuranceCompanies.length > 0 && settlementRef !== undefined && settlementRef.current !== undefined
                                                                && settlementRef.current.values !== undefined && settlementRef.current.values.insurances !== undefined ?
                                                                settlementRef.current.values.insurances.map((insurance, index) => {
                                                                    return <SingleInsurance key={insurance["type"]} labelPrefix={insurance["type"]} index={index} type={[insurance["type"]]} insuranceCompanies={insuranceCompanies} setFieldValue={setFieldValue} callback={(name, value) => { TotalCommissionHandler(setFieldValue) }} />
                                                                }) :
                                                                ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {CommentSection(errors, touched, setFieldValue)}
                                    </div>
                                </div>

                                <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{ float: "right" }}>Please fix the above errors first. </div> : ""}</div>
                                <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}
export default CreateSettlement;


/**
 * 
 * 
 
  <div id="kt_content_container" className="container-xxl">
                <div className="row g-5 g-xl-8">
                    <div className="col-xl-12">
                        <div className="card mb-5 mb-xxl-8">
                            <div className="card-body card-body pt-6 pb-6">
                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                    <h3 className="pb-0 mb-0">Find Referral By #</h3>
                                </div>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <div className="col-md-10 position-relative z-index-1">
                                        <InputField
                                            fieldName="searchApplicationId"
                                            placeholder="Type Application Id and hit submit"
                                            fieldValue={applicationId}
                                            setFieldValue={updateHandler}
                                        />
                                    </div>

                                    <div className="col-md-2 position-relative z-index-1">
                                        <CustomButton
                                            buttonClassName=" btn-primary"
                                            isActive={applicationId.length > 0 ? true : false}
                                            buttonOnClick={() => { fetchApplication() }}
                                            buttonStyle={{ padding: "6px 14px  6px 15px" }}
                                            buttonText="Load"
                                        />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

 * 
<Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-5 g-xl-8">
                                <div className="col-xl-12">
                                    <div className="row">
                                        {BasicDetails(errors, touched, setFieldValue)}
                                    </div>
                                    <div className="row">
                                        {InsurancesHtmlBlock(errors, touched, setFieldValue)}
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    {CommentSection(errors, touched, setFieldValue)}
                                </div>
                                <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{ float: "right" }}>Please fix the above errors first. </div> : ""}</div>
                                <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>

                            </div>
                        </div>
                    </Form>
 */