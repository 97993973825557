import { useEffect, useState, useRef } from "react";
import { DownArrowIcon } from '../../../../components/HtmlComponents/Icons';
import { FormSubmissionButton, TextEditor, CheckBox, FloatField, SelectField, Modal, RadioGroup, InputGroup, InputField  , RightDrawer} from '../../../../components/HtmlComponents';
import{formatDate} from '../../../../helpers';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import BuyingMotorVehicleItem from "./components/BuyingMotorVehicleItem";
import TradeIn from "./components/TradeIn";
import SingleInsurance from './components/SingleInsurance';

import { getReferrerGroupsList, getInsuranceCompaniesList, getUsers, getReferrersListAgainstReferrerGroup, getLendersList, getLender } from "../../../../api";

import Constants from "../../../../components/global/Constants";
import { capitalizeFirst } from '../../../../helpers';
import Insurances from "./components/Insurances";
import { useRole, useToken, useEntityId, useEntityType } from '../../../../hooks/useAuth';
import useCreditReps from '../../../../hooks/useCreditReps';

import Notifications from "../../../../components/global/Notifications";

import SearchForCarInfo from "../../../../components/applications/Utilities/SearchForCarInfo";

const Quote = ({ initialValues, formRef, executeApplicationSubmissionHandler, openByDefault }) => {
    const [isOpen, setIsOpen] = useState(openByDefault == true ? "block" : "none");
    const [loading, setLoading] = useState(true);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });

    const [loanPurposes, setLoanPurposes] = useState({});
    const [loanTypes, setLoanTypes] = useState({});
    const [applicationCategories, setApplicationCategories] = useState([]);
    
    const [referrerGroups, setReferrerGroups] = useState([]);
    const [referrers, setReferrers] = useState([]);
    const [referrerUsers, setReferrerUsers] = useState([]);
    const [lenders, setLenders] = useState([]);

    /***INSURANCE STATES START  */
    const insuranceLabels = { "comprehensive": "Comprehensive", "cci": "CCI", "warrenty": "Warranty", "gap": "GAP", "lti": "LTI", "rti": "RTI" }
    const [insuranceCompanies, setInsuranceCompanies] = useState([]);

    /** INSURANCE STATES END */
    const token = useToken();
    const userRole = useRole();
    const entityId = useEntityId();
    const entityType = useEntityType();
    const { selectedCreditRepId, CreditRepsDropdown  } = useCreditReps();

    const handleChange = () => { setIsOpen(isOpen === "none" ? "block" : "none"); }

    /***** REFERRER GROUPS LIST  ******/
    async function fetchReferrerGroupsList() {
        const result = await getReferrerGroupsList(token);

        var tmpReferrerGrousps = [];
        if (result["error"] == 0 && result["referrerGroups"] != undefined) { tmpReferrerGrousps = result["referrerGroups"]; }

        var referrerGroupList = [];
        referrerGroupList.push({ "value": 0, "label": "Select" });
        if (tmpReferrerGrousps !== undefined && Array.isArray(tmpReferrerGrousps)) {
            tmpReferrerGrousps.map(singleReferrerGroup => { referrerGroupList.push({ "value": singleReferrerGroup["id"], "label": singleReferrerGroup["tradingName"] }) })
            setReferrerGroups(referrerGroupList)
        }
    }

    /***  REFERRERS LIST   ***/
    async function fetchReferresList(fieldName, referrerGroupId, setFieldValue) {
        if (referrerGroupId === 0) {
            var referrersList = [];
            referrersList.push({ "value": 0, "label": "Select" });
            setReferrers(referrersList);
        }
        else {
            //referrersSelectInputRef.current.select.clearValue();
            const referrers = await getReferrersListAgainstReferrerGroup(token, referrerGroupId);
            var referrersList = [];
            referrersList.push({ "value": 0, "label": "Select" });
            if (referrers !== undefined && Array.isArray(referrers)) {
                referrers.map(singleReferrer => { referrersList.push({ "value": singleReferrer["id"], "label": singleReferrer["tradingName"] }) })
                setReferrers(referrersList)
            }
        }
    }

    async function fetchReferresEmployeesList(fieldName, referrerId, setFieldValue) {

        if (referrerId === 0) {
            var referrersUsersList = [];
            referrersUsersList.push({ "value": 0, "label": "Select" });
            setReferrerUsers(referrersUsersList);
        }
        else {
            const response = await getUsers(token, "referrer", referrerId);
            var referrersUsers = response["error"] == 0 && response["data"] !== undefined && Array.isArray(response["data"]) ? response["data"] : [];

            var referrersUsersList = [];
            referrersUsersList = referrersUsers.map(user => { return { "value": user["id"], "label": user["firstName"] } })

            referrersUsersList.unshift({ "value": 0, "label": "Select" });
            setReferrerUsers(referrersUsersList);
        }
        
        //SET SELECTED REFERRER ID TO LOCAL STORAGE
        localStorage.setItem("selectedReferrerId" ,  referrerId);

    }

    //LOAD SINGLE LENDER AND LOAD THE LENDERS FESS AND OTHER CHARGES INTO QUOTE UPON LENDER CHANGE
    const fetchSingleLender = async (id, type, setFieldValue) => {
        if (id != 0) {
            var response = await getLender(id, token);
            if (response["feeStructure"] !== undefined) {
                var feeStructure = response["feeStructure"];
                var commercialFee = feeStructure["commercialFee"] !== undefined ? feeStructure["commercialFee"] : 0;
                var consumerFee = feeStructure["consumerFee"] !== undefined ? feeStructure["consumerFee"] : 0;
                var creditSearchFee = feeStructure["creditSearchFee"] !== undefined ? feeStructure["creditSearchFee"] : 0;
                var monthlyFee = feeStructure["monthlyFee"] !== undefined ? feeStructure["monthlyFee"] : 0;

                //CHECK IF INITIAL VALUES LENDER AND THIS LENDER IS SAME THEN DO NOT LOAD THE VALUES
                setFieldValue("financial.lenderFee", type == "commercial" ? commercialFee : consumerFee);
            }
            else { setFieldValue("financial.lenderFee", 0); }
        }
    }

    async function fetchLendersList() {
        const response = await getLendersList(token);

        if (response["error"] === 0 && response["lenders"]) {
            var lenders = response["lenders"];
            var lendersList = [];
            lendersList.push({ "value": 0, "label": 'Select Lender' }); //FIRST OPTION SO USER CAN DESELECT THE LEDER IF REQUIRED(INSURANC ONLY CASE)

            if (lenders !== undefined && Array.isArray(lenders)) {
                lenders.map(singleLender => { lendersList.push({ "value": singleLender["id"], "label": singleLender["name"] }) })
                setLenders(lendersList)
            }
        }
        else {
            //ERROR OCCURED
        }

    }

    const fetchAllInsuranceCompanies = async () => {
        var response = await getInsuranceCompaniesList(token);
        if (response["error"] != undefined && response["error"] == 0 && response["httpCode"] !== undefined && response["httpCode"] == 200) {
            setInsuranceCompanies(response["data"] || []);
        }
    }
 
    useEffect(() => {
        fetchReferrerGroupsList();
        fetchLendersList();
        fetchAllInsuranceCompanies();

        //LOAD REFERRER AND REFERRER USERS
        if (initialValues.referrerGroupId !== undefined) { fetchReferresList("", initialValues.referrerGroupId, ""); }
        if (initialValues.referrerId !== undefined) { fetchReferresEmployeesList("", initialValues.referrerId, ""); }


        //LOAD LOAN PURPOSES
        var loanPurposeOptions = [];
        Object.keys(Constants.LOAN_PURPOSES).map(function (key, index) {
            loanPurposeOptions.push({ value: key, label: Constants.LOAN_PURPOSES[key] })
        })
        setLoanPurposes(loanPurposeOptions);

        //LOAD LOAN TYPES
        var loanTypeoptions = [];
        Constants.LOAN_TYPE.map(item => {
            loanTypeoptions.push({ value: item, label: capitalizeFirst(item) });
        })
        setLoanTypes(loanTypeoptions);

        //APPLICATION CATEGORIES 
        var applicationCategoriesOptions = [];
        Object.keys(Constants.APPLICATION_CATEGORY).map(itemIndex => {
            applicationCategoriesOptions.push({ value: itemIndex, label: Constants.APPLICATION_CATEGORY[itemIndex] });
        })
        setApplicationCategories(applicationCategoriesOptions);


        setLoading(false);
    }, []);



    //CALL LENDER TO LOAD DEFAULT FEE BASED ON THE SELECTED CRITERIA - IT LETS SAVE/ASSIGN CUSTOM VALUE TO FEES + CHARGES
    const lenderFeesHandler = (lenderId, loanType, callFrom, setFieldValue) => {
        var refValues = formRef.current.values;
        if(refValues !== undefined && refValues !== null){
            console.log("refvalues:::::::: ", refValues);
            var tmpLenderId = refValues["lenderId"];
            var tmpLoanType = refValues["type"];
    
            switch(callFrom){
                case "lenderUpdated":
                    fetchSingleLender(lenderId, tmpLoanType, setFieldValue)
                    break;
                case "loanTypeUpdated" :
                    fetchSingleLender(tmpLenderId, loanType, setFieldValue)
                    break;    
            }
    
        } 
    }

    //LENDER OBSERVER - IT DOES NOT LET SAVE CUSTOM VALUE 
    const LenderObserver = (props) => {
        const {
            values: { lenderId, loan: { type } },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            fetchSingleLender(lenderId, type, setFieldValue)
        }, [lenderId, type, touched.lenderId, touched.type, setFieldValue, props.name]);
        return ("");
    }

    /***** INCLUDING INSURNCE AMOUNT EXLUDING INSURANCE AMOUNT *****/
    const TotalFinancedAmountExcludingInsurances = (props) => {

        const {
            values: { financial: {
                assetPrice, cashDeposit, lenderFee, originationFee, brokerageFee, lenderRiskFee, lenderEqualisationFee, lenderPrivateSaleAdminFee,
                tradeIn: { amount, isTradingIn, finance: { financed, financier: { amountOutstanding } } }
            } },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            var totalAmount = 0;
            totalAmount = assetPrice !== undefined && (!isNaN(parseFloat(assetPrice))) ? parseFloat(assetPrice) : 0;
            totalAmount -= cashDeposit !== undefined && (!isNaN(parseFloat(cashDeposit))) ? parseFloat(cashDeposit) : 0;
            totalAmount += lenderFee !== undefined && (!isNaN(parseFloat(lenderFee))) ? parseFloat(lenderFee) : 0;
            totalAmount += originationFee !== undefined && (!isNaN(parseFloat(originationFee))) ? parseFloat(originationFee) : 0;
            totalAmount += brokerageFee !== undefined && (!isNaN(parseFloat(brokerageFee))) ? parseFloat(brokerageFee) : 0;
            totalAmount += lenderRiskFee !== undefined && (!isNaN(parseFloat(lenderRiskFee))) ? parseFloat(lenderRiskFee) : 0;
            totalAmount += lenderEqualisationFee !== undefined && (!isNaN(parseFloat(lenderEqualisationFee))) ? parseFloat(lenderEqualisationFee) : 0;
            totalAmount += lenderPrivateSaleAdminFee !== undefined && (!isNaN(parseFloat(lenderPrivateSaleAdminFee))) ? parseFloat(lenderPrivateSaleAdminFee) : 0;
            totalAmount -= amount !== undefined && (!isNaN(parseFloat(amount))) && isTradingIn == true ? parseFloat(amount) : 0;
            totalAmount += amountOutstanding !== undefined && (!isNaN(parseFloat(amountOutstanding))) && isTradingIn == true && financed == true ? parseFloat(amountOutstanding) : 0;

            setFieldValue(props.name, totalAmount.toFixed(2));

        }, [assetPrice, cashDeposit, lenderFee, originationFee, brokerageFee, lenderRiskFee, lenderEqualisationFee, lenderPrivateSaleAdminFee, amount, isTradingIn, financed, amountOutstanding,
            touched.assetPrice, touched.cashDeposit, touched.lenderFee, touched.originationFee, touched.brokerageFee, touched.lenderRiskFee, touched.lenderEqualisationFee, touched.lenderPrivateSaleAdminFee, touched.amount, touched.isTradingIn,
            touched.financed, touched.amountOutstanding, setFieldValue, props.name]);
        return (
            <>
                <InputGroup
                    fieldName="f2"
                    symbol="dollar"
                    symbolPosition="left"
                    placeholder="0.00"
                    fieldValue={field.value}
                    setFieldValue={setFieldValue}
                />
                {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
            </>
        );
    };

    /***** INCLUDING INSURNCE AMOUNT INCLUDING INSURANCE AMOUNT *****/
    const TotalFinancedAmountIncludingInsurances = (props) => {
        const {
            values: {
                financial: {
                    assetPrice, cashDeposit, lenderFee, originationFee, brokerageFee, lenderRiskFee, lenderEqualisationFee, lenderPrivateSaleAdminFee,
                    tradeIn: { amount, isTradingIn, finance: { financed, financier: { amountOutstanding } } }
                },
                insurances: [
                    { grossPremium: comprehensiveGrossPremium, active: comprehensiveActive,  extraDetail: { isFinanced: cmoprehensiveIsFinanced}   },
                    { grossPremium: cciGrossPremium, active: cciActive,  extraDetail: { isFinanced: cciIsFinanced} },
                    { grossPremium: gapGrossPremium, active: gapActive , extraDetail: { isFinanced: gapIsFinanced} },
                    { grossPremium: warrentyGrossPremium, active: warrentyActive,  extraDetail: { isFinanced: warrentyIsFinanced} },
                    { grossPremium: ltiGrossPremium, active: ltiActive,  extraDetail: { isFinanced: ltiIsFinanced} },
                    { grossPremium: rtiGrossPremium, active: rtiActive,  extraDetail: { isFinanced: rtiIsFinanced} }
                ]
            },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);

        useEffect(() => {
            var totalAmount = 0;
            totalAmount = assetPrice !== undefined && (!isNaN(parseFloat(assetPrice))) ? parseFloat(assetPrice) : 0;
            totalAmount -= cashDeposit !== undefined && (!isNaN(parseFloat(cashDeposit))) ? parseFloat(cashDeposit) : 0;
            totalAmount += lenderFee !== undefined && (!isNaN(parseFloat(lenderFee))) ? parseFloat(lenderFee) : 0;
            totalAmount += originationFee !== undefined && (!isNaN(parseFloat(originationFee))) ? parseFloat(originationFee) : 0;
            totalAmount += brokerageFee !== undefined && (!isNaN(parseFloat(brokerageFee))) ? parseFloat(brokerageFee) : 0;
            totalAmount += lenderRiskFee !== undefined && (!isNaN(parseFloat(lenderRiskFee))) ? parseFloat(lenderRiskFee) : 0;
            totalAmount += lenderEqualisationFee !== undefined && (!isNaN(parseFloat(lenderEqualisationFee))) ? parseFloat(lenderEqualisationFee) : 0;
            totalAmount += lenderPrivateSaleAdminFee !== undefined && (!isNaN(parseFloat(lenderPrivateSaleAdminFee))) ? parseFloat(lenderPrivateSaleAdminFee) : 0;
            totalAmount -= amount !== undefined && (!isNaN(parseFloat(amount))) && isTradingIn == true ? parseFloat(amount) : 0;
            totalAmount += amountOutstanding !== undefined && (!isNaN(parseFloat(amountOutstanding))) && isTradingIn == true && financed == true ? parseFloat(amountOutstanding) : 0;

            //INSURANCES AMOUNT
            let totalInsurance = 0;
            totalInsurance = comprehensiveActive  && cmoprehensiveIsFinanced && !isNaN(parseFloat(comprehensiveGrossPremium)) ? parseFloat(comprehensiveGrossPremium) : 0;
            totalInsurance += cciActive && cciIsFinanced && !isNaN(parseFloat(cciGrossPremium)) ? parseFloat(cciGrossPremium) : 0;
            totalInsurance += gapActive && gapIsFinanced && gapGrossPremium && !isNaN(parseFloat(gapGrossPremium)) ? parseFloat(gapGrossPremium) : 0;
            totalInsurance += warrentyActive && warrentyIsFinanced && !isNaN(parseFloat(warrentyGrossPremium)) ? parseFloat(warrentyGrossPremium) : 0;
            totalInsurance += ltiActive  && ltiIsFinanced && !isNaN(parseFloat(ltiGrossPremium)) ? parseFloat(ltiGrossPremium) : 0;
            totalInsurance += rtiActive  && rtiIsFinanced && !isNaN(parseFloat(rtiGrossPremium)) ? parseFloat(rtiGrossPremium)   : 0;
 
            setFieldValue(props.name, (totalAmount + parseFloat(totalInsurance)).toFixed(2));


        }, [assetPrice, cashDeposit, lenderFee, originationFee, brokerageFee, lenderRiskFee, lenderEqualisationFee, lenderPrivateSaleAdminFee, amount, isTradingIn, financed, amountOutstanding,
            touched.assetPrice, touched.cashDeposit, touched.lenderFee, touched.originationFee, touched.brokerageFee, touched.lenderRiskFee, touched.lenderEqualisationFee, touched.lenderPrivateSaleAdminFee, touched.amount, touched.isTradingIn,
            touched.financed, touched.amountOutstanding,
            comprehensiveActive, touched.comprehensiveActive, comprehensiveGrossPremium, touched.comprehensiveGrossPremium, cmoprehensiveIsFinanced,  touched.cmoprehensiveIsFinanced,
            cciActive, touched.cciActive, cciGrossPremium, touched.cciGrossPremium, cciIsFinanced, touched.cciIsFinanced, 
            gapActive, touched.gapActive, gapGrossPremium, touched.gapGrossPremium, gapIsFinanced, touched.gapIsFinanced, 
            warrentyActive, touched.warrentyActive, warrentyGrossPremium, touched.warrentyGrossPremium, warrentyIsFinanced, touched.warrentyIsFinanced,
            ltiActive, touched.ltiActive, ltiGrossPremium, touched.ltiGrossPremium, ltiIsFinanced, touched.ltiIsFinanced,
            rtiActive, touched.rtiActive, rtiGrossPremium, touched.rtiGrossPremium, rtiIsFinanced, touched.rtiIsFinanced,
            setFieldValue, props.name]);
        return (
            <>
                <InputGroup
                    fieldName="f2"
                    symbol="dollar"
                    symbolPosition="left"
                    placeholder="0.00"
                    fieldValue={field.value}
                    setFieldValue={setFieldValue}
                />
                {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
            </>
        );
    }

    //REPAYMENT CALCULATION 
    const RepaymentObserve = (props) => {
        const {
            values: { loan: { totalFinancedAmountIncludingInsurances, totalFinancedAmountExcludingInsurances, loanTerm, interestRate, balloon: { balloonAmount } }, repayments: { repaymentType } },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);
        useEffect(() => {
            calculateRepayments(setFieldValue);

        }, [totalFinancedAmountIncludingInsurances, totalFinancedAmountExcludingInsurances, loanTerm, interestRate, balloonAmount, repaymentType,
            touched.totalFinancedAmountIncludingInsurances, touched.totalFinancedAmountExcludingInsurances, touched.loanTerm, touched.interestRate, touched.balloonAmount, touched.repaymentType, setFieldValue, props.name]);
        return ("");

    }
    const calculateRepayments = async (setFieldValue) => {
        var currentValues = formRef !== undefined && formRef.current !== undefined && formRef.current !== null && formRef.current.values !== undefined && formRef.current.values !== null ? formRef.current.values : {}
        let loan = currentValues !== undefined && currentValues.loan !== undefined ? currentValues.loan : {};

        let totalFinancedAmountExcludingInsurances = currentValues.loan !== undefined && loan.totalFinancedAmountExcludingInsurances !== undefined ? loan.totalFinancedAmountExcludingInsurances : 0;
        let totalFinancedAmountIncludingInsurances = loan !== undefined && loan.totalFinancedAmountIncludingInsurances !== undefined ? loan.totalFinancedAmountIncludingInsurances : 0;
        let interestRate = loan !== undefined && loan.interestRate !== undefined ? loan.interestRate : 0;
        interestRate = interestRate / 100;
        interestRate = interestRate / 12;

        let loanTerm = loan !== undefined && loan.loanTerm !== undefined ? loan.loanTerm : 0;
        let balloonAmount = loan !== undefined && loan.balloon !== undefined && loan.balloon.balloonAmount !== undefined ? loan.balloon.balloonAmount : 0;
        let repaymentType = currentValues !== undefined && currentValues.repayments !== undefined && currentValues.repayments.repaymentType ? currentValues.repayments.repaymentType : "";

        //CALCULATE PAYMENT - WITH INSURANCE
        let monthlyRepaymentIncludingInsurance = await PMT(interestRate, loanTerm, -totalFinancedAmountIncludingInsurances, balloonAmount, repaymentType);
        setFieldValue("repayments.includingInsurance.weekly", ((monthlyRepaymentIncludingInsurance * 12) / 52).toFixed(2))
        setFieldValue("repayments.includingInsurance.fortnightly", ((monthlyRepaymentIncludingInsurance * 12) / 26).toFixed(2))
        setFieldValue("repayments.includingInsurance.monthly", (monthlyRepaymentIncludingInsurance).toFixed(2))


        //CALCULATE PAYMENT WITHOUT INSURANCES
        let monthlyRepaymentExcludingInsurance = await PMT(interestRate, loanTerm, -totalFinancedAmountExcludingInsurances, balloonAmount, repaymentType);
        setFieldValue("repayments.excludingInsurance.weekly", ((monthlyRepaymentExcludingInsurance * 12) / 52).toFixed(2))
        setFieldValue("repayments.excludingInsurance.fortnightly", ((monthlyRepaymentExcludingInsurance * 12) / 26).toFixed(2))
        setFieldValue("repayments.excludingInsurance.monthly", (monthlyRepaymentExcludingInsurance).toFixed(2))


    }
    const PMT = async (interestRate, loanTerm, present_value, balloonAmount, repaymentType) => {
        try {
            // console.log("balloonAmount form PMT ::::::::::::::::::::::  ", balloonAmount)
            var when = repaymentType == "arrears" ? 0 : 1;  // 
            if (interestRate == 0) { return - (parseFloat(balloonAmount) + parseFloat(present_value)) / loanTerm; }
            else { return - (parseFloat(balloonAmount) + parseFloat((present_value * Math.pow((1 + parseFloat(interestRate)), loanTerm)))) / ((1 + parseFloat(interestRate * when)) / interestRate * (Math.pow((1 + parseFloat(interestRate)), loanTerm) - 1)); }
        }
        catch (e) { return 0; }
    }

    //UTILITIES HANDLER - DRAW CLOSE/OPEN , UPDATE VALUE GOT FROM CHILD COMPONENTS LIKE SEARCH CAR INFO
    const utilitiesHandler = (action, payload) => {
        switch (action) {
            case "openSearchForCarInfoWindow" : 
            setDrawerSettings({ visible: true , heading: "Search Car Info " , childName: "searchForCarInfo", meta: {} });
            break;
            case "carInfoReceived":
                initialValues.buyingItem.motorVehicles[0] = payload;
                var formrefValues = formRef !== null && formRef !== undefined && formRef.current !== null && formRef.current !== undefined && formRef.current.values !== null && formRef.current.values !== undefined ? formRef.current.values: {};
                utilitiesHandler("closeDrawer" , null);
                break;
            //LIST OF ACTIONS COMES UNDER COMPLIANCES
            case "closeDrawer": setDrawerSettings({ visible: false });
                break;
        }
    }

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="quoteBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap" onClick={handleChange}>
                        <h3 className="pb-0 mb-0">Quote</h3>
                        <DownArrowIcon />
                    </div> 
                    { entityType === "company" ? <><div className="col-md-3  mt-5"><label className="required fs-8 fw-bold mb-2">CreditRep</label><CreditRepsDropdown /></div></> : ""}                         
                                        
                    <div className="hide" style={{ display: isOpen }} >
                        <div className="separator separator-dashed my-5"></div>
                         <Formik
                            initialValues={initialValues}
                            innerRef={formRef}
                            enableReinitialize={true}
                            validateOnBlur={false}
                            onSubmit={values => {
                                var tempValues = JSON.parse(JSON.stringify(values));
                                //CEHCK IF REFERRER GROUP IS SELECTED OR NOT - IF RG NOT SELECTED THEN WHOW ERROR
                                let tempReferrerGroup  = tempValues["referrerGroupId"];
                                if(!isNaN(parseInt(tempReferrerGroup)) && parseInt(tempReferrerGroup) !== 0){
                                    var tempInsurances = tempValues["insurances"] !== undefined && Array.isArray(tempValues["insurances"]) ? tempValues["insurances"] : [];
                                    var activeInsurances = tempInsurances.filter(insurance => insurance.active == true && insurance.insuranceCompanyId != "")
                                    
                                    if (Array.isArray(activeInsurances)) {
                                        var modifiedInsurances = activeInsurances.map((singleInsurance) => {
                                            singleInsurance["expiryDate"] = singleInsurance["expiryDate"].includes("/") ? formatDate(singleInsurance["expiryDate"], 'DD/MM/YYYY', 'YYYY-MM-DD') : singleInsurance["expiryDate"];
                                            return singleInsurance;
                                        })
                                        console.log("modifiedInsurances ::: ", modifiedInsurances);
                                        tempValues["insurances"] = modifiedInsurances;
                                    }
                                    //ADD CREDITREPID TO PAYLAOD SO IF ITS COMPANY IT CAN BE USED BY BACKEND TO CREATEA NA APPLICAITON - FOR UPDATE IT IS NOT REQUIRED
                                    tempValues["creditRepId"] = selectedCreditRepId;
                                    executeApplicationSubmissionHandler("quote", tempValues);

                                }
                                else{
                                    Notifications("error", "Please select Referrer group.")
                                }
                            }}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form>
                                    <div className="row mb-5">
                                        <div className="col-md-7 fv-row fv-plugins-icon-container">
                                            <div className="row mb-5">
                                                 
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Referrer Group {selectedCreditRepId}</label> 
                                                    <Field name="referrerGroupId">
                                                        {({ field }) => (
                                                            <SelectField
                                                                fieldName="referrerGroupId"
                                                                defaultSelectedOption={{ value: field.value, label: "" }}
                                                                options={referrerGroups}
                                                                setFieldValue={ (name, value) => {setFieldValue("referrerGroupId", value); setFieldValue("referrerId", 0); }}
                                                                callback={fetchReferresList}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>

                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Referrer</label>
                                                    <Field name="referrerId">
                                                        {({ field }) => (
                                                            <SelectField
                                                                fieldName="referrerId"
                                                                defaultSelectedOption={{ value: field.value, label: '' }}
                                                                options={referrers}
                                                                setFieldValue={setFieldValue}
                                                                callback={fetchReferresEmployeesList}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>

                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Staff</label>
                                                    <Field name="referrerStaffId">
                                                        {({ field }) => (
                                                            <SelectField
                                                                fieldName="referrerStaffId"
                                                                defaultSelectedOption={{ value: field.value, label: '' }}
                                                                options={referrerUsers}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Purpose</label>
                                                    <Field name="loan.purpose">
                                                        {({ field }) => (
                                                            <SelectField
                                                                fieldName="loan.purpose"
                                                                defaultSelectedOption={{ value: field.value, label: '' }}
                                                                options={loanPurposes}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>

                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Loan Type</label>
                                                    <Field name="loan.type">
                                                        {({ field }) => (
                                                            <SelectField
                                                                fieldName="loan.type"
                                                                defaultSelectedOption={{ value: field.value, label: '' }}
                                                                options={loanTypes}
                                                                setFieldValue={setFieldValue}
                                                                callback = {
                                                                    (name, value, setFieldValue) => { 
                                                                        lenderFeesHandler(null, value,"loanTypeUpdated", setFieldValue);
                                                                    }
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Loan For</label>
                                                    <Field name="category">
                                                        {({ field }) => (
                                                            <SelectField
                                                                fieldName="category"
                                                                defaultSelectedOption={{ value: field.value, label: '' }}
                                                                options={applicationCategories}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Total Asset Price</label>
                                                    <Field name="financial.assetPrice" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="financial.assetPrice"
                                                                symbol="dollar"
                                                                symbolPosition="left"
                                                                placeholder="0.00"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Cash Deposit</label>
                                                    <Field name="financial.cashDeposit" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="financial.cashDeposit"
                                                                symbol="dollar"
                                                                symbolPosition="left"
                                                                placeholder="0.00"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>

                                            <TradeIn setFieldValue={setFieldValue} values={values} />

                                            <div className="row mb-5">
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Lender</label>
                                                    <Field name="lenderId">
                                                        {({ field }) => (
                                                            <SelectField
                                                                fieldName="lenderId"
                                                                defaultSelectedOption={{ value: field.value, label: '' }}
                                                                options={lenders}
                                                                setFieldValue={setFieldValue}
                                                                callback = {
                                                                    (name, value, setFieldValue) => {
                                                                        lenderFeesHandler(value, null,"lenderUpdated", setFieldValue);
                                                                    }
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                    { /*<LenderObserver name="lenderValueObserver" /> */}
                                                </div>

                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Lender Fees + Charges</label>
                                                    <Field name="financial.lenderFee" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="financial.lenderFee"
                                                                symbol="dollar"
                                                                symbolPosition="left"
                                                                placeholder="0.00"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Origination Fee</label>
                                                    <Field name="financial.originationFee" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="financial.originationFee"
                                                                symbol="dollar"
                                                                symbolPosition="left"
                                                                placeholder="0.00"
                                                                listName="originationFeeList"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                    <datalist id="originationFeeList">
                                                        <option key="origination0" value="770" />
                                                        <option key="origination1" value="990" />
                                                    </datalist>
                                                </div>
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Brokerage Fee</label>
                                                    <Field name="financial.brokerageFee" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="financial.brokerageFee"
                                                                symbol="dollar"
                                                                symbolPosition="left"
                                                                placeholder="0.00"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>

                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Lender Risk Fee</label>
                                                    <Field name="financial.lenderRiskFee" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="financial.lenderRiskFee"
                                                                symbol="dollar"
                                                                symbolPosition="left"
                                                                placeholder="0.00"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Lender Equalisation Fee</label>
                                                    <Field name="financial.lenderEqualisationFee" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="financial.lenderEqualisationFee"
                                                                symbol="dollar"
                                                                symbolPosition="left"
                                                                placeholder="0.00"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Lender Private Sale Admin Fee</label>
                                                    <Field name="financial.lenderPrivateSaleAdminFee" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="financial.lenderPrivateSaleAdminFee"
                                                                symbol="dollar"
                                                                symbolPosition="left"
                                                                placeholder="0.00"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-1 fv-row fv-plugins-icon-container"></div>


                                        <div className="col-md-4 fv-row fv-plugins-icon-container">
                                            <div className="row mb-5">
                                                <div className="col-md-10 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Total Financed Amount (excl Insurances)</label>
                                                    <TotalFinancedAmountExcludingInsurances name="loan.totalFinancedAmountExcludingInsurances" />
                                                </div>
                                                <div className="col-md-10 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Total Financed Amount (incl Insurances)</label>
                                                    <TotalFinancedAmountIncludingInsurances name="loan.totalFinancedAmountIncludingInsurances" />
                                                </div>
                                                <div className="col-md-5 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Interest Rate</label>
                                                    <Field name="loan.interestRate" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="loan.interestRate"
                                                                symbolType="custom"
                                                                symbol="%"
                                                                symbolPosition="right"
                                                                placeholder="0.00"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="col-md-5 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Loan Term</label>
                                                    <Field name="loan.loanTerm" >
                                                        {({ field }) => (
                                                            <InputGroup
                                                                fieldName="loan.loanTerm"
                                                                symbolType="custom"
                                                                symbol="Months"
                                                                symbolPosition="right"
                                                                placeholder="0"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="col-md-10 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Customer Weekly Budget</label>
                                                    <Field name="loan.customerWeeklyBudget" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="loan.customerWeeklyBudget"
                                                                symbolType="custom"
                                                                symbol="$"
                                                                symbolPosition="left"
                                                                placeholder="0.00"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>


                                                <div className="col-md-10 fv-row fv-plugins-icon-container">
                                                    <div className="row">
                                                        <div className="col-sm-12"> <label className="required fs-8 fw-bold mb-2">Balloon</label></div>
                                                        <div className="col-md-6">
                                                            <Field name="loan.balloon.balloonPercentage" >
                                                                {({ field }) => (
                                                                    <FloatField
                                                                        fieldName="loan.balloon.balloonPercentage"
                                                                        symbolType="custom"
                                                                        symbol="%"
                                                                        symbolPosition="right"
                                                                        placeholder="0"
                                                                        fieldValue={field.value}
                                                                        setFieldValue={(name, value) => {
                                                                            setFieldValue("loan.balloon.balloonPercentage", value);
                                                                            var tmpAssetPrice = values.financial.assetPrice;
                                                                            var tmpBalloonAmount = ((tmpAssetPrice * value) / 100).toFixed(2);
                                                                            setFieldValue("loan.balloon.balloonAmount", tmpBalloonAmount);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Field name="loan.balloon.balloonAmount" >
                                                                {({ field }) => (
                                                                    <FloatField
                                                                        fieldName="loan.balloon.balloonAmount"
                                                                        symbolType="dollar"
                                                                        symbol="%"
                                                                        symbolPosition="right"
                                                                        placeholder="0"
                                                                        fieldValue={field.value}
                                                                        setFieldValue={(name, value) => {
                                                                            setFieldValue("loan.balloon.balloonAmount", value);
                                                                            var tmpAssetPrice = values.financial.assetPrice;
                                                                            var tmpBalloonPercentage = ((value / tmpAssetPrice) * 100).toFixed(2);
                                                                            setFieldValue("loan.balloon.balloonPercentage", tmpBalloonPercentage);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/** REPAYMENT SECTION HERE  */}
                                                <div className="col-md-10 fv-row fv-plugins-icon-container">
                                                    <RepaymentObserve name="repaymentObserver" />
                                                    <Field name="repayments.repaymentType">
                                                        {({ field }) => (
                                                            <RadioGroup
                                                                fieldName="repayments.repaymentType"
                                                                defaultSelectedOption={field.value}
                                                                options={[{ value: "arrears", label: "Arrears" }, { value: "advance", label: "Advance " }]}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                    { /** PRINT REPAYMENT AMOUNT HERE */}
                                                    <div style={{ display: "flex" }}>
                                                        <table className="table column-bordered-table" style={{ marginTop: "10px" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Frequency</th>
                                                                    <th>With Insurnace</th>
                                                                    <th>No Insurnace</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <table>
                                                                            <tbody>
                                                                                <tr><td>Weekly</td></tr>
                                                                                <tr><td>Fortnightly</td></tr>
                                                                                <tr><td>Monthly</td></tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                    <td>
                                                                        <table>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <Field name="repayments.includingInsurance.weekly">
                                                                                            {({ field }) => ("$ " + field.value)}
                                                                                        </Field>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <Field name="repayments.includingInsurance.fortnightly">
                                                                                            {({ field }) => ("$ " + field.value)}
                                                                                        </Field>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <Field name="repayments.includingInsurance.monthly">
                                                                                            {({ field }) => ("$ " + field.value)}
                                                                                        </Field>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>

                                                                    <td>
                                                                        <table>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <Field name="repayments.excludingInsurance.weekly">
                                                                                            {({ field }) => ("$ " + field.value)}
                                                                                        </Field>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <Field name="repayments.excludingInsurance.fortnightly">
                                                                                            {({ field }) => ("$ " + field.value)}
                                                                                        </Field>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <Field name="repayments.excludingInsurance.monthly">
                                                                                            {({ field }) => ("$ " + field.value)}
                                                                                        </Field>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            <BuyingMotorVehicleItem errors={errors} touched={touched} setFieldValue={setFieldValue} lookupCarInfo = { () => { utilitiesHandler("openSearchForCarInfoWindow", {}) }} />
                                        </div>

                                        {/** insurance section start */}
                                        <div className="col-sm-12">
                                            <div className='row' style={{ marginBottom: "20px" }}>
                                                <div className='col-sm-12'>
                                                    <div style={{ display: "flex", justifyContent: "start" }}>
                                                        <Field name="insurances[0].active" >
                                                            {({ field }) => (
                                                                <CheckBox name="insurances[0].active" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                            )}
                                                        </Field>
                                                        <span style={{ marginLeft: "3px", marginRight: "20px" }}> Comprehensive </span>

                                                        <Field name="insurances[1].active" >
                                                            {({ field }) => (
                                                                <CheckBox name="insurances[1].active" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                            )}
                                                        </Field>
                                                        <span style={{ marginLeft: "3px", marginRight: "20px" }}> CCI </span>

                                                        <Field name="insurances[2].active" >
                                                            {({ field }) => (
                                                                <CheckBox name="insurances[2].active" defaultChecked={field.value !== undefined ? field.value : false} setFieldValue={setFieldValue} />
                                                            )}
                                                        </Field>
                                                        <span style={{ marginLeft: "3px", marginRight: "20px" }}> GAP </span>

                                                        <Field name="insurances[3].active" >
                                                            {({ field }) => (
                                                                <CheckBox name="insurances[3].active" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                            )}
                                                        </Field>
                                                        <span style={{ marginLeft: "3px", marginRight: "20px" }}> Warranty</span>

                                                        <Field name="insurances[4].active" >
                                                            {({ field }) => (
                                                                <CheckBox name="insurances[4].active" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                            )}
                                                        </Field>
                                                        <span style={{ marginLeft: "3px", marginRight: "20px" }}> LTI </span>

                                                        <Field name="insurances[5].active" >
                                                            {({ field }) => (
                                                                <CheckBox name="insurances[5].active" defaultChecked={field.value} setFieldValue={setFieldValue} />
                                                            )}
                                                        </Field>
                                                        <span style={{ marginLeft: "3px", marginRight: "20px" }}> RTI </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                {
                                                    Array.isArray(values.insurances) && Array.isArray(insuranceCompanies) && insuranceCompanies.length > 0 ?
                                                        values.insurances.map((insurance, index) => {
                                                            if (insurance.active) {
                                                                return <SingleInsurance labelPrefix={insuranceLabels[insurance.type]} index={index} type={[insurance.type]} insuranceCompanies={insuranceCompanies} setFieldValue={setFieldValue} key={index} />
                                                            }
                                                        }) :
                                                        ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        { userRole !== "auditor" ? <FormSubmissionButton isActive={true} /> : "" }
                                    </div>

                                    {/** row end **/}
                                </Form>
                            )}
                        </Formik>

                        {  /**   RIGHT WINDOW FOR QUOTE UTILITIES LIKE  (CAR INFO PPSR PPSR PDF ETC ) START  */}
                        {drawerSettings["visible"] ?
                            <RightDrawer heading={drawerSettings["heading"]} callback={utilitiesHandler}>
                                {drawerSettings["childName"] === "searchForCarInfo" ? <SearchForCarInfo payload={initialValues}  callback={utilitiesHandler} /> : ""}
                            </RightDrawer> : ""
                        }
                        {  /**   RIGHT WINDOW FOR QUOTE UTILITIES LIKE  (CAR INFO PPSR PPSR PDF ETC ) END  */}
                    </div>
                </div>
            </div>
        </>
    )

}
export default Quote;