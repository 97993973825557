import { EventSourcePolyfill } from 'event-source-polyfill';
import axios from './axios';



const registerForRealTimeUpdates = (token, onMessage) => {
  var baseURL = process.env.REACT_APP_ENV == "DEVELOPMENT" ? process.env.REACT_APP_DEVELOPMENT_BACKENDENDPOINT : process.env.REACT_APP_BACKENDENDPOINT;
  var endPoint = "sse/registerForLiveUpdates";

  //REGISTER FOR REAL TIME UPDATES FROM THE SERVER
  const eventSource = new EventSourcePolyfill(baseURL + "sse/registerForLiveUpdates/", {
    headers: {
      Authorization: `Bearer ${token}`
    },
    heartbeatTimeout: 1200000,
  });

  eventSource.onopen = () => console.log(">>> Connection opened!");
  eventSource.onerror = (e) => console.log("ERROR!", e);

  eventSource.onmessage = (event) => {
    onMessage(JSON.parse(event.data)); // Pass the received message to the onMessage callback - Assuming the message is JSON
  };

  // Return the event source so it can be closed later
  return eventSource;
};

const isClientConnected = async (token, notificationUid) => {

  try {
    const response = await axios.get(`sse/isClientConnected/${notificationUid}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, data: response.data ? response.data : [], httpCode: response.status }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, httpCode: e.response.status, data: [] }
    }
  }
}


//BELOW CODE IS TO MAKE THIS MANAGER A CENTRAL POINT WHO HAVE LIST OF ALL LISTENERS AND CAN SEND THE RESPONSE TO MULTIPLE LISTENERS
const eventSourceInstance = {
  eventSource: null,
  listeners: [], // Object to store listeners by  uid, listener
};

const createEventSource = async (token, existingNotificationUid = null) => {
  try {
    var baseURL = process.env.REACT_APP_ENV == "DEVELOPMENT" ? process.env.REACT_APP_DEVELOPMENT_BACKENDENDPOINT : process.env.REACT_APP_BACKENDENDPOINT;

    // If an EventSource already exists, return it
    if (eventSourceInstance.eventSource) { return eventSourceInstance.eventSource; }

    console.log("existingNotificationUid", existingNotificationUid);

    //CHECK IF ALIVE CONNECTION EXISTS FOR ANOTHER TAB BY MAKING A CALL TO BACKEND SERVER
    /*if(existingNotificationUid !== null || existingNotificationUid !== undefined){
      const response = await isClientConnected(token, existingNotificationUid);
      if (response?.data?.isConnectionAlive) { throw new Error("CONNECTION ALREADY EXISTS");  }
    }*/

    //REGISTER FOR REAL TIME UPDATES FROM THE SERVER
    eventSourceInstance.eventSource = new EventSourcePolyfill(baseURL + "sse/registerForLiveUpdates/", {
      headers: {
        Authorization: `Bearer ${token}`
      },
      heartbeatTimeout: 1200000,
    });

    eventSourceInstance.eventSource.onopen = () => console.log(">>> Connection opened!");
    eventSourceInstance.eventSource.onerror = (e) => console.log("ERROR!", e);

    //eventSourceInstance.eventSource = new EventSource(url);

    eventSourceInstance.eventSource.onmessage = (event) => {
      const newMessage = JSON.parse(event.data);
      console.log("New message received: line 79  ", newMessage);

      // Call the registered listener for the message type
      if (eventSourceInstance.listeners.length > 0) {
        //SEND THE RESPONSE TO ALL THE LISTENERS
        for (let i = 0; i < eventSourceInstance.listeners.length; i++) { eventSourceInstance.listeners[i].listener(newMessage); }
      }
    };

    return eventSourceInstance.eventSource;
  }
  catch (e) {
    console.log("ERROR IN CREATING EVENT SOURCE::: ", e);
    return { "error": 1, "message": e.message ? e.message : "Connection Exists" }
  }
};

const registerListener = (uid, handler) => {
  eventSourceInstance.listeners.push({ uid: uid, listener: handler });
};

const unregisterListener = (uid) => {
  //SEARCH THE LISTENER AND REMOVE IT
  const index = eventSourceInstance.listeners.findIndex((listener) => listener.uid === uid);
  if (index > -1) {
    eventSourceInstance.listeners.splice(index, 1);
  }
};

const closeEventSource = () => {
  if (eventSourceInstance.eventSource) {
    eventSourceInstance.eventSource.close();
    eventSourceInstance.eventSource = null;
    eventSourceInstance.listeners = {};
  }
};



export {
  registerForRealTimeUpdates,
  createEventSource,
  registerListener,
  unregisterListener,
  closeEventSource,
  isClientConnected
};

