import React from 'react';
import { IconButton } from '../HtmlComponents/';

const LeadListItem = ({ singleLead, callback }) => {
  return (
    <tr key={singleLead.id}>
      <td>{singleLead.firstName}</td>
      <td>{singleLead.surname}</td>
      <td>{singleLead.email}</td>
      <td>{singleLead.contactNumber}</td>
      <td>{singleLead.loanAmount}</td>
      <td>{singleLead.purpose}</td>
      <td>
        <IconButton 
          iconType="view" 
          buttonClassName=" " 
          buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} 
          tootltip={true} 
          tooltipDescription="View" 
          buttonOnClick={() => callback("openViewLead", singleLead)} 
        />
        <IconButton 
          iconType="deleteBin" 
          buttonClassName=" " 
          buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px", color: "#b20f0f" }} 
          tootltip={true} 
          tooltipDescription="Delete" 
          buttonOnClick={() => callback("deleteLead", singleLead)} 
        />
        <IconButton 
          iconType="addPerson" 
          buttonClassName=" " 
          buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "12px" }} 
          tootltip={true} 
          tooltipDescription="Convert to Application" 
          buttonOnClick={() => callback("openConvertToApplication", singleLead)} 
        />
      </td>
    </tr>
  );
};

export default LeadListItem;
