import { useEffect, useState } from "react";
import { useToken } from "../../../../hooks/useAuth";
import { getSettlementRateAgainstReceivedApplications } from '../../../../api';
import LoadingBlock from "../../../global/LoadingBlock";
import {SpeedoMeterWithIcons} from "../graphs/Speedometer";
import {getPastDateTime } from "../../../../helpers"

export const SettlementRateAgainstReceivedApplications = () => {
    const [settlementRate, setSettlementRate] = useState(0);
    const [processing, setProcessing] = useState(false);
    const token = useToken();


    const fetchReport = async() => {
        setProcessing(true);
        const startDate = await getPastDateTime(90);
        const endDate = await getPastDateTime(0);

        var response = await getSettlementRateAgainstReceivedApplications(token, startDate, endDate);

        console.log("response line 55 from settlement rate", response);

        if (response["error"] !== undefined && response["error"] == 0 && response["data"] !== undefined && response["data"]["settlementRate"] !== undefined) {
            setSettlementRate(response["data"]["settlementRate"]);
        }
        setProcessing(false);
    }

    useEffect(() => {
        fetchReport();
    }, [])


    return (        
        <div>
            <SpeedoMeterWithIcons 
            width={200}
            needleHeightRatio={0.4}
            maxValue={100}
            value={settlementRate}
            currentValueText={ settlementRate +  " %" }
            ringWidth={50}
            needleTransitionDuration={3333}
            needleTransition="easeElastic"
            needleColor="black"
            textColor="#d8dee9"
            customSegmentLabels = {[
                {
                    text: '😡',
                    position: 'INSIDE',
                    color: '#555',
                },
                {
                    text: '😕',
                    position: 'INSIDE',
                    color: '#555',
                },
                 
                {
                    text: '😊 ',
                    position: 'INSIDE',
                    color: '#555',
                },
                {
                    text: '😋',
                    position: 'INSIDE',
                    color: '#555',
                },
            ]}
            />
        </div>
    )


}