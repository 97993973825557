import authReducer from "./redux/authSlice";
import themeReducer from "./redux/themeSlice";
import { persistStore,persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import ReduxThunk from 'redux-thunk';
import {combineReducers} from "redux"; 

const reducers = combineReducers({
    auth : authReducer,
    theme : themeReducer         
});

const persistConfig = {
    key : 'root',
    storage,
    debug: true
}

const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [ReduxThunk]
});

const persistor = persistStore(store);

export {store, persistor}; 