import React, { useState, useEffect } from 'react';
import { useToken, useEntityType, useEntityId, useRole } from '../../hooks/useAuth';
import { getReferrerGroupsList , daleteReferrerGroup} from '../../api/referrerGroupApi';
import ReferrerGroupListItem from './ReferrerGroupListItem';
import { CustomButton, InputField, ConfirmDialog } from '../HtmlComponents';
import { Navigate, NavigationType, useNavigate } from 'react-router-dom';
import Notifications from '../global/Notifications';


const ListReferrerGroups = () => {

    const [referrerGroupsList, setReferrerGroupsList] = useState([]);
    const token = useToken();
    const entityId = useEntityId();
    const entityType = useEntityType();
    const role = useRole();

    const status= "active";  //later on pass this as param and get in ListReferrerGroups() to identify if we need to list active or archived referrer groups

    let navigate = useNavigate();

    //FETCH REFERER GROUPS LIST
    const fetchReferrerGroupList = async () => {
        const result = await getReferrerGroupsList(token);
        if (result["error"] == 0 && result["referrerGroups"]) {  setReferrerGroupsList(result["referrerGroups"]);  }
        else {  Notifications("error", result["message"] !== undefined ? result["message"] : "Request failed.")  }
    }

    useEffect(() => {     
        fetchReferrerGroupList();
    }, []);

    const handler = async (name, value) => {
        switch (name) {
            case "archiveReferrerGroup":
                ConfirmDialog({ callback: () => { handler("executeArchiveReferrerGroup", value) }, "purpose": "general", "heading": `Are you sure you want to archive it?`, "description": "" })
                break;
            case "executeArchiveReferrerGroup":
                var response = await daleteReferrerGroup(value, token);
                console.log(response);
                if (response["error"] != undefined && response["error"] == 1) {
                    Notifications("error", response["message"] || "Some error occured.")
                }
                else {
                    Notifications("success", response["message"] || "Deleted Successfully.");
                    fetchReferrerGroupList();
                }

                    console.log("value-----------", value)
                break;
        }
    }


    const CreateReferrerGroupButtonHTML = () => {
        if ( ["company",  "creditRep"].includes(entityType) && ["superAdmin",  "admin"].includes(role)) {
            return (
                <>
                <div className='row'>
                    <div className='cols-m-12'>
                    <CustomButton buttonClassName="btn-primary btn-sm" isActive={true} buttonStyle={{ float: "right", marginBottom: "10px" }} buttonOnClick={() => { navigate("/user/CreateReferrerGroup") }} buttonText="Create" />
                    </div>
                </div>
                </>
            )
        }
        else {
            return "";
        }
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                <CreateReferrerGroupButtonHTML />

                <div className="card" data-select2-id="select2-data-132-dpd0">
                    <div className="card-body pt-0">
                        <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ }}>Trading Name</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ }} >Entity Name</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{  }}>Structure</th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ }}>Email</th>
                                            {status == "active" ? <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">Actions</th> : ""}
                                        </tr>
                                    </thead>
                                    <tbody className="fw-bold">
                                        {
                                            referrerGroupsList && Array.isArray(referrerGroupsList) ?
                                                referrerGroupsList.map(singleReferrerGroup => (
                                                    <ReferrerGroupListItem key={singleReferrerGroup.id} item={singleReferrerGroup} callback={handler} status= {status} />
                                                )) : <tr></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListReferrerGroups;