import { useEffect, useState } from "react";
import { useToken } from "../../../../../hooks/useAuth";
import { loadReporting } from '../../../../../api';
import ReportingConstants from '../../ReportingConstants';

import Chart from 'react-apexcharts';

const DrawPieChart = ({ searchFilters }) => {

  const [pieCharttData, setPieChartData] = useState({
    options: { chart: { type: 'pie', }, labels: [], },
    series: [] /*Data for the pie chart*/
  }); //PIE CHART DATA

  const [pieChartDataComparePeriod, setPieChartDataComparePeriod] = useState({
    options: { chart: { type: 'pie', }, labels: [], },
    series: [] /*Data for the pie chart*/
  }); //PIE CHART DATA

  const [notes, setNotes] = useState(""); // USE THIS TO PROVIDE ADDITIONAL NOTES IF REQUIRED
  const token = useToken();

  //GENERATE A NEW REPORT WHEN SEARCH FILTERS ARE UPDATED
  useEffect(() => {
    generateReport();
    // generateReportForSecondGraph();
    // __updateAdditionalInfoForYAxis();
  }, [searchFilters])


  //GENERATE REPORT FOR FIRST PERIOD - FIRST GRAPH
  const generateReport = async () => {
    var periodRresponse = await loadReporting(token, searchFilters);
    console.log(periodRresponse);
    let periodData = periodRresponse.data.report.periodData;
    let newResponse = await doNecessaryCalculationBeofreDrawingGraph(periodData, searchFilters);
    console.log(" newReportData1 line 112 :: ", newResponse.data);
    setPieChartData(prevState => ({ ...prevState, series: newResponse.data.values, options: { labels: newResponse.data.labels } })); //SET THE FORMATED DATA TO STATE

    //CHECK IF DATA AVAILABLE FOR COMPARE PERIOD
    if (periodRresponse.data.report.comparePeriodData !== undefined && periodRresponse.data.report.comparePeriodData !== null && periodRresponse.data.report.comparePeriodData.length > 0) {
      let comparePeriodData = periodRresponse.data.report.comparePeriodData;
      let comparePeriodResponse = await doNecessaryCalculationBeofreDrawingGraph(comparePeriodData, searchFilters);
      setPieChartDataComparePeriod(prevState => ({ ...prevState, series: comparePeriodResponse.data.values, options: { labels: comparePeriodResponse.data.labels } })); //SET THE FORMATED DATA TO STATE
    }

  }

  //DO NECCESSARY CALCULATION BEFORE DRAWING GRAPH
  const doNecessaryCalculationBeofreDrawingGraph = async (reportData, searchFilters) => {

    return new Promise((resolve, reject) => {
      var newReportData = { labels: [], values: [] };;

      switch (true) {
        case ["statsForAllIndividualReasonsForDeclinedPercentage", "individualLoanPurpose"].includes(searchFilters.stat):
          //IT RETURNS RESULT FOR ALL AVAILE REASONS FOR DECLINED APPLICATIONS SO FILTER THEM FOR THE PROVIDED REASON IF ANY
          let providedsubStatsArray = searchFilters.statExtraInfo.subStat;
          console.log("providedsubStatsArray :: ",  Array.isArray(providedsubStatsArray) && providedsubStatsArray.length > 0  ? "YESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS" : "NOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO");
          let isProvidedsubStatsArray = Array.isArray(providedsubStatsArray) && providedsubStatsArray.length > 0 ? true : false;

          let allAvailableOPtions = [];
          switch(searchFilters.stat){
            case "statsForAllIndividualReasonsForDeclinedPercentage":
              allAvailableOPtions = ReportingConstants.STAT_OPTIONS.statsForAllIndividualReasonsForDeclinedPercentageParentAndChildrenInOneDArray; //FETCH ALL DECLIEND REASONS - ARRAY THAT CONTAINS LABEL AND VALUE
              setNotes("This statistic does not accurately represent the total number of declined applications. Instead, it accounts for all reasons. For instance, if one declined application has three distinct reasons for rejection, it will be counted three times, once for each reason, unless a single reason has been selected explicitly in the filter.")
              break;
            case "individualLoanPurpose":
              allAvailableOPtions = ReportingConstants.STAT_OPTIONS.individualLoanPurpose; //FETCH ALL loan options - ARRAY THAT CONTAINS LABEL AND VALUE
              setNotes("")
              break;
          }

          //GET ALL POSSIBLE REASONS FROM REPORTIN-CONSTANTS AND THEN FILTER IT IF FILTER APPLIED FOR THE REASONS
          allAvailableOPtions.map(singleReason => {
            let label = singleReason.label;  //THIS IS READABLE DESCRIPTION OF REASON
            let value = singleReason.value; //THIS IS THE VALUE THAT IS STORED IN THE DATABASE E.G adverseCreditFile

            //IF NO REASON PROVIDED IN THE REQUEST THEN LIST ALL AVAILABLE REASONS
            if (!isProvidedsubStatsArray) {
              let filteredResult = reportData.filter(singleResult => singleResult.groupByLabel == value);
              if (filteredResult.length > 0) {                
                //FOR APPEX CHART
                newReportData.labels.push(label);
                newReportData.values.push(filteredResult[0].totalApplications);
              }
              else {
                //FOR APPEX CHART
                newReportData.labels.push(label);
                newReportData.values.push(0);
              }
            }
            else if (providedsubStatsArray.includes(value)) {
              let filteredResult = reportData.filter(singleResult => singleResult.groupByLabel == value);
              if (filteredResult.length > 0) {
                //FOR APPEX CHART
                newReportData.labels.push(label);
                newReportData.values.push(filteredResult[0].totalApplications);
              }
              else {
                //FOR APPEX CHART
                newReportData.labels.push(label);
                newReportData.values.push(0);
              }
            }
          })

          break;

        default:
          setNotes("");
          break;
      }

      resolve({ data: newReportData });
    });

  }


  //DRAW RRESULTS IN TABLE FORMAT
  /*const DrawResultsInTable = () => {
    return (
      <div>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>Label</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {
              pieCharttData.map((singleResult, index) => {
                return (
                  <tr key={index}>
                    <td>{singleResult.name}</td>
                    <td>{singleResult.value}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }*/


  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <h4> {searchFilters["stat"] !== undefined && searchFilters["stat"].length > 0 ? ReportingConstants.stats.find(stat => stat.value === searchFilters["stat"])["label"] : ""}</h4><br />
        </div>


        <div className="col-sm-12 col-md-6">
          {
            Array.isArray(pieCharttData["series"]) && pieCharttData["series"].length > 0 ?
              <div className="pie-chart">
                <Chart options={pieCharttData["options"]} series={pieCharttData["series"]} type="pie" width="800" />
              </div>
              : <div> </div>
          }
        </div>
        {
          Array.isArray(pieChartDataComparePeriod["series"]) && pieChartDataComparePeriod["series"].length > 0 ?
            <div className="col-sm-12 col-md-6">
              <div className="pie-chart">
                <Chart options={pieChartDataComparePeriod["options"]} series={pieChartDataComparePeriod["series"]} type="pie" width="800" />
              </div>
            </div>
            : <div> </div>
        }

        <div className="col-sm-12">
          {notes.length > 0 ? <p> <b>Note:</b> {notes} </p> : ""}
        </div>
      </div>

    </>);
}


export default DrawPieChart;