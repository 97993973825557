import axios from '../api/axios';

const createSmsLog = async(token, payload) => {
    try{
        const response = await axios.post(`smsLog` , payload,  { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return { "error": 0, httpCode: response.status, "message": "Created successfully", referrer: response.data }

    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {
                "error" : 1,
                "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data, 
                "errorArray" : e.response.data["errorArray"] ? e.response.data["errorArray"] : [] 
            }
         }
    }
}

//GET ALL SMS LOGS 
const getSmsLogs = async(token, entityType,entityId, applicantId = 0 ) => {
    try{
        const response = await axios.get(`smsLog/${entityType}/${entityId}/${applicantId}`, { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return { "error": 0, httpCode: response.status, "message": "Fetched successfully", smsLogs: response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {
                "error" : 1 , 
                "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data, 
                "errorArray" : e.response.data["errorArray"] ? e.response.data["errorArray"] : [] 
            }
         }
    }
}



export { createSmsLog, getSmsLogs}