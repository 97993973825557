import React, { useState, useEffect, useRef } from 'react';

// MultiSelect with Checkboxes Component
const MultiSelectWithCheckboxes = ({ options, selectedValues, setSelectedValues }) => {
  // Handle parent selection (select/deselect all children)
  const handleParentChange = (parent, isChecked) => {
    let updatedSelectedValues = [...selectedValues];
    if (isChecked) {
      updatedSelectedValues.push(parent.value);
      // Add all children to the selected values
      parent.children.forEach((child) => {
        updatedSelectedValues.push(child.value);
      });
    } else {
      // Remove parent and children from selected values
      updatedSelectedValues = updatedSelectedValues.filter(
        (value) => value !== parent.value && !parent.children.some((child) => child.value === value)
      );
    }
    setSelectedValues(updatedSelectedValues);
  };

  // Handle child selection/deselection
  const handleChildChange = (parent, child, isChecked) => {
    let updatedSelectedValues = [...selectedValues];
    if (isChecked) {
      updatedSelectedValues.push(child.value);
      // If the child is selected, ensure the parent is also selected
      /*if (!updatedSelectedValues.includes(parent.value)) {
        updatedSelectedValues.push(parent.value);
      }*/
    } else {
      // If the child is deselected, remove it and the parent if no other children are selected
      updatedSelectedValues = updatedSelectedValues.filter((value) => value !== child.value);
      const parentHasSelectedChildren = parent.children.some((child) =>
        updatedSelectedValues.includes(child.value)
      );
      /*if (!parentHasSelectedChildren) {
        updatedSelectedValues = updatedSelectedValues.filter((value) => value !== parent.value);
      }*/
    }
    setSelectedValues(updatedSelectedValues);
  };

  // Render each parent with its children
  const renderParent = (parent) => (
    <div key={parent.value} style={{ marginBottom: '15px' }}>
      <div>
        <input
          type="checkbox"
          checked={selectedValues.includes(parent.value)}
          onChange={(e) => handleParentChange(parent, e.target.checked)}
        />
        <strong style={{marginLeft: "5px"}}>{parent.label}</strong>
      </div>
      <div style={{ paddingLeft: '20px' }}>
        {parent.children.map((child) => (
          <div key={child.value}  style={{marginLeft: "5px", }}>
            <input
              type="checkbox"
              checked={selectedValues.includes(child.value)}
              onChange={(e) => handleChildChange(parent, child, e.target.checked)}
            />
            <span style={{marginLeft: "5px", }}>{child.label}</span>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      {options.map((parent) => renderParent(parent))}
    </div>
  );
};


/******** FOLLOWING HAVE THE DESIGN AND ALSO LOAD THE  MultiSelectWithCheckboxes INSIDE ****************** */

// Custom MultiSelect with Checkboxes Component
const CustomMultiSelectWithCheckBoxes = ({ options, fieldName, setFieldValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const dropdownRef = useRef(null);
  const fieldButtonRef = useRef(null);

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !fieldButtonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Toggle the visibility of the MultiSelectWithCheckboxes component
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  // Handle selected values for display in the field
  const getSelectedLabels = () => {
    const selectedOptions = [];
    options.forEach((parent) => {
      if (selectedValues.includes(parent.value)) {
        selectedOptions.push(parent.label);
      }
      parent.children.forEach((child) => {
        if (selectedValues.includes(child.value)) {
          selectedOptions.push(child.label);
        }
      });
    });
    return selectedOptions.join(', ') || 'Select options';
  };

  // Render selected values as tiles with updated, smaller design
  const renderSelectedTiles = () => {
    const selectedOptions = [];
    options.forEach((parent) => {
      if (selectedValues.includes(parent.value)) {
        selectedOptions.push(parent.label);
      }
      parent.children.forEach((child) => {
        if (selectedValues.includes(child.value)) {
          selectedOptions.push(child.label);
        }
      });
    });

    return selectedOptions.map((label, index) => (
      <div
        key={index}
        style={{
          backgroundColor: '#007bff',
          color: 'white',
          padding: '3px 8px',
          borderRadius: '20px',
          margin: '3px',
          display: 'inline-block',
          fontSize: '12px', // Smaller font size
          maxWidth: '150px', // Restrict width for a more modern look
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </div>
    ));
  };

  //SEND THE RESPONSE BACK TO THE PARENT COMPONENT
  const sendResponse = () => {
    const selectedOptions = [];
    options.forEach((parent) => {
      if (selectedValues.includes(parent.value)) {
        selectedOptions.push(parent.label);
      }
      parent.children.forEach((child) => {
        if (selectedValues.includes(child.value)) {
          selectedOptions.push(child.label);
        }
      });
    });

    setFieldValue(fieldName, selectedOptions);

    console.log("selectedOptions", selectedOptions);
  }

  // Deselect all selected options
  const deselectAll = () => {
    setSelectedValues([]);
  };


  useEffect(() => {

    //CALLBACK 
    setFieldValue(fieldName, selectedValues);
  }, [selectedValues]);

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {/* Dropdown-like Field Button */}
      <div
        ref={fieldButtonRef}
        onClick={toggleCollapse}
        style={{
          backgroundColor: '#FFFFFF',
          padding: '10px 15px',
          cursor: 'pointer',
          border: '1px solid #ccc',
          borderRadius: '5px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxSizing: 'border-box',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
          {renderSelectedTiles()}

        </div>
        <span>{isOpen ? '▲' : '▼'}</span> {/* Arrow to indicate open/close */}
      </div>

      {/* Collapsible Dropdown */}
      {isOpen && (
        <div
          ref={dropdownRef}
          style={{
            marginTop: '10px',
            padding: '15px',
            border: '1px solid #ddd',
            borderRadius: '5px',
            backgroundColor: 'white', // White background
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            position: 'absolute',
            zIndex: '1000',
            width: '100%',
            maxHeight: '250px',
            overflowY: 'auto',
            borderTop: 'none',
          }}
        >
          {/* Deselect All Icon */}
          <div
            onClick={deselectAll}
            style={{
              cursor: 'pointer',
              color: '#007bff',
              fontWeight: 'bold',
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <span>Clear All</span>
          </div>

          <MultiSelectWithCheckboxes
            options={options}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelectWithCheckBoxes;
