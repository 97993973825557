import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToken, useRole, useEntityType, useIsAuthenticated, useEntityId } from '../../hooks/useAuth';
import { SelectField } from '../HtmlComponents/';
import MenuItems from './Navigation/MenuItems';
import SubMenu from './Navigation/SubMenu';
import { RiLockPasswordLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import {SettlementRateAgainstReceivedApplications} from  "../reporting/components/admin/SettlementRateAgainstReceivedApplications";

{ /*import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'; */ }


/**export default class Leftbar extends Component */
const Leftbar = ({ openMobileLeftBar }) => {

    const [selectedItemKey, setSelectedItemKey] = useState("");
    const [menuItems, setMenuItems] = useState({});
    let navigate = useNavigate();
    
    const theme = useSelector((state) => state.theme);

    var currentUserRole = useRole();
    var currentEntityType = useEntityType();
    const currentEntityId = useEntityId();
    var navigationHtml = '';

    const loadMenuItems = () => {
        var tmpMenuItems = MenuItems[currentEntityType] !== undefined && MenuItems[currentEntityType][currentUserRole] !== undefined ? MenuItems[currentEntityType][currentUserRole] : {};
        setMenuItems(tmpMenuItems);
    }
    useEffect(() => {
        loadMenuItems();
    }, [])

    //TOGGLE SUB MENU
    const toggleLeftBarSubMenu = (subMenuNameParam) => {
        var newMenuItems = { ...menuItems };

        for (const key in newMenuItems) { if (key !== subMenuNameParam) { newMenuItems[key]["isSelected"] = false; } }
        if (newMenuItems[subMenuNameParam]) {
            var booleanValue = newMenuItems[subMenuNameParam]["isSelected"] !== undefined && newMenuItems[subMenuNameParam]["isSelected"] == true ? false : true;
            newMenuItems[subMenuNameParam]["isSelected"] = booleanValue;
        }
        setMenuItems(newMenuItems);
    }

    //MARK THE MENU ITEM/SUB ITEM THAT IS SELECTED
    const markSelectedMenuItem = (isSubMenuItem, subMenuKey, itemKey) => {
        var newMenuItems = { ...menuItems };
        //MARK ALL ISSELECTED PROPERT TO FALSE FOR ALL ITEMS - START
        for (const key in newMenuItems) {
            newMenuItems[key]["isSelected"] = false;
            if (newMenuItems[key].hasSubMenu) {
                for (const subMenuItemKey in newMenuItems[key]["subMenuNavigation"]) { newMenuItems[key]["subMenuNavigation"][subMenuItemKey]["isSelected"] = false; }
            }
        }
        //MARK ALL ISSELECTED PROPERT TO FALSE FOR ALL ITEMS - END

        //UPDATE THE FALG TRUE FOR THE ITEM THAT IS SELECTED
        if (isSubMenuItem) {
            newMenuItems[subMenuKey]["isSelected"] = true;
            newMenuItems[subMenuKey]["subMenuNavigation"][itemKey]["isSelected"] = true;
        }
        else { newMenuItems[itemKey]["isSelected"] = true; }
        setMenuItems(newMenuItems);
    }

    //HANDLE CLICK ON LINK ITEM - WHEN ITEM IS CLICKED UPDATE THE STATE AND REDIRECT TO RELAVEANT PAGE
    const handleLink = (isSubMenuItem, subMenuKey, itemKey) => {
        var link = '';
        if (isSubMenuItem) {
            markSelectedMenuItem(1, subMenuKey, itemKey);
            link = menuItems[subMenuKey]["subMenuNavigation"][itemKey].link;
            navigate(link);
        }
        else {
            markSelectedMenuItem(0, null, itemKey);
            link = menuItems[itemKey].link;
            navigate(link);
        }
    }

    //ADD ADDITIONAL INFO TO LEFT BAR - DEPENDS UPON ENTITY TYPE
    const ExtraOptions = () => {
        if (currentEntityType == "referrer") {
            var referrersList = JSON.parse(localStorage.getItem('referrersList'));
            var options = [];
            referrersList.map(singleReferrer => {
                options.push({ value: singleReferrer["id"], label: singleReferrer["name"] })
            })
            return (
                <>
                    <div style={{ padding: "10px 15px 0px 15px " }}>
                        <SelectField
                            options={options}
                            fieldName=""
                            defaultSelectedOption={{ value: parseInt(localStorage.getItem('selectedReferrer')), label: "" }}
                            callback={() => { }}
                            setFieldValue={
                                (name, value) => {
                                    localStorage.setItem('selectedReferrer', value);
                                    window.location.reload(true);
                                }
                            }
                        />
                    </div>
                </>
            )
        }
    }


    //GENERATE SINGLE MENU ITEM WHO HAS NO CHILD
    const MenuItem = ({ menuItem, navigationKey, hasSubMenu }) => {
        //if not active then return
        if(typeof menuItem === "object" && Object.hasOwn(menuItem, "active") && menuItem.active){ }else{return "";}


        //CHECK IF THIS ITEM HAS accessibleBy PROPERTY AND IF LOGGED IN USER PARENT (CREDIT REP ID FOR LOOGED IN USER EXIST INT HIS ARRAY)
        if (typeof menuItem === "object" && Object.hasOwn(menuItem, "accessibleBy") && Array.isArray(menuItem.accessibleBy)) {
            //CHECK IF PARENT ID EXISTS 
            if (menuItem.accessibleBy.includes(currentEntityId)) {
                return (<MenuItemHtml menuItem={menuItem} navigationKey={navigationKey} hasSubMenu={hasSubMenu} />)
            }
            else {
                return "";
            }
        }
        else {
            //RETURN
            return (<MenuItemHtml menuItem={menuItem} navigationKey={navigationKey} hasSubMenu={hasSubMenu} />)
        }
    }

    const MenuItemHtml = ({ menuItem, navigationKey, hasSubMenu }) => {
        switch (hasSubMenu) {
            case "yes":
                return (
                    <>
                        <div data-kt-menu-trigger="click" className={menuItem.isSelected !== undefined && menuItem.isSelected == true ? " menu-item menu-accordion show " : "menu-item menu-accordion "} >
                            <span className="menu-link" onClick={() => { toggleLeftBarSubMenu(navigationKey) }} >
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        {menuItem.icon}
                                    </span>
                                </span>
                                <span className="menu-title"> {menuItem.name} </span>
                                <span className="menu-arrow"></span>
                            </span>
                            <SubMenu subMenuItems={menuItem.subMenuNavigation} currentEntityId={currentEntityId} callbak={(subMenuItemKey) => { handleLink(1, navigationKey, subMenuItemKey) }} />
                        </div>
                    </>
                )
                break;
            case "no":
                return (
                    <>
                        <div className="menu-item">
                            <div className={menuItem.isSelected !== undefined && menuItem.isSelected == true ? " menu-link without-sub active" : "menu-link without-sub"} onClick={() => { handleLink(0, null, navigationKey) }}>
                                <span className="menu-icon">
                                    <span className="svg-icon svg-icon-2">
                                        {menuItem.icon}
                                    </span>
                                </span>
                                <span className="menu-title"> {menuItem.name} </span>
                            </div>
                        </div>
                    </>
                )
                break;
        }
    }


    {/**isMobile ? "aside aside-dark aside-hoverable   app-sidebar flex-column drawer drawer-start drawer-on" :  */ }
    let leftBarContainerClass = openMobileLeftBar ? " aside aside-dark aside-hoverable mobileLeftBar " : " aside aside-dark aside-hoverable ";
    return (<> <div id="kt_aside" className={leftBarContainerClass} data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
        {
            theme.logo ?
            <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                <a href="#">
                    <img alt="Logo" src={theme.logo} className={`h-${theme.logoHeight} logo`} />
                </a>
            </div> : ""

        }
        <div className="aside-menu flex-column-fluid">
            <div className="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside_menu" data-kt-scroll-offset="0" style={{ maxHeight: "calc(100vh - 250px)" }}>
                <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500" id="#kt_aside_menu" data-kt-menu="true" data-kt-menu-expand="true">
                    { /*navigationHtml*/}
                    {
                        Object.keys(menuItems).map(singleMenuItemKey => {
                            if (menuItems[singleMenuItemKey].hasSubMenu === true) {
                                return (<>
                                    <MenuItem menuItem={menuItems[singleMenuItemKey]} navigationKey={singleMenuItemKey} hasSubMenu="yes" />
                                </>)
                            } else {
                                return (
                                    <>
                                        <MenuItem menuItem={menuItems[singleMenuItemKey]} navigationKey={singleMenuItemKey} hasSubMenu="no" />
                                    </>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>

        {
            //IF LOGGED IN USER IS ADMIN OR ANALYST THEN SHOW THE SETTLEMENT RATE AND IF ITS NOT MOBILE
            ["admin", "analyst"].includes(currentUserRole) && !openMobileLeftBar ? 
            <div className="aside-footer flex-column-auto px-5" style={{maxHeight:"150px"}} id="kt_aside_footer">
                <SettlementRateAgainstReceivedApplications />
            </div> : ""
        }

        {/*   UPDATE PASSWORD LINK EXTRA OPTION (REFERRER SELECTION )  */}
        <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500">
            <div style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.25)", marginBottom: "8px" }}></div>
            <ExtraOptions />
            <div className="menu-item">
                <Link className="menu-link without-sub" to="/updatePassword">
                    <span className="menu-icon">
                        <span className="svg-icon svg-icon-2">  <RiLockPasswordLine /> </span>
                    </span>
                    <span className="menu-title">Update Password</span>
                </Link>
            </div>
        </div>

        <div className="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
            <Link to="/logout" className="btn btn-custom btn-primary w-100" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss-="click" title="" data-bs-original-title="200+ in-house components and 3rd-party plugins">
                <span className="btn-label">Logout</span>
            </Link>
        </div>
    </div>
    </>)
}

export default Leftbar;


/**
 * 
 * <div className="menu-item">
                        <div className={ menuItems[navigationKey].isSelected !== undefined && menuItems[navigationKey].isSelected == true ? " menu-link without-sub active": "menu-link without-sub"} onClick={() => {handleLink(0, null, navigationKey)}}>
                            <span className="menu-icon">
                                <span className="svg-icon svg-icon-2">
                                    {menuItems[navigationKey].icon}
                                </span>
                            </span>
                            <span className="menu-title"> {menuItems[navigationKey].name} </span>
                            </div>
                    </div>


 */