
import { useEffect } from "react";
import { useState } from "react";
import { SelectField, CustomButton } from "../../HtmlComponents";


const SearchBySingleDate = ({callback, defaultMonth, defaultYear}) => {

    const [month, setMonth] = useState("");     //current, previous
    const [year, setYear] = useState("");     //current, previous

    const months = [{ value: "01", label: "January" }, { value: "02", label: "February" }, { value: "03", label: "March" }, { value: "04", label: "April" },
    { value: "05", label: "May" }, { value: "06", label: "June" }, { value: "07", label: "July" }, { value: "08", label: "August" }, { value: "09", label: "September" },
    { value: "10", label: "October" }, { value: "11", label: "November" }, { value: "12", label: "December" },];
    const [years, setYears] = useState([]);
    const [allowSubmission, setAllowSubmission] = useState(false);



    const updateMonthsAndYears = () => {
        var localYears = [];
        for (var i = 2018; i < 2030; i++) {
            localYears.push({ value: i, label: i });
        }
        setYears(localYears);
    }

    useEffect(() => {
        updateMonthsAndYears();
    }, [])

    useEffect(() => {
        if (month !== "" && year !== "") { setAllowSubmission(true); }
        else { setAllowSubmission(false); }

    }, [year, month])

    //UPDATE THE MONTH AND YEAR STATE IF DEFAULT IS PROVIDED
    useEffect(() => {  
        if (defaultMonth !== "" && defaultMonth !== undefined) { setMonth(defaultMonth);}
        if (defaultYear !== "" && defaultYear !== undefined) { setYear(defaultYear); }
     }, [defaultMonth, defaultYear]);

    const handler = (name, value) => {
        switch (name) {
            case "month":
                setMonth(value);
                break;
            case "year":
                setYear(value)
                break;
            case "submit":
                callback("dateFilter", { month: month, year: year });
                break;
        }
    }

    return (<>
        <div className="card card-flush h-md-50 mb-3 mb-xl-5">
            <div className="card-body">
                <div className="row">

                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-6">
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>Month</label>
                                <SelectField
                                    fieldName="month"
                                    defaultSelectedOption={{ value: month, label: "" }}
                                    options={months}
                                    setFieldValue={handler}
                                />
                            </div>
                            <div className="col-sm-6">
                                <label style={{ fontSize: "10px", fontWeight: "600" }}>Year</label>
                                <SelectField
                                    fieldName="year"
                                    defaultSelectedOption={{ value: year, label: "" }}
                                    options={years}
                                    setFieldValue={handler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <CustomButton
                            buttonClassName="btn btn-sm btn-primary "
                            buttonStyle={{ marginTop: "10px", float: "right" }}
                            buttonOnClick={() => { handler("submit", "") }}
                            buttonText="Search"
                            isActive={allowSubmission}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>)


}

export default SearchBySingleDate;