import axios from '../api/axios';

const createSMS = async(token, payload) => {
    try{
        const response = await axios.post(`sms` , payload,  { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return { "error": 0, httpCode: response.status, "message": "Created successfully", referrer: response.data }

    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {
                "error" : 1 , 
                "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data, 
                "errorArray" : e.response.data["errorArray"] ? e.response.data["errorArray"] : [] 
            }
         }
    }
}


export { createSMS}