import  {useSelector} from "react-redux";


const useToken = () => {
    const user = useSelector( (state) => state.auth.user );
    return user.token  ? user.token : null;
}

const useFullName = () => {
    const user = useSelector( (state) => state.auth.user );
    return user.fullName  ? user.fullName : null;
}

const useRole = () => {
    const user = useSelector( (state) => state.auth.user );
    return user.role  ? user.role : null;
}

const useIsAuthenticated = () => {
    const user = useSelector( (state) => state.auth.user );
    return user.role  ? true : false;
}

const useEntityType = () => {
    const user = useSelector( (state) => state.auth.user );
    return user.entityType  ? user.entityType : null;
}

const useEntityId = () => {
    const user = useSelector( (state) => state.auth.user );
    return user.entityId  ? user.entityId : null;
}

const useExtraInfo = () => {
    const user = useSelector( (state) => state.auth.user );
    return user.extraInfo  ? user.extraInfo : {};
}

//CURRENLTY ITS ONLY FOR REFERRER GROUPS
const useAccessAdvanceReporting = () => {
    const user = useSelector( (state) => state.auth.user );
    return user.accessAdvanceReporting  ? user.accessAdvanceReporting : false;
}


export {useToken , useFullName, useRole , useIsAuthenticated , useEntityType , useEntityId, useExtraInfo, useAccessAdvanceReporting};