import { CustomButton, IconButton } from '../HtmlComponents/';
import { useEntityType } from '../../hooks/useAuth';

const EquifaxListItem = ({item , callback, status}) => {

    const entityType = useEntityType();

    return (
        <>
        <tr className="even text-start">
            <td className="text-gray-800 text-hover-primary mb-1">{item.name}</td>
            <td>{item.txnCode}</td>
            <td>{item.amount}</td>
            <td> { ["company"].includes(entityType) && status == "active" ? <IconButton iconType="edit" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "15px", color: "#b20f0f" }} tootltip={true} tooltipDescription="Edit" buttonOnClick={() => { callback("editEquifaxFees", item.id) }} /> : ""}
            { ["company"].includes(entityType) && status == "active" ? <IconButton iconType="delete" buttonClassName=" " buttonStyle={{ border: "0px", backgroundColor: "transparent", paddingRight: "0px", fontSize: "15px", color: "#b20f0f" }} tootltip={true} tooltipDescription="Delete" buttonOnClick={() => { callback("deleteEquifaxFees", item.id) }} /> : ""} </td>
            
        </tr>
        </>
    )
}

export default EquifaxListItem;