import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useToken, useEntityType, useRole , useEntityId } from '../../hooks/useAuth';
import { EditButton, AddButton, CustomButton } from '../HtmlComponents';
import { FilledTick2, CancelIcon } from '../HtmlComponents/Icons';

import { getReferrerGroup } from "../../api/referrerGroupApi";
import { replaceNullWithString, formatDate, dateFilter } from '../../helpers';

import RightDrawer from "../HtmlComponents/modals/RightDrawer";
import Files from '../files/Files';
import AddUser from "../users/AddUser";
import UpdateUser from "../users/UpdateUser";
import ListUsers from "../users/ListUsers";

const ReferrerGroupDetail = () => {
    const [singleReferrerGroup, setSingleReferrerGroup] = useState({});
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });
    let { id } = useParams();
    const token = useToken();
    const entityType = useEntityType();
    const role = useRole();
    const entityId = useEntityId();
    const userRole = useRole();

    useEffect(() => {
        const fetchSingleReferrerGroup = async () => {
            const result = await getReferrerGroup(id, token);
            if (result["error"] == 0 && result["referrerGroup"]) {
                setSingleReferrerGroup(result["referrerGroup"]);
            }

        }
        fetchSingleReferrerGroup();
    }, []);


    const payloadUpdateHandler = (action, values, meta = {}) => {
        switch (action) {
            case "updatePayloadOnAddUser":
                var users = singleReferrerGroup.users;
                if (!Array.isArray(users)) { users = []; }
                users.push(values);
                //UPDAET THE STATE                    
                setSingleReferrerGroup({ ...singleReferrerGroup, users: users });
                handler("closeDraw");   //CLOSE DRAW
                break;
            case "updatePayloadOnUpdateUser":
                var users = singleReferrerGroup.users;
                var newUsers = [];
                users.map((user) => {
                    if (user.id == values.id) { newUsers.push(values); }
                    else { newUsers.push(user); }
                })
                //UPDAET THE STATE                    
                setSingleReferrerGroup({ ...singleReferrerGroup, users: newUsers });
                handler("closeDrawer");   //CLOSE DRAW
                break;
            case "updatePayloadOnDeleteUser":
                var users = singleReferrerGroup.users;
                var newUsers = [];
                users.map((user) => {
                    if (user.id !== values.id) {
                        newUsers.push(user);
                    }
                })
                //UPDAET THE STATE
                setSingleReferrerGroup({ ...singleReferrerGroup, users: newUsers });                    
                break;


        }
    }

    const handler = (actionName, payload = {}) => {
        switch (actionName) {
            case "openFilesDraw":
                setDrawerSettings({ visible: true, heading: "Files", childName: "files", meta: { width: "800" } })
                break;
            case "openAddUserDraw":
                setDrawerSettings({ visible: true, heading: "Add User", childName: "addUser", meta: { width: "800" } })
                break;
            case "openUpdateUserDraw":
                setDrawerSettings({ visible: true, heading: "Update User", childName: "updateUser", meta: { width: "800", userId: payload["userId"] } })
                break;
            case "closeDrawer":
                setDrawerSettings({ ...drawerSettings, visible: false })
                break;
        }
    }

    const EditButtonHandler = () => {
        if (["company", "creditRep"].includes(entityType) && [ "superAdmin", "admin"].includes(role)  ) {
            return (<> <EditButton URL={"/user/editReferrerGroup/" + singleReferrerGroup.id} /> </>);
        }
        else {
            return "";
        }
    }



    const Summary = () => {
        return (<>
            <div className="card mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Details</h3>
                            <EditButtonHandler />
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Name</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.referrerGroupName}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Trading Name</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.tradingName}  </td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Entity Name</td>
                                    <td className="text-start  fw-bold text-gray-600 "> {singleReferrerGroup.entityName} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Structure</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.structure} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">ABN</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.abn}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">ACN</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.acn}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Date Established</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.dateEstablished && singleReferrerGroup.dateEstablished !== 0 ? formatDate(singleReferrerGroup.dateEstablished, 'unix', 'DD/MM/YYYY') : ""} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Telephone</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.contactNumber} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Fax</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.fax} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Business Address</td>
                                    <td className="text-start  fw-bold text-gray-600">  {singleReferrerGroup.address && singleReferrerGroup.address.fullAddress ? singleReferrerGroup.address.fullAddress : ""}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Website</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.websiteUrl} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Email</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.email}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Payment Method</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.paymentMethod}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Commission Structure</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.commissionStructure}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Gst Registered</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.isGstRegistered ? "Yes" : "No"}  </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Email</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.email}  </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    const BankingDetails = () => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Banking Details</h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">Bank Name</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.bankingDetail && singleReferrerGroup.bankingDetail.name ? singleReferrerGroup.bankingDetail.name : ""}</td>
                                </tr>

                                <tr className="even">
                                    <td className="text-start">Branch</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.bankingDetail && singleReferrerGroup.bankingDetail.branchName ? singleReferrerGroup.bankingDetail.branchName : ""} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">BSB</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.bankingDetail && singleReferrerGroup.bankingDetail.bsb ? singleReferrerGroup.bankingDetail.bsb : ""} </td>
                                </tr>
                                <tr className="even">
                                    <td className="text-start">Account Number</td>
                                    <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.bankingDetail && singleReferrerGroup.bankingDetail.accountNumber ? singleReferrerGroup.bankingDetail.accountNumber : ""} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    const XeroConfig = () => {
        if(["referrerGroupManager"].includes(userRole)){
            return "";
        }
        else{
            return (<>
                <div className="card   mb-xl-8">
                    <div className="card-body p-0">
                        <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                            <div className="d-flex flex-stack">
                                <h3 className="m-0 text-white fw-bolder fs-3">Xero Config</h3>
                            </div>
                        </div>
                        <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                            <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                                <tbody className="fs-6">
                                    <tr className="even">
                                        <td className="text-start">Contact Id</td>
                                        <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.xeroConfig && singleReferrerGroup.xeroConfig.xeroContactId ? singleReferrerGroup.xeroConfig.xeroContactId : ""}</td>
                                    </tr>
    
                                    <tr className="even">
                                        <td className="text-start">Tax Type</td>
                                        <td className="text-start  fw-bold text-gray-600"> {singleReferrerGroup.xeroConfig && singleReferrerGroup.xeroConfig.xeroTaxType ? singleReferrerGroup.xeroConfig.xeroTaxType : ""} </td>
                                    </tr>
    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>)
        }
    }

    //PERMISSIONS | Privileges
    const Permissions = () => {
        return (<>
            <div className="card   mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">Privileges </h3>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <table id="kt_project_users_table" className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder dataTable no-footer">
                            <tbody className="fs-6">
                                <tr className="even">
                                    <td className="text-start">
                                        <div className="permissionRecordWrapper mb-2">
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Allow to edit application </span>
                                            {
                                                singleReferrerGroup.permissions && singleReferrerGroup.permissions.allowToEditApplication ? <FilledTick2 /> : <CancelIcon />
                                            }
                                        </div>

                                        <div className="permissionRecordWrapper mb-2">
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Allow To View Notes </span>
                                            {
                                                singleReferrerGroup.permissions && singleReferrerGroup.permissions.allowToViewNotes ? <FilledTick2 /> : <CancelIcon />
                                            }
                                        </div>

                                        <div className="permissionRecordWrapper mb-2">
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Allow to add notes </span>
                                            {
                                                singleReferrerGroup.permissions && singleReferrerGroup.permissions.allowToAddNotes ? <FilledTick2 /> : <CancelIcon />
                                            }
                                        </div>

                                        <div className="permissionRecordWrapper mb-2">
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Referrer Tab </span>
                                            {
                                                singleReferrerGroup.permissions && singleReferrerGroup.permissions.referrerTab ? <FilledTick2 /> : <CancelIcon />
                                            }
                                        </div>

                                        <div className="permissionRecordWrapper mb-2">
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Add New Referral </span>
                                            {
                                                singleReferrerGroup.permissions && singleReferrerGroup.permissions.addNewReferral ? <FilledTick2 /> : <CancelIcon />
                                            }
                                        </div>

                                        <div className="permissionRecordWrapper mb-2">
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Referrals Listing </span>
                                            {
                                                singleReferrerGroup.permissions && singleReferrerGroup.permissions.referralsListing ? <FilledTick2 /> : <CancelIcon />
                                            }
                                        </div>

                                        <div className="permissionRecordWrapper mb-2">
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Settlement Tab </span>
                                            {
                                                singleReferrerGroup.permissions && singleReferrerGroup.permissions.settlementTab ? <FilledTick2 /> : <CancelIcon />
                                            }
                                        </div>

                                        <div className="permissionRecordWrapper mb-2">
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Archive Tab </span>
                                            {
                                                singleReferrerGroup.permissions && singleReferrerGroup.permissions.archiveTab ? <FilledTick2 /> : <CancelIcon />
                                            }
                                        </div>

                                        <div className="permissionRecordWrapper mb-2">
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Email Server </span>
                                            {
                                                singleReferrerGroup.permissions && singleReferrerGroup.permissions.emailServer ? <FilledTick2 /> : <CancelIcon />
                                            }
                                        </div>

                                        <div className="permissionRecordWrapper mb-2">
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Send Email To Referrer </span>
                                            {
                                                singleReferrerGroup.permissions && singleReferrerGroup.permissions.sendEmailToReferrer ? <FilledTick2 /> : <CancelIcon />
                                            }
                                        </div>

                                        <div className="permissionRecordWrapper mb-2">
                                            <span style={{ marginLeft: "3px", marginRight: "20px" }}> Allow Advance Reporting Access </span>
                                            {
                                                singleReferrerGroup.permissions && singleReferrerGroup.permissions.accessAdvanceReporting ? <FilledTick2 /> : <CancelIcon />
                                            }
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }

    //USER BLOCK - LIST ALL USERS
    const UsersListing = () => {
        return <>
            <div className="card  mb-xl-8">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded h-175px w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3">List Users</h3>
                            {<CustomButton
                                buttonClassName=" btn btn-sm btn-light btn-active-secondary "
                                isActive={true}
                                buttonText="Add New User"
                                buttonOnClick={() => { handler("openAddUserDraw") }}
                            />}
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: "-100px" }}>
                        <ListUsers users={singleReferrerGroup.users ? singleReferrerGroup.users : []} callback={(method, userId) => {
                            if (method == "refreshUsersListing") { payloadUpdateHandler("updatePayloadOnDeleteUser" , {id: userId}); }
                            else if(method == "updateUser") { handler("openUpdateUserDraw", { userId: userId });  }
                        }} />
                    </div>

                </div>
            </div>
        </>
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl">
                <div className="row g-5 g-xl-8">
                    <div className="col-xl-6">
                        <Summary />
                    </div>
                    <div className="col-xl-6">
                        <BankingDetails />
                        {Permissions()}
                    </div>

                    <div className="col-sm-12">
                        {XeroConfig()}
                        <UsersListing />
                    </div>
                </div>
                <div className="engage-toolbar d-flex position-fixed px-5 fw-bold zindex-2 top-50 end-0 transform-90 mt-5 mt-lg-20 gap-2" style={{ zIndex: "1" }}>
                    <CustomButton buttonClassName="btn  bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" buttonStyle={{ minWidth: "100px" }} isActive={true} buttonOnClick={() => { handler("openFilesDraw") }} buttonText="Files" />
                </div>
                {drawerSettings["visible"] ?
                    <RightDrawer heading={drawerSettings["heading"]} width={drawerSettings["meta"]["width"] !== undefined ? drawerSettings["meta"]["width"] : "500"} callback={handler}>
                        {drawerSettings["childName"] === "addUser" ? <AddUser entityType={"referrerGroup"} entityId={singleReferrerGroup.id}
                            callback={(data) => { payloadUpdateHandler("updatePayloadOnAddUser", data) }}
                        /> : ""}
                        {drawerSettings["childName"] === "updateUser" ? <UpdateUser entityType="referrerGroup" userId={drawerSettings["meta"]["userId"] != undefined ? drawerSettings["meta"]["userId"] : 0} callback={(data) => { payloadUpdateHandler("updatePayloadOnUpdateUser", data) }} /> : ""}
                        {drawerSettings["childName"] === "files" ? <Files entityType="referrerGroup" entityId={id} enableUpload={true} /> : ""}
                    </RightDrawer> : ""
                }

            </div>
        </>
    );

}


export default ReferrerGroupDetail;