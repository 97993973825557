import { useEffect } from 'react';
import { useState } from 'react';
import { CheckBox, InputField } from '../../HtmlComponents';
import { Field } from 'formik';

const DeclinedReason = ({ name, label, isChecked, setFieldValue }) => {
    const [visibleDescriptionBlock, setVisibleDescriptionBlock] = useState(false);

    const updatHandler = (localName, value) => {
        if (localName !== "description") {
            if (value === true) { setFieldValue(`${localName}["isChecked"]`, true); }
            else { setFieldValue(`${localName}["isChecked"]`, false); }

            //CHECK IF OTHER IS MARKED
            if (localName == "reasons[11]") { value == true ? setVisibleDescriptionBlock(true) : setVisibleDescriptionBlock(false); }
        }

        //SPLIT THE NAME BASED ON DECIMAL "."
        const splitLocalName = localName.split(".");
        console.log("SPLIT LOCAL NAME:: ", splitLocalName);
        if (splitLocalName.length > 1) {
            let child = splitLocalName[splitLocalName.length - 1];
            let parent = splitLocalName[splitLocalName.length - 2];
            let grandParent = splitLocalName.length > 2 ? splitLocalName[splitLocalName.length - 3] : null;

            console.log("CHILD:: ", child);
            console.log("PARENT:: ", parent);
            /*if(value == true){
                setFieldValue(grandParent ? `${grandParent}${parent}["isChecked"]`: `${parent}["isChecked"]` , true);
            }*/

            //CHECK IF THERE IS NO GRAND PARENT
            if (parent && !grandParent) { setFieldValue(`${parent}["isChecked"]`, true); }
            else if (parent && grandParent) { setFieldValue(`${grandParent}${parent}["isChecked"]`, true); }
            //GRANDPARENT                
            if (grandParent) { setFieldValue(`${grandParent}["isChecked"]`, true); }

        }

        //        else if (localName == "description") { setFieldValue(`${name}["description"]`, value); }
    }
    useEffect(() => {
        if (name == "reasons[11]" && isChecked == true) {
            setVisibleDescriptionBlock(true);


        }
    }, [])

    return (<>

        <CheckBox
            name={name}
            defaultChecked={isChecked == true ? true : false}
            setFieldValue={updatHandler}
        />
        <span style={{ marginLeft: "10px" }}>{label}</span>
        {
            visibleDescriptionBlock == true ?
                <div style={{ marginLeft: "20px" }}>
                    <Field type="text" name={`${name}["description"]`}  >
                        {({ field }) => (
                            console.log("FIELD VALUE DESCRIPTIONS:: ", field.value),

                            <InputField
                                fieldName={`${name}["description"]`}
                                fieldValue={field.value}
                                setFieldValue={setFieldValue} />
                        )}
                    </Field>
                </div>
                : ""
        }
    </>)
}

export default DeclinedReason;