import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user : {userName : null, 
        fullName: "",  
        password : null , 
        token : null, 
        role : null , 
        entityType : null , 
        entityId : null, 
        extraInfo: {},
        accessAdvanceReporting: false,
    }
}

export const AuthSlice = createSlice({
    name : "auth",
    initialState,
    reducers : {
        setUserAuth : (state, action) => {
            state.user = action.payload
        }
    }
})

export const {setUserAuth} = AuthSlice.actions; 
export default AuthSlice.reducer;