// src/redux/themeSlice.js
import { createSlice } from '@reduxjs/toolkit';

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    primaryColor: '',
    secondaryColor: '',
    backgroundColor: '',
    textColor: '',
    logo: '',
    logoHeight: '',
    loginLogo: '',
    loginLogoHeight: '',
    customBtnBackgroundColor: ''

  },
  reducers: {
    setTheme(state, action) {
      return { ...state, ...action.payload };
    }
  }
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;