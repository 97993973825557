import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useRole, useToken, useEntityType, useEntityId } from '../../hooks/useAuth';
import { EditButton, AddButton, CustomButton, RightDrawer } from '../HtmlComponents';
import SearchBySingleDate from '../global/SearchForms/SearchBySingleDate';
import { listAllInsuranceInvocies, submitInsuranceInvociesToXero } from '../../api';
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';


const InsuranceInvoices = () => {
    const [invoices, setInvoices] = useState([]); //collection of lenders and each lender will contain his own invoices
    const [date, setDate] = useState({ year: "", month: "" })
    const [processing, setProcessing] = useState(false);
    const token = useToken();


    //LOAD THE DEFAULT DATE AND THEN CALL TO LOAD INVOICES
    useEffect(() => {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;  //GET MONTH STARTS FROM 0 TO 11 SO ADD 1
        month = month < 10 ? "0" + month : month;
        setDate({ year: year, month: month });  //UPDATE DATE STATE

        fetchInvoices({ year: year, month: month });  //FETCH THE INVOICES
    }, []);

    const updateHandler = (name, value) => {
        switch (name) {
            case "dateFilter":
                fetchInvoices(value);
                break;
        }
    }

    const fetchInvoices = async (searchPayload) => {
        var year = searchPayload["year"];
        var month = searchPayload["month"];
        setDate({ year: year, month: month });
        setProcessing(true);
        var response = await listAllInsuranceInvocies(token, year, month);
        setProcessing(false);
        if (response["error"] == 0) { setInvoices(response["data"]); }
    }

    const submitToXero = async () => {
        var year = date["year"];
        var month = date["month"];
        //SHOW LAODING SCREEN
        setProcessing(true);

        var response = await submitInsuranceInvociesToXero(token, year, month);
        console.log("response:  ::::::::::::  ", response);
        
        if(response["error"] !== undefined && response["error"] == 0){
            var message = response["data"] !== undefined && response["data"]["message"] !== undefined ? response["data"]["message"]: "";
            var errorMessages = response["data"] !== undefined && response["data"]["errorMessages"] !== undefined ? response["data"]["errorMessages"]: "";
            fetchInvoices({year: date["year"], month: date["month"]});
            Notifications("success", message);
            console.log("errorMessages:: ",errorMessages );
        }
        else{
            var message = response["data"] !== undefined && response["data"]["message"] !== undefined ? response["data"]["message"]: "";
            var errorMessages = response["data"] !== undefined && response["data"]["errorMessages"] !== undefined ? response["data"]["errorMessages"]: "";
            Notifications("error", message);
            console.log("errorMessages:: ",errorMessages );
        }        
        setProcessing(false);
    }

    return (
        <>

            <div id="kt_content_container" className="container-xxl">
                <div className="row g-5 g-xl-8">
                    <SearchBySingleDate callback={updateHandler}   defaultMonth={date["month"]} defaultYear={ date["year"]} />
                    <div className="col-sm-12">
                        <div className="row xeroSubHeading2">
                            <div className="col-sm-2"> <span> Applicant Name </span> </div>
                            <div className="col-sm-2"> <span> Reference </span> </div>
                            <div className="col-sm-2"> <span> Amount </span> </div>
                            <div className="col-sm-2"> <span> Company Name </span> </div>
                            <div className="col-sm-2"> <span> Xero Contact Id </span> </div>
                            <div className="col-sm-2"> <span> Xero Account Id </span> </div>
                        </div>
                        {
                            Array.isArray(invoices) ?
                                invoices.map(singleInvoice => {
                                    return (
                                        <>
                                            <div className="row xeroInvoice">
                                                <div className="col-sm-2"><span>{singleInvoice["applicantName"]}</span></div>
                                                <div className="col-sm-2"><span>{singleInvoice["reference"]}</span></div>
                                                <div className="col-sm-2"><span> $ {singleInvoice["amount"]}</span></div>
                                                <div className="col-sm-2"><span> {singleInvoice["companyName"]}</span></div>
                                                <div className="col-sm-2"><span> {singleInvoice["xeroContactId"]}</span></div>
                                                <div className="col-sm-2"><span> {singleInvoice["xeroAccountId"]}</span></div>
                                            </div>
                                        </>
                                    )
                                }) : ""
                        }

                    </div>
                    {/**************************************** SEND THESE INVOICES TO XERO  ********************/}
                    <div className="col-sm-12">
                        <CustomButton
                            buttonClassName="btn btn-sm btn-primary "
                            buttonStyle={{ marginTop: "10px", float: "right" }}
                            buttonOnClick={submitToXero}
                            buttonText="Send to Xero"
                            isActive={true}
                        />
                    </div>
                </div>
            </div >
            {processing ? <LoadingScreen /> : ""}
        </>
    )


}

export default InsuranceInvoices;