import ReactSpeedometer from "react-d3-speedometer/slim"


const defaultCustomSegmentLabels = [
    {
        text: '😡 / Very Bad',
        position: 'INSIDE',
        color: '#555',
    },
    {
        text: '😕 / Bad',
        position: 'INSIDE',
        color: '#555',
    },
    {
        text: '😊',
        position: 'INSIDE',
        color: '#555',
        fontSize: '19px',
    },
    {
        text: '😊 / Good',
        position: 'INSIDE',
        color: '#555',
    },
    {
        text: '😄 / Very Good',
        position: 'INSIDE',
        color: '#555',
    },
];
//
export const SpeedoMeterWithIcons = ({
    width = 300,
    needleHeightRatio = 0.7,
    maxValue = 100,
    value = 50,
    currentValueText = "Speedometer",
    customSegmentLabels = [],
    ringWidth = 47,
    needleTransitionDuration = 3333,
    needleTransition = "easeElastic",
    needleColor = '#90f2ff',
    textColor = '#d8dee9'
}) => {

    return (
        <>
                <ReactSpeedometer
                    width={width}
                    needleHeightRatio={needleHeightRatio}
                    maxValue={maxValue}
                    minValue={0}
                    value={value}
                    currentValueText={currentValueText}
                    segments= {Array.isArray(customSegmentLabels) && customSegmentLabels.length > 0 ? customSegmentLabels.length : defaultCustomSegmentLabels.length}
                    customSegmentLabels = {Array.isArray(customSegmentLabels) && customSegmentLabels.length > 0 ? customSegmentLabels : defaultCustomSegmentLabels}
                    ringWidth={ringWidth}
                    needleTransitionDuration={needleTransitionDuration}
                    needleTransition={needleTransition}
                    needleColor={needleColor}
                    textColor={textColor}
                />
        </>

    );

}