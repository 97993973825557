import axios from './axios';

//GET EQUIFAX FEES
const getEquifaxFees = async (token,equifaxFeesId ) => {

    try {
      const response = await axios.get(`equifaxFees/${equifaxFeesId}`, { headers: { Authorization: "Bearer " + token } });
      return { "error": 0, httpCode: response.status, "message": "",  data: response.data ? response.data.data : {} }
    }
    catch (e) {
      if (e.response) {
        return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
      }
    }
  }
  

  //LIST ALL EQUIFAX FEES   
const listEquifaxFees = async (token) => {
    try {
      const response = await axios.get(`equifaxFees/`, { headers: { Authorization: "Bearer " + token } });
      return { "error": 0, httpCode: response.status, "message": "",  data: response.data ? response.data.data : [] }
    }
    catch (e) {
      if (e.response) {
        return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
      }
    }
  }


  //CREATE EQUIFAX FEES

const createEquifaxFees = async (token,  payload) => {
    try{
        const response = await axios.post(`equifaxFees/`, payload , { headers: { Authorization: "Bearer " + token } });
        return  { "error": 0, "message": response.data["message"] ? response.data["message"] : '', httpCode : response.status, data: response.data.data || {}}
        }
        catch (e) {
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
        }
    }
}

//UPDATE EQUIFAX FEES

const updateEquifaxFees = async (token, equifaxId ,  payload) => {
    try{
        const response = await axios.put(`equifaxFees/${equifaxId}`, payload , { headers : { Authorization: "Bearer " + token } });
        return  { "error": 0, "message": response.data["message"] ? response.data["message"] : '', httpCode : response.status, data: response}
        }
        catch (e) {
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
        }
    }
}
    


export { getEquifaxFees, listEquifaxFees, createEquifaxFees, updateEquifaxFees };