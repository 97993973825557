import axios from '../api/axios';

const getStaffBudget = async(token, id) => {
    try{
        console.log("TOKEN: " + token) ;
        const response = await axios.get(`staffBudget/${id}` , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return response.data ? response.data : {};
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

//GET BUDGET LIST FOR GIVEN DURATION
const getStaffBudgetList = async(token,entityId,  query) => {
    try{
        console.log("TOKEN: " + token) ;
        const response = await axios.get(`staffBudget/listing/${entityId}/?${query}` , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return response.data ? response.data : {};
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}


const createStaffBudget = async(token , StaffBudgetObj) => {
    try{
        const response = await axios.post(`StaffBudget`,  StaffBudgetObj , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0, httpCode: response.status, "message" : "Created Successfully", user:response.data }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data ||{}}
         }
    }
}


const updateStaffBudget = async(token, id, StaffBudgetObj) => {
    try{
        const response = await axios.put(`StaffBudget/${id}`,  StaffBudgetObj , { headers: { Authorization: "Bearer " + token } });
        console.log(response);
        return {"error" : 0, httpCode: response.status, "message" : "Updated Successfully", user: response.data || {} }
    }
    catch(e){
        if (e.response) {
            console.log(e.response.status)
            console.log(e.response.data)
            return {"error" : 1 , "message" : e.response.data["message"] ? e.response.data["message"] : e.response.data }
         }
    }
}

export {getStaffBudget, createStaffBudget, updateStaffBudget, getStaffBudgetList };