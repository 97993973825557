import './App.css';
import { Toaster } from 'react-hot-toast';
import React,{useEffect} from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import LoadTheme from './config/LoadTheme';

import Dashboard from './pages/admin/Dashboard';
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import UpdatePassword from './components/auth/UpdatePassword';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';

import UserLayout from './components/Layouts/UserLayout';
import LenderLayout from './components/Layouts/LenderLayout';
import AuthLayout from './components/Layouts/AuthLayout';
//import DefaultLayout from './components/Layouts/DefaultLayout';

import DefaultComponent from './components/default/DefaultComponent';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import RequireAuth from './components/auth/RequireAuth';
import { useIsAuthenticated, useEntityType, useToken } from './hooks/useAuth';

/** APPLICATIONS ROUTES **/
import ConsumerApplication from './modules/consumerApplication/ConsumerApplication';
import ListApplications from './components/applications/ListApplications';
import ApplicationFiles from './components/applications/ApplicationFiles';


/* CREDIT REP ROUTES */
import ListCreditReps from './components/creditReps/ListCreditReps';
import CreditRepDetail from './components/creditReps/CreditRepDetail';
import EditCreditRep from './components/creditReps/EditCreditRep';
import CreateCreditRep from './components/creditReps/CreateCreditRep';
import CreditRepRCTI from './components/creditReps/CreditRepRCTI';

/* LENDERS ROUTES*/
import ListLenders from './components/lenders/ListLenders';
import LenderDetail from './components/lenders/LenderDetail';
import EditLender from './components/lenders/EditLender';
import AddLender from './components/lenders/AddLender';
import LenderInvoices from './components/lenders/LenderInvoices';

import AddUser from './components/users/AddUser';
import UpdateUser from './components/users/UpdateUser';

/** REFERRER GROUPS ROUTES */
import ListReferrerGroups from './components/referrerGroups/ListReferrerGroups';
import CreateReferrerGroup from './components/referrerGroups/CreateReferrerGroup';
import ReferrerGroupDetail from './components/referrerGroups/ReferrerGroupDetail';
import EditReferrerGroup from './components/referrerGroups/EditReferrerGroup';
import ReferrerGroupRCTI from './components/referrerGroups/ReferrerGroupRCTI';
import ReferrerGroupCommissionSummary from './components/referrerGroups/ReferrerGroupCommissionSummary';
import ReferrerGroupInvoices from './components/referrerGroups/ReferrerGroupInvoices';

/*****  REFERRER GROUP MANAGER ******/
import ListReferrerGroupManagers from './components/referrerGroupManagers/ListReferrerGroupManagers';

/*** REFERRER ROUTES*****/
import ListReferrers from './components/referrers/ListReferrers';
import CreateReferrer from './components/referrers/CreateReferrer';
import ReferrerDetail from './components/referrers/ReferrerDetail';
import EditReferrer from './components/referrers/EditReferrer';
import ReferrerRCTI from './components/referrers/ReferrerRCTI';
import ReferrerPaymentNotification from './components/referrers/ReferrerPaymentNotification';
import GiftCardRecipients from './components/referrers/GiftCardRecipients';
import ReferrerSignup from './components/referrers/ReferrerSignup';
import ReferrerAccreditationForm from './components/referrers/ReferrerAccreditationForm';
import ReferrerInvoices from './components/referrers/ReferrerInvoices';

/** INSURERS **/
import InsuranceCompanies from './components/insuranceCompanies/InsuranceCompanies';
import { useSelector } from "react-redux";

/** SETTLEMENTS */
import ListSettlements from './components/settlements/ListSettlements';
import ListReinstatedSettlements from './components/settlements/ListReinstatedSettlements';
import EditSettlement from './components/settlements/EditSettlement';
import CreateSettlement from './components/settlements/CreateSettlement';

/** DECLINED **/
import ListDeclined from './components/declined/ListDeclined';
import EditDeclined from './components/declined/EditDeclined';
import CreateDeclined from './components/declined/CreateDeclined';
import DueFollowUp from './components/declined/DueFollowUp';


/** UTILITIES   **/
/** BANK STATEMENT LINKS **/
import ListBankStatementLinks from './components/bankStatementLinks/ListBankStatementLinks';
import GeneralFiles from './components/files/GeneralFiles';
import Edm from './components/files/Edm';
import ListOfficeContacts from './components/officeContacts/ListOfficeContacts';
import GiftCardRecipientsText from './components/referrers/GiftCardRecipientsText';
import ListStaffBudget from './components/staffBudget/ListStaffBudget';
import Crm from './components/crm/Crm';
import ListEmailTemplates from './components/emailTemplates/ListEmailTemplates';

//INSURANCES
import InsuranceInvoices from './components/insurance/InsuranceInvoices';

//USERS
import ListUsers from './components/users/ListUsers';


//REPORTING
import AnalystReporting from './components/reporting/analyst/AnalystReporting';
import AnalystReportingV2 from './components/reporting/analyst/AnalystReportingV2';
import AdminReporting from './components/reporting/admin/AdminReporting';
import AdminReportingV2 from './components/reporting/admin/AdminReportingV2';
import SuperAdminReporting from './components/reporting/SuperAdminReporting';
import ReferrerGroupReporting from './components/reporting/referrerGroup/ReferrerGroupReporting';

//REGISTERS
import ListComplaints from './components/register/complaints/ListComplaints';
import ListConflictOfInterests from './components/register/conflictOfInterests/ListConflictOfInterests';
import ListCpd from './components/register/cpd/ListCpd';

//EQUIFAX FEES
import ListEquifaxFees from './components/equifaxFees/ListEquifaxFees';

const ProtectedRoutes = () => {
  const location = useLocation();
  const token = useToken();
  let firstTimePasswordChanged = localStorage.getItem("firstTimePasswordChanged");
  if (firstTimePasswordChanged == '0') {
    return <Navigate to="/updatePassword/" state={{ from: location }} replace />;
  }
  else {
    return token == null ? <Navigate to="/login/" state={{ from: location }} replace /> : <Outlet />;
  }
}

function App() {

  const userValue = useSelector((state) => state.auth.user);
  const entityType = useEntityType();
  //CEHCK LOACLSTORAGE FOR SESSION VALIDITY 
  LoadTheme();

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

          {<Route path="/" element={<DefaultComponent />}></Route>}

          {/* AUTH PAGES  */}
          <Route path="/" element={<AuthLayout />}>
            <Route path="login" element={<Login />}></Route>
            <Route path="logout" element={<Logout />}></Route>
            <Route path="referrerSignup" element={<ReferrerSignup />}></Route>
            <Route path="referrerAccreditationForm" element={<ReferrerAccreditationForm />}></Route>
            <Route path="referrerAccreditationForm/:referrerName" element={<ReferrerAccreditationForm />}></Route>
            <Route path="forgotPassword" element={<ForgotPassword />}></Route>
            <Route path="resetPassword" element={<ResetPassword />}></Route>

          </Route>

          <Route path="/" element={<UserLayout />}>
            <Route path="updatePassword" element={<UpdatePassword />}></Route>
          </Route>

          {/* DEFAULT  */}
          <Route path="/" element={<ProtectedRoutes />}>

            { /*<Route path="/" element={<DefaultComponent />}></Route> */}

            {/*ROUTES REQUIRE AUTHENTICATION */}
            <Route path="/user/*" element={<UserLayout />}>

              {userValue.role === "superAdmin" &&
                <Route element={<RequireAuth allowedRoles={['superAdmin']} />}>
                  <Route index element={<Dashboard />}></Route>

                  {/** SUPER-ADMIN REPORTING */}
                  <Route index element={<SuperAdminReporting />}></Route>
                  <Route path="dashboard" element={<SuperAdminReporting />}></Route>

                  { /*APPLICATIONS */}
                  <Route path="consumerApplication" element={<ConsumerApplication />}></Route>
                  <Route path="editConsumerApplication/:id" element={<ConsumerApplication />}></Route>
                  <Route path="listApplications" element={<ListApplications />}></Route>
                  <Route path="listApplications/:type" element={<ListApplications />}></Route>
                  <Route path="applicationFiles/:id" element={<ApplicationFiles />}></Route>

                  { /*INSURANCE COMPANIES   InsuranceCompanies */}
                  <Route path="insurers" element={<InsuranceCompanies />}></Route>

                  { /**  CREDIT REPS  **/}
                  <Route path="listCreditReps" element={<ListCreditReps />}></Route>
                  <Route path="creditRepDetail/:id" element={<CreditRepDetail />}></Route>
                  <Route path="editCreditRep/:id" element={<EditCreditRep />}></Route>
                  <Route path="createCreditRep" element={<CreateCreditRep />}></Route>

                  {/**  USERS */}
                  <Route path="addUser/:entityType/:entityId" element={<AddUser />}></Route>
                  <Route path="updateUser/:entityType/:userId" element={<UpdateUser />}></Route>

                  { /*LENDER ROUTES*/}
                  <Route path="listLenders" element={<ListLenders />}></Route>
                  <Route path="lenderDetail/:id" element={<LenderDetail />}></Route>
                  <Route path="editLender/:id" element={<EditLender />}></Route>
                  <Route path="addLender" element={<AddLender />}></Route>

                  { /*** SETTLEMENTS ****/}
                  <Route path="settlements" element={<ListSettlements />}></Route>
                  <Route path="settlement/:id" element={<EditSettlement />}></Route>
                  <Route path="createSettlement/" element={<CreateSettlement />}></Route>

                  { /* Declined */}
                  <Route path="declined" element={<ListDeclined />}></Route>
                  <Route path="editDeclined/:id" element={<EditDeclined />}></Route>
                  <Route path="createDeclined/:id" element={<CreateDeclined />}></Route>
                  <Route path="dueFollowUp" element={<DueFollowUp />}></Route>

                  { /* REFERER GROUP  */}
                  <Route path="createReferrerGroup" element={<CreateReferrerGroup />}></Route>
                  <Route path="editReferrerGroup/:id" element={<EditReferrerGroup />}></Route>
                  <Route path="referrerGroupDetail/:id" element={<ReferrerGroupDetail />}></Route>
                  <Route path="listReferrerGroups" element={<ListReferrerGroups />}></Route>

                  {/** REFERRER GROUP MANAGERS */}
                  <Route path="listReferrerGroupManagers" element={<ListReferrerGroupManagers />}></Route>

                  { /* REFERRER */}
                  <Route path="listReferrers" element={<ListReferrers status="active" />}></Route>
                  <Route path="archivedReferrers" element={<ListReferrers status="inactive" />}></Route>
                  <Route path="referrerDetail/:id" element={<ReferrerDetail />}></Route>
                  <Route path="editReferrer/:id" element={<EditReferrer />}></Route>

                  {/** UTILITIES */}
                  <Route path="creditRepRCTI" element={<CreditRepRCTI />}></Route>
                  <Route path="referrerGroupRCTI" element={<ReferrerGroupRCTI />}></Route>
                  <Route path="referrerRCTI" element={<ReferrerRCTI />}></Route>
                  <Route path="referrerPaymentNotification" element={<ReferrerPaymentNotification />}></Route>
                  <Route path="giftCardRecipients" element={<GiftCardRecipients />}></Route>
                  <Route path="referrerGroupCommissionSummary" element={<ReferrerGroupCommissionSummary />}></Route>
                  <Route path="listOfficeContacts" element={<ListOfficeContacts />}></Route>
                  <Route path="complaintRegister" element={<ListComplaints />}></Route>
                  <Route path="conflictOfInterestRegister" element={<ListConflictOfInterests />}></Route>
                  <Route path="cpd" element={<ListCpd />}></Route>
                  <Route path="emailTemplates" element={<ListEmailTemplates />}></Route>
                  <Route path="equifaxFees" element={<ListEquifaxFees />}></Route>


                </Route>
              }

              { /****************** FINANCE ***************************/}

              {userValue.role === "finance" &&
                <Route element={<RequireAuth allowedRoles={['finance']} />}>
                  <Route index element={<Dashboard />}></Route>

                  { /*APPLICATIONS */}
                  <Route path="consumerApplication" element={<ConsumerApplication />}></Route>
                  <Route path="editConsumerApplication/:id" element={<ConsumerApplication />}></Route>
                  <Route path="listApplications" element={<ListApplications />}></Route>
                  <Route path="listApplications/:type" element={<ListApplications />}></Route>
                  <Route path="applicationFiles/:id" element={<ApplicationFiles />}></Route>

                  { /*INSURANCE COMPANIES   InsuranceCompanies */}
                  <Route path="insurers" element={<InsuranceCompanies />}></Route>

                  { /**  CREDIT REPS  **/}
                  <Route path="listCreditReps" element={<ListCreditReps />}></Route>
                  <Route path="creditRepDetail/:id" element={<CreditRepDetail />}></Route>

                  {/**  USERS */}
                  <Route path="addUser/:entityType/:entityId" element={<AddUser />}></Route>
                  <Route path="updateUser/:entityType/:userId" element={<UpdateUser />}></Route>

                  { /*LENDER ROUTES*/}
                  <Route path="listLenders" element={<ListLenders />}></Route>
                  <Route path="lenderDetail/:id" element={<LenderDetail />}></Route>
                  <Route path="editLender/:id" element={<EditLender />}></Route>

                  { /*** SETTLEMENTS ****/}
                  <Route path="settlements" element={<ListSettlements />}></Route>
                  <Route path="listReinstatedSettlements" element={<ListReinstatedSettlements />}></Route>
                  <Route path="settlement/:id" element={<EditSettlement />}></Route>
                  <Route path="createSettlement/" element={<CreateSettlement />}></Route>

                  { /* Declined */}
                  <Route path="declined" element={<ListDeclined />}></Route>
                  <Route path="editDeclined/:id" element={<EditDeclined />}></Route>
                  <Route path="createDeclined/:id" element={<CreateDeclined />}></Route>
                  <Route path="dueFollowUp" element={<DueFollowUp />}></Route>

                  { /* REFERER GROUP  */}
                  <Route path="referrerGroupDetail/:id" element={<ReferrerGroupDetail />}></Route>
                  <Route path="listReferrerGroups" element={<ListReferrerGroups />}></Route>

                  { /* REFERRER */}
                  <Route path="listReferrers" element={<ListReferrers status="active" />}></Route>
                  <Route path="referrerDetail/:id" element={<ReferrerDetail />}></Route>
                  <Route path="editReferrer/:id" element={<EditReferrer />}></Route>

                  {/** UTILITIES */}
                  <Route path="creditRepRCTI" element={<CreditRepRCTI />}></Route>
                  <Route path="referrerGroupRCTI" element={<ReferrerGroupRCTI />}></Route>
                  <Route path="referrerRCTI" element={<ReferrerRCTI />}></Route>
                  <Route path="referrerPaymentNotification" element={<ReferrerPaymentNotification />}></Route>
                  <Route path="giftCardRecipients" element={<GiftCardRecipients />}></Route>
                  <Route path="referrerGroupCommissionSummary" element={<ReferrerGroupCommissionSummary />}></Route>
                  <Route path="listOfficeContacts" element={<ListOfficeContacts />}></Route>
                  <Route path="lendersXero" element={<LenderInvoices />}></Route>
                  <Route path="referrerXero" element={<ReferrerInvoices />}></Route>
                  <Route path="referrerGroupXero" element={<ReferrerGroupInvoices />}></Route>
                  <Route path="InsuranceInvoices" element={<InsuranceInvoices />}></Route>

                </Route>
              }

              { /****************** AUDITOR ***************************/}

              {userValue.role === "auditor" &&
                <Route element={<RequireAuth allowedRoles={['auditor']} />}>
                  <Route index element={<Dashboard />}></Route>

                  { /*APPLICATIONS */}
                  <Route path="consumerApplication" element={<ConsumerApplication />}></Route>
                  <Route path="editConsumerApplication/:id" element={<ConsumerApplication />}></Route>
                  <Route path="listApplications" element={<ListApplications />}></Route>
                  <Route path="listApplications/:type" element={<ListApplications />}></Route>
                  <Route path="applicationFiles/:id" element={<ApplicationFiles />}></Route>


                  { /*** SETTLEMENTS ****/}
                  <Route path="settlements" element={<ListSettlements />}></Route>

                </Route>
              }

              { /****************** ADMIN ***************************/}

              {userValue.role === "admin" &&
                <Route element={<RequireAuth allowedRoles={['admin']} />}>
                  <Route index element={<Dashboard />}></Route>

                  {/** ADMIN REPORTING */}
                  <Route index element={<AdminReporting />}></Route>
                  <Route path="dashboard" element={<AdminReporting />}></Route>
                  <Route path="dashboardV2" element={<AdminReportingV2 />}></Route>

                  { /*APPLICATIONS */}
                  <Route path="consumerApplication" element={<ConsumerApplication />}></Route>
                  <Route path="editConsumerApplication/:id" element={<ConsumerApplication />}></Route>
                  <Route path="listApplications" element={<ListApplications />}></Route>
                  <Route path="listApplications/:type" element={<ListApplications />}></Route>
                  <Route path="applicationFiles/:id" element={<ApplicationFiles />}></Route>

                  { /*LENDERS */}
                  <Route path="listLenders" element={<ListLenders />}></Route>
                  <Route path="lenderDetail/:id" element={<LenderDetail />}></Route>
                  <Route path="editLender/:id" element={<EditLender />}></Route>

                  { /* REFERER GROUP  */}
                  <Route path="createReferrerGroup" element={<CreateReferrerGroup />}></Route>
                  <Route path="referrerGroupDetail/:id" element={<ReferrerGroupDetail />}></Route>
                  <Route path="editReferrerGroup/:id" element={<EditReferrerGroup />}></Route>
                  <Route path="listReferrerGroups" element={<ListReferrerGroups />}></Route>

                  { /* REFERRER */}
                  <Route path="listReferrers" element={<ListReferrers status="active" />}></Route>
                  <Route path="archivedReferrers" element={<ListReferrers status="inactive" />}></Route>
                  <Route path="CreateReferrer" element={<CreateReferrer />} ></Route>
                  <Route path="referrerDetail/:id" element={<ReferrerDetail />}></Route>
                  <Route path="editReferrer/:id" element={<EditReferrer />}></Route>

                  { /*INSURANCE COMPANIES   InsuranceCompanies */}
                  <Route path="insurers" element={<InsuranceCompanies />}></Route>

                  { /*** SETTLEMENTS ****/}
                  <Route path="settlements" element={<ListSettlements />}></Route>
                  <Route path="listReinstatedSettlements" element={<ListReinstatedSettlements />}></Route>

                  { /* Declined */}
                  <Route path="declined" element={<ListDeclined />}></Route>
                  <Route path="createDeclined/:id" element={<CreateDeclined />}></Route>
                  <Route path="editDeclined/:id" element={<EditDeclined />}></Route>
                  <Route path="dueFollowUp" element={<DueFollowUp />}></Route>



                  {/** UTILITIES */}
                  <Route path="referrerGroupRCTI" element={<ReferrerGroupRCTI />}></Route>
                  <Route path="referrerRCTI" element={<ReferrerRCTI />}></Route>
                  <Route path="referrerPaymentNotification" element={<ReferrerPaymentNotification />}></Route>
                  <Route path="giftCardRecipients" element={<GiftCardRecipients />}></Route>
                  <Route path="giftCardRecipientsText" element={<GiftCardRecipientsText />}></Route>
                  <Route path="listBankStatementLinks" element={<ListBankStatementLinks />}></Route>
                  <Route path="generalFiles" element={<GeneralFiles />}></Route>
                  <Route path="edm" element={<Edm />}></Route>
                  <Route path="listOfficeContacts" element={<ListOfficeContacts />}></Route>
                  <Route path="staffBudget" element={<ListStaffBudget />}></Route>
                  <Route path="crm" element={<Crm />}></Route>
                  <Route path="cpd" element={<ListCpd />}></Route>
                  <Route path="emailTemplates" element={<ListEmailTemplates />}></Route>
                </Route>
              }

              { /****************** ANALYST ***************************/}

              {userValue.role === "analyst" &&
                <Route element={<RequireAuth allowedRoles={['analyst']} />}>

                  {/** ANALYST REPORTING */}
                  <Route index element={<AnalystReporting />}></Route>
                  <Route path="dashboard" element={<AnalystReporting />}></Route>
                  <Route path="dashboardV2" element={<AnalystReportingV2 />}></Route>

                  { /*APPLICATIONS */}
                  <Route path="consumerApplication" element={<ConsumerApplication />}></Route>
                  <Route path="editConsumerApplication/:id" element={<ConsumerApplication />}></Route>
                  <Route path="listApplications" element={<ListApplications />}></Route>
                  <Route path="listApplications/:type" element={<ListApplications />}></Route>
                  <Route path="applicationFiles/:id" element={<ApplicationFiles />}></Route>

                  { /*LENDERS */}
                  <Route path="listLenders" element={<ListLenders />}></Route>
                  <Route path="lenderDetail/:id" element={<LenderDetail />}></Route>

                  { /* REFERER GROUP  */}


                  { /* REFERRER */}
                  <Route path="listReferrers" element={<ListReferrers status="active" />}></Route>
                  <Route path="referrerDetail/:id" element={<ReferrerDetail />}></Route>

                  { /*INSURANCE COMPANIES   InsuranceCompanies */}
                  <Route path="insurers" element={<InsuranceCompanies />}></Route>

                  { /*** SETTLEMENTS ****/}
                  <Route path="settlements" element={<ListSettlements />}></Route>

                  { /* Declined */}
                  <Route path="declined" element={<ListDeclined />}></Route>
                  <Route path="dueFollowUp" element={<DueFollowUp />}></Route>

                  {/** UTILITIES */}
                  <Route path="referrerGroupRCTI" element={<ReferrerGroupRCTI />}></Route>
                  <Route path="referrerRCTI" element={<ReferrerRCTI />}></Route>
                  <Route path="giftCardRecipients" element={<GiftCardRecipients />}></Route>
                  <Route path="listBankStatementLinks" element={<ListBankStatementLinks />}></Route>
                  <Route path="generalFiles" element={<GeneralFiles />}></Route>
                  <Route path="listOfficeContacts" element={<ListOfficeContacts />}></Route>
                  <Route path="cpd" element={<ListCpd />}></Route>

                  {/********USERS ************/}
                  <Route path="listUsers" element={<ListUsers />}></Route>


                </Route>
              }

              { /**************  REFERRER GROUP  **************/}
              {['user'].includes(userValue.role) && entityType === "referrerGroup" &&
                <Route element={<RequireAuth allowedRoles={['user']} />}>
                  <Route path="dashboard" element={<ReferrerGroupReporting />}></Route>

                  { /*APPLICATIONS */}
                  <Route path="consumerApplication" element={<ConsumerApplication />}></Route>
                  <Route path="editConsumerApplication/:id" element={<ConsumerApplication />}></Route>
                  <Route path="listApplications" element={<ListApplications />}></Route>
                  <Route path="applicationFiles/:id" element={<ApplicationFiles />}></Route>

                  { /* REFERRER */}
                  <Route path="listReferrers" element={<ListReferrers status="active" />}></Route>
                  <Route path="referrerDetail/:id" element={<ReferrerDetail />}></Route>
                  <Route path="editReferrer/:id" element={<EditReferrer />}></Route>

                  { /*** SETTLEMENTS ****/}
                  <Route path="settlements" element={<ListSettlements />}></Route>

                  { /* Declined */}
                  <Route path="declined" element={<ListDeclined />}></Route>

                  {/** UTILITIES */}
                  <Route path="referrerRCTI" element={<ReferrerRCTI />}></Route>
                  <Route path="cpd" element={<ListCpd />}></Route>

                </Route>
              }

              { /********************* REFERRER **************************/}
              {['user'].includes(userValue.role) && entityType === "referrer" &&
                <Route element={<RequireAuth allowedRoles={['user']} />}>

                  { /*APPLICATIONS */}
                  <Route path="listApplications" element={<ListApplications />}></Route>

                  { /*** SETTLEMENTS ****/}
                  <Route path="settlements" element={<ListSettlements />}></Route>

                  { /* Declined */}
                  <Route path="declined" element={<ListDeclined />}></Route>

                  {/** UTILITIES */}
                  <Route path="referrerRCTI" element={<ReferrerRCTI />}></Route>

                </Route>
              }

              { /****************** REFERRER GROUP MANAGER *************************/}

              {userValue.role === "referrerGroupManager" &&
                <Route element={<RequireAuth allowedRoles={['referrerGroupManager']} />}>
                  <Route index element={<Dashboard />}></Route>

                  { /*APPLICATIONS */}
                  <Route path="consumerApplication" element={<ConsumerApplication />}></Route>
                  <Route path="editConsumerApplication/:id" element={<ConsumerApplication />}></Route>
                  <Route path="listApplications" element={<ListApplications />}></Route>
                  <Route path="listApplications/:type" element={<ListApplications />}></Route>
                  <Route path="applicationFiles/:id" element={<ApplicationFiles />}></Route>

                  { /* REFERER GROUP  */}
                  <Route path="createReferrerGroup" element={<CreateReferrerGroup />}></Route>
                  <Route path="referrerGroupDetail/:id" element={<ReferrerGroupDetail />}></Route>
                  <Route path="editReferrerGroup/:id" element={<EditReferrerGroup />}></Route>
                  <Route path="listReferrerGroups" element={<ListReferrerGroups />}></Route>

                  { /* REFERRER */}
                  <Route path="listReferrers" element={<ListReferrers status="active" />}></Route>
                  <Route path="archivedReferrers" element={<ListReferrers status="inactive" />}></Route>
                  <Route path="CreateReferrer" element={<CreateReferrer />} ></Route>
                  <Route path="referrerDetail/:id" element={<ReferrerDetail />}></Route>
                  <Route path="editReferrer/:id" element={<EditReferrer />}></Route>

                  { /*** SETTLEMENTS ****/}
                  <Route path="settlements" element={<ListSettlements />}></Route>

                  { /* Declined */}
                  <Route path="declined" element={<ListDeclined />}></Route>

                  { /*LENDER ROUTES*/}
                  <Route path="listLenders" element={<ListLenders />}></Route>
                  <Route path="lenderDetail/:id" element={<LenderDetail />}></Route>
                  
                  {/** UTILITIES */}
                  <Route path="referrerGroupRCTI" element={<ReferrerGroupRCTI />}></Route>
                  <Route path="referrerRCTI" element={<ReferrerRCTI />}></Route>
                  
                </Route>
              }


            </Route>

            {/*********LENDER ROUTE HERE - BELOW */}
            <Route path='/lender/*' element={<LenderLayout />}>
              {
                ['user'].includes(userValue.role) && entityType === "lender" &&
                <Route element={<RequireAuth allowedRoles={['user']} />}>
                  { /*LENDER*/}
                  <Route path="lenderDetail" element={<LenderDetail />}></Route>
                  <Route path="editLender" element={<EditLender />}></Route>
                </Route>
              }
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>

      <Toaster />  { /** FOR NOTIFICATIONS **/}
    </div>

  );
}

export default App;



/*



           

            */