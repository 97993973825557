import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useToken, useEntityId, useEntityType } from '../../../hooks/useAuth';
import { Formik, Form, Field } from 'formik';
import { createBankStatementLink } from '../../../api';
import { FormSubmissionButton, TextEditor, CheckBox, FloatField, SelectField, Modal } from '../../HtmlComponents';
import LoadingScreen from '../../global/LoadingScreen';
import Notifications from '../../../components/global/Notifications';
import BankStatementLinkValidaitonSchema from '../schema/BankStatementLinkValidaitonSchema';
import useCreditReps from '../../../hooks/useCreditReps';


const CreateBankStatementLink = ({callback}) => {
    const [bankStatementLink, setBankStatementLink] = useState({ subject: "", message: "", duration: 0 });
    const [processing, setProcessing] = useState(false);
    const { selectedCreditRepId, CreditRepsDropdown  } = useCreditReps();

    const token = useToken();
    const entityType = useEntityType();
    const entityId = useEntityId();



    return (
        <>
            <Formik
                initialValues={setBankStatementLink}
                validationSchema={BankStatementLinkValidaitonSchema}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    // same shape as initial values
                    values["creditRepId"] = entityType === "company" ? selectedCreditRepId : entityId;

                    setProcessing(true);
                    console.log("values ready for submission ::", values);

                    var response = await createBankStatementLink(token, values)
                    if (response["error"]) {
                        Notifications("error", response["message"] || "Error")
                    }
                    else {
                        Notifications("success", response["message"] || "Created Successfully");
                        callback("refreshListing")
                    }
                    
                    setProcessing(false);
                }}
            >
                {({ errors, touched, setFieldValue }) => (
                    <Form>
                        <div id="kt_content_container" className="container-xxl">
                            <div className="row g-5 g-xl-8">

                                <div className="row mt-3">
                                    <div className='col-sm-12 mt-7'>
                                        {/**LOAD CREDIT REPS IN A DROPDOWN  */}              
                                        { entityType === "company" ?   <><label className="required fs-8 fw-bold mb-2">CreditRep</label><CreditRepsDropdown /></> : "" }
                                    </div>

                                    <div className="col-sm-12 mt-7">
                                        <label className="required fs-8 fw-bold mb-2">Subject</label>
                                        <Field name="subject" placeholder="" className="form-control " style={{ height: "35px", fontWeight: "400" }} />
                                        <div className="error">{errors.subject ? errors.subject : ""}</div>
                                    </div>

                                    <div className="col-sm-12 mt-7">
                                        <label className="required fs-8 fw-bold mb-2">Duration</label>
                                        <Field name="duration" placeholder="" className="form-control " style={{ height: "35px", fontWeight: "400" }} />
                                        <div className="error">{errors.duration ? errors.duration : ""}</div>
                                    </div>

                                    <div className="col-sm-12 mt-5">
                                        <label className="required fs-8 fw-bold mb-2">Email Message</label>
                                        <div className="bg-body text-start shadow-sm card-rounded mb-2 px-6 py-9 position-relative z-index-1" >
                                            <TextEditor fieldName="message" initialValue={""} height={200} setFieldValue={setFieldValue} />
                                        </div>
                                        <div className="error">{errors.message ? errors.message : ""}</div>
                                    </div>



                                </div>
                                <div className="col-xl-12">{JSON.stringify(errors).length > 2 ? <div className='error' style={{ float: "right" }}>Please fix the above errors first. </div> : ""}</div>
                                <div className="col-xl-12"> {<FormSubmissionButton isActive={JSON.stringify(errors).length < 3 ? true : false} />}</div>

                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>

    )
}

export default CreateBankStatementLink;