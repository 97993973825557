
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useRole, useToken, useEntityType, useEntityId } from '../../hooks/useAuth';
import { EditButton, AddButton, CustomButton, RightDrawer } from '../HtmlComponents';
import SearchBySingleDate from '../global/SearchForms/SearchBySingleDate';
import { listAllLenderInvocies, submitInvociesToXero } from '../../api';
import LoadingScreen from '../global/LoadingScreen';
import Notifications from '../../components/global/Notifications';

const LenderInvoices = () => {
    const [invoicesPackage, setInvoicesPackage] = useState([]); //collection of lenders and each lender will contain his own invoices
    const [date, setDate] = useState({year: "", month: ""})
    const [processing, setProcessing] = useState(false);
    const token = useToken();


    //LOAD THE DEFAULT DATE AND THEN CALL TO LOAD INVOICES
    useEffect(() => {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1; 
        month = month < 10 ? "0" + month : month;
        setDate({year: year, month: month});  //UPDATE DATE STATE

        fetchLenderInvoices({year: year, month: month});  //FETCH THE INVOICES
    }, []);

    const updateHandler = (name, value) => {
        switch (name) {
            case "dateFilter":
                fetchLenderInvoices(value);
                break;
        }
    }

    const fetchLenderInvoices = async (searchPayload) => {
        var year = searchPayload["year"];
        var month = searchPayload["month"];
        setDate({year: year, month: month});
        var response = await listAllLenderInvocies(token, year, month);
        if (response["error"] == 0) {
            setInvoicesPackage(response["data"]);
        }
    }

    const submitToXero = async() => {
        var year = date["year"];
        var month = date["month"];
        //SHOW LAODING SCREEN
        setProcessing(true);
        var response = await submitInvociesToXero(token, year, month);
        if(response["error"] !== undefined && response["error"] == 0){
            var message = response["data"] !== undefined && response["data"]["message"] !== undefined ? response["data"]["message"]: "";
            var errorMessages = response["data"] !== undefined && response["data"]["errorMessages"] !== undefined ? response["data"]["errorMessages"]: "";
            fetchLenderInvoices({year: date["year"], month: date["month"]});
            Notifications("success", message);
            console.log("errorMessages:: ",errorMessages );
        }
        else{
            var message = response["data"] !== undefined && response["data"]["message"] !== undefined ? response["data"]["message"]: "";
            var errorMessages = response["data"] !== undefined && response["data"]["errorMessages"] !== undefined ? response["data"]["errorMessages"]: "";
            Notifications("error", message);
            console.log("errorMessages:: ",errorMessages );
        }
        setProcessing(false);
    }


    return (
        <>
            <div id="kt_content_container" className="container-xxl">
                <div className="row g-5 g-xl-8">
                    <SearchBySingleDate callback={updateHandler} defaultMonth={date["month"]} defaultYear={ date["year"]} />
                    <div className="col-sm-12">
                        <div className="row">
                            {
                                invoicesPackage["lenders"] !== undefined ?
                                    Object.keys(invoicesPackage["lenders"]).map(index => {
                                        return (
                                            <>
                                                <div className="col-sm-12 xeroHeading1"  >
                                                    <span>Name: {invoicesPackage["lenders"][index]["name"]} </span> <span style={{ float: "right", paddingRight: "20px" }}>  Xero Contact Id:  {invoicesPackage["lenders"][index]["xeroContactId"]} </span>
                                                </div>
                                                {
                                                    invoicesPackage["lenders"][index]["records"] !== undefined && Array.isArray(invoicesPackage["lenders"][index]["records"]) ?
                                                        invoicesPackage["lenders"][index]["records"].map(singleCustomer => {
                                                            return (
                                                                <>
                                                                    {
                                                                        singleCustomer != undefined && singleCustomer.reference !== undefined ?
                                                                            <>
                                                                                <div className="col-sm-12 xeroSubHeading2"> <span> Reference : {singleCustomer.reference} </span> <span style={{ float: "right", paddingRight: "20px" }}>  Amount </span></div>
                                                                            </>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        singleCustomer != undefined && singleCustomer.invoices !== undefined && Array.isArray(singleCustomer.invoices) ?
                                                                            singleCustomer.invoices.map(singleInvoice => {
                                                                                return (
                                                                                    <>
                                                                                        <div className="col-sm-12 xeroInvoice">
                                                                                            <span>{singleInvoice["applicantName"]} ({singleInvoice["reference"]})</span>
                                                                                            <span style={{ float: "right", paddingRight: "20px" }}>{singleInvoice["amount"]}</span>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                            : ""
                                                                    }


                                                                </>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </>
                                        )
                                    })
                                    : ""
                            }
                        </div>
                    </div>


                    { /****************************************FINTELLIGENCE******************************* */}
                    <div className="col-sm-12">
                        <div className="row">

                            {
                                invoicesPackage["fintelligence"] !== undefined ?
                                    <>
                                        <div className="col-sm-12 xeroHeading1"  >
                                            <span>Name: Fintelligence </span> <span style={{ float: "right", paddingRight: "20px" }}>  Xero Contact Id:  {invoicesPackage["fintelligence"]["xeroContactId"]} </span>
                                        </div>
                                        <div className="col-sm-12 xeroSubHeading2"> <span> Reference </span> <span style={{ float: "right", paddingRight: "20px" }}>  Amount </span></div>
                                    </> : ""
                            }
                            {

                                invoicesPackage["fintelligence"] !== undefined && invoicesPackage["fintelligence"]["invoices"] !== undefined && Array.isArray(invoicesPackage["fintelligence"]["invoices"]) ?
                                    invoicesPackage["fintelligence"]["invoices"].map(singleinvoice => {
                                        return (<>
                                            <div className="col-sm-12 xeroInvoice">
                                                <span>{singleinvoice["reference"]}</span>
                                                <span style={{ float: "right", paddingRight: "20px" }}>{singleinvoice["amount"]}</span>
                                            </div>
                                        </>)
                                    })
                                    : ""
                            }
                        </div>
                    </div>

                    { /****************************************PENDING - THAT CAN NOT BE SETTLED DUE TO MISSING INFO******************************* */}
                    <div className="col-sm-12">
                        <div className="row">
                            {
                                invoicesPackage["pending"] !== undefined && invoicesPackage["pending"]["invoices"] !== undefined && Array.isArray(invoicesPackage["pending"]["invoices"]) && invoicesPackage["pending"]["invoices"].length > 0 ?
                                    <>
                                        <div className="col-sm-12 xeroHeading1"  > <span>PENDING: CANNOT BE SENT TO XERO DUE TO MISSING XERO INFO </span> </div>
                                        <div className="col-sm-12 xeroSubHeading2"> <span> Reference </span> <span style={{ float: "right", paddingRight: "20px" }}>  Amount </span></div>
                                    </> : ""
                            }
                            {

                                invoicesPackage["pending"] !== undefined && invoicesPackage["pending"]["invoices"] !== undefined && Array.isArray(invoicesPackage["pending"]["invoices"]) ?
                                    invoicesPackage["pending"]["invoices"].map(singleInvoice => {
                                        return (<>
                                            <div className="col-sm-12 xeroInvoice">
                                                <span>{singleInvoice["applicantName"]} ({singleInvoice["reference"]})</span>
                                                <span style={{ float: "right", paddingRight: "20px" }}>{singleInvoice["amount"]}</span>
                                            </div>
                                        </>)
                                    })
                                    : ""
                            }
                        </div>
                    </div>

                    {/**************************************** SEND THESE INVOICES TO XERO  ********************/}
                    <div className="col-sm-12">
                        <CustomButton
                            buttonClassName="btn btn-sm btn-primary "
                            buttonStyle={{ marginTop: "10px", float: "right" }}
                            buttonOnClick={submitToXero }
                            buttonText="Send to Xero"
                            isActive={true}
                        />
                    </div>      

                </div>
            </div >

            {processing ? <LoadingScreen /> :  "" }
        </>
    )


}

export default LenderInvoices;