import moment from "moment";

/*
replaceNullWithString
getCurrentDate
formatDate
convertDateintoUnix
getCurrentMonth
getPreviousMonth
getLastDayOfMonth
dateFilter
calculateTimeDifference
countMonthDays
getPastDateTime
getStartAndEndTimeForGivenPeriod
removeSpace
capitalizeFirst
capitalizeFirstLowercaseRest
capitalizeFirstOfEachWord
getdayPeriodType  //morning, afternoon, evening
getUniqueId
calculateMonthlyAmount
isValidDate
isEmailValid
*/



//LOOP AND CHECK IF ANY VALUE IS NULL THEN REPLACE IT WITH SINGLE QUOTE BCOZ FORMIK DOES NOT ACCEPT NULL VALUE
export const replaceNullWithString = async (obj) => {
  //CASE 1 - > PROVIDED PARAM IS OBJECT
  //if(typeof obj === null){console.log("NULL-----------"); }
  if (typeof obj === "object") {  //typeof obj !== null && 
    await Object.keys(obj).map(function (key) {
      if (obj[key] !== null && typeof obj[key] === "object") { replaceNullWithString(obj[key]); }
      else { if (obj[key] === null) { obj[key] = ''; } }
      return;

    })
  }
  return obj;

}

export const getCurrentDate = (format = "DD/MM/YYYY") => {
  return moment().format(format);
}

export const formatDate = (currentDateR, currentDateFormat, newDateformat) => {
  if (currentDateR === null || currentDateR === '' || currentDateR === 0) { return ''; }
  var formatedDate = '';
  switch (currentDateFormat) {
    case 'unix':
      let currentDate = new Date(currentDateR * 1000).toLocaleString("en-AU");
      formatedDate = moment(currentDate, 'DD/MM/YYYY HH:mm:ss a').format(newDateformat);  //BEFORE WE HAD DD/MM/YYYY HH:mm:ss BUT THE DATE STRING GENERATED BY LOCALAE STRING IS IN FORMAT DD/MM/YYYY HH:mm:ss a SO THATS WHY WE UPDATED THE FORMAT
      break;
    default:
      formatedDate = moment(currentDateR, currentDateFormat).format(newDateformat);
      break;
  }
  return formatedDate;
}

//date format dd/mm/yyyy
//REMOVE HOURS AND MINUTES -> SELECT ONLY FIRST 10 NUMBERS THAT REPRESENTS DATE ONLY
export const convertDateintoUnix = (date, format = "DD/MM/YYYY") => {
  var tempDate = moment(date, format).valueOf();
  var str = String(tempDate);
  str = str.substring(0, 10)
  return Number(str);
}

export const getCurrentMonth = (type) => {
  switch (type) {
    case "firstDay":
      return moment().startOf('month').format('YYYY-MM-DD H:mm:ss');
      break;
    case "lastDay":
      return moment().endOf('month').format('YYYY-MM-DD H:mm:ss');
      break;
  }
}

export const getPreviousMonth = (type) => {
  switch (type) {
    case "firstDay":
      return moment(moment()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
      break;
    case "lastDay":
      return moment(moment()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
      break;
  }
}

export const getLastDayOfMonth = (date) => {
  return moment(moment(date, "DD/MM/YYYY").valueOf()).endOf('month').format('YYYY-MM-DD HH:mm:ss');
}




/*
const formatDate = (date) => {
  console.log("DATE: " + date);

  try {
    const DateObject = parse(date, "dd/MM/yyyy", new Date());
    const formattedDate = format(DateObject, "yyyy-MM-dd");
    return formattedDate;
    //            console.log("VALID DATE FORMAT: "  + formattedDate);
  }
  catch (err) {
    return "";
  }
}

*/


//change date YYYY/MM/DD TO DD/MM/YYYY
export const dateFilter = (input) => {
  let updatedDate = '';
  if (input?.length === 10) {
    if (input?.includes('/')) {
      updatedDate = input;
    } else if (input?.includes('-')) {
      let year = input.slice(0, 4);
      let month = input.slice(5, 7);
      let day = input.slice(8, 10);
      updatedDate = day + '/' + month + '/' + year;
    }
    return updatedDate;
  }
  return input;
};

/** CALCULATE THE TIME DIFFERENCE IN HOURS = > CURRENT TIMESTAMP - PROVIDED TIMESTAMP  - TAKE UNIX ONLY **/
export const calculateTimeDifference = (unixTimeStamp) => {
  var currentUnixTimeStamp = Math.floor(new Date().getTime() / 1000);
  return Math.round((currentUnixTimeStamp - unixTimeStamp) / 3600);
}


//CALCULATE DAYS IN THE GIVEN MONTH

export const countMonthDays= (date) => {
  try{ return moment(date, "YYYY-MM").daysInMonth(); }
  catch(e){return 1;}
}

export const getPastDateTime = async(daysBack) => {
  if (typeof daysBack !== 'number' || daysBack < 0) {
      throw new Error("Invalid input: daysBack must be a non-negative number.");
  }

  const now = new Date();
  const pastDate = new Date(now.getTime() - daysBack * 24 * 60 * 60 * 1000);

  return Math.floor(pastDate.getTime() / 1000); // Converts milliseconds to seconds
}


//getStartAndEndTimeForGivenPeriod
export const getStartAndEndDateForGivenPeriod = (period, options = {}) => {

  var timeRange = {startDate: 0 , endDate: 0}
  switch (period) {
    case "currentDay":
      timeRange["startDate"] = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
      timeRange["endDate"] = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
      break;
    case "currentWeekToDate":
      moment.updateLocale('en', {  week : { dow : 1 } }); 
      timeRange["startDate"] = moment().startOf('week').format('YYYY-MM-DD HH:mm:ss ');
      timeRange["endDate"] = moment().endOf('week').format('YYYY-MM-DD HH:mm:ss');
      break;
    case "currentMonthToDate":
      timeRange["startDate"] = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
      timeRange["endDate"] = moment().format('YYYY-MM-DD HH:mm:ss'); //moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
      break;
    case "previousMonth":
      timeRange["startDate"] = moment(moment()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
      timeRange["endDate"] = moment(moment()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
      break;
    case "lastQuarter":
      timeRange["startDate"] = moment(moment()).subtract(3, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
      timeRange["endDate"] = moment(moment()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
      break;
    case "lastSixMonth":
      timeRange["startDate"] = moment(moment()).subtract(6, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
      timeRange["endDate"] = moment(moment()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
      break;
    case "lastTwelveMonths":
      timeRange["startDate"] = moment(moment()).subtract(12, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
      timeRange["endDate"] = moment(moment()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
      break;
    case "sameMonthLastYear":
      timeRange["startDate"] = moment(moment()).subtract(12, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
      timeRange["endDate"] = moment(moment()).subtract(12, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
      break;
    case "currentYearToDate":
      timeRange["startDate"] = moment().startOf('year').format('YYYY-MM-DD HH:mm:ss');
      timeRange["endDate"] = moment(moment()).endOf('month').format('YYYY-MM-DD HH:mm:ss');
      break;
    case "samePeriodLastYear":
      let startDate = options["startDate"]
      let endDate = options["endDate"]
      timeRange["startDate"] = moment(startDate).subtract(12, 'months').format('YYYY-MM-DD HH:mm:ss');
      timeRange["endDate"] = moment(endDate).subtract(12, 'months').format('YYYY-MM-DD HH:mm:ss');
      break;
     

    default: break;

    /**
     * moment().startOf('month').format('YYYY-MM-DD H:mm:ss');
      startDate = convertDateintoUnix(getCurrentMonth("firstDay") , "YYYY-MM-DD H:mm:ss") ;
      endDate = convertDateintoUnix(getCurrentMonth("lastDay"), "YYYY-MM-DD H:mm:ss");
     */
  }

  return timeRange;
}

/*
COUNT DAYS THAT ARE ELAPSED OF GIVEN MONTH
IF CURRENT MONTH THEN FIND THE DAYS ELAPSED
OTHERIWSE RETURN THE TOTAL DAYS OF GIVEN MONTH
date => YYYY-MM
*/

export const countMonthDaysElapsed = (date) => {
  //var currDate = moment.now().format("YYYY-MM");
  try{
    var currentDate = new Date().toLocaleString("en-AU");
    var currDate = moment(currentDate, 'DD/MM/YYYY HH:mm:ss').format("YYYY-MM-DD");
  
  
    var currentYear = moment(currDate , "YYYY-MM-DD").format( "YYYY");
    var currentMonth =  moment(currDate, "YYYY-MM-DD").format("M");
    var currentDay = moment(currDate, "YYYY-MM-DD").format("D");
  
    var givenDate = moment(date, "YYYY-MM");
    var dateMonth = moment(givenDate, "YYYY-MM").format( "M");
    var dateYear=   moment(givenDate, "YYYY-MM").format("YYYY");


    console.log(` currentDate: ${currentDate}  -- currDate: ${currDate}   --  currentYear : ${currentYear}   --  currentMonth: ${currentMonth}  currentDays : ${currentDay}  --- dateMonth: ${dateMonth}  -- dateYear: ${dateYear} `)

    if(currentYear === dateYear &&  currentMonth === dateMonth){
      //MONTH AND YEAR SAME THENRETURN THE CURRENT DAY (E.G 5, 27)
      return currentDay;
    }
    else{
      //NOT CURRENT MONTH SO RETURN THE TOTAL NUMBER OF DAYS OF GIVEN MONTH
      return moment(date, "YYYY-MM").daysInMonth();
    }

  }
  catch(e){ return 1;}
 
  
}

export const removeSpace = (str) =>{
  if(str !== undefined){ return str.replace(/\s/g, ''); }
  else{ return 0;}
   
}

export const capitalizeFirst = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeFirstLowercaseRest = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizeFirstOfEachWord = str => {
  str = str.toLowerCase();
  return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
};

export const getdayPeriodType = () => {
  var today = new Date()
  var curHr = today.getHours()
  var periodName  = '';
  if (curHr < 12) { periodName= 'morning'; } 
  else if (curHr < 18) { periodName='afternoon'; } 
  else { periodName='evening'; }
  return periodName;
};



export const getUniqueId = () => {
  return (new Date()).getTime().toString(36) + Math.random().toString(36).slice(2)
}

export const calculateMonthlyAmount =   (frequency, amount) => {
  var monthlyAmount = 0;
  amount = isNaN(parseFloat(amount)) ? 0 : parseFloat(amount);
  switch (frequency) {
    case "weekly": monthlyAmount = (amount * 52) / 12; break;
    case "fortnightly": monthlyAmount = (amount * 26) / 12; break;
    case "monthly": monthlyAmount = amount ; break;
    case "quarterly": monthlyAmount = (amount * 4) / 12; break;
    case "yearly": monthlyAmount = (amount) / 12; break;
  }
  return parseFloat(monthlyAmount.toFixed(2));
}

export const isValidDate = (date, format = "YYYY-MM-DD") => { 
  try{
    return moment(date, format, true).isValid(); 
  }
  catch(e){
    return 0;
  }
}

export const isEmailValid = (emailAdress) => {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
    if (emailAdress.match(regexEmail)) { return true; } else { return false; }
}