import React, { useState, useEffect } from 'react';
import { getLeads } from '../../api/leadsApi';
import { RightDrawer, SelectField, CustomButton, InputField, ConfirmDialog } from '../HtmlComponents';
import { getUsers, performApplicationAdditionalActions } from '../../api';
import { deleteLead, convertLeadToApplication } from '../../api/leadsApi';
import Pagination from '../HtmlComponents/Form/Pagination';
import Lookup from '../global/SearchForms/Lookup';
import { useToken, useEntityType, useEntityId } from '../../hooks/useAuth';
import LeadListItem from './LeadListItem';
import ConvertToApplication from './components/ConvertToApplication';
import Notifications from '../../components/global/Notifications';

const ListLeads = () => {
  const [leadsList, setLeadsList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState({ currentPageNumber: 1, q: "" });
  const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: { width: "800" } });
  const [loading, setLoading] = useState(true);
  const [analystList, setAnalystList] = useState([]);
  const token = useToken();
  const entityId = useEntityId();


  const fetchLeads = async () => {
    setLoading(true);

    let query = "";
    if (filter["currentPageNumber"]) { query = `pageNumber=${filter["currentPageNumber"]}`; }
    if (filter["q"] && filter["q"].length > 2) { query += `&q=${filter["q"]}`; }

    try {
      const response = await getLeads(token, query);
      if (response.error !== undefined && response.error === 1) {
        console.error(response.message || "Unable to fetch leads. Please contact IT");
      } else {
        const records = response.data.records;
        const paginationData = response.data.pagination;

        console.log("response :::::::::::::::::::::::::::::::::::  ", response);
        console.log("records", records);
        console.log("paginationData", paginationData);

        if (paginationData) { setTotalRecords(paginationData.totalRecords); }
        if (records) { setLeadsList(records); }
      }
    } catch (error) {
      console.error('Error fetching leads:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAnalysts = async () => {
    try {
      const response = await getUsers(token, "creditRep", entityId);
      console.log("response - anltics --------------------- >>> ", response);
      console.log(response);
      if (response["error"] == 0) {
        var users = response["data"];
        if (Array.isArray(users)) {
            var options = users.map(user => {
                return { value: user.id, label: user.firstName }
            })
            setAnalystList(options);
        }
    }
      //setAnalystList(response.data);
    } catch (error) {
      console.error('Error fetching analysts:', error);
    }
  };

  useEffect(() => {
    fetchLeads();
    fetchAnalysts();
  }, []);

  useEffect(() => {
    fetchLeads();
  }, [filter]);

  const handler = async (action, lead, selectedAnalyst) => {
    switch (action) {
      case "openViewLead":
        setDrawerSettings({ visible: true, heading: "View Lead", childName: "viewLead", meta: { lead: lead } });
        break;
      case "deleteLead":
        try {
          await deleteLead(token, lead.id);
          setLeadsList(leadsList.filter((l) => l.id !== lead.id));
          console.log('Lead deleted:', lead);
        } catch (error) {
          console.error('Error deleting lead:', error);
        }
        break;
      case "openConvertToApplication":
        setDrawerSettings({ visible: true, heading: "Convert to Application", childName: "convertToApplication", meta: { lead: lead, analystList: analystList } });
        break;
      case "closeDrawer":
        setDrawerSettings({ visible: false });
        break;
      case "convertLead":
        try {
          var response = await convertLeadToApplication(token, lead.id, selectedAnalyst);
          console.log('Lead converted to application and assigned to analyst:', lead, selectedAnalyst);
          Notifications("success", response["message"] || "Converted lead to application successfully.");
          fetchLeads();

        } catch (error) {
          Notifications("error", response["message"] || "Unable to fetch the applications.")
          console.error('Error converting lead to application:', error);
        }
        break;
      case "lookup":
        if (lead.length > 2) { setFilter({ ...filter, "q": lead, currentPageNumber: 1 }) }
        else if (lead.length === 0) { setFilter({ ...filter, "q": "", currentPageNumber: 1 }) }
        break;
      case "currentPageNumber":
        setFilter({ ...filter, "currentPageNumber": lead });
        break;
      default:
        break;
    }
  };

  const TableHeader = () => (
    <thead>
      <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
        <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">First Name</th>
        <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">Surname</th>
        <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">Email</th>
        <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">Contact Number</th>
        <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">Loan Amount</th>
        <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1">Purpose</th>
        <th className="" tabIndex="0" aria-controls="kt_customers_table" rowSpan="1" colSpan="1" style={{ width: "125px" }}>Actions</th>
      </tr>
    </thead>
  );

  const ViewLead = ({ lead }) => (
    <div>
      <p>First Name: {lead.firstName}</p>
      <p>Surname: {lead.surname}</p>
      <p>Email: {lead.email}</p>
      <p>Contact Number: {lead.contactNumber}</p>
      {/* Add more lead details here */}
    </div>
  );

  return (
    <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
      <Lookup label="" callback={handler} />

      <div className="card" data-select2-id="select2-data-132-dpd0">
        <div className="card-body pt-0">
          <div id="kt_customers_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                <TableHeader />
                <tbody className="fw-bold">
                  {leadsList && Array.isArray(leadsList) ? (
                    leadsList.map(singleLead => (
                      <LeadListItem key={singleLead.id} singleLead={singleLead} callback={handler} />
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <p>No Leads found</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination totalRecords={totalRecords} currentPageNumber={filter.currentPageNumber} totalRecordsOnpage={10} updateCurrentPageNumber={handler} />

      {drawerSettings["visible"] ? (
        <RightDrawer heading={drawerSettings["heading"]} width={drawerSettings["meta"]["width"] !== undefined ? drawerSettings["meta"]["width"] : "500"} callback={handler}>
          {drawerSettings["childName"] === "viewLead" ? (
            <ViewLead lead={drawerSettings["meta"].lead} />
          ) : null}
          {drawerSettings["childName"] === "convertToApplication" ? (
            <ConvertToApplication lead={drawerSettings["meta"].lead} analystList={analystList} callback={(action, lead, selectedAnalyst) => handler(action, lead, selectedAnalyst)} />
          ) : null}
        </RightDrawer>
      ) : null}
    </div>
  );
};

export default ListLeads;
