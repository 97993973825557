const Pagination = ({ totalRecords, currentPageNumber, totalRecordsOnpage, updateCurrentPageNumber }) => {
    const handlePageClicked = (e) => {
        e.preventDefault();
        const page = parseInt(e.currentTarget.dataset.id);
        updateCurrentPageNumber("currentPageNumber", page);
    };

    const totalPages = Math.ceil(totalRecords / totalRecordsOnpage);
    const pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1); // Array of page numbers [1, 2, 3, ...]

    // Function to generate the range of pages to display
    const getPageRange = () => {
        const range = [];
        const start = Math.max(1, currentPageNumber - 4); // Ensure start is at least 1
        const end = Math.min(totalPages, currentPageNumber + 4); // Ensure end does not exceed totalPages

        for (let i = start; i <= end; i++) {
            range.push(i);
        }

        // Ensure we always show 10 pages if possible
        if (range.length < 10) {
            if (start === 1) {
                // If we're at the beginning, add more pages at the end
                for (let i = end + 1; i <= Math.min(totalPages, 10); i++) {
                    range.push(i);
                }
            } else if (end === totalPages) {
                // If we're at the end, add more pages at the beginning
                for (let i = Math.max(1, start - (10 - range.length)); i < start; i++) {
                    range.unshift(i);
                }
            }
        }

        return range;
    };

    const pageRange = getPageRange();

    return (
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", alignItems: "center" }}>
            <div>Total Records: {totalRecords}</div>
            <div>
                <ul className="pagination pagination-circle pagination-outline">
                    {/* Previous Button */}
                    <li
                        onClick={handlePageClicked}
                        data-id={Math.max(1, currentPageNumber - 1)}
                        className={currentPageNumber === 1 ? "page-item disabled previous m-1" : "page-item previous m-1"}
                    >
                        <span className="page-link"><i className="previous"></i></span>
                    </li>

                    {/* Page Numbers */}
                    {pageRange.map((page) => (
                        <li
                            key={page}
                            onClick={handlePageClicked}
                            className={`page-item m-1 ${currentPageNumber === page ? "active" : ""}`}
                            data-id={page}
                        >
                            <span className="page-link">{page}</span>
                        </li>
                    ))}

                    {/* Next Button */}
                    <li
                        onClick={handlePageClicked}
                        data-id={Math.min(totalPages, currentPageNumber + 1)}
                        className={currentPageNumber === totalPages ? "page-item disabled next m-1" : "page-item next m-1"}
                    >
                        <span className="page-link"><i className="next"></i></span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Pagination;

/*const Pagination = ({ totalRecords, currentPageNumber, totalRecordsOnpage, updateCurrentPageNumber }) => {


    const handlePageClicked = (e) => {
        e.preventDefault()
        updateCurrentPageNumber("currentPageNumber", e.currentTarget.dataset.id);
    }

    var totalPages = Math.ceil(totalRecords / totalRecordsOnpage);
    var pagesArray = Array.from(Array(totalPages).keys());
    var pageNumberingDevider = 1;   //DECIDE WHAT WOULD BE THE NEXT NUMBER AFTER 2 (1, 10, 20, 30 OR 1,2,3,4 ETC)
    pageNumberingDevider = totalPages > 15 ? 10 : 1;
    pageNumberingDevider = pageNumberingDevider > 100 ? 50 : pageNumberingDevider;      //IF PAGES ARE MORE THAN 100 THEN REMINDER IS 50 
 

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", alignItems: "center" }}>
                <div>Total Records:  {totalRecords}</div>                 
                <div>
                    <ul className="pagination pagination-circle pagination-outline">
                        <li
                            onClick={handlePageClicked}
                            data-id={parseInt(currentPageNumber) - 1 > 1 ? parseInt(currentPageNumber) - 1 : 1}
                            className={currentPageNumber == 1 ? " page-item disabled previous m-1 " : "page-item previous m-1"} ><span className="page-link"><i className="previous"></i></span></li>
                        {
                            pagesArray.map((index) => {
                                if ((parseInt(index) + 1) == currentPageNumber) {
                                    return <li key={index} onClick={handlePageClicked} className="page-item active m-1 " data-id={parseInt(index) + 1}> <span className="page-link">  {parseInt(index) + 1} </span> </li>
                                }
                                else {
                                    if(index == 0 || index ==1 || index%pageNumberingDevider == 0){
                                        return <li key={index} onClick={handlePageClicked} className="page-item m-1 " data-id={parseInt(index) + 1}> <span className="page-link">  {parseInt(index) + 1} </span> </li>
                                    }
                                    else{
                                        return "";
                                    }
                                }
                            })
                        }
                        <li
                            onClick={handlePageClicked}
                            data-id={parseInt(currentPageNumber) + 1 > totalPages ? totalPages : parseInt(currentPageNumber) + 1}
                            className="page-item next m-1">
                            <span className="page-link"><i className="next"></i></span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Pagination;*/


/**
 * 
 *                   <div style={{    padding: "0 30px" }}>
                <ul className="pagination pagination-circle pagination-outline" style={{    marginTop: "26px" ,  justifyContent: "end"}}>
                    <li className="page-item previous disabled m-1"><a href="#" className="page-link"><i className="previous"></i></a></li>
                    <li className="page-item m-1"><a href="#" className="page-link">1</a></li>
                    <li className="page-item active m-1"><a href="#" className="page-link">2</a></li>
                    <li class="page-item "><a href="#" class="page-link">...</a></li>
                    <li className="page-item m-1"><a href="#" className="page-link">9</a></li>
                    <li className="page-item m-1"><a href="#" className="page-link">10</a></li>
                    <li className="page-item next m-1"><a href="#" className="page-link"><i className="next"></i></a></li>
                </ul>
            </div>
 */ 