
import { TextArea, FormSubmissionButton, SelectField, RadioGroup, InputField, SelectDatePicker, CheckBox, CustomButton } from "../../HtmlComponents";

import React, { useEffect, useState, useRef } from "react";

import { createSmsLog, getSmsLogs, registerForRealTimeUpdates, testEndpoint } from "../../../api";
import Notifications from "../../global/Notifications";
import { useToken } from "../../../hooks/useAuth";
import { formatDate } from "../../../helpers/";

const SmsModule = ({ entityType, entityId, applicants, callback }) => {

    const [recipients, setRecipients] = useState([]);
    const [smsPayload, setSmsPayload] = useState({
        message: "",
        recipientMobileNumber: "",
        entityId: entityId,
        entityType: entityType,
        applicantId: ""
    })
    const [messagesLoaded, setMessagesLoaded] = useState(false);
    const [allowToSend, setAllowToSend] = useState(false);
    const [messages, setMessages] = useState([])

    const latestRecipientMobileNumberRef = useRef(smsPayload.recipientMobileNumber);
    const messageBoxRef = useRef(null);

    const token = useToken();


    //LOAD THE APPLICANTS
    useEffect(() => {
        //IF ENTITY TYPE IS APPLICATION THEN ALSO FETCH THE INFORMAITON FRMO THE APPLICANTS
        const fetchApplicants = async () => {
            if (entityType === "application") {
                let applicantOptions = [];
                try {
                    for (let i = 0; i < applicants.length; i++) {
                        applicantOptions.push({
                            value: applicants[i].personalDetail.mobileNumber,
                            label: applicants[i].personalDetail.firstName + " " + applicants[i].personalDetail.surname + " (" + applicants[i].personalDetail.mobileNumber + ")",
                            id: applicants[i].id
                        })
                    }
                }
                catch (e) { console.log(e); }

                setRecipients(applicantOptions);
                console.log("applicant drop down options --- ", applicantOptions);
            }
        }
        fetchApplicants();
    }, []);

    //REAL TIME UPDATES LISTENER
    useEffect(() => {
        //REGISTER FOR REAL TIME UPDATES FROM THE SERVER
        const eventSource = registerForRealTimeUpdates(token, (newMessage) => {
            console.log("New message received: ", newMessage);
            let messageType = newMessage.type; //MESSAGE TYPE
            if(messageType !== "sms"){ return;}

            console.log("New message received  passe the return statement and now moving to add: ", newMessage);
            
            let recipientMobileNumber = latestRecipientMobileNumberRef.current   //SELECTED RECIPIENT MOBILE NUMBER
            let fromMobileNumber = newMessage.fromMobileNumber; //IT ALWAYS HAVE +61 IN THE BEGINNING

            console.log(`Recipient Mobile Number: ${recipientMobileNumber}  From Mobile Number: ${fromMobileNumber}`);
            console.log(`Recipient Mobile Number Length: ${recipientMobileNumber.length}  From Mobile Number Length:` , recipientMobileNumber === fromMobileNumber || fromMobileNumber.includes(recipientMobileNumber));

            

            //REMOVE +61 FROM THE FROM MOBILE NUMBER
            fromMobileNumber = fromMobileNumber.replace("+61", "");

            //REMOVE 0 FROM THE RECIPIENT MOBILE NUMBER
            recipientMobileNumber = recipientMobileNumber.replace("0", "");

            console.log(`recipientMobileNumber === fromMobileNumber ${recipientMobileNumber === fromMobileNumber}`);
            console.log(`fromMobileNumber.includes(recipientMobileNumber) ${fromMobileNumber.includes(recipientMobileNumber)}`);
            console.log(`${fromMobileNumber} .includes(${recipientMobileNumber}) ${fromMobileNumber.includes(recipientMobileNumber)}`);


            //CHECK IF BOTH NUMBERS ARE SAME OR IF THE RECIPIENT NUMBER IS SUBSTRING OF FROM NUMBER
            if (recipientMobileNumber.length > 8 &&
                (recipientMobileNumber === fromMobileNumber || fromMobileNumber.includes(recipientMobileNumber))
            ) {
                console.log("LINE 72 - ADDING NOTIFICAITON  ", newMessage);
                setMessages((prevMessages) => [...prevMessages, newMessage]);
                Notifications("success", "New message received: " + newMessage.message);
            }            
        });

        // Cleanup on component unmount
        return () => {
            console.log("Closing the event source");
            eventSource.close();
        }
    }, []);


    //SCROLL THE MESSAGE BOX TO LAST MESSAGE
    useEffect(() => {
        //SCROLL TO BOTTOM OF THE DIV
        messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }, [messages])

    //UPDATE THE REF IF THE RECIPIENT MOBILE NUMBER CHANGES
    useEffect(() => {
        latestRecipientMobileNumberRef.current = smsPayload.recipientMobileNumber;
    }, [smsPayload.recipientMobileNumber]);

    //MONITOR CHANGE IN THE STATE OF smsPayload
    useEffect(() => {
        //CEHCK IF ALL REQUIRED FIELDS ARE FILLED
        if (smsPayload.message.length == 0 || smsPayload.recipientMobileNumber.length == 0) { setAllowToSend(false); }
        else if (["application"].includes(entityType) && smsPayload.applicantId == "") { setAllowToSend(false); }
        else { setAllowToSend(true); }
    }, [smsPayload])



    const handler = (name, value) => {
        setSmsPayload((preValues) => ({ ...preValues, [name]: value }));

        //CHECK IF THE NAME IS recipientMobileNumber  AND THE ENTITY TYPE IS APPLICATION THEN FETCH THE APPLICANT ID FROM THE recipients ARRAY
        if (name === "recipientMobileNumber" && entityType === "application") {
            let applicantId = "";
            for (let i = 0; i < recipients.length; i++) {
                if (recipients[i].value === value) {
                    applicantId = applicants[i].id;
                    break;
                }
            }
            setSmsPayload((preValues) => ({ ...preValues, ["applicantId"]: applicantId }));
            fetchSmsLogs(entityType, entityId, applicantId);
        }
    }

    const fetchSmsLogs = async (entityType, entityId, applicantId = 0) => {
        if (["application"].includes(entityType) && applicantId == 0) { return; }
        const response = await getSmsLogs(token, entityType, entityId, applicantId);
        if (response.error === 0) {
            setMessages(response.smsLogs);
            setMessagesLoaded(true); // ENABLE THE FLAG THAT MESSAGE ARE LOADED
        }
    }

    //SEND SMS 
    const sendSMS = async () => {
        const response = await createSmsLog(token, smsPayload);

        if (response.error === 0) {
            Notifications("success", response["message"]);
            fetchSmsLogs(entityType, entityId, smsPayload.applicantId);
            setSmsPayload({ ...smsPayload, message: "" });
        }
        else {
            var errorArray = response.errorArray;
            var errorArrayString = errorArray.join("\n");
            var errorMessage = errorArrayString.length > 0 ? errorArrayString : response.message;
            Notifications("error", errorMessage);
        }
    }

    const DisplayMessagesPlaceholder = () => {
        if (smsPayload.recipientMobileNumber.length === 0) {
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <label style={{ fontSize: "30px" }}>Select an applicant to view messages</label>

                </div>
            )

        }
        else if (!messagesLoaded && smsPayload.recipientMobileNumber.length !== 0) {
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <label style={{ fontSize: "30px" }}>Loading...</label>

                </div>
            )
        }
        else if (messagesLoaded && messages.length === 0) {
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <label style={{ fontSize: "30px" }}>No messages to display</label>

                </div>
            )
        }
        else {
            //DISPLAY THE MESSAGES IN A FASHION THAT THE MESSAGES SENT BY APPLICANT SHOULD BE LEFT SIDE AND THE MESSAGE BY SYSTEM SHOULD BE RIGHT SIDE
            return "";
        }
    }

    return (
        <div style={{ height: "100vh" }}>
            <div>                
                <label>Select Applicant</label>
                <SelectField
                    label="Select Applicant"
                    fieldName="recipientMobileNumber"
                    options={recipients}
                    setFieldValue={handler}
                    defaultSelectedOption={{ value: smsPayload["recipientMobileNumber"], label: "Select Applicant" }}
                />
            </div>
            <div>
                <div
                    ref={messageBoxRef}
                    style={{
                        height: "50vh",
                        border: "1px solid black",
                        borderRadius: "10px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        overflowY: "auto",

                    }}>


                    <DisplayMessagesPlaceholder />
                    {
                        messages.map((record, index) => {
                            return (
                                <div key={index} style={{ marginTop: "10px" }}>
                                    {
                                        record.sender !== "recipient" ?
                                            <div className="chat-container">
                                                <div className="message sender">{record.message}</div>
                                                <span className="sender-time-stamp"> {record.sender}   -  {formatDate(record.timeStamp, "unix", "DD/MM/YYYY hh:mm:s")}</span>
                                            </div> : ""
                                    }
                                    {
                                        record.sender === "recipient" ?
                                            <div className='chat-container' >
                                                <div className="message receiver">{record.message}</div>
                                                <span className="receiver-time-stamp"> {record.sender}   -  {formatDate(record.timeStamp, "unix", "DD/MM/YYYY hh:mm:s")}</span>
                                            </div> : ""
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div>
                <TextArea
                    fieldName={"message"}
                    placeholder={"Enter your message here"}
                    fieldValue={smsPayload["message"]}
                    setFieldValue={handler}
                />
            </div>
            <div>
                <CustomButton
                    buttonText="Send"
                    isActive={allowToSend === true ? true : false}
                    buttonStyle={{ float: "right", marginTop: "20px" }}
                    buttonClassName={"btn-primary btn-sm"}
                    buttonOnClick={(e) => sendSMS()}
                />

            </div>

            <div>
                {
                    allowToSend === true ? <div style={{ marginTop: "10px" }}><label style={{ color: "green" }}>Ready to send</label></div> : <div style={{ marginTop: "10px" }}><label style={{ color: "red" }}>Fill all the required fields</label></div>
                }
            </div>
        </div>
    );
};

export default SmsModule;