import { EventSourcePolyfill } from 'event-source-polyfill';
import axios from './axios';



const registerForRealTimeUpdates = (token, onMessage) => {
  var baseURL = process.env.REACT_APP_ENV == "DEVELOPMENT" ? process.env.REACT_APP_DEVELOPMENT_BACKENDENDPOINT : process.env.REACT_APP_BACKENDENDPOINT;
  var endPoint = "sse/registerForLiveUpdates";
  var endpointFullPath = baseURL + endPoint;
  //SEND OPTION REQUEST TO THE BROWSER TO ALLOW CROSS ORIGIN REQUEST
  /*await axios.options(
    endPoint, 
    {
       method: 'OPTIONS'
    }
  )*/

    //REGISTER FOR REAL TIME UPDATES FROM THE SERVER
    const eventSource = new EventSourcePolyfill(baseURL + "sse/registerForLiveUpdates/", {
        headers: {
            Authorization: `Bearer ${token}`
        },
        heartbeatTimeout: 1200000,
    });

    eventSource.onopen = () => console.log(">>> Connection opened!");
    eventSource.onerror = (e) => console.log("ERROR!", e);

    eventSource.onmessage = (event) => {
      console.log("EVENT DATA", event.data)
        onMessage(JSON.parse(event.data)); // Pass the received message to the onMessage callback - Assuming the message is JSON
    };

    // Return the event source so it can be closed later
    return eventSource;
};

const testEndpoint = async(token) => {

  try{
    console.log("URL" , `sse/registerForLiveUpdates`)
    const response = await axios.get(`sse/registerForLiveUpdates/`, { headers: { Authorization: "Bearer " + token } });
    return  { "error": 0, data : response.data ? response.data : [] , httpCode : response.status}
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data , httpCode : e.response.status, data : []}
    }
  }
}

export { registerForRealTimeUpdates, testEndpoint };

/*
BELOW CODE IS TO MAKE THIS MANAGER A CENTRAL POINT WHO HAVE LIST OF ALL LISTENERS AND CAN SEND THE RESPONSE TO MULTIPLE LISTENERS
const eventSourceInstance = {
    eventSource: null,
    listeners: {},
  };
  
  export const createEventSource = (url) => {
    if (eventSourceInstance.eventSource) {
      // If an EventSource already exists, return it
      return eventSourceInstance.eventSource;
    }
  
    eventSourceInstance.eventSource = new EventSource(url);
  
    eventSourceInstance.eventSource.onmessage = (event) => {
      const newMessage = JSON.parse(event.data);
      
      // Call the registered listener for the message type
      if (eventSourceInstance.listeners[newMessage.type]) {
        eventSourceInstance.listeners[newMessage.type](newMessage);
      }
    };
  
    return eventSourceInstance.eventSource;
  };
  
  export const registerListener = (type, handler) => {
    eventSourceInstance.listeners[type] = handler;
  };
  
  export const unregisterListener = (type) => {
    delete eventSourceInstance.listeners[type];
  };
  
  export const closeEventSource = () => {
    if (eventSourceInstance.eventSource) {
      eventSourceInstance.eventSource.close();
      eventSourceInstance.eventSource = null;
      eventSourceInstance.listeners = {};
    }
  };
  
  */