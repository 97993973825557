import React, { useEffect } from 'react';
import { useState } from 'react';
import { createReportingBookmark } from '../../../../api';

import { InputField, CustomButton, SubmitButton } from '../../../HtmlComponents';
import { useToken } from '../../../../hooks/useAuth';
import Notifications from '../../../global/Notifications';

const CreateBookmark = ({ searchFilters , callback }) => {

    const token = useToken();
    const [bookmark, setBookmark] = useState({
        name: "",
        payload: {
            reportFor: "",
            reportForExtraInfo: [],
            stat: "",
            statExtraInfo: { subStat: '', pieChart: false },
            periodicType: "",
            period: { name: "", startDate: "", endDate: "" },
            comparePeriod: { name: "", startDate: "", endDate: "" }
        }
    })

    const createReportingBookmarkHandler = async () => {
        const response = await createReportingBookmark(token, bookmark);
        if(response.error === 0){
            Notifications("success" , response.message || "Created Successfully.");
            callback("reloadBookmarks")
        }
        else { Notifications("error" , response.message || "Error"); }
    }

    const handler= (name, value) => {
        switch (name) {
            case "name":
                value = value.trim();
                //CHECK IF THE NAME OCNTAINS ONLY ALPHABETS, NUMBERS, AND SPACES
                if (value.match(/^[a-zA-Z0-9 -]*$/)) {
                    setBookmark({ ...bookmark, name: value });
                } else {
                    setBookmark({ ...bookmark, name: "" });
                }
                break;
        }
    }

    useEffect(() => {
        console.log(searchFilters);
        setBookmark({ ...bookmark, payload: searchFilters })
    }, [])

    useEffect(() => {
    }, [bookmark])

    return (
        <div>
            <div className='row'>
                <div className='col-sm-12'>
                    <label className="form-label">Bookmark Name</label>
                    <InputField
                        label="Name"
                        type="text"
                        value={bookmark.name}
                        setFieldValue={(fieldName, value) => handler( "name",value)}
                    />
                </div>
                <div className='col-sm-12 mt-3'>
                    <CustomButton   
                        buttonText="Create Bookmark"
                        buttonClassName="btn btn-primary btn-sm btn-hover-rise me-5"
                        isActive={ bookmark.name.trim().length > 0 ? true : false}
                        buttonStyle={{ float: "right" }}

                        buttonOnClick={() => createReportingBookmarkHandler()}                        
                    />
                </div>
            </div>

        </div>
    )
}

export default CreateBookmark;